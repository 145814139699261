import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Product } from '../types/Product/interface';
import ProductItem from '../components/ProductItem';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { mainStore } from '../stores/MainStore';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Scrollbar]);

interface RecommendProductsSlider {
  sku: string[];
  source: string;
  className?: string;
}

export default observer(({ sku, source, className }: RecommendProductsSlider) => {
  const { t } = useTranslation();
  const [recommendProducts/*, setRecommendProducts*/] = useState<Product[]>([]);
  const [isLoading/*, setIsLoading*/] = useState(false);
  //const previousSku = usePrevious(sku);

  // TODO: need fix
  // useEffect(() => {
  //   if (!sku.length || isEqual(sku, previousSku)) return;
  //   setIsLoading(true);
  //   catalogStore
  //     .requestRecommendProducts(sku, source === 'reco_cart' ? 'cart' : 'card')
  //     .then((products) => {
  //       setRecommendProducts((products || []).map((item) => catalogStore.calcProductFields(item)));
  //     })
  //     .catch((error) => error && console.error(error))
  //     .finally(() => setIsLoading(false));
  //   // eslint-disable-next-line
  // }, [sku]);

  if (!isLoading && !recommendProducts.length) return <></>;

  if (mainStore.isDesktop) {
    return (
      <div className={className}>
        <div className="fs-16 fw-500 lh-36 my-8 pl-24">{t('youMightAlsoLike')}</div>
        {!isLoading ? (
          <Swiper
            direction="horizontal"
            // slidesPerView={source === 'reco_card' ? 4.25 : 3}
            slidesPerView="auto"
            spaceBetween={10}
            scrollbar={{ draggable: true, hide: true }}
            freeMode={true}
            mousewheel={false}
          >
            <SwiperSlide className="scroll-list _swiper pb-8">
              {recommendProducts.map((item) => (
                <ProductItem product={item} source={source} isWithinSlider key={item.id} />
              ))}
            </SwiperSlide>
          </Swiper>
        ) : (
          <div className="scroll-list _24 hide-scroll-bar pb-8 mb-n8">
            {[0, 1, 2, 3].map(
              (i) => <Skeleton className="product-item br-5 mr-16 h-150" key={i} />)}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="fs-16 fw-500 lh-36 my-8">{t('youMightAlsoLike')}</div>
      <div className="scroll-list _24 hide-scroll-bar pb-8 mb-n8 mx-n24">
        {!isLoading
          ? recommendProducts.map((item) => (
            <ProductItem product={item} source={source} isWithinSlider key={item.id} />
          )) : [0, 1, 2, 3].map((i) => (
            <Skeleton className="product-item br-5 mr-16 h-150" key={i} />
          ))}
      </div>
    </div>
  );
});
