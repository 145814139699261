import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import PopoverETA from '../PopoverETA';
import PopoverInvalidPromocode from '../PopoverInvalidPromocode';
import { mainStore } from '../../stores/MainStore';
import { catalogStore } from '../../stores/CatalogStore';
import { orderStore } from '../../stores/OrderStore';
import BoardInfo from './BoardInfo/BoardInfo';
import PopoverServiceFee from '../PopoverServiceFee';
import { checkoutStore } from '../../stores/CheckoutStore';
import i18n from 'i18next';
import { company } from '../../company/Company';

type BoardProps = {
  isCartPage: boolean;
};

const Board = observer(({ isCartPage }: BoardProps) => {
  const { t } = useTranslation();

  const checkCart = (e: React.MouseEvent) => {
    mainStore.sendToRN('hapticFeedback', {
      count: 1,
    });

    mainStore.sendAnalytics(['analytics'], {
      name: `Loyalty: ${company.config.name} points`,
      params: {
        Choice: checkoutStore.useBonuses ? 'Spend' : 'Keep_collecting',
      },
    });

    if (catalogStore.promocode.errorType === 'error' || (!catalogStore.isPromoCodeApplied && catalogStore.promocode.coupon?.type)) {
      e.preventDefault();
      mainStore.setIsInvalidPromocodePopover(true);
      return;
    }
    if (
      catalogStore.promocode.success &&
      mainStore.isZero(catalogStore.totalCartPrice.promocodeDiscount) &&
      catalogStore.promocode.coupon?.minimumPurchase
    ) {
      e.preventDefault();
      mainStore.setIsInvalidPromocodePopover(true);
      return;
    }

    if (catalogStore.calculationProcess.isError) {
      e.preventDefault();
      mainStore.pushAlert('error', i18n.t('errors:unableCreateOrder'));
      return;
    }

    if (mainStore.convertPoundsToPence(catalogStore.finalPrice) < 50) {
      e.preventDefault();
      mainStore.setIsZeroCartPopover(true);
    }
  };
  const handleSendHaptic = () => {
    mainStore.sendToRN('hapticFeedback', {
      count: 1,
    });
  };

  if (isCartPage && !catalogStore.cart.length) return <></>;

  return (
    <div
      className={htmlClasses('cart-board', {
        _expanded: catalogStore.cart.length,
      })}
    >
      {(!isCartPage || !catalogStore.isMinimalOrderFeePassed) && <BoardInfo />}
      {catalogStore.cart.length > 0 && (
        <Link
          className={htmlClasses(
            'cart-board__btn-checkout button _primary fs-16 w-100p px-0',
            isCartPage && {
              _disabled: !orderStore.etaCalculation?.warehouse.availability.availableNow || !catalogStore.isMinimalOrderFeePassed,
            },
          )}
          to={isCartPage ? '/checkout' : '/cart'}
          onClick={isCartPage ? checkCart : handleSendHaptic}
        >
          {orderStore.etaCalculation?.warehouse.availability.availableNow ? (
            <div className="fs-14 w-100p pl-14">
              {orderStore.etaCalculation?.duration.range.replace(/\s-\s/gi, '–') || '?'}
              {' '}
              {t('min')}
            </div>
          ) : (
            <div className="w-100p" />
          )}
          <div className="text-center w-100p fw-possibly-bold">{t('checkout')}</div>
          {catalogStore.calculationProcess.isLoading ? (
            <div className="w-100p text-right">
              <div className="pr-14 d-flex justify-content-end">
                <span className="spinner _white" />
              </div>
            </div>
          ) : (
            <div className="w-100p text-right">
              {isCartPage ? (
                <div className="pr-14">
                  {mainStore.addCurrencySymbol(catalogStore.finalPrice || '0')}
                </div>
              ) : (
                <div className="pr-14">
                  {mainStore.addCurrencySymbol(catalogStore.totalCartPrice.paidWithDiscount || '0')}
                </div>
              )}
            </div>
          )}

        </Link>
      )}
    </div>
  );
});

export default observer(() => {
  const { pathname } = useLocation();

  if (!orderStore.etaCalculation) return <></>;

  if (
    !pathname.match(/^\/(category|product|search|cart|favorites|banner|bestsellers|discounts|$)/g)
  )
    return <PopoverETA />;

  return (
    <>
      <Board isCartPage={pathname.match(/^\/cart/g) !== null} />
      <PopoverInvalidPromocode />
      <PopoverETA />
      <PopoverServiceFee />
    </>
  );
});
