import { observer } from 'mobx-react-lite';
import googlePlay from '../assets/img/googlePlayStore.svg';
import appstore from '../assets/img/appstore.svg';
import instagram from '../assets/img/instagram.svg';
import facebook from '../assets/img/facebook.svg';
import Dialog from './Dialog';
import { desktopStore } from '../stores/DesktopStore';
import QRCode from '../assets/img/qr-code-shop-jiffy.jpg';
import { mainStore } from '../stores/MainStore';
import { useTranslation } from 'react-i18next';
import { CompanyName } from '../company/interface';
import { company } from '../company/Company';
import LogoJiffy from '../assets/img/logo_jiffy_white.svg';
import LogoBaqal from '../assets/img/logo_baqal.svg';
import LogoSwifftW from '../assets/img/logo_swifft_w.png';

const logo: Record<CompanyName, string> = {
  [CompanyName.Jiffy]: LogoJiffy,
  [CompanyName.Baqal]: LogoBaqal,
  [CompanyName.Swifft]: LogoSwifftW,
};

export default observer(() => {
  const { t } = useTranslation();

  return (
    <div className="footer">
      <div className="footer__constraint">
        <div className="footer__logo">
          <img src={logo[company.name]} alt="" />
          {company.isIncludedIn([CompanyName.Jiffy]) &&
            <span className="fs-12 c-white">© Cloud Retail LTD</span>
          }
        </div>
        <div className="footer_info">
          <div className="footer__links">
            <div>
              {company.config.links.terms &&
                <a href={company.config.links.terms} target="_blank" rel="noreferrer">
                  {t('footer:termsAndConditions')}
                </a>
              }
              {company.config.links.privacy &&
                <a href={company.config.links.privacy} target="_blank" rel="noreferrer">
                  {t('footer:privacyPolicy')}
                </a>
              }
              {company.config.links.deliveryZones &&
                <a href={company.config.links.deliveryZones} target="_blank" rel="noreferrer">
                  {t('footer:deliveryZomes')}
                </a>
              }
            </div>
            <div>
              {company.config.links.cookie &&
                <a href={company.config.links.cookie} target="_blank" rel="noreferrer">
                  {t('footer:cookiePolicy')}
                </a>
              }
              {company.config.links.forRiders &&
                <a href={company.config.links.forRiders} target="_blank" rel="noreferrer">
                  {t('footer:forRiders')}
                </a>
              }
              <a href={`mailto:${company.config.email}`} target="_blank" rel="noreferrer">
                {company.config.email}
              </a>
            </div>
          </div>
          <div className="footer__socials">
            {!company.isIncludedIn([CompanyName.Swifft]) &&
              <>
                <a
                  className="d-flex flex-center s-30"
                  href="https://www.facebook.com/jiffygroceryuk"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebook} alt="" />
                </a>
                <a
                  className="d-flex flex-center s-30 ml-10"
                  href="https://www.instagram.com/jiffygrocery/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagram} alt="" />
                </a>
              </>
            }
          </div>
        </div>
        <div className="footer__apps">
          {!company.isIncludedIn([CompanyName.Swifft]) &&
            <>
              <img
                src={appstore}
                alt="appstore"
                onClick={() => desktopStore.setShowQR(true)}
                className="cursor-pointer"
              />
              <img
                src={googlePlay}
                alt="googlePlay"
                onClick={() => desktopStore.setShowQR(true)}
                className="cursor-pointer"
              />
            </>
          }
        </div>
      </div>
      <Dialog
        show={desktopStore.showQR}
        onBackdrop={() => desktopStore.setShowQR(false)}
        maxWidth="500px"
      >
        <div className="h-42" />
        <div className="w-100p d-flex justify-content-center mb-16">
          <img
            src={QRCode}
            width="200"
            alt=""
          />
        </div>
        <div className="ff-manrope text-center lh-26 px-25 fw-500">
          Download the app with QR-code and get {mainStore.addCurrencySymbol(
          20)} to spend by promocode
        </div>
        <div
          style={{ color: '#9ed00d' }}
          className="fs-22 px-25 pb-20 fw-500 text-center"
        >
          SPEEDY20
        </div>
      </Dialog>
    </div>
  );
});
