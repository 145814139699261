import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from './ui/Icon/Icon';

export default observer(() => {
  const handleOpenFC = () => {
    window.fcWidget?.show();
    window.fcWidget?.open();
  };

  useEffect(() => {
    return () => {
      window.fcWidget?.hide();
      window.fcWidget?.close();
    };
  }, []);

  return (
    <>
      <div
        className="desktop-chat flex-center"
        onClick={handleOpenFC}
      >
        <Icon type="fchat" size={32} className="d-flex flex-center desktop-chat-icon" />
        <div className={'desktop-chat__window'} />
      </div>
    </>
  );
});
