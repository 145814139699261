import React, { useLayoutEffect, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { mainStore } from '../stores/MainStore';
import {
  Link,
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
  matchPath,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CartList, EmptyCart } from './Cart';
import { catalogStore } from '../stores/CatalogStore';
import htmlClasses from 'html-classes';
import { userStore } from '../stores/UserStore';
import LineThroughWrap from '../components/LineThroughWrap';
import IllustrationClock from '../assets/img/clock.svg';
import DesktopCatalog from './DesktopCatalog';
import Dialog from '../components/Dialog';
import { desktopStore, urlModalQueryName } from '../stores/DesktopStore';
import Profile from './Profile';
import Orders from './Orders';
import Favorites from './Favorites';
import PaymentMethods from './PaymentMethods';
import About from './About';
import Auth from './Auth/Auth';
import Checkout from './Checkout';
import DeliveryAddress from './DeliveryAddress/DeliveryAddress';
import Notifications from './Notifications';
import OutsideAlerter from '../components/OutsideAlerter';
import Product from './Product';
import { orderStore } from '../stores/OrderStore';
import Status, { Awaiting } from './Status';
import Inject from './Inject';
import Order from './Order';
import DesktopSearch from './DesktopSearch';
import Skeleton from 'react-loading-skeleton';
import BoardInfo from '../components/CartBoard/BoardInfo/BoardInfo';
import { scrollTo } from '../UI';
import PopoverETA from '../components/PopoverETA';
import PopoverOutStock from '../components/PopoverOutStock';
import { BannersList } from './Main';
import Discounts from './Discounts';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import PageBanner from './Banner';
import PopoverNotFindProduct from '../components/PopoverNotFindProduct';
import DesktopSearchBar from '../components/DesktopSearchBar';
import DesktopChat from '../components/DesktopChat';
import { Scrollbars } from 'react-custom-scrollbars-2';
import PopoverInvalidPromocode from '../components/PopoverInvalidPromocode';
import PopoverAgeRestriction from '../components/PopoverAgeRestriction';
import PopoverChangeAddress from '../components/PopoverChangeAddress';
import DeliveryTracking from './DeliveryTracking';
import WarehouseTracking from './WarehouseTracking';
import PopoverNeedChangeAddress from '../components/PopoverNeedChangeAddress';
import IconMapPoint from '../assets/img/map_point_red.svg';
import RecommendProductsSlider from './RecommendProductsSlider';
import OrderStatusWidget from '../components/OrderStatusWidget';
import Alerts from '../components/Alerts';
import ReferralPage from './ReferralPage';
import PopoverServiceFee from '../components/PopoverServiceFee';
import JiffyPoints from './JiffyPoints/JiffyPoints';
import i18n from 'i18next';
import {
  JiffyPointsCashbackWidget,
} from './Cart/JiffyPointsCashbackWidget/JiffyPointsCashbackWidget';
import {
  JiffyPointsUseBonusesToggler,
} from './Cart/JiffyPointsUseBonusesToggler/JiffyPointsUseBonusesToggler';
import CategoryList from './Main/CategoryList/CategoryList';
import { checkoutStore } from '../stores/CheckoutStore';
import PointsPopover from './Cart/JiffyPointsUseBonusesToggler/PointsPopover/PointsPopover';
import Icon from '../components/ui/Icon/Icon';
import LogoJiffy from '../assets/img/logo_jiffy.svg';
import LogoBaqal from '../assets/img/logo_baqal.svg';
import LogoSwifftB from '../assets/img/logo_swifft_b.png';
import { CompanyName } from '../company/interface';
import { company } from '../company/Company';

const logo: Record<CompanyName, string> = {
  [CompanyName.Jiffy]: LogoJiffy,
  [CompanyName.Baqal]: LogoBaqal,
  [CompanyName.Swifft]: LogoSwifftB,
};

const Catalog = observer(() => {
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = useLocation<{ isDeeplink: boolean }>();
  const match = matchPath<{ slug: string }>(pathname, {
    path: '/category/:slug',
    exact: true,
    strict: false,
  });

  useLayoutEffect(() => {
    catalogStore.requestCategories();
  }, [history.location.pathname]);

  if (match !== null && catalogStore.subCategories !== null)
    return (
      <>
        <div className={'mr-50 w-min-250'}>
          <div className="position-sticky t-104">
            <Link
              className="desktop__back c-mgray"
              to={'/'}
              onClick={() => {
                desktopStore.setSelectedCategory('');
                desktopStore.setSelectedSubCat('');
              }}
            >
              <span className="icon icon-chevron-left s-10 mr-8 fs-10" />
              <div>{t('backToMain')}</div>
            </Link>
            <div className="desktop__title mb-18  text-break-word">
              {
                catalogStore.categoryList
                  ?.flatMap((o) => o.subcategory)
                  .find((o) => match?.params.slug === String(o.id))?.name
              }
            </div>
            {catalogStore.subCategories.map((item, i) => (
              <div className="category-element" key={i}>
                <span
                  className={htmlClasses(
                    'd-flex align-items-center fs-14 cursor-pointer mb-8',
                    { 'c-udgray': item.name !== desktopStore.selectedSubCat },
                    { 'c-blue': item.name === desktopStore.selectedSubCat },
                  )}
                  onClick={(e) => {
                    mainStore.sendToRN('analytics', {
                      name: 'Catalog: category visited',
                      params: {
                        lvl3_category_id: item.id,
                        source: 'catalog',
                      },
                    });
                    const el = document.querySelector(
                      `.product-list__header#subcategory-${item.id}`,
                    );
                    e.preventDefault();
                    desktopStore.setSelectedSubCat(item.name);
                    el && el.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }}
                >
                  <div className="pl-24 text-break-word w-max-100p">{item.name}</div>
                </span>
              </div>
            ))}
          </div>
        </div>
      </>
    );

  if (catalogStore.categoryList !== null)
    return (
      <>
        <div className={'position-sticky t-104 mr-50 w-min-250'}>
          <div className="desktop__title mb-18">{t('catalog')}</div>
          {catalogStore.categoryList.map(
            (item) =>
              item.subcategory &&
              item.subcategory.length > 0 &&
              item.subcategory.map((item, i) => (
                <div className="category-element" key={i}>
                  <Link
                    to={`/category/${item.id}`}
                    className={htmlClasses('d-flex align-items-center fs-14 mb-8 c-udgray')}
                    onClick={() => {
                      desktopStore.setSelectedCategory(item.name || '');
                      mainStore.sendAnalyticsToFirebase({
                        name: 'catalog_category_clicked',
                        params: {
                          category: item.id,
                          source: 'web_homepage',
                        },
                      });
                      scrollTo('desktop', 0, 100);
                    }}
                  >
                    {/*{item.tile_size === 1 ? (
                      <img
                        className={'desktop-categories__preview'}
                        src={item.preview_image?.path || ''}
                        alt=""
                      />
                    ) : (
                      <div
                        className="desktop-categories__preview-2" style={{ backgroundImage: item.preview_image?.path ? `url(${item.preview_image.path})` : 'none' }}
                      />
                    )}*/}
                    {item.name}
                  </Link>
                </div>
              )),
          )}
        </div>
      </>
    );

  return (
    <>
      {Array(4)
        .fill(null)
        .map((item, i) => (
          <div className="category-element" key={i}>
            {i > 0 && <div className="pe-n h-1 c-bg-tf2" />}
            <Skeleton className="h-48 d-flex align-items-center c-udgray fs-16 c-bg-white pl-15" />
          </div>
        ))}
    </>
  );
});

const Default = observer(() => {
  return (
    <>
      <BannersList />
      <CategoryList />
    </>
  );
});

export default observer(() => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [skuList, setSkuList] = useState<string[]>([]);
  const matchCheckout = useRouteMatch('/checkout');
  const matchStatus = useRouteMatch('/status/:id');

  useEffect(() => {
    if (location.pathname === '/') {
      mainStore.sendToRN('analytics', {
        name: 'Catalog: home page visited',
        params: {
          warehouse_code: orderStore.etaWarehouseCode,
        },
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    mainStore.clearAllCache();
    catalogStore.requestCategories();
    catalogStore.requestBanners();
    catalogStore.requestSearchHistory().catch(() => void 0);
    //eslint-disable-next-line
  }, [orderStore.etaWarehouseCode]);

  useLayoutEffect(() => {
    desktopStore.setSearchQuery(new URLSearchParams(location.search));
    const modalName = desktopStore.searchQuery.get('modal') as
      | typeof urlModalQueryName[number]
      | null;
    if (modalName) {
      desktopStore.urlModalQueryMap[modalName]();
      const selectedId = desktopStore.searchQuery.get('id');
      if (selectedId) {
        desktopStore.setSelectedId(selectedId);
      }
    }
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    desktopStore.setSearchQuery(new URLSearchParams(location.search));
    const modalName = desktopStore.searchQuery.get('modal') as
      | typeof urlModalQueryName[number]
      | null;
    const selectedId = desktopStore.searchQuery.get('id');
    if (modalName) {
      desktopStore.closeAllModals();
      desktopStore.urlModalQueryMap[modalName]();
      if (selectedId) {
        desktopStore.setSelectedId(selectedId);
      }
    } else {
      desktopStore.setSelectedId('');
      desktopStore.closeAllModals();
    }
    //eslint-disable-next-line
  }, [location]);

  useLayoutEffect(() => {
    setIsOpen(orderStore.etaCalculation?.warehouse.availability.availableNow || false);
    //eslint-disable-next-line
  }, [orderStore.etaCalculation?.warehouse.availability.availableNow]);

  useEffect(() => {
    setSkuList(catalogStore.cart.map((el) => el.sku));
    //eslint-disable-next-line
  }, [catalogStore.cart.length]);

  const handleShowServiceFeePopover = () => mainStore.setIsShowServiceFeePopover(true);

  const checkCart = (e: React.MouseEvent): void => {
    if (catalogStore.promocode.errorType === 'error') {
      e.preventDefault();
      mainStore.setIsInvalidPromocodePopover(true);
      return;
    }
    if (
      catalogStore.promocode.success &&
      catalogStore.totalCartPrice.promocodeDiscount === '0' &&
      mainStore.convertPoundsToPence(
        catalogStore.promocode.coupon?.value || 0,
      )
    ) {
      e.preventDefault();
      mainStore.setIsInvalidPromocodePopover(true);
      return;
    }

    if (catalogStore.calculationProcess.isError) {
      e.preventDefault();
      mainStore.pushAlert('error', i18n.t('errors:unableCreateOrder'));
      return;
    }

    if (
      mainStore.convertPoundsToPence(
        catalogStore.finalPrice,
      ) < 50
    ) {
      e.preventDefault();
      mainStore.setIsZeroCartPopover(true);
    }

    mainStore.sendToRN('analytics', {
      name: 'Loyalty: Jiffy points',
      params: {
        Choice: checkoutStore.useBonuses ? 'Spend' : 'Keep_collecting',
      },
    });
  };

  const getDeliveryStatus = (): string => {
    if (userStore.personalData.freeDeliveryDaysLeft > 0) {
      return t('cartBoard:freeDeliveryDaysLeft', {
        count: userStore.personalData.freeDeliveryDaysLeft,
      });
    }
    if (catalogStore.isFreeDelivery) {
      return t('cartBoard:freeDelivery');
    }
    return `${mainStore.addCurrencySymbol(0)} – ${mainStore.addCurrencySymbol(
      orderStore.fee.shippingPounds || 0)}`;
  };

  const handleLogout = (): void => {
    userStore.logout().catch((error) => error && console.error(error));

    if (matchCheckout || matchStatus) {
      history.push('/');
    }
  };

  const getBonusesCanUse = (): number => {
    return catalogStore.totalCartPrice.bonusesToSpentPounds;
  };

  return (
    <div className="desktop scroll-layout">
      <div className="position-absolute h-0 w-0 overflow-hidden">
        <OrderStatusWidget />
      </div>
      <Scrollbars>
        <div className="desktop-main-wrapper position-fixed z-12">
          <div className="desktop-header align-items-center mb-20 flex-shrink-0 c-bg-white">
            <img
              className="h-48 mr-20 cursor-pointer"
              onClick={() => history.push('/')}
              src={logo[company.name]}
              alt=""
            />
            <div className="mr-auto position-relative">
              <button
                className="button c-bg-white border-border h-40 px-12"
                onClick={() => desktopStore.setShowMenu(!desktopStore.showMenu)}
              >
                {!desktopStore.showMenu ? (
                  <div className="icon icon-burger c-blue s-22 d-flex flex-center fs-20 mr-8">
                    <div className="dash" />
                  </div>
                ) : (
                  <span className="icon icon-close c-blue s-22 d-flex flex-center fs-20 mr-8" />
                )}
                <span className="fw-500 fs-14 c-blue">{t('menu')}</span>
              </button>
              <OutsideAlerter onOutsideClick={() => desktopStore.setShowMenu(false)}>
                <div
                  className={htmlClasses(
                    'desktop-menu-container position-absolute c-bg-white pt-10 mt-6 br-5',
                    desktopStore.showMenu ? '_open' : '',
                  )}
                >
                  <div>
                    {desktopStore.menuItems.map(
                      (item, i) => item.isHide ? <React.Fragment key={i} /> : (
                        <div key={i}>
                          {i > 0 && <div className="pe-n h-1 c-bg-tf2" />}
                          <div
                            className="h-48 w-230 d-flex align-items-center c-udgray fs-16 cursor-pointer desktop-menu-icon"
                            onClick={item.onClick}
                          >
                            {item.icon.isSvg ? (
                              <Icon type={item.icon.name} size={24} className="d-flex flex-center ml-12 mr-20" />
                            ) : (
                              <div
                                className={`icon icon-${item.icon.name} d-flex flex-center s-24 fs-24 c-udgray mr-20`}
                              />
                            )}
                            {item.title}
                            {item.icon.name === 'jiffy-point' && (
                              <div className="ml-auto mr-12">
                                {userStore.isAuthorized ? (
                                  <div className="fs-16 fw-500 lh-36 ">
                                    {userStore.bonuses}
                                  </div>
                                ) : (
                                  <div className="c-bg-corange c-white fw-500 fs-16 lh-22 px-8 py-3 br-22">
                                    {t('new')}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </OutsideAlerter>
            </div>
            <DesktopSearchBar />
            {orderStore.etaCalculation === null ? (
              <button
                className="button w-200 c-bg-dgreen c-white mr-16 fs-13 px-0 h-40"
                onClick={() => desktopStore.setShowMap(true)}
              >
                <span className="fw-500 fs-14">{t('enterDeliveryAddress')}</span>
              </button>
            ) : (
              <>
                <div
                  className="position-relative br-8 border-border c-bg-white pl-12 pr-40 cursor-pointer mr-14 h-40 button-address"
                  onClick={() => {
                    if (userStore.isAuthorized) mainStore.setIsChangeAddressPopover(true);
                    else desktopStore.setShowMap(true);
                  }}
                >
                  <div className="icon icon-chevron-right s-24 position-absolute pe-n fs-14 d-flex flex-center cart-address__icon" />
                  <div className="desktop-header__delivery-to align-items-center h-100p fs-13 c-udgray">
                    <div>
                      {t('yourAddress')}
                      {':'}
                    </div>
                    <span className="ml-5 c-blue">
                      {userStore.deliveryAddress?.zip || t('notChosen')}
                    </span>
                  </div>
                </div>
                <div
                  className="d-flex flex-direction-column align-items-end justify-content-start cursor-pointer ml-auto mr-23"
                  onClick={() => mainStore.setIsShowETAPopover(true)}
                >
                  <div className="d-flex align-items-center h-22">
                    <div
                      className={htmlClasses(
                        's-22 d-flex flex-center fs-17',
                        orderStore.etaCalculation?.highDemand
                          ? 'icon icon-flash-outline c-black'
                          : '',
                      )}
                    />
                    <div className="fs-14">
                      {orderStore.etaCalculation?.duration.range || '–'}{t('min')}
                    </div>
                  </div>
                  <div>
                    <span className="fs-12 c-dgray lh-14">{getDeliveryStatus()}</span>
                  </div>
                </div>
              </>
            )}
            <div className="text-end">
              <button
                className="button w-100 c-bg-dgreen c-white h-40 auth"
                onClick={
                  userStore.isAuthorized
                    ? () => handleLogout()
                    : () => desktopStore.setShowLogin(true)
                }
              >
                <span className="fw-500 fs-14">{t(
                  userStore.isAuthorized ? 'logout' : 'loginIn')}</span>
              </button>
            </div>
          </div>
        </div>
        <Banner />
        <div className="desktop-main-wrapper px-30 pt-104 height-100vh">
          <div className="desktop-main">
            <Catalog />
            <div className="w-100p w-min-320">
              <Switch>
                <Route path="/" component={Default} exact />
                <Route path="/category/:slug" component={DesktopCatalog} />
                <Route path="/discounts" component={Discounts} />
                <Route path="/checkout" component={Checkout} />
                <Route path="/status/:id" component={userStore.isAuthorized ? Status : Awaiting} />
                <Route path="/search/:query" component={DesktopSearch} />
                <Route path="/banner/:bannerId" component={PageBanner} />
                <Route
                  path="/tracking-warehouse"
                  component={() => (
                    <div style={{ height: '100%', maxHeight: 'calc(100vh - 154px)' }}>
                      <WarehouseTracking />
                    </div>
                  )}
                />
                <Route path="/referral" component={ReferralPage} />
                <Redirect to="/" />
              </Switch>
              <div className="desktop-chat__wrapper">
                <DesktopChat />
              </div>
              {orderStore.etaCalculation === null && (
                <>
                  <div className="desktop-demo-catalog__wrap">
                    <div className="desktop-demo-catalog">
                      <img className="flex-shrink-0" src={IconMapPoint} alt="" />
                      <div
                        className="desktop-demo-catalog__text"
                        dangerouslySetInnerHTML={{ __html: t('demoCatalogText') }}
                      />
                      <button
                        className="button w-150 c-bg-dgreen c-white fs-14 lh-16 px-0"
                        onClick={() => desktopStore.setShowMap(true)}
                      >
                        <span className="fw-500">{t('enterDeliveryAddress')}</span>
                      </button>
                    </div>
                  </div>
                  <div className="h-20" />
                </>
              )}
            </div>
            <div className="desktop-cart-column position-sticky t-104 ml-50 w-min-320 w-max-320">
              <div
                className={htmlClasses(
                  'position-relative br-tl-8 br-tr-8 border-teb c-bg-white d-flex flex-direction-column overflow-auto',
                  {
                    'br-bl-8 br-br-8': !catalogStore.cart.length || !orderStore.etaCalculation || matchCheckout,
                  },
                )}
              >
                <h3 className="desktop__title c-bg-transparent pt-18 px-18">
                  {t('yourCart')}
                </h3>
                {orderStore.etaCalculation === null ? (
                  <div className="px-18 pb-18">
                    <EmptyCart />
                  </div>
                ) : isOpen ? (
                  catalogStore.cart.length > 0 ? (
                    <>
                      <div className="desktop-cart-layout d-flex flex-direction-column overflow-auto">
                        <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%">
                          <CartList />
                          <RecommendProductsSlider sku={skuList} source="reco_cart" />
                          <div className="px-18">
                            <JiffyPointsUseBonusesToggler />
                          </div>
                          <div className="br-8 c-bg-white px-2 py-12 px-18">
                            <div className="d-flex align-items-start justify-content-between fs-16 mb-8">
                              <div>{t('subtotal')}:</div>
                              <div className="ml-10 text-right">
                                {mainStore.addCurrencySymbol(catalogStore.totalCartPrice.base)}
                              </div>
                            </div>

                            {catalogStore.promocode.success === true &&
                              catalogStore.totalCartPrice.promocodeDiscount !== '0' && (
                                <div className="d-flex align-items-start justify-content-between fs-16 mb-8 c-red">
                                  <div>{t('promocode')}:</div>
                                  <div className="ml-10 text-right">
                                    - {mainStore.addCurrencySymbol(
                                    catalogStore.totalCartPrice.promocodeDiscount)}
                                  </div>
                                </div>
                              )
                            }

                            {catalogStore.totalCartPrice.discount !== '0' && (
                              <div className="d-flex align-items-start justify-content-between fs-16 mb-8 c-red">
                                <div>{t('discount')}:</div>
                                <div className="ml-10 text-right">
                                  - {mainStore.addCurrencySymbol(
                                  catalogStore.totalCartPrice.discount)}
                                </div>
                              </div>
                            )
                            }

                            {checkoutStore.useBonuses && (
                              <div className="d-flex align-items-start justify-content-between fs-16 fw-500 mb-8 c-red">
                                <div>{t('cartPage:companyPointsApplied')}:</div>
                                <div className="ml-10 text-right">
                                  - {mainStore.addCurrencySymbol(mainStore.addZeroFraction(
                                  mainStore.convertPenceToPounds(getBonusesCanUse())))}
                                </div>
                              </div>
                            )}
                            {!!orderStore.fee.serviceFeeThreshold && (
                              <div className="d-flex align-items-start justify-content-between fs-16 mb-8">
                                <div className="d-flex align-items-center cursor-pointer" onClick={handleShowServiceFeePopover}>
                                  {t('etaPopover:serviceFee')}

                                  <div className="ml-4 icon-faq icon c-gray" />
                                </div>

                                <div className="ml-10 text-right">
                                  <LineThroughWrap isWrapped={catalogStore.isFreeServiceFee}>
                                    {mainStore.addCurrencySymbol(
                                      orderStore.fee.serviceFeeShippingPounds || 0)}
                                  </LineThroughWrap>
                                </div>
                              </div>
                            )}
                            {company.isShowTaxes &&
                              <div className="d-flex align-items-start justify-content-between fs-16 mb-8">
                                <div>{t('etaPopover:taxes')}:</div>
                                <div className="ml-10 text-right">
                                  {mainStore.addCurrencySymbol(
                                    catalogStore.totalCartPrice.taxAmount)}
                                </div>
                              </div>
                            }
                            <div className="d-flex align-items-start justify-content-between fs-16 mb-8">
                              <div>{t('etaPopover:deliveryFee')}</div>
                              <div className="ml-10 text-right">
                                <LineThroughWrap isWrapped={catalogStore.isFreeDelivery}>
                                  {mainStore.addCurrencySymbol(orderStore.fee.shippingPounds || 0)}
                                </LineThroughWrap>
                              </div>
                            </div>

                            <JiffyPointsCashbackWidget />
                          </div>
                        </Scrollbars>
                      </div>
                    </>
                  ) : (
                    <div className="px-18 pb-18">
                      <EmptyCart />
                    </div>
                  )
                ) : (
                  <div className="px-18">
                    {mainStore.isOverdueWarehouseTime() ? (
                      <div className="px-18 pb-18">
                        <img className="d-block mx-auto" src={IllustrationClock} alt="" />
                        <div className="text-center fs-21 c-udgray mt-24">
                          {t('cartPage:notWorkingTitle')}
                        </div>
                        <div className="text-center fs-14 c-udgray mt-5 w-max-290 mx-auto">
                          {t('cartPage:notWorkingText')}
                        </div>
                      </div>
                    ) : (
                      <div className="px-18 pb-18">
                        <img className="d-block mx-auto" src={IllustrationClock} alt="" />
                        <div className="text-center fs-21 c-udgray mt-24">
                          {t('cartPage:startWorking')}
                        </div>
                        <div className="text-center fs-18 mt-5 c-udgray">
                          {t('cartPage:closedTill', {
                            time: orderStore.etaCalculation?.warehouse.availability.opening || '',
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {catalogStore.cart.length > 0 &&
                !matchCheckout &&
                orderStore.etaCalculation !== null && (
                  <div className="checkout__button">
                    <BoardInfo />
                    <Link
                      className={htmlClasses(
                        'cart-board__btn-checkout button _primary fs-16 w-100p px-0',
                        {
                          _disabled:
                            !orderStore.etaCalculation?.warehouse.availability.availableNow,
                        },
                      )}
                      to={userStore.isAuthorized ? '/checkout' : `${location.pathname}?modal=login`}
                      onClick={checkCart}
                    >
                      <div className="fs-14 w-100p pl-14">
                        {orderStore.etaCalculation?.duration.range || '?'} {t('min')}
                      </div>
                      <div className="text-center w-100p">{t('checkout')}</div>
                      {catalogStore.calculationProcess.isLoading ? (
                        <div className="w-100p text-right">
                          <div className="pr-14 d-flex justify-content-end">
                            <span className="spinner _white" />
                          </div>
                        </div>
                      ) : (
                        <div className="w-100p text-right">
                          <div className="pr-14">
                            {mainStore.addCurrencySymbol(catalogStore.finalPrice || '0')}
                          </div>
                        </div>
                      )}
                    </Link>
                  </div>
                )}
            </div>
          </div>
        </div>
        <Footer />
        <PointsPopover isShow={desktopStore.showPointsPopover} onDismiss={() => desktopStore.setShowPointsPopover(
          false)} />
        <Dialog
          show={desktopStore.showLogin && !userStore.isAuthorized}
          onBackdrop={() => desktopStore.setShowLogin(false)}
          modalParam="login"
        >
          <div className="h-24" />
          <Auth />
        </Dialog>
        <Dialog
          show={desktopStore.showAbout}
          onBackdrop={() => desktopStore.setShowAbout(false)}
          modalParam="about"
        >
          <div className="dialog__header">
            <div className="d-flex align-items-center">
              <div className={`icon icon-info d-flex flex-center s-24 fs-24 c-udgray mr-12`} />
              <div className="fw-700">{t('about')}</div>
            </div>
          </div>
          <About />
        </Dialog>
        <Dialog
          show={desktopStore.showProfile}
          onBackdrop={() => desktopStore.setShowProfile(false)}
          modalParam="profile"
        >
          <div className="dialog__header">
            <div className="d-flex align-items-center">
              <div className={`icon icon-profile d-flex flex-center s-24 fs-24 c-udgray mr-12`} />
              <div className="fw-700">{t('myProfile')}</div>
            </div>
          </div>
          <Profile />
        </Dialog>
        <Dialog
          show={desktopStore.showPaymentMethods}
          onBackdrop={() => desktopStore.setShowPaymentMethods(false)}
          modalParam="paymentMethods"
          minWidth="600px"
        >
          <div className="dialog__header">
            <div className="d-flex align-items-center">
              <div
                className={`icon icon-payment-card d-flex flex-center s-24 fs-24 c-udgray mr-12`}
              />
              <div className="fw-700">{t('paymentMethods')}</div>
            </div>
          </div>
          <PaymentMethods />
        </Dialog>
        <Dialog
          show={desktopStore.showFavorites}
          onBackdrop={() => desktopStore.setShowFavorites(false)}
          modalParam="favorites"
          maxWidth="500px"
        >
          <div className="dialog__header">
            <div className="d-flex align-items-center">
              <div className={`icon icon-favorite d-flex flex-center s-24 fs-24 c-udgray mr-12`} />
              <div className="fw-700">{t(
                company.isHideFavourites ? 'purchasedItems' : 'faves')}</div>
            </div>
          </div>
          <Favorites />
        </Dialog>
        <Dialog
          show={desktopStore.showBonuses}
          onBackdrop={() => desktopStore.setShowBonuses(false)}
          modalParam="points"
          maxWidth="500px"
        >
          <div className="dialog__header">
            <div className="d-flex align-items-center">
              <Icon type={company.config.points?.icon || 'coins'} size={24} className="d-flex flex-center c-udgray mr-12" />
              <div className="fw-700">{t('companyPoints')}</div>
            </div>
          </div>
          <JiffyPoints />
        </Dialog>
        <Dialog
          show={desktopStore.showOrders}
          onBackdrop={() => desktopStore.setShowOrders(false)}
          modalParam="orders"
        >
          <div className="dialog__header">
            <div className="d-flex align-items-center">
              <div className={`icon icon-bag d-flex flex-center s-24 fs-24 c-udgray mr-12`} />
              <div className="fw-700">{t('myOrder_other')}</div>
            </div>
          </div>
          <Orders />
        </Dialog>
        <Dialog
          show={desktopStore.showNotifications}
          onBackdrop={() => desktopStore.setShowNotifications(false)}
          modalParam="notifications"
        >
          <div className="dialog__header">
            <div className="d-flex align-items-center">
              <div className={`icon icon-bell d-flex flex-center s-24 fs-24 c-udgray mr-12`} />
              <div className="fw-700">{t('notificationsSettings')}</div>
            </div>
          </div>
          <Notifications />
        </Dialog>
        <Dialog
          show={desktopStore.showProduct}
          onBackdrop={() => desktopStore.setShowProduct(false)}
          modalParam="product"
          minWidth="450px"
          idParam={desktopStore.selectedId}
        >
          <div className="dialog__header">
            <div className="d-flex align-items-center">
              <div className="fw-700">{t('productPage:title')}</div>
            </div>
          </div>
          <Product />
        </Dialog>
        <Dialog
          show={desktopStore.showOrder}
          onBackdrop={() => desktopStore.setShowOrder(false)}
          modalParam="order"
          idParam={desktopStore.selectedId}
        >
          <div className="dialog__header">
            <div className="d-flex align-items-center">
              <div className="fw-700">{t('order')}</div>
            </div>
          </div>
          <Order />
        </Dialog>
        <Dialog
          show={desktopStore.showInject}
          onBackdrop={() => desktopStore.setShowInject(false)}
          modalParam="inject"
          minHeight={'80vh'}
        >
          <Inject />
        </Dialog>
        <Dialog
          show={desktopStore.showMap}
          onBackdrop={() => {
            desktopStore.setShowMap(false);
            desktopStore.setLastSavedDeliveryAddressState(null);
          }}
          modalParam="map"
          minHeight={'90%'}
          minWidth={'90%'}
        >
          <div className="dialog__header">
            <div className="d-flex align-items-center">
              <div className={`icon icon-flag d-flex flex-center s-24 fs-24 c-udgray mr-12 z-1`} />
              <div className="fw-700">{t('yourAddress')}</div>
            </div>
          </div>
          <DeliveryAddress />
        </Dialog>
        <Dialog
          show={desktopStore.showChangeAddressDialog}
          onBackdrop={() => desktopStore.setShowChangeAddressDialog(false)}
        >
          <div className="dialog__header d-flex align-items-center">
            <div className="icon icon-attention s-20 fs-20 d-flex flex-center c-red mr-12" />
            <div className="fs-21">{t('attention')}</div>
          </div>
          <div className="fs-14 lh-20 mt-20">{t('cartPage:changeAddress')}</div>
          <div className="d-flex align-items-center justify-content-end mt-24">
            <div
              className="h-40 mr-20 px-10 lh-40 c-blue fs-14 cursor-pointer"
              onClick={() => desktopStore.setShowChangeAddressDialog(false)}
            >
              {t('cancel')}
            </div>
            <div
              className="button _primary h-40 fs-14"
              onClick={() => {
                desktopStore.setShowChangeAddressDialog(false);
                desktopStore.setShowMap(true);
              }}
            >
              {t('change')}
            </div>
          </div>
        </Dialog>
        <Dialog
          show={desktopStore.showTracking}
          onBackdrop={() => desktopStore.setShowTracking(false)}
          modalParam="tracking"
          minHeight="90vh"
          minWidth="500px"
          idParam={desktopStore.selectedId}
        >
          <DeliveryTracking />
        </Dialog>
        <PopoverETA />
        <PopoverServiceFee />
        <PopoverOutStock />
        <PopoverNotFindProduct />
        <PopoverInvalidPromocode />
        <PopoverAgeRestriction />
        <PopoverChangeAddress />
        <PopoverNeedChangeAddress />
        <Alerts />
        {/*<PopoverRateOrder />*/}
        {/*<PopoverPayTip />*/}
      </Scrollbars>
    </div>
  );
});
