import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { mainStore } from '../stores/MainStore';
import htmlClasses from 'html-classes';
import { company } from '../company/Company';

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state = { page: '' } } = useLocation<{ page: string }>();
  const [src, setSrc] = useState('');
  const [title, setTitle] = useState('');
  const matchCheckout = useRouteMatch('/checkout');

  useEffect(() => {
    if (!state?.page) {
      if (mainStore.isDesktop) {
        state.page = 'terms';
      } else {
        history.goBack();
        return;
      }
    }
    switch (state.page) {
      case 'terms':
        setSrc(company.config.links.terms);
        setTitle('aboutPage:termsAndConditions');
        break;
      case 'privacy':
        setSrc(company.config.links.privacy);
        setTitle('aboutPage:securityPolicy');
        break;
      case 'cookie':
        setSrc(company.config.links.cookie);
        setTitle('aboutPage:privacyAndCookiesPolicy');
        break;
    }
  }, [state, history]);

  const showBack = (): boolean => {
    return !matchCheckout?.isExact;
  };

  if (!state?.page) {
    if (mainStore.isDesktop) {
      state.page = 'terms';
    } else {
      return <></>;
    }
  }

  return (
    <div className="content-layout">
      <div className="flex-shrink-0 h-50 d-flex align-items-center px-7">
        {showBack() && (
          <div
            className="icon icon-arrow-back d-flex flex-center s-50 fs-20 c-black cursor-pointer"
            onClick={() => history.goBack()}
          />
        )}
        <h3 className={htmlClasses('w-100p text-center lh-20', { 'pr-50': showBack() })}>
          {t(title)}
        </h3>
      </div>
      <iframe className="h-100p w-100p" frameBorder={0} title="jiffy" src={src} />
    </div>
  );
});
