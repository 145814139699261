import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import Popover from '../components/Popover';
import { userStore } from '../stores/UserStore';
import { mainStore } from '../stores/MainStore';
import htmlClasses from 'html-classes';
import Dialog from '../components/Dialog';
import ExternalLink from '../components/ExternalLink';
import { company } from '../company/Company';
import { CompanyName } from '../company/interface';
import FCButton from '../components/FCButton';

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isShowPopoverRemoveData, setIsShowPopoverRemoveData] = useState(false);
  const handleLogout = async () => {
    try {
      await userStore.logout().catch((error) => error && console.error(error));
      history.replace('/');
    } catch (e) {
      console.error(e);
    }
  };
  const handlePopoverRemoveDataDismiss = () => setIsShowPopoverRemoveData(false);

  useEffect(() => {
    userStore.requestPersonalData().catch((error) => error && console.error(error));
  }, []);

  return (
    <>
      {!mainStore.isDesktop && <PageHeader title={t('myProfile')} />}
      <div className={htmlClasses('scroll-layout h-100p', { 'pt-12 px-24': !mainStore.isDesktop })}>
        <div className="fs-14 lh-22">{t('personalData')}</div>
        <div className="d-flex mt-12 align-items-start justify-content-between fs-14 ">
          <div className="c-gray mr-24 text-nowrap">{t('phone')}</div>
          <div className="text-right">
            {mainStore.prettyPhoneNumber(userStore.personalData.phone || '') || t('unknown')}
          </div>
        </div>
        <div className="d-flex mt-12 align-items-start justify-content-between fs-14 ">
          <div className="c-gray mr-24 text-nowrap">{t('yourName')}</div>
          <div className="text-right">{userStore.fullName || t('unknown')}</div>
        </div>
        <div className="d-flex mt-12 align-items-start justify-content-between fs-14 ">
          <div className="c-gray mr-24 text-nowrap">{t('email')}</div>
          <div className="text-right">{userStore.personalData.email || t('unknown')}</div>
        </div>
        <div
          className="fs-14  c-blue mt-20 cursor-pointer"
          onClick={() => setIsShowPopoverRemoveData(true)}
        >
          {t('profilePage:deletePersonalData')}
        </div>
        <div className="h-1 my-16 c-bg-tf2" />
        <div className="fs-14  c-blue cursor-pointer" onClick={handleLogout}>
          {t('logout')}
        </div>
      </div>
      {!mainStore.isDesktop ? (
        <Popover
          isShow={isShowPopoverRemoveData}
          onBackdropDismiss={handlePopoverRemoveDataDismiss}
        >
          <h2>{t('profilePage:deletePersonalData')}</h2>
          <div className="fs-14 lh-20 mt-20  c-mgray">{t('profilePage:warning')}</div>
          <div className="d-flex align-items-center justify-content-end mt-20">
            <div
              className="h-40 mr-20 px-10 lh-40 c-blue fs-14  fw-500 cursor-pointer"
              onClick={handlePopoverRemoveDataDismiss}
            >
              {t('cancel')}
            </div>
            {company.isIncludedIn([CompanyName.Baqal]) ? (
              <ExternalLink
                className="button _primary w-180 h-40 fs-14"
                href={company.config.links.whatsapp}
                onClick={handlePopoverRemoveDataDismiss}
                targetBlank
              >
                {t('contactSupport')}
              </ExternalLink>
            ) : (
              <FCButton className="button _primary w-180 h-40 fs-14" title={t('contactSupport')} />
            )}
          </div>
        </Popover>
      ) : (
        <Dialog show={isShowPopoverRemoveData} onBackdrop={handlePopoverRemoveDataDismiss}>
          <div className="dialog__header">
            <h2>{t('profilePage:deletePersonalData')}</h2>
          </div>
          <div className="fs-14 lh-20 mt-20  c-mgray">{t('profilePage:warning')}</div>
          <div className="d-flex align-items-center justify-content-end mt-20">
            <div
              className="h-40 mr-20 px-10 lh-40 c-blue fs-14  fw-500 cursor-pointer"
              onClick={handlePopoverRemoveDataDismiss}
            >
              {t('cancel')}
            </div>
            {company.isIncludedIn([CompanyName.Baqal]) && (
              <ExternalLink
                className="button _primary w-180 h-40 fs-14"
                href={company.config.links.whatsapp}
                onClick={handlePopoverRemoveDataDismiss}
                targetBlank
              >
                {t('contactSupport')}
              </ExternalLink>
            )}
          </div>
        </Dialog>
      )}
    </>
  );
});
