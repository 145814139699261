import { observer } from 'mobx-react-lite';
import img from '../assets/img/welcome_banner.png';
import { desktopStore } from '../stores/DesktopStore';
import { mainStore } from '../stores/MainStore';

export default observer(() => {
  if (!desktopStore.showBanner) return null;
  return (
    <div className={'banner'}>
      <div className={'banner__constraint'}>
        <div className={'banner__text'}>
          Install our app and get <em>{mainStore.addCurrencySymbol(5)} off</em> your <br /> first shop with promo code{' '}
          <em>WELCOME5</em>
        </div>
        <div className={'banner__img'}>
          <img alt="" src={img} />
        </div>
        <div className={'banner__button'} onClick={() => desktopStore.setShowQR(true)}>
          <div className={'goal'}>Try now</div>
        </div>
        <div className={'banner__close-icon'}>
          <div
            className="input-text__clear fs-16 s-20 icon icon-close cursor-pointer"
            onClick={() => desktopStore.setShowBanner(false)}
          />
        </div>
      </div>
    </div>
  );
});
