import * as Sentry from '@sentry/react';
import htmlClasses from 'html-classes';
import { makeAutoObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useState, useLayoutEffect, useCallback, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import PageHeader from '../components/PageHeader';
import Popover from '../components/Popover';
import ImgMasterCard from '../assets/img/logo_mastercard.png';
import ImgVisa from '../assets/img/logo_visa.png';
import UnknownCard from '../assets/img/unknown_card.png';
import InputText from '../components/InputText';
import { Payment } from '../api/Order';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  loadStripe,
  Stripe,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import Skeleton from 'react-loading-skeleton';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';

type PaymentMethodItemProps = {
  paymentMethod: Payment;
};

class Store {
  isShowEditPaymentCards = false;
  isShowAddPaymentCards = false;
  isLoading = false;
  listToDelete: string[] = [];
  defaultCardId = '';

  changeError = '';
  addError = '';

  constructor() {
    makeAutoObservable(this);
  }

  // Setters
  emptyListToDelete() {
    this.listToDelete = [];
  }

  addItemToDelete(id: string) {
    this.listToDelete.push(id);
  }

  setIsShowEditPaymentCards(flag: boolean) {
    this.isShowEditPaymentCards = flag;
    this.emptyListToDelete();
    this.setDefaultCardId('');
  }

  setDefaultCardId(id: string) {
    this.defaultCardId = id;
  }

  setIsShowAddPaymentCards(flag: boolean) {
    this.isShowAddPaymentCards = flag;
  }

  setChangeError(e: string) {
    this.changeError = e;
  }

  setAddError(e: string) {
    this.addError = e;
  }

  setIsLoading(flag: boolean) {
    this.isLoading = flag;
  }

  // Actions
  async handleSave() {
    this.setIsLoading(true);
    for (let i = 0; i < this.listToDelete.length; i++) {
      try {
        await orderStore.deletePayment(this.listToDelete[i]);
      } catch (e) {
        Sentry.captureMessage('[PaymentCards] Failed delete payments', 'warning');
        await orderStore.requestPayments().catch((error) => error && console.error(error));
        this.setIsLoading(false);
        return;
      }
    }
    if (store.defaultCardId) {
      try {
        await orderStore.setDefaultPayment(store.defaultCardId);
      } catch (e) {
        await orderStore.requestPayments().catch((error) => error && console.error(error));
        this.setIsLoading(false);
        return;
      }
    }
    await orderStore.requestPayments().catch((error) => error && console.error(error));
    this.setIsLoading(false);
    this.setIsShowEditPaymentCards(false);
  }
}

const store = new Store();

const cardBrands: Record<string, { name: string; icon: string }> = {
  visa: {
    name: 'Visa',
    icon: ImgVisa,
  },
  mastercard: {
    name: 'MasterCard',
    icon: ImgMasterCard,
  },
};

export default observer(() => {
  const { t } = useTranslation();
  const [stripePromise, setStripePromise] = useState<Stripe | null | undefined>(undefined);
  const handleEditPaymentCards = () => store.setIsShowEditPaymentCards(true);
  const handleEditPaymentCardsDismiss = () => store.setIsShowEditPaymentCards(false);
  const init = useCallback(async () => {
    let stripePromise: Stripe | null = null;
    try {
      stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string, {
        locale: 'en-GB',
      });
      setStripePromise(stripePromise || null);
    } catch (error) {
      error && console.error(error);
    }
    if (!stripePromise) {
      Sentry.captureMessage('[PaymentCards] Failed Stripe loading', 'warning');
    }
  }, []);

  useLayoutEffect(() => {
    store.setIsLoading(true);
    init().catch((error) => error && console.error(error));
    orderStore
      .requestPayments()
      .catch((error) => error && console.error(error))
      .finally(() => {
        runInAction(() => {
          store.setIsLoading(false);
        });
      });
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {!mainStore.isDesktop && (
        <PageHeader
          title={store.isShowEditPaymentCards ? t('editPaymentMethods') : t('paymentMethods')}
        />
      )}
      <div className="scroll-layout h-100p px-24">
        {store.isLoading && orderStore.paymentCards.length === 0 ? (
          <div className="sm-item c-bg-white br-8 px-16 d-block mt-12 border-tf2">
            {[1, 2, 3].map((i) => (
              <div
                className="d-flex justify-content-start align-items-start payment-methods-page__item"
                key={i}
              >
                <Skeleton className="w-44 h-22" />
                <div className="d-flex flex-direction-column align-self-center ml-10 w-100p">
                  <Skeleton className="fs-12 lh-15 c-dgray w-160" />
                  <Skeleton className="fs-12 lh-15 c-dgray w-38" />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <CardList />
            {!store.isShowEditPaymentCards && orderStore.paymentCards.length > 0 && (
              <>
                <div
                  className="fs-14 c-blue mt-16 px-16 cursor-pointer"
                  onClick={handleEditPaymentCards}
                >
                  {t('paymentMethodsPage:editPaymentMethods')}
                </div>
                {/*<div className="h-1 my-16 mx-16 c-bg-tf2" />*/}
              </>
            )}
            {/*{!store.isShowEditPaymentCards && (
              <div className="fs-14 c-blue mt-16 px-16 cursor-pointer"
                   onClick={() => store.setIsShowAddPaymentCards(true)}
              >
                {t('paymentMethodsPage:addNewCard')}
              </div>
            )}*/}
          </>
        )}
        {store.isShowEditPaymentCards && (
          <div className="align-items-center justify-content-end mt-16 d-flex">
            <div
              className="h-40 mr-18 px-10 lh-40 c-blue fs-14 cursor-pointer"
              onClick={handleEditPaymentCardsDismiss}
            >
              {t('cancel')}
            </div>
            <button
              className="button _primary w-160 h-40 fs-14"
              onClick={() => store.handleSave()}
              disabled={store.isLoading || (!store.defaultCardId && !store.listToDelete.length)}
              type="button"
            >
              {store.isLoading ? <span className="spinner" /> : t('saveChanges')}
            </button>
          </div>
        )}
        <div className="h-42" />
      </div>
      {stripePromise !== undefined && (
        <Elements stripe={stripePromise}>
          <AddPaymentMethod />
        </Elements>
      )}
    </>
  );
});

const CardList = observer(() => {
  const { t } = useTranslation();

  return (
    <div className="sm-item c-bg-white br-8 px-16 d-block mt-12 border-tf2">
      {orderStore.paymentCards.length > 0 &&
      orderStore.paymentCards.length !== store.listToDelete.length ? (
        orderStore.paymentCards.map((item) => (
          <PaymentMethodItem paymentMethod={item} key={item.id} />
        ))
      ) : (
        <div className="py-16 fs-14 text-center">
          {t('paymentMethodsPage:noCards')}
          {orderStore.paymentCards.length === 0 && (
            <>
              <br />
              {t('paymentMethodsPage:addCardsInfo')}
            </>
          )}
        </div>
      )}
    </div>
  );
});

const PaymentMethodItem = observer(({ paymentMethod }: PaymentMethodItemProps) => {
  const { t } = useTranslation();
  const {
    card: { brand, last4, exp_month, exp_year },
    metadata: { is_default },
    id,
  } = paymentMethod;
  const handleDelete = () => store.addItemToDelete(id);
  const handleSetDefault = () => store.setDefaultCardId(id);
  const isDefaultCard = store.defaultCardId ? store.defaultCardId === id : is_default === 'true';

  if (store.listToDelete.indexOf(id) !== -1) return <></>;

  return (
    <div className="d-flex justify-content-start align-items-start payment-methods-page__item">
      <img
        className={htmlClasses('w-38 h-22', { 'mt-18': isDefaultCard })}
        src={cardBrands[brand]?.icon || UnknownCard}
        alt=""
      />
      <div className="d-flex flex-direction-column justify-content-start ml-10 w-100p">
        <div className={htmlClasses('fs-12 lh-15 c-dgray', { 'd-none': !isDefaultCard })}>
          {t('paymentMethodsPage:defaultCard')}
        </div>
        <div className="mt-2 d-flex justify-content-between align-items-center">
          <div>
            <span className="fs-14 lh-19 mr-5 text-capitalize">
              {cardBrands[brand]?.name || brand}
            </span>
            <span className="fs-14 lh-19">{last4}</span>
          </div>
          <div
            onClick={handleDelete}
            className={htmlClasses(
              'icon icon-close c-cred payment-methods-page__icon-close position-unset cursor-pointer',
              { '_default-card': isDefaultCard },
              store.isShowEditPaymentCards ? 'd-flex' : 'd-none',
            )}
          />
        </div>
        <div className="fs-12 lh-14 mt-3 pb-5">
          {`${('0' + exp_month).slice(-2)}/${String(exp_year).slice(-2)}`}
        </div>
        <div
          className={htmlClasses(
            'fs-14 lh-20 c-blue cursor-pointer',
            !isDefaultCard && store.isShowEditPaymentCards ? 'd-flex' : 'd-none',
          )}
          onClick={handleSetDefault}
        >
          {t('paymentMethodsPage:makeDefault')}
        </div>
      </div>
    </div>
  );
});

const AddPaymentMethod = observer(() => {
  const { t } = useTranslation();
  const [currentFocus, setFocus] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const handleFocus = (fieldName: string) => {
    setFocus(fieldName);
  };
  const handleChange = (
    e:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent,
  ) => {
    store.setChangeError(e.error ? e.error.message : '');
  };
  const handlePopoverAddCardDismiss = () => store.setIsShowAddPaymentCards(false);
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    store.setAddError('');
    if (!stripe || !elements) return;
    setIsLoading(true);
    let intent: any;
    try {
      intent = await orderStore.createIntent();
    } catch (error) {
      setIsLoading(false);
      return;
    }
    if (!intent || !intent.client_secret) {
      setIsLoading(false);
      return;
    }
    try {
      const paymentMethod = {
        card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
      };
      const confirm = await stripe.confirmCardSetup(intent.client_secret, {
        payment_method: paymentMethod,
      });
      if (confirm.error) {
        setIsLoading(false);
        store.setAddError(confirm.error.message as string);
        mainStore.pushAlert('error', confirm.error.message as string);
        return;
      } else if (confirm.setupIntent?.payment_method) {
        await orderStore.addPayment(confirm.setupIntent?.payment_method as string);
        await orderStore.requestPayments();
        store.setIsShowAddPaymentCards(false);
      }
    } catch ({ response }) {
      setIsLoading(false);
      if (!response) return;
      Sentry.withScope((scope) => {
        scope.setExtras({
          error: response,
        });
        Sentry.captureMessage('[PaymentCards] Stripe error', 'warning');
      });
    }
    setIsLoading(false);
  };

  useLayoutEffect(() => {
    if (!stripe || !elements) {
      Sentry.captureMessage('[PaymentCards] Failed Stripe init', 'warning');
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Popover isShow={store.isShowAddPaymentCards} onBackdropDismiss={handlePopoverAddCardDismiss}>
      <h2>{t('paymentMethodsPage:addNewCard')}</h2>
      <form onSubmit={handleSubmit}>
        <div className="fs-14 lh-20 mt-20 c-mgray position-relative">
          <InputText
            className={htmlClasses(
              'mt-20',
              currentFocus === 'number' && '_active',
              store.changeError && '_error',
            )}
          >
            <CardNumberElement
              className="input-text pt-16 pl-48 z-3 position-relative"
              onFocus={() => handleFocus('number')}
              onBlur={() => handleFocus('')}
              onChange={handleChange}
            />
            <div className="payment-methods-page__card-svg icon icon-card fs-22 c-black z-3" />
          </InputText>
        </div>
        <div className="d-flex justify-content-center fs-14 lh-20 mt-18 c-mgray">
          <InputText
            className={htmlClasses(
              'payment-methods-page__card-input w-100p',
              currentFocus === 'expiry' && '_active',
              store.changeError && '_error',
            )}
          >
            <CardExpiryElement
              className="input-text mt-0 pt-16 z-3 position-relative"
              onFocus={() => handleFocus('expiry')}
              onBlur={() => handleFocus('')}
              onChange={handleChange}
            />
          </InputText>
          <InputText
            className={htmlClasses(
              'payment-methods-page__card-input w-100p',
              currentFocus === 'cvc' && '_active',
              store.changeError && '_error',
            )}
          >
            <CardCvcElement
              className="input-text mt-0 pt-16 z-3 position-relative"
              onFocus={() => handleFocus('cvc')}
              onBlur={() => handleFocus('')}
              onChange={handleChange}
            />
          </InputText>
        </div>
        <div className="d-flex align-items-center justify-content-end mt-20">
          <div
            className="h-40 mr-18 px-10 lh-40 c-blue fs-14  cursor-pointer"
            onClick={handlePopoverAddCardDismiss}
          >
            {t('cancel')}
          </div>
          <button
            className={htmlClasses('button _primary w-100 h-40 fs-14', isLoading && '_disabled')}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? <span className="spinner" /> : t('add')}
          </button>
        </div>
      </form>
    </Popover>
  );
});
