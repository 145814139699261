import { Environment } from '../../stores/MainStore';

const courierTrackingUrl = window.location.origin.replace(
  'frontend-partner', 'frontend-courier-tracking');

export const COURIER_TRACKING_URL: Record<Environment, string> = {
  development: courierTrackingUrl,
  staging: courierTrackingUrl,
  production: process.env.REACT_APP_API_ROOT ? process.env.REACT_APP_API_ROOT.replace(
    'api2.', 'courier-tracking.') : courierTrackingUrl,
};
