import { observer } from 'mobx-react-lite';
import htmlClasses from 'html-classes';
import { catalogStore } from '../../../../stores/CatalogStore';
import { orderStore } from '../../../../stores/OrderStore';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StoreOpensAt = observer(() => {
  const { t } = useTranslation();
  return (
    <div className={htmlClasses(
      'd-flex align-items-center justify-content-center c-dgray',
      { 'mb-8': catalogStore.cart.length },
    )}>
      <div className="icon icon-sleep s-14 d-flex flex-center mr-5 fs-15" />
      <div className="fs-11">
        {t('cartBoard:storeOpensAt', {
          time: orderStore.etaCalculation?.warehouse.availability.opening || '',
        })}
      </div>
    </div>
  );
});

export default StoreOpensAt;
