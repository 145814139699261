import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link, useHistory } from 'react-router-dom';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperClass from 'swiper/types/swiper-class';
import MainHeader from '../components/MainHeader';
import OrderStatusWidget from '../components/OrderStatusWidget';
import ScrollRestoration from '../components/ScrollRestoration';
import { mainStore } from '../stores/MainStore';
import { catalogStore } from '../stores/CatalogStore';
import { Banner } from '../api/Catalog';
import { Product } from '../types/Product/interface';
import { BANNERS_SLIDER_DURATION, GRABAGAIN_ITEMS_LENGTH } from '../config';
import ProductItem from '../components/ProductItem';
import { orderStore } from '../stores/OrderStore';
import { storiesStore } from '../stores/StoriesStore';
import StoriesList from './Main/StoriesList/StoriesList';
import CategoryList from './Main/CategoryList/CategoryList';
import { Bestsellers } from './Main/Bestsellers/Bestsellers';

SwiperCore.use([Autoplay]);

type BannersListSliderProps = {
  banners: Banner[];
};

const BannersListSlider = observer(({ banners }: BannersListSliderProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleSlideChange = (swiper: SwiperClass) => setActiveIndex(swiper.activeIndex);
  const handleClick = (bannerId: number) => {
    mainStore.sendToRN('logAmplitudeEvent', {
      name: 'Home Page: Banner open',
      params: {
        banner_id: bannerId,
      },
    });
  };

  return (
    <>
      <Swiper
        className={htmlClasses('category-slider', { 'mt-16': !mainStore.isDesktop })}
        slidesPerView={1}
        autoplay={{
          delay: BANNERS_SLIDER_DURATION,
          disableOnInteraction: false,
        }}
        onSlideChange={(swiper) => handleSlideChange(swiper)}
      >
        {banners.map((item) => (
          <SwiperSlide className="px-16" key={item.id}>
            <div className="category-item">
              <div
                className="category-item__img"
                style={{
                  backgroundImage: item.preview_image?.path
                    ? `url(${item.preview_image.path})`
                    : 'none',
                }}
              />
              {item.offer?.length > 0 && (
                <Link
                  className="link-abs category-item__link"
                  to={`/banner/${item.id}`}
                  onClick={() => handleClick(item.id)}
                />
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="onboarding__dots mt-8">
        {banners.map((item, i) => (
          <div className={htmlClasses('onboarding__dot', { _active: i === activeIndex })} key={i} />
        ))}
      </div>
    </>
  );
});

export const BannersList = observer(() => {
  const handleClick = (bannerId: number) => {
    mainStore.sendToRN('logAmplitudeEvent', {
      name: 'Home Page: Banner open',
      params: {
        banner_id: bannerId,
      },
    });
  };

  return (
    <>
      {catalogStore.bannerList !== null ? (
        catalogStore.bannerList.items.length > 0 &&
        (catalogStore.bannerList.displayType === 'slider' ? (
          <BannersListSlider banners={catalogStore.bannerList.items} />
        ) : (
          <div className="category-list d-flex flex-wrap px-11">
            {catalogStore.bannerList.items.map((item) => (
              <div
                className={htmlClasses('category-list__col', {
                  _2: item.tile_size === 2,
                  _3: item.tile_size === 3,
                })}
                key={item.id}
              >
                <div className="category-item">
                  <div
                    className="category-item__img"
                    style={{
                      backgroundImage: item.preview_image?.path
                        ? `url(${item.preview_image.path})`
                        : 'none',
                    }}
                  />
                  {item.offer?.length > 0 && (
                    <Link
                      className="link-abs category-item__link"
                      to={`/banner/${item.id}`}
                      onClick={() => handleClick(item.id)}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        ))
      ) : (
        <SkeletonTheme color="#EBECED" highlightColor="#F7F7F7">
          <div className="d-flex align-items-start flex-wrap px-11 mt-16">
            {[1, 2, 3].map((subitem, j) => (
              <div className="category-list__col" key={j}>
                <Skeleton className="pt-100p br-8" />
              </div>
            ))}
          </div>
        </SkeletonTheme>
      )}
    </>
  );
});


const GrabAgain = observer(() => {
  const { t } = useTranslation();
  const purchasedItemsList = catalogStore.purchasedItemsList.filter((product) => product.sellable);
  const [grabAgainProducts, setGrabAgainProducts] = useState<Product[] | null>(
    purchasedItemsList.length ? purchasedItemsList : null,
  );
  const handleSeeAllClick = () => {
    mainStore.sendToRN('analytics', {
      name: 'Catalog: Grabagain visited',
      params: {
        source: 'homepage',
      },
    });
  };

  useEffect(() => {
    catalogStore
      .fetchPurchasedItems()
      .then(() =>
        setGrabAgainProducts(catalogStore.purchasedItemsList.filter((product) => product.sellable)),
      )
      .catch((error) => error && console.error(error));
  }, [orderStore.etaWarehouseCode]);

  if (!grabAgainProducts) return <></>;

  if (!grabAgainProducts.length) return <Bestsellers />;

  return (
    <>
      <div className="category-title fw-500 mx-16 mb-16 mt-16">{t('grabAgain')}</div>
      <div className="scroll-list _16 hide-scroll-bar pb-8 mb-n8">
        {grabAgainProducts.slice(0, GRABAGAIN_ITEMS_LENGTH).map((item) => (
          <ProductItem product={item} source="grabagain_slider" isWithinSlider key={item.id} />
        ))}
        {grabAgainProducts.length > GRABAGAIN_ITEMS_LENGTH && (
          <Link
            className="product-item border-0 c-bg-none flex-center"
            onClick={handleSeeAllClick}
            to={{ pathname: '/favorites', state: { activeTabNum: 1 } }}
          >
            <div className="text-center">
              <div className="icon icon-arrow-long s-60 d-flex flex-center rounded-circle fs-24 c-bg-dgreen c-white" />
              <div className="ff-manrope fw-500 mt-8 lh-20 c-black">{t('seeAll')}</div>
            </div>
          </Link>
        )}
      </div>
    </>
  );
});

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleClick = () => {
    catalogStore.setSearchQuery('');
    mainStore.sendToRN('analytics', {
      name: 'Catalog: search clicked',
      params: {
        source: 'homepage',
      },
    });
  };

  useEffect(() => {
    mainStore.clearAllCache();
    catalogStore.requestCategories();
    catalogStore.requestBanners();
    if (mainStore.isDesktop) return;
    storiesStore.requestStories().then(() => {
      const startStory = storiesStore.stories.find((story) => story.isStart);
      if (startStory && !storiesStore.isStoryOpened(startStory.id)) {
        history.push(`/stories/${startStory.id}`);
      }
    }).catch((error) => error && console.error(error));
    //eslint-disable-next-line
  }, [orderStore.etaWarehouseCode]);

  useEffect(() => {
    mainStore.sendToRN('analytics', {
      name: 'Catalog: home page visited',
      params: {
        warehouse_code: orderStore.etaWarehouseCode,
      },
    });
  }, []);

  return (
    <>
      <ScrollRestoration pageName="main" />
      <MainHeader />
      <div className="scroll-layout h-100p position-relative main-page">
        <OrderStatusWidget />
        <Link
          className="mx-16 mt-8 mb-16 d-block"
          to={{ pathname: '/search', state: { source: 'homepage' } }}
          onClick={handleClick}
        >
          <div className="search-dummy br-8 d-flex align-items-center pe-n">
            <div className="icon icon-search c-salad s-40 d-flex flex-center fs-18 ml-3" />
            <div className="fs-14 c-salad fw-500">
              {t('searchPage:inputPlaceholder')}
            </div>
          </div>
        </Link>
        <StoriesList />
        <BannersList />
        {mainStore.analytics.totalSuccessfulOrders ? <GrabAgain /> : <Bestsellers />}
        <CategoryList />
        <div className="h-24" />
      </div>
    </>
  );
});
