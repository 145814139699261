import React, { useEffect } from 'react';
import { mainStore } from '../stores/MainStore';

export default function useIntersection(
  ref: React.RefObject<HTMLDivElement>,
  analyticsData: Record<string, any>,
): void {
  useEffect(() => {
    if (!ref || !ref.current || !window.IntersectionObserver) return;
    const observer = new IntersectionObserver(
      ([entry], observer) => {
        if (!entry.isIntersecting) return;
        mainStore.analyticsOnProductsShown.push(analyticsData);
        observer.disconnect();
      },
      { threshold: 0.8, rootMargin: '0%' },
    );
    observer.observe(ref.current);
    return () => observer.disconnect();
    //eslint-disable-next-line
  }, []);
}
