import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ScrollRestoration from '../components/ScrollRestoration';
import { SyncSubcategory, scrollTo, closeTags } from '../UI';
import { mainStore } from '../stores/MainStore';
import { catalogStore } from '../stores/CatalogStore';
import { PRODUCT_DESCRIPTION_LENGTH } from '../config';
import { toJS } from 'mobx';
import 'swiper/swiper.scss';
import Popover from '../components/Popover';
import Dialog from '../components/Dialog';
import { useDrag } from 'react-use-gesture';
import PageHeader from '../components/PageHeader';
import ProductList from '../components/ProductList';
import { Category } from '../types/Category/interface';

type CategoryListProps = {
  categoryList: Category[] | null;
};

type SubcategoryListProps = {
  subcategoryList: Category[] | null;
  sendAnalytics?: boolean;
};

type ProductGroupProps = {
  subcategoryList: Category[] | null;
  isSuitableAppVersion?: boolean;
  isReady: boolean;
  source: string;
  onReady: () => void;
};

type CategoryDescriptionProps = {
  name: string;
  description: string;
  categoryId: number;
};

let isInit = true;

const CategoryList = observer(({ categoryList }: CategoryListProps) => {
  const history = useHistory();
  const { subCat2Id } = useParams<{ subCat2Id: string }>();
  const refCategoryList = useRef<HTMLDivElement>(null);
  const refActiveBadge = useRef<HTMLDivElement>(null);
  const isActiveBadge = (id: number) => {
    if (subCat2Id !== id.toString()) return;
    return refActiveBadge;
  };

  useEffect(() => {
    isInit = true;
  }, []);

  useEffect(() => {
    if (!refActiveBadge || !refActiveBadge.current || !refCategoryList || !refCategoryList.current) return;
    scrollTo(refCategoryList.current,
      refActiveBadge.current,
      isInit ? 0 : 500,
      refActiveBadge.current.clientWidth / 2 - refCategoryList.current.clientWidth / 2, 'x',
    );
    isInit = false;
  });

  if (categoryList !== null && !categoryList.length) return <></>;

  return (
    <div
      className="category-list-slider text-nowrap overflow-auto px-16 flex-shrink-0 hide-scroll-bar"
      ref={refCategoryList}
    >
      {categoryList !== null ? (
        categoryList.map((item) => (
          <div
            className={htmlClasses('badge _category', {
              '_active': subCat2Id === item.id.toString(),
            })}
            ref={isActiveBadge(item.id)}
            key={item.id}
            onClick={() => history.push(`/category/${item.id}`)}
          >
            {item.name}
          </div>
        ))
      ) : (
        <Skeleton className="w-120 d-inline-block h-30 br-30 mr-8" count={4} />
      )}
    </div>
  );
});

export const SubcategoryList = observer(
  ({ subcategoryList, sendAnalytics = true }: SubcategoryListProps) => {
    const sendAnalytic = (catId: number, source: string) => {
      if (!sendAnalytics) return;
      mainStore.sendToRN('analytics', {
        name: 'Catalog: category clicked',
        params: {
          category: catId,
          source: source,
        },
      });
      mainStore.sendToRN('firebaseAnalytics', {
        name: 'catalog_category_clicked',
        params: {
          category: catId,
          source: source,
        },
      });
      mainStore.sendToRN('analytics', {
        name: 'Catalog: category visited',
        params: {
          lvl3_category_id: catId,
          source: source,
        },
      });
    };

    if (subcategoryList !== null && !subcategoryList.length) return <></>;

    return (
      <>
        {subcategoryList !== null ? (
          subcategoryList.map((item) => (
            <div
              className="badge _subcategory cursor-pointer"
              key={item.id}
              data-id={item.id}
              onClick={() => sendAnalytic(item.id, 'category')}
            >
              {item.name}
            </div>
          ))
        ) : (
          <Skeleton className="w-120 d-inline-block h-30 br-30 mr-8" count={4} />
        )}
      </>
    );
  });

export const ProductGroup = observer(
  ({ subcategoryList, isSuitableAppVersion, isReady, source, onReady }: ProductGroupProps) => {
    const { t } = useTranslation();
    const handleShowNotFindProductPopover = () => mainStore.setIsNotFindProductPopover(true);
    const handleScrollTop = () => scrollTo('scroll-layout', 0, 200);

    if (subcategoryList !== null && !subcategoryList.length)
      return (
        <div className="text-center p-28 fs-14 c-gray">{t('comingSoon')}</div>
      );

    return (
      <>
        {subcategoryList !== null ? (
          <>
            {!isReady && <CategorySkeleton />}
            <div className={htmlClasses({ 'd-none': !isReady })}>
              {subcategoryList.map((item) => (
                <div className="product-list" data-id={item.id} key={item.id}>
                  <div className="product-list__header d-flex align-items-end justify-content-between c-bg-white pb-12 mx-n24 px-24">
                    <div className="fw-500">
                      {item.name}
                      <span className="c-tc7 ml-8 flex-shrink-0">{item.products.length || 0}</span>
                    </div>
                  </div>
                  <CategoryDescription name={item.name} description={item.description || ''} categoryId={item.id} />
                  <ProductList
                    productList={item.products}
                    source={source}
                    categoryId={item.id}
                    onReady={onReady}
                  />
                </div>
              ))}
            </div>
            {subcategoryList.length > 0 && isReady && source !== 'purchased' && (
              <>
                {isSuitableAppVersion && (
                  <div
                    className="button _bordered w-100p mt-10"
                    onClick={handleShowNotFindProductPopover}
                  >
                    {t('categoryPage:canNotFindItems')}
                  </div>
                )}
                <div className="button _bordered w-100p mt-10" onClick={handleScrollTop}>
                  {t('backToTop')}
                </div>
              </>
            )}
          </>
        ) : (
          <CategorySkeleton />
        )}
      </>
    );
  },
);

const CategorySkeleton = observer(() => {
  return (
    <>
      {[1, 2, 3].map((item, i) => (
        <div className="product-list" key={i}>
          <Skeleton className="fs-16 w-50p mb-12 mt-16" />
          <div className="d-flex mx-n4 flex-wrap">
            {[1, 2, 3, 4].map((subitem, j) => (
              <div className="w-50p px-4 pb-8 flex-shrink-0" key={j}>
                <Skeleton className="product-item br-8 h-200" />
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
});

export const CategoryDescription = observer(
  ({ name, description, categoryId }: CategoryDescriptionProps) => {
    const { t } = useTranslation();
    const refDescription = useRef<HTMLDivElement>(null);
    const [isShowPopover, setIsShowPopover] = useState(false);
    const handleShowPopover = () => {
      mainStore.sendToRN('analytics', {
        name: 'Recipe: See more',
        params: {
          lvl3_category_id: categoryId,
          category_name: name,
        },
      });
      setIsShowPopover(true);
    };
    const handleDismissPopover = () => setIsShowPopover(false);

    useEffect(() => {
      if (!refDescription.current) return;
      const elText = refDescription.current.querySelector<HTMLElement>('p:nth-child(3)');
      if (!elText) return;
      elText.innerHTML = closeTags(
        elText.innerHTML.slice(0, PRODUCT_DESCRIPTION_LENGTH - 3)) + '...';
    }, [refDescription]);

    if (!description.length) return <></>;

    const popoverComponent = (
      <>
        <div className="d-flex align-items-center justify-content-between py-16">
          <div className="fs-19">{name}</div>
          {!mainStore.isDesktop && (
            <div
              className="icon icon-close d-flex flex-center s-24 fs-20"
              onClick={handleDismissPopover}
            />
          )}
        </div>
        <div className="overflow-auto mx-n24">
          <div
            className="product-list__description-popover"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </>
    );

    return (
      <>
        <div
          className={htmlClasses('product-list__description', { 'mx-0': mainStore.isDesktop })}
          dangerouslySetInnerHTML={{ __html: description }}
          ref={refDescription}
          onClick={handleShowPopover}
        />
        <div className="text-right fs-14 mt-5 mb-16 c-blue" onClick={handleShowPopover}>
          {t('seeMore')}
        </div>
        {!mainStore.isDesktop ? (
          <Popover
            className="d-flex flex-direction-column overflow-hidden pt-0"
            isShow={isShowPopover}
            onBackdropDismiss={handleDismissPopover}
          >
            {popoverComponent}
          </Popover>
        ) : (
          <Dialog show={isShowPopover} onBackdrop={handleDismissPopover}>
            <div className="d-flex flex-direction-column overflow-hidden pt-0">
              {popoverComponent}
            </div>
          </Dialog>
        )}
      </>
    );
  });

let countReadyCategory = 0;

export default observer(() => {
  const history = useHistory();
  const { subCat2Id } = useParams<{ subCat2Id: string }>();
  const { state } = useLocation<{ isDeeplink?: boolean; scrollToId?: string | null }>();
  const refSubcategyList = useRef<HTMLDivElement>(null);
  const refScrollLayout = useRef<HTMLDivElement>(null);
  const [categoryName, setCategoryName] = useState('');
  const [subCategories, setSubCategories] = useState<Category[] | null>(null);
  const [isSuitableAppVersion, setIsSuitableAppVersion] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [categoryList, setCategoryList] = useState<(Category & {
    parentCategoryName: string
  })[] | null>(
    null);
  const handleSearchLink = () => {
    catalogStore.setSearchQuery('');
    mainStore.sendToRN('analytics', {
      name: 'Catalog: search clicked',
      params: {
        source: 'category',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'catalog_search_clicked',
      params: {
        source: 'category',
      },
    });
  };
  const handleIsReady = () => {
    countReadyCategory += 1;
    if (countReadyCategory === subCategories?.length) setIsReady(true);
  };

  const bind = useDrag(({ movement: [mx], cancel, memo }) => {
    if (!categoryList) {
      cancel();
      return false;
    }
    const currentIndex = categoryList.findIndex(
      (category) => category.id === parseInt(subCat2Id, 10));
    if (Math.abs(mx) > 50) {
      cancel();
      if (!memo) {
        const category = categoryList[currentIndex + (mx > 0 ? -1 : 1)];
        if (category) {
          history.push(`/category/${category.id}`);
          return true;
        }
      }
    }
  });

  useEffect(() => {
    if (!subCat2Id) return;
    if (history.action === 'PUSH') {
      catalogStore.resetProductsOutOfStockExpandedList();
      scrollTo(refScrollLayout.current, 0, 0);
    }
    countReadyCategory = 0;
    setIsReady(false);
    setSubCategories(null);
    setCategoryName(
      (categoryList || []).find(
        (category) => category.id.toString() === subCat2Id)?.parentCategoryName || '');
    catalogStore
      .requestProducts(subCat2Id, state?.isDeeplink || false)
      .then((e) => {
        if (!e) {
          setSubCategories([]);
          return;
        }
        setCategoryName(e.categoryName);
        setSubCategories(e.subcategory);
        mainStore.sendToRN('analytics', {
          name: 'view_item_list',
          params: {
            item_list_name: e.categoryName,
            item_list_id: subCat2Id,
          },
        });
      })
      .catch(() => setSubCategories([]));
    //eslint-disable-next-line
  }, [subCat2Id, state, history.action]);

  useEffect(() => {
    catalogStore.requestCategories();
  }, []);

  useEffect(() => {
    if (!subCategories?.length || !isReady) return;
    const syncSubcategory = new SyncSubcategory(
      refScrollLayout.current,
      refSubcategyList.current,
      subCat2Id,
    );
    if (state && state?.scrollToId) {
      scrollTo(
        refScrollLayout.current,
        syncSubcategory.getSubcategoryOffsetById(state.scrollToId),
        300,
      );
    }
    return () => syncSubcategory.destroy();
    //eslint-disable-next-line
  }, [subCategories, isReady, catalogStore.productsOutOfStockExpandedList.length]);

  useEffect(() => {
    if (!subCategories?.length) return;
    const isSuitableAppVersionNew = mainStore.isSuitableAppVersion('1.0.12');
    if (!isSuitableAppVersionNew) mainStore.setIsOutdatedAppPopover(true);
    setIsSuitableAppVersion(isSuitableAppVersionNew);
  }, [subCategories]);

  useEffect(() => {
    if (!subCat2Id) return;
    mainStore.sendToRN('analytics', {
      name: 'Catalog: category visited',
      params: {
        category: subCat2Id,
        source: 'homepage',
      },
    });
  }, [subCat2Id]);

  useEffect(() => {
    if (!catalogStore.categoryList) return;
    const list = mainStore.flatArray(catalogStore.categoryList.map((category) => {
      return toJS(category.subcategory).map((subcategory) => {
        return { ...subcategory, parentCategoryName: category.name || '' };
      });
    }));
    setCategoryList(list);
    setCategoryName(
      list.find((category) => category.id.toString() === subCat2Id)?.parentCategoryName || '');
    //eslint-disable-next-line
  }, [catalogStore.categoryList]);

  return (
    <>
      {subCategories && isReady && <ScrollRestoration pageName="category" />}
      <div className="d-flex h-50 flex-shrink-0 align-items-center">
        <div className="flex-grow-1">
          <PageHeader
            title={categoryName}
            iconClickAction={
              () => history.push({ pathname: '/', state: { scrollToCategoryId: subCat2Id } })
            }>
            <Link
              className="icon icon-search d-flex flex-center s-50 fs-20"
              to={{ pathname: '/search', state: { source: 'category' } }}
              onClick={handleSearchLink}
            />
          </PageHeader>
        </div>


      </div>
      <CategoryList categoryList={categoryList} />
      <div
        className="text-nowrap overflow-auto px-16 flex-shrink-0 hide-scroll-bar py-12"
        ref={refSubcategyList}
      >
        <SubcategoryList subcategoryList={subCategories} />
      </div>
      <div
        className={htmlClasses('scroll-layout h-100p px-24', {
          'overflow-hidden': !subCategories || !isReady,
        })}
        ref={refScrollLayout}
      >
        <div {...bind()}>
          <ProductGroup
            subcategoryList={subCategories}
            isSuitableAppVersion={isSuitableAppVersion}
            isReady={isReady}
            source="category"
            onReady={handleIsReady}
          />
        </div>
        <div className="h-24" />
      </div>
    </>
  );
});
