import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable, isHydrated } from 'mobx-persist-store';
import {
  CustomerRequests,
  RequestCodeResponse,
  SubscriptionFlag,
  UpdatePersonalFields,
  VerifyCodeResponse,
  Customer,
  BonusesTransaction,
} from '../api/Customer';
import { AddressComponent, AddressComponentType, GoogleMapGeocode } from '../api/GoogleMapGeocode';
import { findNearest } from 'geolib';
import {
  DeliveryRequests,
  Address,
  UpdateAddressRequest,
  AddAddressRequest,
  DeliveryZoneCoverage,
  AutocompleteRequest,
  AutocompleteResponse,
} from '../api/DeliveryAddress';
import { mainStore } from './MainStore';
import { catalogStore } from './CatalogStore';
import { orderStore } from './OrderStore';
import { desktopStore } from './DesktopStore';
import { AxiosError } from 'axios';
import { refreshTokens, ApiErrorResponse } from '../api/Requests';
import { company } from '../company/Company';
import { checkoutStore } from './CheckoutStore';

type AddressComponents = Partial<Record<AddressComponentType, Omit<AddressComponent, 'types'>>>;

export type DeliveryAddress = {
  zip: string;
  country: string;
  city: string;
  region: string;
  address1: string;
  address2: string;
  shortAddress: string;
  coordinates: GeoCoordinates;
  placeId: string;
  addressId: string | null;
  comment: string;
  instructions: string[];
};

export type PersonalData = {
  id: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  phone: string | null;
  flat: string;
  dateOfBirth: string | null;
  email: string | null;
  lastLogin: string | null;
  subscriptionFlags: Record<string, SubscriptionFlag>;
  subscriptionId: string;
  isAdult: boolean;
  isRateApp: boolean;
  type: 'User' | 'Staff';
  freeDeliveryDaysLeft: number;
};

type BonusesTransactionsPagination = {
  page: number;
  total: number;
}

class UserStore {
  isFirstLaunch = true;
  isAuthorized = false;
  deliveryAddress: DeliveryAddress | null = null;
  token = '';
  refreshToken = '';
  personalData: PersonalData = {
    id: null,
    firstName: null,
    middleName: null,
    lastName: null,
    phone: null,
    flat: '',
    dateOfBirth: null,
    email: null,
    lastLogin: null,
    subscriptionFlags: {},
    subscriptionId: '',
    isAdult: false,
    isRateApp: false,
    type: 'User',
    freeDeliveryDaysLeft: 0,
  };
  deviceId = '';
  deviceId_frontOnly = '00000000-0000-0000-0000-000000000000';
  playerId = '';
  sessionList: string[] = [];
  addressList: Address[] = [];
  bonuses: number | null = null;
  bonusesTransactionsList: BonusesTransaction[] = [];
  bonusesTransactionsPagination: BonusesTransactionsPagination = {
    total: 0,
    page: 1,
  };

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'UserStore',
      properties: [
        'isFirstLaunch',
        'isAuthorized',
        'deliveryAddress',
        'token',
        'refreshToken',
        'personalData',
        'deviceId',
        'playerId',
        'sessionList',
      ],
      storage: window.localStorage,
    }).catch((error) => error && console.error(error));
  }

  // Getters
  get isSynchronized(): boolean {
    return isHydrated(this);
  }

  get fullName(): string {
    return `${this.personalData.firstName || ''} ${this.personalData.middleName || ''} ${
      this.personalData.lastName || ''
    }`
      .trim()
      .replace('  ', ' ');
  }

  get isStaff(): boolean {
    return this.personalData.type === 'Staff';
  }

  // Setters
  setIsFirstLaunch(flag: boolean) {
    this.isFirstLaunch = flag;
  }

  setDeliveryAddress(deliveryAddress: DeliveryAddress | null) {
    this.deliveryAddress = deliveryAddress;
  }

  setDeliveryAddress1(val: string) {
    if (!this.deliveryAddress) return;
    this.deliveryAddress.address1 = val;
    this.deliveryAddress.shortAddress = `${this.deliveryAddress.zip}, ${val}`;
  }

  setDeliveryAddress2(val: string) {
    if (!this.deliveryAddress) return;
    this.deliveryAddress.address2 = val;
  }

  setPersonalDataName(val: string) {
    this.personalData = { ...this.personalData, ...this.splitFullName(val) };
  }

  setPersonalData(data: Omit<Customer, 'app_rating' | 'type'>) {
    if (!data.id) return;
    this.personalData.id = data.id;
    this.personalData.firstName = data.first_name;
    this.personalData.middleName = data.middle_name;
    this.personalData.lastName = data.last_name;
    this.personalData.dateOfBirth = data.date_of_birth;
    this.personalData.email = data.email;
    this.personalData.lastLogin = data.last_login;
    this.personalData.isAdult = data.age_restriction || false;
    this.personalData.type = data.customer_type?.code || 'User';
    this.personalData.freeDeliveryDaysLeft = data.freeDeliveryDaysLeft || 0;
    if (this.personalData.subscriptionId && data.subscription?.length) {
      for (let i = 0; i < data.subscription.length; i++) {
        if (data.subscription[i].id !== this.personalData.subscriptionId) continue;
        for (let j = 0; j < data.subscription[i].subscription_flags.length; j++) {
          this.personalData.subscriptionFlags[data.subscription[i].subscription_flags[j].name] =
            data.subscription[i].subscription_flags[j];
        }
      }
    }
    const fullName = `${data.first_name || ''} ${data.middle_name || ''} ${data.last_name || ''}`
      .trim()
      .replace('  ', ' ');
    mainStore.sendToRN('setUserName', {
      welcomeText: i18n.t('hello'),
      name: fullName,
    });
    mainStore.setSentryUser({
      id: data.id,
      name: fullName,
      email: data.email || '',
    });
    mainStore.sendToRN('sendTags', {
      first_name: data.first_name || '',
    });
    mainStore.sendToRN('setOneSignalUserID', {
      id: data.id,
    });
  }

  setIsAdult(flag: boolean) {
    this.personalData.isAdult = flag;
    this.updatePersonalData({
      age_restriction: flag,
      email: this.personalData.email || '',
    }).catch((error) => error && console.error(error));
  }

  setIsRateApp(flag: boolean) {
    this.personalData.isRateApp = flag;
    this.updatePersonalData({
      app_rating: flag,
      email: this.personalData.email || '',
    }).catch((error) => error && console.error(error));
  }

  setToken(val: string) {
    this.token = val;
  }

  setRefreshToken(val: string) {
    this.refreshToken = val;
  }

  setDeviceId(val: string) {
    this.deviceId = val;
    if (!val || val === '00000000-0000-0000-0000-000000000000') {
      if (this.isAuthorized) this.logout().catch((error) => error && console.error(error));
      Sentry.withScope((scope) => {
        scope.setExtras({
          deviceId: val,
        });
        Sentry.captureMessage('[DeviceID] Received invalid deviceID', 'warning');
      });
    }
  }

  setPlayerId(val: string) {
    this.playerId = val;
  }

  setSubscriptionFlags(flags: Record<string, SubscriptionFlag>) {
    this.personalData.subscriptionFlags = flags;
  }

  setAddressList(addressList: Address[]) {
    this.addressList = addressList;
  }

  resetCustomerType() {
    this.personalData.type = 'User';
    this.personalData.freeDeliveryDaysLeft = 0;
    orderStore.setIsFirstOrder(true);
  }

  setBonuses(bonuses: number | null) {
    this.bonuses = bonuses;
  }

  setBonusesTransactionsList(list: BonusesTransaction[]) {
    if (!list.length) {
      this.bonusesTransactionsList = [];
    } else {
      this.bonusesTransactionsList.push(...list);
    }
  }

  setBonusesTransactionsPagination(val: BonusesTransactionsPagination) {
    this.bonusesTransactionsPagination = val;
  }

  // Actions
  getShortAddress(addressComponents: AddressComponents): string {
    let address = '';
    if (addressComponents.street_number && addressComponents.route) {
      address += addressComponents.street_number.long_name;

      if (addressComponents.route) {
        if (address) address += ' ';
        address += addressComponents.route.long_name;
      }
      if (addressComponents.administrative_area_level_1) {
        if (address) address += ', ';
        address += addressComponents.administrative_area_level_1.long_name;
      }
      if (addressComponents.locality) {
        if (address) address += ', ';
        address += addressComponents.locality.long_name;
      }
      if (addressComponents.country) {
        if (address) address += ', ';
        address += addressComponents.country.long_name;
      }
    } else if (addressComponents.premise || addressComponents.route || addressComponents.neighborhood) {
      if (addressComponents.premise) {
        address += addressComponents.premise.long_name;
      }
      if (addressComponents.route) {
        if (address) address += ', ';
        address += addressComponents.route.long_name;
      }
      if (addressComponents.neighborhood) {
        if (address) address += ', ';
        address += addressComponents.neighborhood.long_name;
      }
    } else {
      if (addressComponents.locality) {
        address += addressComponents.locality.long_name;
      }
      if (addressComponents.administrative_area_level_1) {
        if (address) address += ', ';
        address += addressComponents.administrative_area_level_1.long_name;
      }
      if (addressComponents.country) {
        if (address) address += ', ';
        address += addressComponents.country.long_name;
      }
    }
    return address;
  }

  splitFullName(fullName: string): { firstName: string; middleName: string; lastName: string } {
    let firstName = '';
    let middleName = '';
    let lastName = '';
    if (!fullName) return { firstName, middleName, lastName };
    let nameParts = fullName.replace(/\s+/g, ' ').split(' ');
    nameParts = nameParts.filter((item) => item.length);
    if (nameParts.length === 1) {
      firstName = nameParts[0];
      middleName = '';
      lastName = '';
    } else if (nameParts.length === 2) {
      firstName = nameParts[0];
      middleName = '';
      lastName = nameParts[1];
    } else if (nameParts.length >= 3) {
      firstName = nameParts[0];
      middleName = nameParts[1];
      lastName = nameParts.slice(2).join(' ').trim();
    }
    return { firstName, middleName, lastName };
  }

  sendSubscriptionAnalytics(subscriptionFlags: SubscriptionFlag[], source: string) {
    const sendAnalytics = ({ name, push = false, email = false }: SubscriptionFlag) => {
      mainStore.sendAnalytics(['analytics', 'firebase'], {
        name: 'Subscribe: change settings',
        params: {
          type: 'push',
          kind: name,
          toggle: push ? 'on' : 'off',
          source: source,
        },
      });
      mainStore.sendAnalytics(['analytics', 'firebase'], {
        name: 'Subscribe: change settings',
        params: {
          type: 'email',
          kind: name,
          toggle: email ? 'on' : 'off',
          source: source,
        },
      });
    };
    const tagData: {
      subscribed_personal_offers?: string;
      subscribed_discounts?: string;
      subscribed_thirdparty?: string;
    } = {};
    for (let i = 0; i < subscriptionFlags.length; i++) {
      let flag = '';
      const { name, push = false, email = false } = subscriptionFlags[i];
      if (push && email) flag = 'all';
      else if (!push && !email) flag = '0';
      else flag = push ? 'push' : 'email';
      sendAnalytics(subscriptionFlags[i]);
      if (name === 'personalised') {
        tagData['subscribed_personal_offers'] = flag;
      }
      if (name === 'discounts') {
        tagData['subscribed_discounts'] = flag;
      }
      if (name === 'third_party') {
        tagData['subscribed_thirdparty'] = flag;
      }
    }
    if (Object.keys(tagData).length) mainStore.sendToRN('sendTags', tagData);
  }

  // Requests API
  requestAuthCode(
    phone: string, recaptchaToken: string): Promise<RequestCodeResponse | AxiosError> {
    if (!desktopStore.desktopDeviceId) return Promise.reject();
    return CustomerRequests.requestCode({
      phone_number: phone,
      recaptcha_token: recaptchaToken,
      device_id: desktopStore.desktopDeviceId,
    }).catch((e) => this.errorHandler(e, 'requestAuthCode'));
  }

  verifyAuthCode(phone: string, code: string, restore: boolean): Promise<VerifyCodeResponse & {
    previousEmail: string | null
  } | AxiosError> {
    if (!desktopStore.desktopDeviceId) return Promise.reject();
    const isAdult = this.personalData.isAdult;
    return CustomerRequests.verifyCode({
      phone_number: phone,
      otp: code,
      device_id: desktopStore.desktopDeviceId,
      ...(restore ? { restore } : {}),
    })
      .then((e) => {
        const previousEmail = this.personalData.email;
        runInAction(() => {
          if (e.verified && e.access_token && e.refresh_token) {
            this.personalData.id = e.customer.id;
            this.personalData.firstName = e.customer.first_name;
            this.personalData.middleName = e.customer.middle_name;
            this.personalData.lastName = e.customer.last_name;
            this.personalData.phone = phone;
            this.personalData.dateOfBirth = e.customer.date_of_birth;
            this.personalData.email = e.customer.email;
            this.personalData.lastLogin = e.customer.last_login;
            this.personalData.isAdult = e.customer.age_restriction || false;
            this.personalData.isRateApp = e.customer.app_rating || false;
            this.personalData.type = e.customer.type?.code || 'User';
            this.personalData.freeDeliveryDaysLeft = e.customer.freeDeliveryDaysLeft || 0;
            this.token = e.access_token;
            this.refreshToken = e.refresh_token;
            this.isAuthorized = true;
            this.sessionList.push(new Date().toISOString());
            if (
              catalogStore.cart.length && (e.customer.freeDeliveryDaysLeft > 0)
            ) {
              mainStore.setIsFreeDeliveryPopover(true);
            }
            if (catalogStore.favoritesList.length) {
              catalogStore.addFavorite(
                catalogStore.favoritesList.map((item) => {
                  return { product_id: item.id };
                }),
              );
            }
            if (isAdult && !e.customer.age_restriction) {
              this.updatePersonalData({
                age_restriction: true,
                email: this.personalData.email || '',
              }).catch((error) => error && console.error(error));
            }
            this.setAddressList(e.addresses || []);
            if (this.deliveryAddress?.address1) {
              for (let i = 0; i < e.addresses.length; i++) {
                if (e.addresses[i].street_address_1 === this.deliveryAddress.address1) {
                  this.deliveryAddress.addressId = e.addresses[i].id;
                  this.deliveryAddress.comment = e.addresses[i].comment || '';
                  this.deliveryAddress.instructions = e.addresses[i].instructions || [];
                  this.deliveryAddress.address2 = e.addresses[i].street_address_2 || '';
                  break;
                }
              }
            }
            if (
              this.deliveryAddress &&
              this.deliveryAddress.address1 &&
              !this.deliveryAddress.addressId
            ) {
              this.addAddress({
                street_address_1: this.deliveryAddress.address1,
                street_address_2: this.deliveryAddress.address2,
                city: this.deliveryAddress.city,
                postcode: this.deliveryAddress.zip,
                latitude: this.deliveryAddress.coordinates.lat,
                longitude: this.deliveryAddress.coordinates.lng,
                country: this.deliveryAddress.country,
                comment: this.deliveryAddress.comment,
                instructions: this.deliveryAddress.instructions,
              })
                .then((e) => {
                  runInAction(() => {
                    if (!this.deliveryAddress) return;
                    this.deliveryAddress.addressId = e;
                  });
                })
                .catch((error) => error && console.error(error));
            }
            const fullName = `${e.customer.first_name || ''} ${e.customer.middle_name || ''} ${
              e.customer.last_name || ''
            }`
              .trim()
              .replace('  ', ' ');
            mainStore.setSentryUser({
              id: e.customer.id,
              name: fullName,
              email: e.customer.email || '',
              phone: phone,
            });
            mainStore.sendToRN('analytics', {
              name: 'Login: code entered',
              params: {
                success: true,
              },
            });
            mainStore.sendToRN('firebaseAnalytics', {
              name: 'login_code_entered',
              params: {
                success: true,
              },
            });
            mainStore.sendToRN('setOneSignalUserID', {
              id: e.customer.id,
            });

            // when user signed up, check for the first order
            orderStore.checkIsFirstOrder(true)
              .then(() => {
                catalogStore.calculateCart().catch((error) => error && console.error(error));
              })
              .catch((error) => error && console.error(error));

            mainStore.sendToRN('setUserProperties', {
              'General: logged in': true,
            });
            mainStore.sendToRN('setUserProperties', {
              'General: login date': new Date().toISOString(),
            });
            mainStore.sendToRN('setUserProperties', {
              'General: last session date': this.sessionList[this.sessionList.length - 1],
            });
            mainStore.sendToRN('setUserProperties', {
              'General: total sessions': this.sessionList.length,
            });
            mainStore.sendToRN('setUserProperties', {
              'General: user type': 'normal',
            });
            mainStore.sendToRN('setUserProperties', {
              userId: e.customer.id,
            });
            mainStore.sendToRN('firebaseAnalytics', {
              name: 'login',
              params: {
                method: 'SMS',
              },
            });
          } else {
            mainStore.pushAlert('error', i18n.t('errors:otpCodeError'));
            mainStore.sendToRN('analytics', {
              name: 'Login: code entered',
              params: {
                success: false,
              },
            });
            mainStore.sendToRN('firebaseAnalytics', {
              name: 'login_code_entered',
              params: {
                success: false,
              },
            });
            mainStore.sendToRN('setUserProperties', {
              'General: logged in': false,
            });
            mainStore.sendToRN('setUserProperties', {
              userId: null,
            });
          }
        });
        return Promise.resolve({ ...e, previousEmail });
      })
      .catch((e) => {
        mainStore.sendToRN('analytics', {
          name: 'Login: code entered',
          params: {
            success: false,
          },
        });
        mainStore.sendToRN('firebaseAnalytics', {
          name: 'login_code_entered',
          params: {
            success: false,
          },
        });
        return this.errorHandler(e, 'verifyAuthCode');
      });
  }

  async requestPersonalData() {
    if (!this.personalData.id) return Promise.reject();
    try {
      const [data] = await CustomerRequests.getPersonalData(this.personalData.id);
      if (!data) return;
      this.setPersonalData(data);

      // when user signed up, check for the first order
      orderStore.checkIsFirstOrder()
        .then(() => {
          catalogStore.calculateCart().catch((error) => error && console.error(error));
        })
        .catch((error) => error && console.error(error));
    } catch (error) {
      this.errorHandler(error, 'requestPersonalData').catch(
        (error) => error && console.error(error),
      );
      return Promise.reject();
    }
  }

  async updatePersonalData(data: UpdatePersonalFields) {
    if (!this.personalData.id) return Promise.reject();
    try {
      const { customer: [customerData] = [] } = await CustomerRequests.updatePersonalData(
        this.personalData.id, data);
      if (!customerData) {
        mainStore.pushAlert('error', i18n.t('errors:failedUpdatePersonal'));
        await this.requestPersonalData().catch((error) => error && console.error(error));
        return Promise.reject();
      }
      this.setPersonalData(customerData);
    } catch (error) {
      mainStore.pushAlert('error', i18n.t('errors:failedUpdatePersonal'));
      await this.requestPersonalData().catch((error) => error && console.error(error));
      return Promise.reject();
    }
  }

  async updatePersonalEmail(email: string): Promise<boolean> {
    if (!this.personalData.id) return false;
    try {
      const { customer: [customerData] = [] } = await CustomerRequests.updatePersonalData(
        this.personalData.id,
        {
          email: email,
        },
      );
      if (!customerData) {
        await CustomerRequests.updatePersonalData(this.personalData.id, { email: '' }).catch(
          (error) => error && console.error(error),
        );
        mainStore.pushAlert('error', i18n.t('errors:emailAlreadyExists'));
        return false;
      }
      this.setPersonalData(customerData);
      return true;
    } catch (error) {
      mainStore.pushAlert('error', i18n.t('errors:emailAlreadyExists'));
    }
    return false;
  }

  async updateDeviceData(name: string, email: string, flags: SubscriptionFlag[]): Promise<boolean> {
    if (!desktopStore.desktopDeviceId) return false;
    try {
      const { device } = await CustomerRequests.updateDeviceData(
        desktopStore.desktopDeviceId,
        {
          name: name,
          email: email.trim(),
          subscription_flags: flags,
        },
      );
      runInAction(() => {
        const { firstName, middleName, lastName } = this.splitFullName(device.name || '');
        this.personalData.firstName = firstName;
        this.personalData.middleName = middleName;
        this.personalData.lastName = lastName;
        this.personalData.email = device.email;
        if (
          device.subscriptions?.subscription_flags &&
          device.subscriptions?.subscription_flags?.length
        ) {
          for (let i = 0; i < device.subscriptions.subscription_flags.length; i++) {
            this.personalData.subscriptionFlags[device.subscriptions.subscription_flags[i].name] =
              device.subscriptions.subscription_flags[i];
          }
        }
        mainStore.sendToRN('sendTags', {
          first_name: firstName || '',
        });
      });
      return true;
    } catch (e) {
      await this.errorHandler(e, 'updateDeviceData');
    }
    return false;
  }

  async updateSubscription(flags: SubscriptionFlag[], source: string): Promise<boolean> {
    if (!desktopStore.desktopDeviceId) return false;
    try {
      const data = await CustomerRequests.updateSubscription(
        desktopStore.desktopDeviceId,
        {
          subscription_flags: flags,
        },
      );
      runInAction(() => {
        if (data.subscription.subscription_flags && data.subscription.subscription_flags.length) {
          for (let i = 0; i < data.subscription.subscription_flags.length; i++) {
            this.personalData.subscriptionFlags[data.subscription.subscription_flags[i].name] =
              data.subscription.subscription_flags[i];
          }
          this.sendSubscriptionAnalytics(data.subscription.subscription_flags, source);
        } else this.personalData.subscriptionFlags = {};
        this.personalData.subscriptionId = data.subscription.id;
      });
      return true;
    } catch (e) {
      await this.errorHandler(e, 'updateSubscription');
    }
    return false;
  }

  requestNewToken() {
    if (!this.isAuthorized) return Promise.reject();

    return CustomerRequests.refreshToken({
      refresh_token: this.refreshToken,
      customer_id: this.personalData.id || '',
    }).then(({ access_token, refresh_token }) => {
      this.setToken(access_token);
      this.setRefreshToken(refresh_token);
    });
  }

  async logout() {
    if (!this.isAuthorized) return Promise.reject();

    try {
      await CustomerRequests.logout({
        refresh_token: this.refreshToken,
        customer_id: this.personalData.id || '',
      });
    } catch (error) {
      console.error(error);
    }
    runInAction(() => {
      this.isAuthorized = false;
      this.token = '';
      this.refreshToken = '';
      this.personalData.id = null;
      this.personalData.firstName = null;
      this.personalData.middleName = null;
      this.personalData.lastName = null;
      this.personalData.phone = null;
      this.personalData.email = null;
      this.personalData.flat = '';
      this.personalData.dateOfBirth = null;
      this.personalData.lastLogin = null;
      this.personalData.isAdult = false;
      this.personalData.isRateApp = false;
      this.personalData.type = 'User';
      this.personalData.freeDeliveryDaysLeft = 0;
      this.addressList = [];
      if (this.deliveryAddress) {
        this.deliveryAddress.comment = '';
        this.deliveryAddress.instructions = [];
      }
      this.setBonuses(0);
      Object.keys(this.personalData.subscriptionFlags).forEach((flagName) => {
        this.personalData.subscriptionFlags[flagName].email = false;
      });
      orderStore.setIsFirstOrder(true);
      orderStore.setOrderStatus('none');
      orderStore.setActiveOrderID('');
      orderStore.setPaymentCards([]);
      orderStore.setActiveGift(null);
      orderStore.setGift(null);
      catalogStore.resetPromocode();
      catalogStore.proposedPromocodes = [];
      catalogStore.favorites = {};
      mainStore.resetAnalytics();
      checkoutStore.setPayments([]);
    });
    refreshTokens.reset();
    Sentry.setUser({ ip_address: '{{auto}}' });
    mainStore.sendToRN('logout');
    mainStore.sendToRN('setUserProperties', {
      userId: null,
    });
  }

  async deleteProfile() {
    if (!this.isAuthorized) return Promise.reject();

    try {
      await CustomerRequests.deleteProfile();
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  geocodingByCoordinates(mapCenter: GeoCoordinates): Promise<DeliveryAddress | null> {
    return GoogleMapGeocode.geocodingByCoordinates({
      latlng: `${mapCenter.lat},${mapCenter.lng}`,
    })
      .then((e) => {
        let deliveryAddress: DeliveryAddress | null = null;
        if (e.status === 'OK') {
          if (e.results.length) {
            const country = e.results[0].address_components.reduce(
              (str, item) => str ? str : item.types.includes('country') ? item.short_name : '', '');
            const addressComponentType: AddressComponentType = country === 'GB' ? 'postal_code' : 'plus_code';
            e.results = e.results
              .slice(0, 6)
              .filter((item) =>
                item.address_components.some((el) => el.types.includes(addressComponentType)),
              );
            if (!e.results.length) return Promise.resolve(deliveryAddress);
            let index = 0;
            if (e.results.length > 1) {
              const pointsStringify: string[] = [];
              const points = e.results.map((item) => {
                const point = {
                  latitude: item.geometry.location.lat,
                  longitude: item.geometry.location.lng,
                };
                pointsStringify.push(JSON.stringify(point));
                return point;
              });
              index = pointsStringify.indexOf(
                JSON.stringify(
                  findNearest(
                    {
                      latitude: mapCenter.lat,
                      longitude: mapCenter.lng,
                    },
                    points,
                  ),
                ),
              );
            }
            const addressComponents: AddressComponents = {};
            e.results[index].address_components.forEach((item) => {
              addressComponents[item.types[0]] = {
                long_name: item.long_name,
                short_name: item.short_name,
              };
            });
            const address1 = this.getShortAddress(addressComponents);
            const shortAddress =
              (addressComponents.postal_code?.long_name || addressComponents.plus_code?.long_name) && address1
                ? `${address1}, ${addressComponents.postal_code?.long_name || addressComponents.plus_code?.long_name}`
                : address1 ? address1 : addressComponents.postal_code?.long_name || addressComponents.plus_code?.long_name || '';
            deliveryAddress = {
              zip: addressComponents.postal_code?.long_name ||
                addressComponents.plus_code?.long_name || '000000',
              country: addressComponents.country?.short_name || '',
              city:
                addressComponents.postal_town?.long_name ||
                addressComponents.locality?.long_name ||
                addressComponents.administrative_area_level_1?.long_name ||
                addressComponents.country?.long_name ||
                '',
              region:
                addressComponents.administrative_area_level_2?.long_name ||
                addressComponents.country?.long_name ||
                '',
              address1: shortAddress,
              address2: '',
              shortAddress: shortAddress,
              coordinates: e.results[index].geometry.location,
              placeId: e.results[index].place_id,
              addressId: null,
              comment: '',
              instructions: [],
            };
          }
        }
        return Promise.resolve(deliveryAddress);
      })
      .catch((error) => {
        error && console.error(error);
        return null;
      });
  }


  async getAutocomplete(query: AutocompleteRequest): Promise<AutocompleteResponse> {
    try {
      return await DeliveryRequests.autocomplete(query);
    } catch (e) {
      await this.errorHandler(e, 'getAutocomplete');
    }
    return {
      data: {
        result: null,
        predictions: [],
      },
    };
  }

  async getDeliveryZones(): Promise<DeliveryZoneCoverage[]> {
    try {
      const { data: { coverages = [] } } = await DeliveryRequests.fetchDeliveryZones();
      return coverages;
    } catch (e) {
      console.error(e);
    }
    return [];
  }

  async fetchAddresses(): Promise<void> {
    try {
      const { data } = await DeliveryRequests.getAddresses();
      this.setAddressList(data || []);
    } catch (e) {
      this.setAddressList([]);
      await this.errorHandler(e, 'fetchAddresses');
    }
  }

  async addAddress(address: AddAddressRequest): Promise<string> {
    try {
      const { data } = await DeliveryRequests.addAddress(address);
      return data.id;
    } catch (e) {
      return '';
    }
  }

  async updateAddress(id: string, data: UpdateAddressRequest): Promise<boolean> {
    try {
      await DeliveryRequests.updateAddress(id, data);
      return true;
    } catch (e) {
      return false;
    }
  }

  async deleteAddress(id: string): Promise<boolean> {
    try {
      await DeliveryRequests.deleteAddress(id);
      return true;
    } catch (e) {
      return false;
    }
  }

  async requestBonuses() {
    if (!this.isAuthorized || company.isDisableLoyaltyProgram) {
      this.setBonuses(null);

      return;
    }

    try {
      const bonuses = await CustomerRequests.requestBonuses();
      if (bonuses || bonuses === 0) this.setBonuses(bonuses);
    } catch (e) {
      this.setBonuses(null);
      console.error(e);
    }
  }

  /**
   * @param {number} page - page number
   * @param {boolean} reset - needs to reset history for requesting full list on page open
   */
  async requestBonusesTransactions(page = 1, reset?: boolean): Promise<number> {
    const size = 10;

    if (reset) {
      this.setBonusesTransactionsPagination({
        total: 0,
        page: 1,
      });
      this.setBonusesTransactionsList([]);
      page = 1;
    }

    if (page < this.bonusesTransactionsPagination.page) {
      // does not call "requestBonusesTransactions", if items for page were fetched
      return this.bonusesTransactionsPagination.total - this.bonusesTransactionsList.length;
    }

    try {
      const list = await CustomerRequests.requestBonusesTransactions(
        { 'pagination[page]': page, 'pagination[size]': size });
      this.setBonusesTransactionsList(list.items || []);

      this.setBonusesTransactionsPagination({
        page,
        total: list.pagination.total,
      });

      return list.pagination.total;
    } catch (e) {
      this.bonusesTransactionsList = [];
      return 0;
    }
  }

  // Errors
  errorHandler = (error: AxiosError<ApiErrorResponse>, context: string): Promise<AxiosError> =>
    mainStore.errorHandler(error, context);
}

export const userStore = new UserStore();
