import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import InputText from './InputText';
import htmlClasses from 'html-classes';
import { mainStore } from '../stores/MainStore';
import Dialog from './Dialog';

export default observer(() => {
  const { t } = useTranslation();
  const [messageVal, setMessageVal] = useState('');
  const handleDismiss = () => {
    mainStore.setIsNotFindProductPopover(false);
    setMessageVal('');
  };
  const handleSend = () => {
    mainStore.setIsNotFindProductPopover(false);
    mainStore.sendToRN('logAmplitudeEvent', {
      name: 'Catalog: assortment feedback',
      params: {
        message: messageVal,
      },
    });
    setMessageVal('');
  };
  const handleChangeMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessageVal(e.currentTarget.value);
  };

  const component = (
    <>
      <InputText className="mt-10" value={messageVal}>
        <textarea
          className="input-textarea"
          rows={3}
          placeholder={t('categoryPage:whatWereYouLookingFor')}
          onChange={handleChangeMessage}
          value={messageVal}
        />
      </InputText>
      <div className="d-flex align-items-center justify-content-end mt-24">
        <div
          className="h-40 mr-20 px-10 lh-40 c-blue fs-14 fw-500 cursor-pointer"
          onClick={handleDismiss}
        >
          {t('close')}
        </div>
        <div
          className={htmlClasses('button _primary w-100 h-40 fs-14', { _disabled: !messageVal })}
          onClick={handleSend}
        >
          {t('send')}
        </div>
      </div>
    </>
  );

  if (mainStore.isDesktop) {
    return (
      <Dialog show={mainStore.isNotFindProductPopover} onBackdrop={handleDismiss}>
        <div className="h-50" />
        {component}
      </Dialog>
    );
  } else {
    return (
      <Popover isShow={mainStore.isNotFindProductPopover} onBackdropDismiss={handleDismiss}>
        {component}
      </Popover>
    );
  }
});
