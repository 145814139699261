import { observer } from 'mobx-react-lite';
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyMasked, IMaskInput } from 'react-imask';
import InputPhone from '../../components/InputPhone/InputPhone';
import InputText from '../../components/InputText';
import PageHeader from '../../components/PageHeader';
import { userStore } from '../../stores/UserStore';
import { mainStore } from '../../stores/MainStore';
import { useHistory, useLocation } from 'react-router-dom';
import htmlClasses from 'html-classes';
import { desktopStore } from '../../stores/DesktopStore';
import { useGoogleRecaptchaHook } from './RecaptchaWidget/hooks/useGoogleRecaptchaHook';
import { RecaptchaWidget } from './RecaptchaWidget/RecaptchaWidget';
import i18n from 'i18next';
import { ResponseErrorCodes } from './constants';
import { OtpErrorBody } from './interface';
import SmsRequestTimer from './SmsRequestTimer/SmsRequestTimer';
import ZeroDeviceIdPopover from './ZeroDeviceIdPopover/ZeroDeviceIdPopover';
import ApplySMSButton from './ApplySMSButton/ApplySMSButton';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import { company } from '../../company/Company';
import Popover from '../../components/Popover';

export default observer(() => {
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<{ redirectToPage?: string }>();
  const [phoneVal, setPhoneVal] = useState(userStore.personalData.phone || '');
  const [phoneValid, setPhoneValid] = useState(false);

  const [codeVal, setCodeVal] = useState('');
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [isPendingCode, setIsPendingCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRecaptchaLoading, setIsRecaptchaLoading] = useState(true);
  const [isZeroDeviceId, setIsZeroDeviceId] = useState(false);
  const [canRequestAgain, setCanRequestAgain] = useState(false);
  const [maxAttemptsThreshold, setMaxAttemptsThreshold] = useState(0);
  const [isDeletedUser, setIsDeletedUser] = useState(false);
  const [isShowPopoverDeletedUser, setIsShowPopoverDeletedUser] = useState(false);

  const RecaptchaRef = useRef<RecaptchaProps>(null);
  const { isRecaptchaChallengeOpened, startCaptcha, getCaptchaToken } =
    useGoogleRecaptchaHook(RecaptchaRef);
  const phoneNumber = useRef<string>('');

  useEffect(() => {
    if (isRecaptchaChallengeOpened) {
      setIsLoading(false);
    }
  }, [isRecaptchaChallengeOpened]);

  useEffect(() => {
    const checkConnection = () => {
      if (!window.navigator.onLine) {
        mainStore.setIsNoInternetPopover(true);

        return;
      }
    };

    if ((window.navigator as Navigator & { connection: any })?.connection) {
      (window.navigator as Navigator & { connection: any }).connection.addEventListener(
        'change', checkConnection);
    }

    return () => {
      if ((window.navigator as Navigator & { connection: any })?.connection) {
        (window.navigator as Navigator & { connection: any }).connection.removeEventListener(
          'change', checkConnection);
      }
    };
  }, []);

  const handlePopoverIsDeletedUserDismiss = () => setIsShowPopoverDeletedUser(false);

  const onResolvedRecaptchaHandler = async () => {
    try {
      const res = await userStore.requestAuthCode(phoneNumber.current, getCaptchaToken());
      if ('isDeleted' in res && res.isDeleted) setIsDeletedUser(true);
      setIsPendingCode(true);
      setCanRequestAgain(false);
    } catch (error) {
      if (error.response?.status === 429 && !error.response?.data?.message) {
        mainStore.pushAlert(
          'warning',
          `<div>${t('errors:unknown')}<br>${t('errors:supportLink')}</div>`,
          () => history.push('/chat'),
        );
      }
    }
    setIsLoading(false);
  };

  const onErrorRecaptchaHandler = () => {
    setIsLoading(false);
    setIsRecaptchaLoading(false);
    mainStore.pushAlert('error', t('errors:recaptchaError'));
  };

  const handlePhoneChange = (val: string) => {
    setPhoneVal(val);
    phoneNumber.current = val;
  };

  const handlePhoneFocus = () => {
    if (phoneVal === '') setPhoneVal(company.config.defaultPhoneCode || '');
  }

  const handlePhoneValidate = (flag: boolean) => {
    setPhoneValid(flag);

    if (!flag) {
      setCodeVal('');
      setIsCodeValid(false);
      setIsPendingCode(false);
      setMaxAttemptsThreshold(0);
    } else {
      mainStore.sendToRN('analytics', {
        name: 'Login: phone entered',
        params: {},
      });
      mainStore.sendToRN('firebaseAnalytics', {
        name: 'login_phone_entered',
      });
    }
  };

  const handleCodeChange = (val: string, mask: AnyMasked) => {
    setCodeVal(val);
    setIsCodeValid(mask.masked.isComplete);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isPendingCode) {
      handleRequestCode().catch((error) => error && console.error(error));
    } else {
      if (isDeletedUser) setIsShowPopoverDeletedUser(true);
      else handleVerifyCode()
        .catch((error) => error && console.error(error))
        .then(() => {
          if (mainStore.isDesktop && userStore.isAuthorized) {
            desktopStore.setShowLogin(false);
            history.push({
              pathname: location.pathname,
              search: '',
            });
          }
        });
    }
  };

  const handleRestoreUser = () =>
    handleVerifyCode(true).catch((error) => error && console.error(error));

  const handleRequestCode = async (): Promise<void> => {
    if (!phoneVal) return;

    setIsLoading(true);

    if (!startCaptcha()) {
      // code below will fire error when user click on button but recaptcha was not loaded yet
      onErrorRecaptchaHandler();
    }
  };

  const handleVerifyCode = async (restore?: boolean): Promise<void> => {
    if (!phoneVal || !codeVal) return;
    setIsLoading(true);
    try {
      const data = await userStore.verifyAuthCode(phoneVal, codeVal, Boolean(restore));
      if ('customer' in data && !data.customer.email && data.previousEmail) {
        mainStore.pushAlert('error', t('authPage:emailIsAlreadyUse'), () => {
          history.push('/notifications');
        });
      }
    } catch (error) {
      const errorBody: OtpErrorBody = error?.response?.data || {};
      if (errorBody.code) {
        switch (errorBody.code) {
          case ResponseErrorCodes.InvalidOtp:
            mainStore.pushAlert('error', i18n.t('authPage:invalidCodeError'));
            break;
          case ResponseErrorCodes.TryOtpLimitIsReached:
            mainStore.pushAlert(
              'error',
              i18n.t(
                'authPage:tryOtpLimitIsReached',
                { timer: errorBody.timeout ? ` in ${errorBody.timeout}s` : '' },
              ),
            );
            setMaxAttemptsThreshold(errorBody.timeout ?? 0);
            setIsPendingCode(false);
            setCodeVal('');
            break;
          default:
            mainStore.pushAlert('error', t('errors:otpCodeError'));
        }
      } else {
        mainStore.pushAlert('error', t('errors:otpCodeError'));
      }
      error && console.error(error);
    }
    setIsLoading(false);
  };
  const onDismissPopover = () => setIsZeroDeviceId(false);

  useEffect(() => {
    mainStore.sendToRN('analytics', {
      name: 'Login: screen shown',
      params: {},
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'login_screen_shown',
    });
  }, []);

  useEffect(() => {
    if (!userStore.isAuthorized) return;
    history.replace(`/${state?.redirectToPage || ''}`);
    //eslint-disable-next-line
  }, [userStore.isAuthorized]);

  return (
    <>
      {!mainStore.isDesktop && <PageHeader />}
      <div className={htmlClasses('scroll-layout', { 'px-40 pt-70': !mainStore.isDesktop })}>
        <h1>{t('yourPhone')}</h1>
        <div className="auth__desc fs-16 c-tad mt-10">{t('authPage:announce')}</div>
        <form onSubmit={handleSubmit}>
          <InputPhone
            className="mt-30 mb-10"
            label={t('phone')}
            value={phoneVal}
            onChange={handlePhoneChange}
            onFocus={handlePhoneFocus}
            onValidate={handlePhoneValidate}
          />

          {phoneValid && isPendingCode && (
            <>
              <InputText
                className="mb-10"
                label={t('authPage:inputCodePlaceholder')}
                value={codeVal}
              >
                {isCodeValid && <div className="icon icon-check input-text__icon _check" />}
                <IMaskInput
                  className="input-text"
                  mask="0000"
                  value={codeVal}
                  unmask={true}
                  type="tel"
                  autoFocus={true}
                  onAccept={handleCodeChange}
                  autoComplete="one-time-code"
                />
              </InputText>
              <ApplySMSButton isLoading={isLoading} isDisabled={!phoneValid || !isCodeValid}>
                {t('sendCode')}
              </ApplySMSButton>

              <div className="mt-16 fs-14 cursor-default">
                {!canRequestAgain ? (
                  <SmsRequestTimer onTimerEnd={() => setCanRequestAgain(true)} />
                ) : (
                  t('authPage:recievedCode')
                )}
              </div>
              <div
                className={htmlClasses(
                  'fs-14 mt-5',
                  { 'c-mgray pe-n': !canRequestAgain },
                  { 'c-blue cursor-pointer': canRequestAgain },
                )}
                onClick={handleRequestCode}
              >
                {t('authPage:sendAgain')}
              </div>
            </>
          )}

          {!isPendingCode && (
            <>
              <ApplySMSButton
                isLoading={isLoading}
                isDisabled={!phoneValid || isRecaptchaLoading || maxAttemptsThreshold > 0}
              >
                {t('loginIn')}
              </ApplySMSButton>

              {maxAttemptsThreshold > 0 && (
                <div className="mt-16 fs-14 ">
                  <SmsRequestTimer onTimerEnd={() => setMaxAttemptsThreshold(
                    0)} timeoutThreshold={maxAttemptsThreshold} />
                </div>
              )}

              <TermsAndConditions />
            </>
          )}
        </form>

        <RecaptchaWidget
          sitekey={mainStore.recaptchaSiteID}
          ref={RecaptchaRef}
          onLoaded={() => {
            setIsRecaptchaLoading(false);
          }}
          onResolved={onResolvedRecaptchaHandler}
          onError={onErrorRecaptchaHandler}
        />
        <div className="h-24" />
      </div>

      <ZeroDeviceIdPopover isZeroDeviceId={isZeroDeviceId} onDismissPopover={onDismissPopover} />

      <Popover isShow={isShowPopoverDeletedUser} onBackdropDismiss={handlePopoverIsDeletedUserDismiss}>
        <h2>{t('accountStatus')}</h2>
        <div className="fs-14 lh-20 mt-20 c-mgray">{t('accountDeleted')}</div>
        <div className="d-flex align-items-center justify-content-end mt-20">
          <div
            className="h-40 mr-20 px-10 lh-40 c-blue fs-14 fw-500"
            onClick={handlePopoverIsDeletedUserDismiss}
          >
            {t('cancel')}
          </div>
          <button
            className="button _primary w-180 h-40 fs-14"
            onClick={handleRestoreUser}
          >
            {t(`recoverAccount`)}
          </button>
        </div>
      </Popover>
    </>
  );
});
