import { observer } from 'mobx-react-lite';
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';
import htmlClasses from 'html-classes';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {
  loadStripe,
  Stripe,
  StripeCardElementChangeEvent,
  StripeCardElement,
  PaymentIntentResult,
} from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';
import { PaymentMethods } from '../pages/Checkout';
import { checkoutStore, MobilePaymentMethod } from '../stores/CheckoutStore';
import InputText from './InputText';
import Checkbox from './Checkbox';
import { PAYMENT_TIMEOUT, REQUEST_STRIPE_CONFIRM_TIMEOUT } from '../config';
import { userStore } from '../stores/UserStore';
import Dialog from './Dialog';
import IllustrationFailedPayment from '../assets/img/failed_payment.svg';
import { ETAPaymentMethod } from '../api/ETA';
import { company } from '../company/Company';

type PopoverFormProps = {
  orderId: string;
  tipAmount: string;
}

const PopoverForm = observer(({ orderId, tipAmount }: PopoverFormProps) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [isCardEmpty, setIsCardEmpty] = useState('');
  const [agreeSaveCard, setAgreeSaveCard] = useState(true);
  const [error, setError] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isInstantPay, setIsInstantPay] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentError, setIsPaymentError] = useState(false);
  const handleDismiss = () => {
    mainStore.setIsPayTipPopover(false);
    orderStore.setTipAmount('');
  };
  const handleAgreeChange = () => setAgreeSaveCard(!agreeSaveCard);
  const handleChange = (e: StripeCardElementChangeEvent) => {
    setIsCardEmpty(e.empty ? '' : 'true');
    setIsDisabled(!e.complete);
    setError(e.error ? e.error.message : '');
  };
  const isDisabledForm = (): boolean => {
    if (isLoading || stripe === null || elements === null) return true;
    if (checkoutStore.activePaymentMethod === ETAPaymentMethod.Card) return isDisabled;
    return false;
  };
  const handlePay = async () => {
    setIsLoading(true);
    try {
      let isSaveCard = agreeSaveCard;
      if (
        window.ReactNativeWebView &&
        checkoutStore.activePaymentMethod === MobilePaymentMethod.ApplePay &&
        mainStore.isApplePay
      ) {
        isSaveCard = false;
      }
      if (
        window.ReactNativeWebView &&
        checkoutStore.activePaymentMethod === MobilePaymentMethod.GooglePay &&
        mainStore.isGooglePay
      ) {
        isSaveCard = false;
      }
      const clientSecret = await orderStore.payTip(orderId, tipAmount, isSaveCard);
      if (!clientSecret || !elements || !stripe) {
        setIsPaymentError(true);
        setIsLoading(false);
        return;
      }
      if (
        window.ReactNativeWebView &&
        checkoutStore.activePaymentMethod === MobilePaymentMethod.ApplePay &&
        mainStore.isApplePay
      ) {
        applePay(clientSecret).catch((error) => error && console.error(error));
        return;
      }
      if (
        window.ReactNativeWebView &&
        checkoutStore.activePaymentMethod === MobilePaymentMethod.GooglePay &&
        mainStore.isGooglePay
      ) {
        googlePay(clientSecret).catch((error) => error && console.error(error));
        return;
      }
      if (isInstantPay) {
        let paymentMethod: { card: StripeCardElement } | string;
        if (checkoutStore.payments && checkoutStore.payments.length && !checkoutStore.isAddNewCard) {
          paymentMethod = checkoutStore.paymentsMethodId || checkoutStore.payments[0].value;
        } else paymentMethod = { card: elements.getElement(CardElement) as StripeCardElement };
        confirmCardPayment(clientSecret, paymentMethod).catch(
          (error) => error && console.error(error));
      } else {
        confirmCardPayment(clientSecret, {
          card: elements.getElement(CardElement) as StripeCardElement,
        }).catch((error) => error && console.error(error));
      }
    } catch (error) {
      setIsPaymentError(true);
      setIsLoading(false);
      error && console.error(error);
    }
  };
  const applePay = async (clientSecret: string) => {
    if (!clientSecret) {
      setIsPaymentError(true);
      setIsLoading(false);
      return;
    }
    try {
      const paymentResult = await new Promise<'success' | 'failed' | 'canceled' | 'timeout'>(
        (resolve) => {
          const timeout = setTimeout(() => {
            resolve('timeout');
          }, PAYMENT_TIMEOUT);
          mainStore.sendToRN(
            'initApplePay',
            {
              params: {
                cartItems: [
                  {
                    label: company.config.name,
                    amount: tipAmount,
                    type: 'final',
                  },
                ],
                country: 'GB',
                currency: orderStore.currency,
                shippingMethods: [],
              },
              clientSecret: clientSecret,
            },
            () => {
              clearTimeout(timeout);
              resolve('success');
            },
            (e) => {
              clearTimeout(timeout);
              resolve(e.error?.code === 'Canceled' ? 'canceled' : 'failed');
            },
          );
        },
      );
      if (paymentResult === 'success') {
        setIsLoading(false);
        handleDismiss();
        mainStore.pushAlert(
          'success',
          orderStore.activeOrderID ? t('ratePopover:thanksTitleTip') : t(
            'ratePopover:thanksTitleAll'),
        );
      } else {
        setIsPaymentError(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsPaymentError(true);
      setIsLoading(false);
      Sentry.withScope((scope) => {
        scope.setExtras({
          error: error,
        });
        Sentry.captureMessage('[ApplePay] Error exception', 'warning');
      });
    }
  };
  const googlePay = async (clientSecret: string) => {
    if (!clientSecret) {
      setIsPaymentError(true);
      setIsLoading(false);
      return;
    }
    try {
      const paymentResult = await new Promise<'success' | 'failed' | 'canceled' | 'timeout'>(
        (resolve) => {
          const timeout = setTimeout(() => {
            resolve('timeout');
          }, PAYMENT_TIMEOUT);
          mainStore.sendToRN(
            'requestGooglePayment',
            {
              transaction: {
                totalPrice: tipAmount,
                totalPriceStatus: 'FINAL',
                currencyCode: orderStore.currency.toUpperCase(),
              },
              merchantName: userStore.fullName,
              clientSecret: clientSecret,
            },
            () => {
              clearTimeout(timeout);
              resolve('success');
            },
            (e) => {
              clearTimeout(timeout);
              resolve(
                e.confirmationError?.code === 'PAYMENT_RESULT_CANCELED' ? 'canceled' : 'failed',
              );
            },
          );
        },
      );
      if (paymentResult === 'success') {
        setIsLoading(false);
        handleDismiss();
        mainStore.pushAlert(
          'success',
          orderStore.activeOrderID ? t('ratePopover:thanksTitleTip') : t(
            'ratePopover:thanksTitleAll'),
        );
      } else {
        setIsPaymentError(true);
        setIsLoading(false);
      }
    } catch (error) {
      setIsPaymentError(true);
      setIsLoading(false);
      Sentry.withScope((scope) => {
        scope.setExtras({
          error: error,
        });
        Sentry.captureMessage('[GooglePay] Error exception', 'warning');
      });
    }
  };
  const confirmCardPayment = async (
    clientSecret: string, paymentMethod: { card: StripeCardElement } | string) => {
    if (!clientSecret || !stripe) {
      setIsPaymentError(true);
      setIsLoading(false);
      return;
    }
    try {
      const paymentResult = await new Promise<PaymentIntentResult | 'failed' | 'timeout'>(
        (resolve, reject) => {
          const timeout = setTimeout(() => {
            resolve('timeout');
          }, REQUEST_STRIPE_CONFIRM_TIMEOUT);
          const timeoutFroze = setTimeout(() => {
            resolve('timeout');
          }, PAYMENT_TIMEOUT);
          stripe
            .confirmCardPayment(clientSecret, {
              payment_method: paymentMethod,
            })
            .then((e) => {
              clearTimeout(timeout);
              clearTimeout(timeoutFroze);
              resolve(e);
            })
            .catch((e) => {
              clearTimeout(timeout);
              clearTimeout(timeoutFroze);
              reject(e);
            });
        },
      );
      if (paymentResult === 'timeout' || paymentResult === 'failed' || paymentResult.error) {
        setIsPaymentError(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        handleDismiss();
        mainStore.pushAlert(
          'success',
          orderStore.activeOrderID ? t('ratePopover:thanksTitleTip') : t(
            'ratePopover:thanksTitleAll'),
        );
      }
    } catch (error) {
      setIsPaymentError(true);
      setIsLoading(false);
    }
  };
  const handleResetPaymentError = () => {
    setIsPaymentError(false);
    setIsInstantPay(false);
  };

  useEffect(() => {
    if (!stripe || !elements) {
      mainStore.pushAlert('error', t('errors:paymentSystemNotLoaded'));
      Sentry.captureMessage('[Checkout] Failed Stripe init', 'warning');
      handleDismiss();
      return;
    }
    if (checkoutStore.activePaymentMethod === MobilePaymentMethod.ApplePay || checkoutStore.activePaymentMethod === MobilePaymentMethod.GooglePay) {
      setIsInstantPay(true);
    } else if (Array.isArray(checkoutStore.payments) && checkoutStore.payments.length > 0) {
      setIsInstantPay(true);
    } else {
      setIsInstantPay(false);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isInstantPay) handlePay().catch((error) => error && console.error(error));
    //eslint-disable-next-line
  }, [isInstantPay]);

  return (
    <>
      {isPaymentError ? (
        <>
          <img className="h-130 d-block mx-auto mb-24" src={IllustrationFailedPayment} alt="" />
          <div
            className="text-center mb-24 fs-21 lh-30"
            dangerouslySetInnerHTML={{ __html: t('ratePopover:paymentErrorText') }}
          />
          <div className="d-flex align-items-center justify-content-end mx-n4">
            <button
              className="button _secondary w-100p mx-4 flex-shrink-1"
              onClick={handleDismiss}
            >
              {t('skip')}
            </button>
            <button
              className="button _primary w-100p mx-4 flex-shrink-1"
              onClick={handleResetPaymentError}
            >
              {t('changePayment')}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="fs-21 lh-25  fw-500">{t('pay')}{' '}{mainStore.addCurrencySymbol(
            tipAmount)}</div>
          {isInstantPay === false && (
            <>
              <PaymentMethods />
              {(checkoutStore.activePaymentMethod === null || checkoutStore.activePaymentMethod === ETAPaymentMethod.Card) && (
                <div className="pt-12">
                  <InputText className="mt-10 px-12 pt-15" value={isCardEmpty}>
                    <CardElement
                      className="z-3 position-relative"
                      options={checkoutStore.cardStyle}
                      onChange={handleChange}
                    />
                  </InputText>
                  {error.length > 0 && (
                    <div className="alert _error mt-10 z-auto">{error}</div>
                  )}
                  <div className="d-flex align-items-start mt-20 mb-20 ml-14" onClick={handleAgreeChange}>
                    <Checkbox className="mr-10 flex-shrink-0" checked={agreeSaveCard} />
                    <div className="fs-13 c-gray">{t('checkoutPage:saveCardCheckbox')}</div>
                  </div>
                </div>
              )}
            </>
          )}
          <div className="d-flex align-items-center justify-content-end mt-24">
            <div
              className={htmlClasses('fs-14 c-blue mr-20 px-10', { 'pe-n': isLoading })}
              onClick={handleDismiss}
            >
              {t('cancel')}
            </div>
            <button
              className="button _primary w-100 flex-shrink-0"
              onClick={handlePay}
              disabled={isDisabledForm()}
            >
              {isLoading ? <span className="spinner" /> : t('pay')}
            </button>
          </div>
        </>
      )}
    </>
  );
});

export default observer(() => {
  const { t } = useTranslation();
  const [stripePromise, setStripePromise] = useState<Stripe | null | undefined>(undefined);
  const [orderId, setOrderId] = useState('');
  const [tipAmount, setTipAmount] = useState('');
  const init = useCallback(async () => {
    try {
      const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_API_KEY as string, {
        locale: 'en-GB',
      });
      setStripePromise(stripePromise || null);
      return true;
    } catch (error) {
      Sentry.captureMessage('[Checkout] Failed Stripe loading', 'warning');
      error && console.error(error);
    }
    return false;
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    init().catch((error) => error && console.error(error));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mainStore.isPayTipPopover && !stripePromise) {
      init().then((success) => {
        if (!success) {
          mainStore.setIsPayTipPopover(false);
          mainStore.pushAlert('error', t('errors:paymentSystemNotLoaded'));
        }
      }).catch((error) => error && console.error(error));
    }
    //eslint-disable-next-line
  }, [mainStore.isPayTipPopover, stripePromise]);

  useEffect(() => {
    if (!orderStore.activeOrderID) return;
    setOrderId(orderStore.activeOrderID);
    //eslint-disable-next-line
  }, [orderStore.activeOrderID]);

  useEffect(() => {
    if (!orderStore.tipAmount) return;
    setTipAmount(orderStore.tipAmount);
    //eslint-disable-next-line
  }, [orderStore.tipAmount]);

  if (!mainStore.isDesktop) {
    return (
      <Popover isShow={mainStore.isPayTipPopover && !!stripePromise}>
        {stripePromise !== undefined && (
          <Elements stripe={stripePromise}>
            <PopoverForm orderId={orderId} tipAmount={tipAmount} />
          </Elements>
        )}
      </Popover>
    );
  } else {
    return (
      <Dialog show={mainStore.isPayTipPopover && !!stripePromise} rejectable={false}>
        <div className="h-24" />
        {stripePromise !== undefined && (
          <Elements stripe={stripePromise}>
            <PopoverForm orderId={orderId} tipAmount={tipAmount} />
          </Elements>
        )}
      </Dialog>
    );
  }
});
