import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { catalogStore } from '../stores/CatalogStore';
import InputText from './InputText';
import htmlClasses from 'html-classes';
import useDebounce from './DebounceHook';
import { useTranslation } from 'react-i18next';
import { mainStore } from '../stores/MainStore';
import { useHistory, useLocation } from 'react-router-dom';
import { SEARCH_PRODUCTS_STANDARD_LENGTH } from '../config';
import { HistoryAndPopular } from '../pages/Search';
import OutsideAlerter from './OutsideAlerter';
import { desktopStore } from '../stores/DesktopStore';
import { Product } from '../types/Product/interface';

interface IProductSuggestionProps {
  show: boolean;
  onProductClick: () => void;
}

const ProductsSuggestion = observer(({ show, onProductClick }: IProductSuggestionProps) => {
  if (!show) return <></>;

  const handleProductClick = (product: Product) => {
    onProductClick();
    desktopStore.setSavedSource('search');
    desktopStore.setSelectedId(String(product.id));
    desktopStore.setShowProduct(true);
  };

  if (!catalogStore.searchProductList.length) return <></>;

  return (
    <div className="mt-12 mb-12 overflow-hidden">
      {catalogStore.searchProductList.slice(0, 10).map((item, i) => (
        <React.Fragment key={i}>
          {i !== 0 && <div className="h-1 c-bg-tf2 my-12" />}
          <div
            className="d-flex align-items-center fs-14 fw-500 px-12 cursor-pointer"
            onClick={() => handleProductClick(item)}
          >
            <span className="text-nowrap">{item.name}</span>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
});

export default observer(() => {
  const refSearchField = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isBlur, setIsBlur] = useState(true);
  const debSearchVal = useDebounce<string>(desktopStore.searchBarValue, 500);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const handleSearchFocus = () => {
    mainStore.sendAnalyticsToFirebase({
      name: 'catalog_search_clicked',
      params: {
        source: 'homepage',
      },
    });
    setIsFocused(true);
    setIsBlur(false);
  };

  const handleSearchBlur = () => {
    setIsBlur(true);
  };

  useEffect(() => {
    if (!isBlur) return;
    const timeout = setTimeout(() => {
      // setIsFocused(false);
    }, 100);
    return () => clearTimeout(timeout);
  }, [isBlur]);

  useEffect(() => {
    if (debSearchVal.length < 2 || !isFocused) return;
    setIsLoading(true);
    // setShowAllProducts(false);
    catalogStore
      .requestSearch(debSearchVal, 'typein', SEARCH_PRODUCTS_STANDARD_LENGTH)
      .catch(() => void 0)
      .finally(() => setIsLoading(false));
    //eslint-disable-next-line
  }, [debSearchVal]);

  useEffect(() => {
    setIsFocused(false);
  }, [location.pathname]);

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    desktopStore.setSearchBarValue(e.currentTarget.value);
    catalogStore.setSearchQuery(e.currentTarget.value);
  };

  const clearSearch = () => {
    desktopStore.setSearchBarValue('');
  };

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSetSuggestion(desktopStore.searchBarValue);
    }
  };

  const handleSetSuggestion = (value: string): void => {
    setIsFocused(false);
    refSearchField.current?.blur();
    desktopStore.setSearchBarValue(value);
    catalogStore.setSearchQuery(value);
    history.push(`/search/${value}`);
    mainStore.setPageScrollY('search', 0);
    catalogStore.updateSearchHistory(value);
    catalogStore.resetProductsOutOfStockExpandedList();
  };

  return (
    <InputText
      className={htmlClasses('desktop-search search-bar mx-20 flex-grow-1 h-40', {
        'desktop-search--active': isFocused,
      })}
      value={desktopStore.searchBarValue}
      inputTextBorder={false}
    >
      {!isLoading ? (
        <div className="icon icon-search search-bar__icon-search c-salad d-flex flex-center fs-20 pe-n h-40" />
      ) : (
        <div className="icon spinner mt-8 ml-8" />
      )}
      {desktopStore.searchBarValue.length > 0 && (
        <div
          className="icon icon-close s-24 fs-18 c-t99 d-flex flex-center cursor-pointer"
          onClick={clearSearch}
        />
      )}
      <input
        className="input-text desktop-search__input h-100p"
        ref={refSearchField}
        inputMode="search"
        placeholder={t('searchPage:inputPlaceholder')}
        onChange={handleSearchChange}
        value={desktopStore.searchBarValue}
        onKeyPress={handleEnter}
        onFocus={handleSearchFocus}
        onBlur={handleSearchBlur}
      />
      {/*{catalogStore.searchProductList.length > 0 && !isLoading && (
        <div className="text-nowrap overflow-auto mt-16 px-16 flex-shrink-0 hide-scroll-bar" />
      )}*/}
      <div
        className={htmlClasses('desktop-search__suggests', {
          'border-teb':
            (!desktopStore.searchBarValue && isFocused) ||
            (desktopStore.searchBarValue.length > 2 &&
              catalogStore.searchProductList.length &&
              isFocused),
        })}
      >
        <OutsideAlerter onOutsideClick={() => setIsFocused(false)}>
          {!desktopStore.searchBarValue && isFocused && (
            <HistoryAndPopular
              onSetSuggest={handleSetSuggestion}
              isLoading={isLoading}
              showHistory={!desktopStore.searchBarValue}
            />
          )}
          {desktopStore.searchBarValue.length > 2 && (
            <ProductsSuggestion show={isFocused} onProductClick={() => setIsFocused(false)} />
          )}
        </OutsideAlerter>
      </div>
    </InputText>
  );
});
