import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { mainStore } from '../../../stores/MainStore';
import { orderStore } from '../../../stores/OrderStore';
import { userStore } from '../../../stores/UserStore';
import IconGift from '../../../assets/img/icon_gift.svg';
import htmlClasses from 'html-classes';
import { ProductBadge } from '../../../components/Badges/ProductBadge/ProductBadge';
import Popover from '../../../components/Popover';
import ActiveGift from './ActiveGift/ActiveGift';
import { catalogStore } from '../../../stores/CatalogStore';

const Gift = observer(() => {
  const { t } = useTranslation();
  const [isShowPopoverChooseGift, setIsShowPopoverChooseGift] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handlePopoverChooseGiftOpen = () => {
    mainStore.sendToRN('logAmplitudeEvent', {
      name: 'Gift: Open',
      params: {
        giftMechanismID: orderStore.gift?.id,
      },
    });
    setIsShowPopoverChooseGift(true);
  };

  const handlePopoverChooseGiftDismiss = () => setIsShowPopoverChooseGift(false);

  const handleChooseGift = async (index: number) => {
    if (index < 0) {
      orderStore.setActiveGift(null);
    } else if (orderStore.gift) {
      const selectedGift = orderStore.gift.gift_items[index];

      const product = await catalogStore.requestProduct(String(selectedGift.id), false);
      if (product && product.offers[0].properties.age_restriction && !userStore.personalData.isAdult) {
        catalogStore.addToGiftCatch = {
          gift: selectedGift,
          callback: () => setIsShowPopoverChooseGift(false),
        };
        mainStore.setIsAgeRestrictionPopover(true);
        return;
      }

      orderStore.setActiveGift(selectedGift || null);
      mainStore.sendToRN('logAmplitudeEvent', {
        name: 'Gift: Selected',
        params: {
          gift_mechanism_ID: orderStore.gift?.id,
          product_name: orderStore.activeGift?.name,
        },
      });
    }
    setIsShowPopoverChooseGift(false);
  };

  useEffect(() => {
    if (!userStore.isAuthorized || !orderStore.isStoreAvailable) {
      return;
    }

    orderStore.fetchGifts().catch((error) => error && console.error(error));
    //eslint-disable-next-line
  }, [userStore.isAuthorized, orderStore.isStoreAvailable]);

  useEffect(() => {
    if (!isShowPopoverChooseGift) {
      return;
    }

    if (!orderStore.gift || !orderStore.activeGift) {
      setActiveIndex(-1);
      return;
    }

    for (let i = 0; i < orderStore.gift.gift_items.length; i++) {
      if (orderStore.gift.gift_items[i].id === orderStore.activeGift.id) {
        setActiveIndex(i);
        break;
      }
    }
  }, [isShowPopoverChooseGift]);

  if (!orderStore.isStoreAvailable) {
    return <></>;
  }

  if (!userStore.isAuthorized) {
    return (
      <>
        {/*<div className='h-1 c-bg-tf2 pe-n' />
        <div className='d-flex py-12 align-items-center overflow-hidden position-relative'>
          <div className='w-44 h-36 flex-shrink-0 d-flex flex-center'>
            <img className='flex-shrink-0' src={IconGift} alt='' />
          </div>
          <div className='pl-8 w-100p fs-12 fw-500 lh-16 c-udgray'>
            <span className='c-blue'>{t('loginIn')}</span> {t('cartPage:toReceiveGift')}
          </div>
          <Link
            className='link-abs'
            to={{ pathname: '/auth', state: { redirectToPage: 'cart' } }}
            onClick={() => orderStore.setIsAuthInCart(true)}
          />
        </div>*/}
      </>
    );
  }

  if (!orderStore.gift) {
    return <></>;
  }

  return (
    <>
      <div className='h-1 c-bg-tf2 pe-n' />

      <ActiveGift
        onPopoverChooseGiftOpen={handlePopoverChooseGiftOpen}
        activeGift={orderStore.activeGift}
        cartPriceForGift={orderStore.cartPriceForGift}
      />

      {orderStore.activeGift === null && (
        <div
          className='d-flex py-12 align-items-center overflow-hidden'
          onClick={handlePopoverChooseGiftOpen}
        >
          <div className='w-44 h-36 flex-shrink-0 d-flex flex-center'>
            <img className='flex-shrink-0' src={IconGift} alt='' />
          </div>
          <div className='px-8 w-100p fs-12 fw-500 lh-16 c-udgray'>
            {orderStore.cartPriceForGift
              ? t(
                'cartPage:addToBasketAndGetGift',
                { total: orderStore.currencySymbol + orderStore.cartPriceForGift },
              )
              : t('cartPage:chooseGift')}
          </div>
          <div className='icon icon-chevron-right s-28 fs-14 d-flex flex-center' />
        </div>
      )}

      <Popover
        className='d-flex flex-direction-column overflow-hidden'
        isShow={isShowPopoverChooseGift}
        onBackdropDismiss={handlePopoverChooseGiftDismiss}
      >
        <div className='fs-21  fw-500 mb-24 flex-shrink-0'>
          {t('cartPage:chooseGift')}
        </div>
        <div className='overflow-auto mx-n24 px-24'>
          <div
            className='d-flex align-items-center overflow-hidden'
            onClick={() => handleChooseGift(-1)}
          >
            <div className='px-8 w-100p'>
              <div className='fs-12 fw-500 lh-16 c-udgray'>{t('cartPage:withoutGift')}</div>
            </div>
            <div className={htmlClasses('radio-bullet', { _active: activeIndex === -1 })} />
          </div>
          {orderStore.gift.gift_items.map((item, i) => (
            <React.Fragment key={item.id}>
              <div className='h-1 c-bg-tf2 pe-n my-12' />
              <div
                className={htmlClasses(
                  'd-flex align-items-center overflow-hidden',
                  { 'pe-n': orderStore.cartPriceForGift },
                )}
                onClick={() => handleChooseGift(i)}
              >
                <div
                  className={htmlClasses(
                    'w-44 h-36 flex-shrink-0 img-contain position-relative',
                    { 'o-5': orderStore.cartPriceForGift },
                  )}
                  style={{
                    backgroundImage:
                      item.preview_image_thumb || item.preview_image
                        ? `url(${item.preview_image_thumb || item.preview_image})`
                        : 'none',
                  }}
                />
                <div className='px-8 w-100p'>
                  <div
                    className={`fs-12 lh-16 ${orderStore.cartPriceForGift ? 'c-mgray' : 'c-udgray'}`}
                  >
                    {item.name || ''}
                  </div>
                  <div className='d-flex align-items-center mt-4'>
                    <ProductBadge
                      type='discount'
                      className='position-relative flex-shrink-0 l-0 t-0'
                    >
                      {t('free')}
                    </ProductBadge>
                    {item.price !== undefined && (
                      <s className='fs-12 lh-16 fw-500 c-mgray'>
                        {mainStore.addCurrencySymbol(mainStore.addZeroFraction(item.price || ''))}
                      </s>
                    )}
                  </div>
                </div>
                <div
                  className={htmlClasses(
                    'radio-bullet',
                    { _active: i === activeIndex },
                    { _disabled: orderStore.cartPriceForGift },
                  )}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className='d-flex align-items-center justify-content-end mt-24 flex-shrink-0'>
          <div className='button _primary w-100p' onClick={handlePopoverChooseGiftDismiss}>
            {t('understood')}
          </div>
        </div>
      </Popover>
    </>
  );
});

export default Gift;
