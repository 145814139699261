import GoogleMapReact, { ChangeEventValue } from 'google-map-react';
import htmlClasses from 'html-classes';
import { makeAutoObservable, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, FormEvent, useEffect, useLayoutEffect, useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { a, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { uuid } from 'lodash-uuid';
import IllustrationAddressNotFound from '../../assets/img/address_not_found.svg';
import MapPoint from '../../assets/img/map_point.svg';
import IllustrationTeam from '../../assets/img/team.jpg';
import useDebounce from '../../components/DebounceHook';
import InputText from '../../components/InputText';
import { MAP_DEBOUNCE_TIME, DELIVERY_ZONES_STYLE, MAP_GEOLOCATION_ZOOM } from './constants';
import { DeliveryAddress, userStore } from '../../stores/UserStore';
import { orderStore } from '../../stores/OrderStore';
import { mainStore } from '../../stores/MainStore';
import { catalogStore } from '../../stores/CatalogStore';
import { AutocompletePrediction, AutocompleteSuggestion } from '../../api/DeliveryAddress';
import { ETACalculation, ETADeliveryMethodType } from '../../api/ETA';
import ExternalLink from '../../components/ExternalLink';
import { desktopStore } from '../../stores/DesktopStore';
import Dialog from '../../components/Dialog';
import { GOOGLE_API_KEY } from '../../config';
import { company } from '../../company/Company';
import { CompanyName } from '../../company/interface';

type DeliveryAddressZipProps = {
  shortAddress: string;
  fullAddress: string;
  onClick: () => void;
};

type SubscribeFormProps = {
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
  handleDissmis: () => void;
  type: string;
  className: string;
};

type PrivacyPolicyProps = {
  className: string;
};

type EditAddressData = {
  addressId: string | null;
  comment: string;
  instructions: string[];
};

const { map: { center: mapCenter, zoom: mapZoom, markers: mapMarkers } } = company.config;

class Store {
  isMapBusy = true;
  mapCenter: GeoCoordinates = userStore.deliveryAddress?.coordinates || mapCenter;
  mapZoom = mapZoom;
  geolocationCoordinates: GeoCoordinates | null = null;
  curGeolocationAddress: AutocompleteSuggestion | null = null;
  isActiveGeolocation = false;
  isManuallyInput = false;
  isLoading = false;
  isAwaitGeolocation = false;
  isAwaitSubscription = false;
  inputAddressValue = '';
  inputAddressFocused = false;
  addresessList: AutocompletePrediction[] = [];
  deliveryAddress: DeliveryAddress | null = null;
  etaCalculation: ETACalculation | null = null;
  isAddressNotExist = false;
  isAddressNotCovered = false;
  isSmallSizeCover = true;
  nameVal = '';
  emailVal = '';
  isShowAddressNotFound = false;
  isShowAddressFound = false;
  isPushRequested = false;
  editAddressData: EditAddressData = {
    addressId: null,
    comment: '',
    instructions: [],
  };
  isChangeDeliveryAreaPopover = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isEmailValid(): boolean {
    return mainStore.validateEmail(this.emailVal);
  }

  get isFormValid(): boolean {
    return this.isEmailValid && !!this.nameVal.length;
  }

  setIsMapBusy(flag: boolean) {
    this.isMapBusy = flag;
  }

  setIsActiveGeolocation(flag: boolean) {
    this.isActiveGeolocation = flag;
  }

  setIsManuallyInput(flag: boolean) {
    this.isManuallyInput = flag;
  }

  setIsLoading(flag: boolean) {
    this.isLoading = flag;
  }

  setInputAddressFocused(flag: boolean) {
    this.inputAddressFocused = flag;
  }

  setIsAddressNotExist(flag: boolean) {
    this.isAddressNotExist = flag;
  }

  setIsAddressNotCovered(flag: boolean) {
    this.isAddressNotCovered = flag;
  }

  setMapCenter(val: GeoCoordinates) {
    this.mapCenter = val;
  }

  setMapZoom(val: number) {
    this.mapZoom = val;
  }

  setInputAddressValue(val: string) {
    this.inputAddressValue = val;
  }

  setAddresessList(val: AutocompletePrediction[]) {
    this.addresessList = val;
  }

  setDeliveryAddress(val: DeliveryAddress | null) {
    this.deliveryAddress = !val ? val : Object.assign(val, this.editAddressData);
  }

  setEtaCalculation(val: ETACalculation | null) {
    this.etaCalculation = val;
  }

  setIsSmallSizeCover(flag: boolean) {
    this.isSmallSizeCover = flag;
  }

  setNameVal(val: string) {
    this.nameVal = val;
  }

  setEmailVal(val: string) {
    this.emailVal = val;
  }

  setIsShowAddressNotFound(flag: boolean) {
    this.isShowAddressNotFound = flag;
  }

  setIsShowAddressFound(flag: boolean) {
    this.isShowAddressFound = flag;
  }

  setIsAwaitGeolocation(flag: boolean) {
    this.isAwaitGeolocation = flag;
  }

  setIsAwaitSubscription(flag: boolean) {
    this.isAwaitSubscription = flag;
  }

  setGeolocationCoordinates(val: GeoCoordinates | null) {
    this.geolocationCoordinates = val;

    /**
     * Code below sets new center of map, together with "geolocationCoordinates"
     *
     * TODO: Not sure why this properties are different. Needs to remove one of them
     * */
    if (val) {
      this.mapCenter = val;
    }
  }

  setCurGeolocationAddress(val: AutocompleteSuggestion | null) {
    this.curGeolocationAddress = val;
  }

  setIsPushRequested(flag: boolean) {
    this.isPushRequested = flag;
  }

  setEditAddressData(val: EditAddressData) {
    this.editAddressData = val;
  }

  setIsChangeDeliveryAreaPopover(flag: boolean) {
    this.isChangeDeliveryAreaPopover = flag;
  }

  resetStore() {
    this.isMapBusy = true;
    this.mapCenter = mapCenter;
    this.geolocationCoordinates = null;
    this.isActiveGeolocation = false;
    this.isManuallyInput = false;
    this.isLoading = false;
    this.isAwaitGeolocation = false;
    this.isAwaitSubscription = false;
    this.inputAddressValue = '';
    this.inputAddressFocused = false;
    this.addresessList = [];
    this.deliveryAddress = null;
    this.etaCalculation = null;
    this.isAddressNotExist = false;
    this.isAddressNotCovered = false;
    this.isSmallSizeCover = true;
    this.nameVal = '';
    this.emailVal = '';
    this.isShowAddressNotFound = false;
    this.isShowAddressFound = false;
    this.curGeolocationAddress = null;
    this.isPushRequested = false;
    this.editAddressData = {
      addressId: null,
      comment: '',
      instructions: [],
    };
  }

  async geocoding(skipGeocoding = false) {
    if (!skipGeocoding) {
      const prevPlaceId = this.deliveryAddress?.placeId;
      try {
        const deliveryAddress = await userStore.geocodingByCoordinates(this.mapCenter);
        this.setDeliveryAddress(deliveryAddress);
        this.setInputAddressValue(deliveryAddress?.shortAddress || '');
      } catch (e) {
        this.setDeliveryAddress(null);
        this.setInputAddressValue('');
      }
      if (prevPlaceId === this.deliveryAddress?.placeId) {
        if (!this.etaCalculation && !userStore.isFirstLaunch) this.setIsAddressNotCovered(true);
        return;
      }
    }
    this.setAddresessList([]);
    try {
      if (this.deliveryAddress) {
        const etaCalculation = await orderStore.fetchETA(
          `${this.deliveryAddress.coordinates.lat},${this.deliveryAddress.coordinates.lng}`,
        );
        this.setEtaCalculation(etaCalculation);
        this.setIsAddressNotCovered(false);
        this.setIsAddressNotExist(false);
        if (!etaCalculation) {
          this.setIsAddressNotCovered(true);
        }
      } else {
        this.setEtaCalculation(null);
        if (!userStore.isFirstLaunch) this.setIsAddressNotCovered(true);
      }
    } catch (e) {
      this.setEtaCalculation(null);
      if (!userStore.isFirstLaunch) this.setIsAddressNotCovered(true);
    }
  }
}

const store = new Store();

const Geocoding = observer(() => {
  useEffect(() => {
    if (store.isMapBusy) {
      return;
    }

    if (
      store.deliveryAddress &&
      store.deliveryAddress.coordinates.lat.toFixed(7) === store.mapCenter.lat.toFixed(7) &&
      store.deliveryAddress.coordinates.lng.toFixed(7) === store.mapCenter.lng.toFixed(7)
    ) {
      return;
    }

    const timeout = setTimeout(() => {
      store.setIsLoading(true);
      store.geocoding(
        store.mapCenter.lng === mapCenter.lng && store.mapCenter.lat === mapCenter.lat,
      )
        .catch((error) => error && console.error(error))
        .finally(() => store.setIsLoading(false));
    }, MAP_DEBOUNCE_TIME);

    return () => clearTimeout(timeout);
    //eslint-disable-next-line
  }, [store.isMapBusy, store.mapCenter]);

  return <></>;
});

const GMap = observer(() => {
  const [defaultCenter, setDefaultCenter] = useState<GeoCoordinates | null>(null);
  const handleMapChange = (e: ChangeEventValue) => {
    if (store.isActiveGeolocation) {
      return;
    }

    if (
      e.center.lat.toFixed(7) === mapCenter.lat.toFixed(7) &&
      e.center.lng.toFixed(7) === mapCenter.lng.toFixed(7)
    )
      return;
    store.setMapCenter(e.center);
    store.setMapZoom(e.zoom);
  };

  const handleMapAction = (flag: boolean, shouldDropGeolocation: boolean) => {
    store.setIsMapBusy(flag);

    if (shouldDropGeolocation) {
      /**
       * Because "handleMapAction" is called when after zoom animation,
       * it is drop geolocation flag, and rewrite map center coordinates
       * To avoid it, we need to drop geolocation flag only when user drag map,
       * but not when map was just zoomed
       * */
      store.setIsActiveGeolocation(false);
    }
    store.setIsManuallyInput(false);
    store.setInputAddressFocused(false);
    store.setIsAddressNotCovered(false);
    store.setIsAddressNotExist(false);
  };

  const handleMapClick = () => {
    store.setIsManuallyInput(false);
    store.setInputAddressFocused(false);
    if (document.activeElement) {
      const activeEl: HTMLInputElement = document.activeElement as HTMLInputElement;
      activeEl.blur();
    }
  };

  const handleApiLoaded = async ({ map, maps }: { map: any, maps: any }) => {
    try {
      if (mapMarkers) {
        mapMarkers?.forEach(marker =>
          new maps.Marker({ ...marker, map }),
        );
      }

      const coverages = await userStore.getDeliveryZones();
      if (!coverages.length) return;
      coverages.forEach((el) => {
        new Map(
          el.zones.sort((a, b) => a.priority - b.priority).map((zone) => [zone.name, zone]),
        ).forEach((zone) => {
          const convert = mainStore.flatArray(zone.polygon.segments.map((segment) => {
            return Object.values(segment).map((coord) => ({
              lat: coord.latitude,
              lng: coord.longitude,
            }));
          }));
          const style = DELIVERY_ZONES_STYLE[zone.priority as number] || DELIVERY_ZONES_STYLE[100];
          const drawPolygons = new maps.Polygon({
            paths: convert,
            strokeWeight: 1,
            fillColor: zone.color,
            strokeColor: zone.color,
            fillOpacity: style.fillOpacity,
            strokeOpacity: style.strokeOpacity,
          });
          drawPolygons.setMap(map);
        });
      });
    } catch (error) {
      error && console.error(error);
    }
  };

  const center: GeoCoordinates =
    store.isActiveGeolocation && store.geolocationCoordinates
      ? store.geolocationCoordinates
      : store.deliveryAddress?.coordinates || store.mapCenter;

  useLayoutEffect(() => {
    setDefaultCenter(center);
    //eslint-disable-next-line
  }, []);

  if (defaultCenter === null) return <></>;

  return (
    <GoogleMapReact
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={{
        key: GOOGLE_API_KEY,
        language: 'en-GB',
        region: 'GB',
      }}
      defaultCenter={defaultCenter}
      defaultZoom={mapZoom}
      zoom={store.mapZoom}
      center={center}
      options={{
        clickableIcons: false,
        disableDefaultUI: true,
        gestureHandling: 'greedy',
      }}
      onGoogleApiLoaded={handleApiLoaded}
      shouldUnregisterMapOnUnmount={true}
      onChange={handleMapChange}
      onClick={handleMapClick}
      onDrag={() => handleMapAction(true, true)}
      onDragEnd={() => handleMapAction(false, true)}
      onZoomAnimationStart={() => handleMapAction(true, false)}
      onZoomAnimationEnd={() => handleMapAction(false, false)}
      style={{ 'pointerEvents': store.isChangeDeliveryAreaPopover ? 'none' : 'auto' }}
    />
  );
});

const AddressInputWrap = observer(({ sessionToken }: { sessionToken: string }) => {
  const { t } = useTranslation();
  const refAddressField = useRef<HTMLInputElement>(null);
  const debInputAddressValue = useDebounce<string>(store.inputAddressValue, 800);

  const handleManuallyInput = () => {
    store.setIsManuallyInput(true);
    store.setInputAddressFocused(true);
    store.setIsAddressNotExist(false);
    store.setIsAddressNotCovered(false);
    store.setIsSmallSizeCover(false);
    if (store.isManuallyInput) return;
    if (store.deliveryAddress?.shortAddress)
      store.setInputAddressValue(store.deliveryAddress?.shortAddress);
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    store.setInputAddressValue(e.currentTarget.value);
    store.setIsAddressNotExist(false);
    store.setIsAddressNotCovered(false);
  };
  const handleClear = () => {
    store.setInputAddressValue('');
    refAddressField.current?.focus();
  };

  useEffect(() => {
    if (store.isSmallSizeCover) return;
    if (!debInputAddressValue) {
      store.setAddresessList([]);
      return;
    }
    const query = {
      sessionToken: sessionToken,
      input: debInputAddressValue,
      placeId: '',
    };
    store.setIsLoading(true);
    userStore
      .getAutocomplete(query)
      .then(({ data: { predictions = [] } }) => {
        store.setAddresessList(predictions);
        if (!predictions.length) store.setIsAddressNotExist(true);
      })
      .catch(() => {
        store.setAddresessList([]);
        store.setIsAddressNotExist(true);
      })
      .finally(() => store.setIsLoading(false));
    //eslint-disable-next-line
  }, [debInputAddressValue, store.isSmallSizeCover]);

  useEffect(() => {
    if (store.isSmallSizeCover) return;
    navigator.geolocation.getCurrentPosition((e) => {
      userStore
        .geocodingByCoordinates({
          lat: e.coords.latitude,
          lng: e.coords.longitude,
        })
        .then((res) => {
          if (!res) return;
          store.setCurGeolocationAddress({
            address: res.shortAddress,
            postcode: res.zip,
            city: res.city,
            country: res.country,
            location: {
              latitude: e.coords.latitude,
              longitude: e.coords.longitude,
            },
            place_id: '',
          });
        })
        .catch((error) => error && console.error(error));
      store.setIsMapBusy(false);
      store.setIsAwaitGeolocation(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.isSmallSizeCover]);

  return (
    <div
      className={htmlClasses(
        'delivery-address__input-wrap position-relative br-8 border-tf2 h-48 d-flex px-16',
        { _error: store.isAddressNotCovered || store.isAddressNotExist },
        { 'shadow-none border-0 px-0': store.isSmallSizeCover },
      )}
    >
      {store.isManuallyInput && store.inputAddressFocused ? (
        <>
          <input
            className="input-text w-100p pr-24"
            type="text"
            ref={refAddressField}
            placeholder={t('deliveryAddressPage:inputAddressPlaceholder')}
            value={store.inputAddressValue}
            autoFocus={true}
            onChange={handleInputChange}
            onClick={handleManuallyInput}
          />
          {store.inputAddressValue.length > 0 && (
            <div
              className="input-text__clear icon icon-close s-24 fs-18 c-t99 d-flex flex-center cursor-pointer"
              onClick={handleClear}
            />
          )}
        </>
      ) : (
        <>
          {!store.deliveryAddress?.shortAddress ? (
            <div
              className={htmlClasses(
                'position-relative h-48 br-8 border-teb c-bg-white pl-12 w-100p d-flex align-items-center  fs-14 c-gray',
                { 'pe-n': store.isLoading },
              )}
              onClick={handleManuallyInput}
            >
              {t('deliveryAddressPage:inputAddressPlaceholder')}
            </div>
          ) : (
            <div
              className={htmlClasses(
                'position-relative h-48 br-8 border-teb c-bg-white pt-7 pl-12 pr-40 w-100p',
                { 'pe-n': store.isLoading },
              )}
              onClick={handleManuallyInput}
            >
              <div className="icon icon-chevron-right s-24 position-absolute pe-n fs-14 d-flex flex-center cart-address__icon" />
              <div className="text-truncate fs-13 c-udgray">
                {store.deliveryAddress.shortAddress}
              </div>
              <div className="text-truncate fs-10 c-mgray">
                {store.deliveryAddress.shortAddress}, {store.deliveryAddress.city},{' '}
                {store.deliveryAddress.country}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
});

const AddressButtons = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<{ isShowBackBtn?: boolean; source?: string }>();
  const handleSaveAddress = async () => {
    store.setIsLoading(true);
    let deliveryAddress = toJS(store.deliveryAddress);
    mainStore.sendToRN('analytics', {
      name: 'General: address changed',
      params: {
        postcode: deliveryAddress?.zip || '',
        warehouse_code: store.etaCalculation?.warehouse.code || '',
        source: state?.source || '',
        eta_min: store.etaCalculation?.duration.min || 0,
        eta_max: store.etaCalculation?.duration.max || 0,
        delivery_fee: store.etaCalculation?.cost[ETADeliveryMethodType.JiffyDelivery]?.shippingPounds || 0,
        threshold: store.etaCalculation?.cost[ETADeliveryMethodType.JiffyDelivery]?.thresholdPounds || 0,
        is_surger: store.etaCalculation?.highDemand || false,
        lat: deliveryAddress?.coordinates.lat || '',
        lon: deliveryAddress?.coordinates.lng || '',
      },
    });
    if (!deliveryAddress || !store.etaCalculation) {
      if (userStore.isFirstLaunch) store.setIsShowAddressNotFound(true);
      store.setIsLoading(false);
      return;
    }
    if (userStore.isAuthorized) {
      if (deliveryAddress.addressId) {
        userStore
          .updateAddress(deliveryAddress.addressId, {
            street_address_1: deliveryAddress.address1,
            street_address_2: deliveryAddress.address2,
            postcode: deliveryAddress.zip,
            latitude: deliveryAddress.coordinates.lat,
            longitude: deliveryAddress.coordinates.lng,
          })
          .catch((error) => error && console.error(error));
      } else {
        try {
          const addressId = await userStore
            .addAddress({
              street_address_1: deliveryAddress.address1,
              street_address_2: deliveryAddress.address2,
              city: deliveryAddress.city,
              postcode: deliveryAddress.zip,
              latitude: deliveryAddress.coordinates.lat,
              longitude: deliveryAddress.coordinates.lng,
              country: deliveryAddress.country,
            })
            .catch((error) => error && console.error(error));
          deliveryAddress = { ...deliveryAddress, addressId: addressId || null };
        } catch (error) {
          error && console.error(error);
        }
      }
    }
    userStore.setDeliveryAddress(deliveryAddress);
    orderStore.setEtaCalculation(toJS(store.etaCalculation));
    catalogStore.setCategoryListCacheExpired(null);
    catalogStore.setProductListCacheExpired({});
    catalogStore.setProductsCacheExpired({});
    mainStore.sendToRN('trackOneSignalOutcome', {
      name: 'warehouse_selected',
    });
    mainStore.sendToRN('sendTags', {
      postcode: deliveryAddress.zip || '',
    });
    mainStore.sendToRN('setUserProperties', {
      'Address: postcode': deliveryAddress.zip || '',
    });
    mainStore.sendToRN('setUserProperties', {
      'Address: lat': deliveryAddress.coordinates.lat,
    });
    mainStore.sendToRN('setUserProperties', {
      'Address: lon': deliveryAddress.coordinates.lng,
    });
    if (!userStore.isFirstLaunch) {
      desktopStore.setShowMap(false);
      history.replace('/');
    }
    if (userStore.isFirstLaunch) {
      mainStore.sendToRN('analytics', {
        name: 'Onboarding: warehouse selected',
        params: {
          warehouse_code: store.etaCalculation.warehouse.code,
          postcode: deliveryAddress.zip || '',
          lat: deliveryAddress.coordinates.lat,
          lon: deliveryAddress.coordinates.lng,
        },
      });
      mainStore.sendToRN('firebaseAnalytics', {
        name: 'onboarding_warehouse_selected',
        params: {
          warehouse_code: store.etaCalculation.warehouse.code,
          postcode: deliveryAddress.zip || '',
          lat: deliveryAddress.coordinates.lat,
          lon: deliveryAddress.coordinates.lng,
        },
      });
      mainStore.yaMetrika('Onboarding: warehouse selected', {
        warehouse_code: store.etaCalculation.warehouse.code,
        postcode: deliveryAddress.zip || '',
        lat: deliveryAddress.coordinates.lat,
        lon: deliveryAddress.coordinates.lng,
      });
      if (!store.deliveryAddress || !store.etaCalculation) {
        if (userStore.isFirstLaunch) store.setIsShowAddressNotFound(true);
        return;
      }
      store.setIsShowAddressFound(true);
      store.setIsLoading(false);
      return;
    }
    if (mainStore.deferedDeeplink) {
      history.replace({
        pathname: mainStore.deferedDeeplink,
        state: { isDeeplink: true },
      });
      mainStore.setDeferedDeeplink('');
      return;
    }
    if (state?.isShowBackBtn) history.length === 1 ? history.replace('/') : history.goBack();
    else if (!mainStore.isDesktop) history.replace('/');
  };

  if (!store.isSmallSizeCover) return <></>;

  return (
    <div className="flex-shrink-0 mt-16">
      <button
        className="button _primary _block w-100p delivery-address__address-button"
        disabled={
          !store.deliveryAddress ||
          store.inputAddressFocused ||
          store.isLoading ||
          (!userStore.isFirstLaunch && !store.etaCalculation)
        }
        onClick={handleSaveAddress}
      >
        {store.isLoading ? <span className="spinner" /> : t('chooseAddress')}
      </button>
    </div>
  );
});

const AddressList = observer(({ sessionToken }: { sessionToken: string }) => {
  const { t } = useTranslation();
  const handleSetDeliverAddress = (suggestion: AutocompletePrediction | AutocompleteSuggestion) => {
    const query = {
      sessionToken: sessionToken,
      input: '',
      placeId: suggestion.place_id || '',
    };

    if (store.isLoading) return;
    store.setIsLoading(true);

    userStore
      .getAutocomplete(query)
      .then((e) => {
        const place = e.data.result;
        if (place) {
          store.setMapCenter({
            lat: place.location.latitude,
            lng: place.location.longitude,
          });
          store.setDeliveryAddress({
            zip: '',
            country: '',
            city: '',
            region: '',
            address1: '',
            address2: '',
            shortAddress: '',
            coordinates: {
              lat: place.location.latitude,
              lng: place.location.longitude,
            },
            placeId: mainStore.getPseudoId(),
            addressId: null,
            comment: '',
            instructions: [],
          });
          setTimeout(() => store.geocoding(), 500);
        }
      })
      .catch(() => {
        store.setDeliveryAddress(null);
      })
      .finally(() => store.setIsLoading(false));

    store.setIsActiveGeolocation(false);
    store.setInputAddressFocused(false);
    store.setIsManuallyInput(false);
    store.setIsSmallSizeCover(true);
  };

  if (!store.isManuallyInput || !store.inputAddressFocused || !store.addresessList.length)
    return <></>;

  return (
    <div className="mt-16 h-100p overflow-auto">
      {store.curGeolocationAddress !== null && (
        <>
          <AddressZip
            shortAddress={t('deliveryAddressPage:currentLocation')}
            fullAddress={store.curGeolocationAddress.address}
            onClick={() =>
              handleSetDeliverAddress(store.curGeolocationAddress as AutocompleteSuggestion)
            }
          />
          <div className="ml-32 mr-16 c-bg-tf2 h-1 pe-n" />
        </>
      )}
      {store.addresessList.map((item, i) => (
        <React.Fragment key={item.place_id}>
          {i > 0 && <div className="ml-32 mr-16 c-bg-tf2 h-1 pe-n" />}
          <AddressZip
            shortAddress={item.description}
            fullAddress={item.description}
            onClick={() => handleSetDeliverAddress(item)}
          />
        </React.Fragment>
      ))}
    </div>
  );
});

const AddressZip = observer(({ shortAddress, fullAddress, onClick }: DeliveryAddressZipProps) => {
  return (
    <div
      className={htmlClasses(
        'delivery-address-item d-flex align-items-center h-48 overflow-hidden w-100p cursor-pointer',
        { 'pe-n': store.isLoading },
      )}
      onClick={onClick}
    >
      <div className="icon icon-marker s-20 fs-20 d-flex flex-center mr-8 pe-n c-tc7" />
      <div className="pr-15 w-100p overflow-hidden pe-n">
        <div className="text-truncate delivery-address__zip-text-caption lh-16">
          {shortAddress}
        </div>
        <div className="text-truncate delivery-address__zip-text lh-16 mt-4 c-tad">
          {fullAddress}
        </div>
      </div>
    </div>
  );
});

const Errors = observer(() => {
  const { t } = useTranslation();

  return (
    <>
      {store.isAddressNotExist && (
        <div className="alert _error" onClick={() => store.setIsAddressNotExist(false)}>
          {t('deliveryAddressPage:errorAddressNotExist')}
        </div>
      )}
      {store.isAddressNotCovered && (
        <div className="alert _error" onClick={() => store.setIsAddressNotCovered(false)}>
          {t('deliveryAddressPage:errorAddressNotCovered')}
        </div>
      )}
    </>
  );
});

const PrivacyPolicy = observer(({ className }: PrivacyPolicyProps) => {
  const location = useLocation();
  const history = useHistory();
  const handleInjectClick = (page: string) => {
    history.push({
      pathname: location.pathname,
      search: '?modal=inject',
      state: { page },
    });
    desktopStore.setShowInject(true);
  };

  return (
    <div className={className}>
      <Trans i18nKey="authPage:subscribeTerms">
        By subscribing, you accept our
        {!mainStore.isDesktop ? (
          <ExternalLink
            className="c-dgray text-underline"
            to={{ pathname: '/inject', state: { page: 'terms' } }}
            href={company.config.links.terms}
          >
            Terms and Conditions
          </ExternalLink>
        ) : (
          <span
            className="c-dgray text-underline cursor-pointer"
            onClick={() => handleInjectClick('terms')}
          >
            Terms and Conditions
          </span>
        )}
        and
        {!mainStore.isDesktop ? (
          <ExternalLink
            className="c-dgray text-underline"
            to={{ pathname: '/inject', state: { page: 'privacy' } }}
            href={company.config.links.privacy}
          >
            Privacy policy
          </ExternalLink>
        ) : (
          <span
            className="c-dgray text-underline cursor-pointer"
            onClick={() => handleInjectClick('privacy')}
          >
            Privacy policy
          </span>
        )}
        .
        {!mainStore.isDesktop ? (
          <ExternalLink
            className="c-dgray text-underline"
            to={{ pathname: '/inject', state: { page: 'cookie' } }}
            href={company.config.links.cookie}
          >
            See more
          </ExternalLink>
        ) : (
          <span
            className="c-dgray text-underline cursor-pointer"
            onClick={() => handleInjectClick('cookie')}
          >
            See more
          </span>
        )}
      </Trans>
    </div>
  );
});

const SubscribeForm = observer(
  ({ handleSubmit, handleDissmis, type, className }: SubscribeFormProps) => {
    const { t } = useTranslation();
    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) =>
      store.setNameVal(e.currentTarget.value);
    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) =>
      store.setEmailVal(e.currentTarget.value);

    useEffect(() => {
      if (!window.ReactNativeWebView || store.isPushRequested) return;
      mainStore.sendToRN(
        'subscribeToNotifications',
        {
          email: store.emailVal.trim(),
          isPush: true,
          isEmail: false,
        },
        () => {
          store.setIsPushRequested(true);
          mainStore.setIsBlockedPush(false);
          userStore
            .updateDeviceData(store.nameVal.trim(), store.emailVal.trim(), [
              {
                name: 'personalised',
                push: true,
                email: false,
              },
              {
                name: 'discounts',
                push: true,
                email: false,
              },
              {
                name: 'third_party',
                push: true,
                email: false,
              },
            ])
            .then((e) => {
              if (!e) return;
              userStore.setSubscriptionFlags({
                discounts: {
                  name: 'discounts',
                  push: true,
                  email: false,
                },
                personalised: {
                  name: 'personalised',
                  push: true,
                  email: false,
                },
                third_party: {
                  name: 'third_party',
                  push: true,
                  email: false,
                },
              });
            })
            .catch((error) => error && console.error(error));
        },
        () => {
          store.setIsPushRequested(true);
          mainStore.setIsBlockedPush(true);
        },
      );
    }, []);

    return (
      <form className={className} onSubmit={handleSubmit}>
        <InputText label={t('yourName')} value={store.nameVal} onChange={handleNameChange} />
        <InputText
          className="mt-10"
          label={t('email')}
          value={store.emailVal}
          isValid={store.isEmailValid}
          isError={!!(store.emailVal.length && !store.isEmailValid)}
          onChange={handleEmailChange}
        />
        <div className="d-flex align-items-center justify-content-end mt-16 mx-n4">
          {type === 'AddressNotFound' ? (
            <div
              className={htmlClasses(
                'button _secondary mx-4 w-100p flex-shrink-1 cursor-pointer',
                {
                  'pe-n': store.isAwaitSubscription,
                },
              )}
              onClick={handleDissmis}
            >
              {t('skip')}
            </div>
          ) : (
            <div
              className={htmlClasses(
                'button _secondary mx-4 w-100p flex-shrink-1 cursor-pointer',
                {
                  'pe-n': store.isAwaitSubscription,
                },
              )}
              onClick={handleDissmis}
            >
              {t('later')}
            </div>
          )}
          <button
            className="button _primary mx-4 w-100p flex-shrink-1"
            disabled={!store.isFormValid || store.isAwaitSubscription}
          >
            {store.isAwaitSubscription ? <span className="spinner" /> : t('subscribe')}
          </button>
        </div>
        <PrivacyPolicy className="mt-32 text-center fs-12 lh-16 c-dgray" />
        <div className="h-24" />
      </form>
    );
  },
);

const AddressNotFound = observer(() => {
  const { t } = useTranslation();
  const [isSubscribePush, setIsSubscribePush] = useState<boolean | null>(null);
  const [isSuccessSubscribe, setIsSuccessSubscribe] = useState<boolean | null>(null);
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    store.setIsAwaitSubscription(true);
    setIsSubscribePush(null);
    mainStore.sendToRN('analytics', {
      name: 'Onboarding: subscribe dialog clicked',
      params: {
        answer: 'next',
        type: 'no_store',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_subscribe_dialog_clicked',
      params: {
        answer: 'next',
        type: 'no_store',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendToRN(
      'subscribeToNotifications',
      {
        email: store.emailVal.trim(),
        isEmail: true,
        isPush: true,
      },
      () => {
        setIsSubscribePush(true);
        mainStore.setIsBlockedPush(false);
      },
      () => {
        setIsSubscribePush(false);
        if (mainStore.isBlockedPush) mainStore.pushAlert('warning', t('pushBlockedWarning'));
        mainStore.setIsBlockedPush(true);
      },
    );

    await userStore
      .updateDeviceData(store.nameVal.trim(), store.emailVal.trim(), [
        {
          name: 'personalised',
          push: undefined,
          email: true,
        },
        {
          name: 'discounts',
          push: undefined,
          email: true,
        },
        {
          name: 'third_party',
          push: undefined,
          email: true,
        },
      ]).catch((error) => error && console.error(error));

    // store.setIsAwaitSubscription(false);
    if (!window.ReactNativeWebView) setIsSubscribePush(true);
  };
  const handleDissmis = () => {
    setIsSuccessSubscribe(null);
    mainStore.sendToRN('analytics', {
      name: 'Onboarding: subscribe dialog clicked',
      params: {
        answer: 'skip',
        type: 'no_store',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_subscribe_dialog_clicked',
      params: {
        answer: 'skip',
        type: 'no_store',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    store.setDeliveryAddress(null);
    store.setMapCenter(mapCenter);
    store.setMapZoom(mapZoom);
    store.setIsShowAddressNotFound(false);
    store.setIsChangeDeliveryAreaPopover(true);
  };

  useEffect(() => {
    store.setIsAddressNotExist(false);
    store.setIsAddressNotCovered(false);
    if (!store.isShowAddressNotFound) return;
    mainStore.sendToRN('analytics', {
      name: 'Onboarding: subscribe dialog shown',
      params: {
        type: 'no_store',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_subscribe_dialog_shown',
      params: {
        type: 'no_store',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    //eslint-disable-next-line
  }, [store.isShowAddressNotFound]);

  useEffect(() => {
    if (isSubscribePush === null) return;
    userStore
      .updateDeviceData(store.nameVal.trim(), store.emailVal.trim(), [
        {
          name: 'personalised',
          push: isSubscribePush,
          email: true,
        },
        {
          name: 'discounts',
          push: isSubscribePush,
          email: true,
        },
        {
          name: 'third_party',
          push: isSubscribePush,
          email: true,
        },
      ])
      .then((e) => {
        if (!e) return;
        mainStore.pushAlert('success', t('deliveryAddressPage:addressNotFound.successSubscribe'));
        mainStore.sendToRN('analytics', {
          name: 'Subscribe: subscribe from dialog',
          params: {
            type: 'email',
            source: 'onboarding',
          },
        });
        mainStore.sendToRN('firebaseAnalytics', {
          name: 'subscribe_subscribe_from_dialog',
          params: {
            type: 'email',
            source: 'onboarding',
          },
        });
        mainStore.sendToRN('sendTags', {
          in_waiting_list: true,
        });
        mainStore.sendToRN('sendTags', {
          subscribed_personal_offers: isSubscribePush ? 'all' : 'email',
        });
        mainStore.sendToRN('sendTags', {
          subscribed_discounts: isSubscribePush ? 'all' : 'email',
        });
        mainStore.sendToRN('sendTags', {
          subscribed_thirdparty: isSubscribePush ? 'all' : 'email',
        });
        setIsSuccessSubscribe(true);
      })
      .catch((error) => error && console.error(error))
      .finally(() => {
        store.setIsAwaitSubscription(false);
        setIsSubscribePush(null);
      });
    //eslint-disable-next-line
  }, [isSubscribePush]);

  useEffect(() => {
    if (isSuccessSubscribe === null) return;
    const timeout = setTimeout(() => {
      handleDissmis();
    }, 2000);
    return () => clearTimeout(timeout);
  }, [isSuccessSubscribe]);

  if (!store.isShowAddressNotFound) return <></>;

  const component = (
    <>
      <div
        className={htmlClasses('h-100p d-flex flex-direction-column overflow-hidden', {
          'pe-n o-5': isSuccessSubscribe,
        })}
      >
        <div className="h-100p d-flex align-items-center justify-content-center overflow-hidden">
          <img className="h-130" src={IllustrationAddressNotFound} alt="" />
        </div>
        <div className="mt-auto px-24 pt-20 flex-shrink-0">
          <div className="fs-21 lh-25 text-center">
            {t('deliveryAddressPage:addressNotFound.title')}
          </div>
          <div className="fs-14  lh-20 text-center mt-10 c-udgray">
            {t('deliveryAddressPage:addressNotFound.text')}
          </div>
          <SubscribeForm
            className="mt-20"
            handleSubmit={handleSubmit}
            handleDissmis={handleDissmis}
            type="AddressNotFound"
          />
        </div>
      </div>
    </>
  );

  if (!mainStore.isDesktop) {
    return <div className="content-layout c-bg-white">{component}</div>;
  } else {
    return (
      <Dialog show={true} onBackdrop={handleDissmis} maxWidth="550px">
        <div className="h-24" />
        {component}
      </Dialog>
    );
  }
});

const AddressFound = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isSubscribePush, setIsSubscribePush] = useState<boolean | null>(null);
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    store.setIsAwaitSubscription(true);
    setIsSubscribePush(null);
    userStore.setIsFirstLaunch(false);
    mainStore.sendToRN('analytics', {
      name: 'Onboarding: subscribe dialog clicked',
      params: {
        answer: 'next',
        type: 'store_found',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Onboarding: complete',
      params: {
        eta_min: store.etaCalculation?.duration.min || 0,
        eta_max: store.etaCalculation?.duration.max || 0,
        delivery_fee: store.etaCalculation?.cost[ETADeliveryMethodType.JiffyDelivery]?.shippingPounds || 0,
        threshold: store.etaCalculation?.cost[ETADeliveryMethodType.JiffyDelivery]?.thresholdPounds || 0,
        is_surger: store.etaCalculation?.highDemand || false,
        type: 'passed',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_subscribe_dialog_clicked',
      params: {
        answer: 'next',
        type: 'store_found',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_complete',
      params: {
        eta: store.etaCalculation?.duration.min || 0,
        type: 'passed',
      },
    });
    mainStore.sendToRN(
      'subscribeToNotifications',
      {
        email: store.emailVal.trim(),
        isEmail: true,
        isPush: true,
      },
      () => {
        setIsSubscribePush(true);
        mainStore.setIsBlockedPush(false);
      },
      () => {
        setIsSubscribePush(false);
        if (mainStore.isBlockedPush) mainStore.pushAlert('warning', t('pushBlockedWarning'));
        mainStore.setIsBlockedPush(true);
      },
    );

    await userStore
      .updateDeviceData(store.nameVal.trim(), store.emailVal.trim(), [
        {
          name: 'personalised',
          push: undefined,
          email: true,
        },
        {
          name: 'discounts',
          push: undefined,
          email: true,
        },
        {
          name: 'third_party',
          push: undefined,
          email: true,
        },
      ]).catch((error) => error && console.error(error));

    store.setIsAwaitSubscription(false);
    if (mainStore.isDesktop) {
      catalogStore.requestCategories();
      catalogStore.requestBanners();
      catalogStore.requestSearchHistory().catch(() => void 0);
    }
    desktopStore.setShowMap(false);
    history.replace('/');
    // if (!window.ReactNativeWebView) setIsSubscribePush(true);
  };
  const handleDissmis = () => {
    if (mainStore.isDesktop) {
      catalogStore.requestCategories();
      catalogStore.requestBanners();
      catalogStore.requestSearchHistory().catch(() => void 0);
    }
    mainStore.sendToRN('analytics', {
      name: 'Onboarding: subscribe dialog clicked',
      params: {
        answer: 'skip',
        type: 'store_found',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Onboarding: complete',
      params: {
        eta_min: store.etaCalculation?.duration.min || 0,
        eta_max: store.etaCalculation?.duration.max || 0,
        delivery_fee: store.etaCalculation?.cost[ETADeliveryMethodType.JiffyDelivery]?.shippingPounds || 0,
        threshold: store.etaCalculation?.cost[ETADeliveryMethodType.JiffyDelivery]?.thresholdPounds || 0,
        is_surger: store.etaCalculation?.highDemand || false,
        type: 'skipped',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_subscribe_dialog_clicked',
      params: {
        answer: 'skip',
        type: 'store_found',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_complete',
      params: {
        eta: store.etaCalculation?.duration.min || 0,
        type: 'skipped',
      },
    });
    userStore
      .updateDeviceData('', '', [
        {
          name: 'personalised',
          push: false,
          email: false,
        },
        {
          name: 'discounts',
          push: false,
          email: false,
        },
        {
          name: 'third_party',
          push: false,
          email: false,
        },
      ])
      .catch((error) => error && console.error(error));
    mainStore.sendToRN('sendTags', {
      subscribed_personal_offers: '0',
    });
    mainStore.sendToRN('sendTags', {
      subscribed_discounts: '0',
    });
    mainStore.sendToRN('sendTags', {
      subscribed_thirdparty: '0',
    });
    if (mainStore.isDesktop) {
      if (userStore.isAuthorized && company.isIncludedIn([CompanyName.Jiffy])) {
        mainStore.pushAlert('warning', t('deliveryAddressPage:addressFound.skipSubscribe'), () => {
          desktopStore.setShowNotifications(true);
        });
      }
    } else {
      if (company.isIncludedIn([CompanyName.Jiffy])) {
        mainStore.pushAlert('warning', t('deliveryAddressPage:addressFound.skipSubscribe'), () => {
          history.push('/notifications');
        });
      }
    }
    userStore.setIsFirstLaunch(false);
    desktopStore.setShowMap(false);
    history.replace('/');
  };

  useEffect(() => {
    if (!store.isShowAddressFound) return;
    mainStore.sendToRN('analytics', {
      name: 'Onboarding: subscribe dialog shown',
      params: {
        type: 'store_found',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'onboarding_subscribe_dialog_shown',
      params: {
        type: 'store_found',
        postcode: store.deliveryAddress?.zip || '',
        lat: store.deliveryAddress?.coordinates.lat || '',
        lon: store.deliveryAddress?.coordinates.lng || '',
      },
    });
    //eslint-disable-next-line
  }, [store.isShowAddressFound]);

  useEffect(() => {
    if (isSubscribePush === null) return;
    userStore
      .updateDeviceData(store.nameVal.trim(), store.emailVal.trim(), [
        {
          name: 'personalised',
          push: isSubscribePush,
          email: true,
        },
        {
          name: 'discounts',
          push: isSubscribePush,
          email: true,
        },
        {
          name: 'third_party',
          push: isSubscribePush,
          email: true,
        },
      ])
      .then((e) => {
        if (!e) return;
        userStore.setSubscriptionFlags({
          discounts: {
            name: 'discounts',
            push: isSubscribePush,
            email: true,
          },
          personalised: {
            name: 'personalised',
            push: isSubscribePush,
            email: true,
          },
          third_party: {
            name: 'third_party',
            push: isSubscribePush,
            email: true,
          },
        });
        mainStore.pushAlert(
          'success',
          t('deliveryAddressPage:addressFound.successSubscribe'),
          () => {
            history.push('/notifications');
          },
        );
        mainStore.sendToRN('analytics', {
          name: 'Subscribe: subscribe from dialog',
          params: {
            type: 'email',
            source: 'onboarding',
          },
        });
        mainStore.sendToRN('firebaseAnalytics', {
          name: 'subscribe_subscribe_from_dialog',
          params: {
            type: 'email',
            source: 'onboarding',
          },
        });
        mainStore.sendToRN('sendTags', {
          subscribed_personal_offers: isSubscribePush ? 'all' : 'email',
        });
        mainStore.sendToRN('sendTags', {
          subscribed_discounts: isSubscribePush ? 'all' : 'email',
        });
        mainStore.sendToRN('sendTags', {
          subscribed_thirdparty: isSubscribePush ? 'all' : 'email',
        });
      })
      .catch((error) => error && console.error(error))
      .finally(() => {
        store.setIsAwaitSubscription(false);
        setIsSubscribePush(null);
        if (mainStore.deferedDeeplink) {
          history.replace({
            pathname: mainStore.deferedDeeplink,
            state: { isDeeplink: true },
          });
          mainStore.setDeferedDeeplink('');
        } else history.replace('/');
      });
    //eslint-disable-next-line
  }, [isSubscribePush]);

  if (!store.isShowAddressFound) return <></>;

  if (!company.isIncludedIn([CompanyName.Jiffy])) {
    handleDissmis();

    return <></>;
  }

  const component = (
    <>
      <div className="h-100p d-flex flex-direction-column overflow-hidden">
        <div className="d-flex flex-direction-column align-items-center justify-content-center h-100p overflow-hidden px-24">
          <div className="fs-21 lh-25 text-center flex-shrink-0 mt-24">
            {t('deliveryAddressPage:addressFound.title')}
          </div>
          <div className="fs-14 lh-20 text-center mt-16 c-udgray flex-shrink-0">
            {t('deliveryAddressPage:addressFound.upperText')}
          </div>
          <div className="photo-team d-flex align-items-center justify-content-center overflow-hidden my-16">
            <img
              className={htmlClasses(
                'h-100p br-8 w-max-100p',
                { 'h-max-160': !mainStore.isDesktop || window.innerHeight > 700 },
                { 'h-max-100': mainStore.isDesktop && window.innerHeight < 700 },
              )}
              src={IllustrationTeam}
              alt=""
            />
          </div>
          <div className="fs-14 lh-20 text-center c-udgray flex-shrink-0">
            {t('deliveryAddressPage:addressFound.text')}
          </div>
        </div>
        <SubscribeForm
          className="px-24 pt-20 flex-shrink-0"
          handleSubmit={handleSubmit}
          handleDissmis={handleDissmis}
          type="AddressFound"
        />
      </div>
    </>
  );

  if (!mainStore.isDesktop) {
    return <div className="content-layout c-bg-white">{component}</div>;
  } else {
    return (
      <Dialog show={true} onBackdrop={handleDissmis} maxWidth="550px">
        <div className="h-24" />
        {component}
      </Dialog>
    );
  }
});

const Header = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<{ isShowBackBtn?: boolean }>();

  if (!state?.isShowBackBtn) return <></>;

  return (
    <div className="d-flex flex-shrink-0 h-50 align-items-center px-7">
      <div
        className="icon icon-arrow-back d-flex flex-center s-50 fs-20 c-black cursor-pointer"
        onClick={() => (history.length === 1 ? history.push('/') : history.goBack())}
      />
      <h3 className="w-100p text-center pr-50 text-truncate">
        {t('deliveryAddressPage:pageShortTitle')}
      </h3>
    </div>
  );
});

const Cover = observer(() => {
  const [sessionToken, setSessionToken] = useState<string>('');
  const { t } = useTranslation();
  const [{ y }, set] = useSpring(() => ({ y: 0 }));

  useEffect(() => {
    setSessionToken(uuid());
  }, []);

  const handleCollapseCover = () => {
    if (store.isSmallSizeCover) return;
    store.setInputAddressFocused(false);
    store.setIsManuallyInput(false);
    store.setIsSmallSizeCover(true);
    store.setIsMapBusy(false);
    store.setIsSmallSizeCover(true);
    store.setIsAddressNotExist(false);
    set.start({ y: 0, immediate: true });
  };
  const bind = useDrag(({ last, vxvy: [, vy], movement: [, my], event }) => {
    if (
      event.target &&
      (event.target as HTMLDivElement)?.classList?.contains('delivery-address-item')
    )
      return;
    if (store.isSmallSizeCover) {
      handleCollapseCover();
      return;
    }
    if (my < 0) return;
    if (my >= 100) {
      handleCollapseCover();
      return;
    }
    if (last) {
      if (my > 80 || vy > 0.5) {
        handleCollapseCover();
        return;
      }
      set.start({ y: 0, immediate: false });
    } else set.start({ y: my, immediate: true });
  });

  const handleGeolocation = () => {
    if (!window.ReactNativeWebView) return;
    if (store.isActiveGeolocation) store.setIsActiveGeolocation(false);
    else {
      store.setIsAwaitGeolocation(true);
      navigator.geolocation.getCurrentPosition(
        (e) => {
          if (!e || !e.coords?.latitude || !e.coords?.longitude) {
            store.setIsMapBusy(false);
            store.setIsAwaitGeolocation(false);
            return;
          }
          store.setGeolocationCoordinates({
            lat: e.coords.latitude,
            lng: e.coords.longitude,
          });
          store.setMapZoom(MAP_GEOLOCATION_ZOOM);
          store.setIsActiveGeolocation(true);
          store.setIsMapBusy(false);
          store.setIsSmallSizeCover(true);
          store.setIsAwaitGeolocation(false);
        },
        () => {
          store.setIsActiveGeolocation(false);
          store.setGeolocationCoordinates(null);
          store.setIsAwaitGeolocation(false);
          store.setIsMapBusy(false);
        },
      );
    }
    store.setIsManuallyInput(false);
    store.setInputAddressFocused(false);

    /**
     * When user clicks on geolocation button, we need to block map actions,
     * so the user will not to see wrong address from MAP_CENTER
     * */
    store.setIsMapBusy(true);
  };

  return (
    <>
      {!store.isSmallSizeCover && <div className="popover__bg" onClick={handleCollapseCover} />}
      <a.div
        {...bind()}
        style={{ y }}
        className={htmlClasses(
          'delivery-address__cover position-absolute d-flex flex-direction-column justify-content-between w-100p c-bg-white ',
          { '_expanded overflow-hidden': !store.isSmallSizeCover },
          mainStore.isDesktop ? 'w-max-421 l r-0 h-max-75p' : 'l-0',
        )}
      >
        {store.isSmallSizeCover && (
          <div className="delivery-address__geolocation s-40 rounded-circle c-bg-white position-absolute">
            {store.isAwaitGeolocation ? (
              <span className="spinner delivery-address__btn-geolocation position-absolute" />
            ) : (
              <div
                className={htmlClasses(
                  'icon s-40 d-flex flex-center fs-21 position-absolute c-blue rounded-circle cursor-pointer',
                  store.isActiveGeolocation ? 'icon-geolocation-fill' : 'icon-geolocation',
                  { 'pe-n': store.isLoading },
                )}
                onClick={handleGeolocation}
              />
            )}
          </div>
        )}
        <div className="flex-shrink-0">
          {store.isSmallSizeCover && (
            <>
              <div className="delivery-address__title ">
                {t('deliveryAddressPage:pageTitle')}
              </div>
              <div className="delivery-address__announce c-mgray  mb-16">
                {t('deliveryAddressPage:announce')}
              </div>
            </>
          )}
          <AddressInputWrap sessionToken={sessionToken} />
          <Errors />
        </div>
        <AddressList sessionToken={sessionToken} />
        <AddressButtons />
      </a.div>
    </>
  );
});

const PopoverChangeDeliveryArea = observer(() => {
  const { t } = useTranslation();
  const handleDissmis = () => {
    store.setIsChangeDeliveryAreaPopover(false);
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Onboarding: Change Delivery Area clicked',
      params: {},
    });
  };

  return (
    <div className="delivery-address__cover position-absolute d-flex flex-direction-column justify-content-between w-100p c-bg-white">
      <div className="fs-21 lh-25 text-center">
        {t('deliveryAddressPage:changeDeliveryAreaPopover.title')}
      </div>
      <div
        className="fs-14 lh-20 text-center mt-10 c-black"
        dangerouslySetInnerHTML={{
          __html: t('deliveryAddressPage:changeDeliveryAreaPopover.text'),
        }}
      />
      <div className="button _primary w-100p mt-32" onClick={handleDissmis}>
        {t('deliveryAddressPage:changeDeliveryAreaPopover.button')}
      </div>
      <PrivacyPolicy className="mt-24 text-center fs-12 lh-16 c-dgray" />
    </div>
  );
});

const MapLegend = observer(() => {
  const { t } = useTranslation();

  if (company.isHideMapLegend) return <></>;

  return (
    <div className="delivery-address__legend">
      <div className="d-flex align-items-center flex-shrink-0 mx-10">
        <div className="s-14 rounded-circle c-bg-salad mr-4" />
        <div>15 – 20 {t('min')}</div>
      </div>
      <div className="d-flex align-items-center flex-shrink-0 mx-10">
        <div className="s-14 rounded-circle c-bg-green mr-4" />
        <div>25 – 30 {t('min')}</div>
      </div>
      <div className="d-flex align-items-center flex-shrink-0 mx-10">
        <div className="s-14 rounded-circle c-bg-lorange mr-4" />
        <div>35 – 60 {t('min')}</div>
      </div>
    </div>
  );
});

export default observer(() => {
  const { state } = mainStore.isDesktop
    ? { state: desktopStore.lastSavedDeliveryAddressState }
    : useLocation<{ isNewAddress?: boolean; editAddress?: DeliveryAddress }>();

  useEffect(() => {
    return () => store.resetStore();
  }, []);

  useLayoutEffect(() => {
    if (state?.isNewAddress) return;
    if (state?.editAddress) {
      store.setEditAddressData({
        addressId: state.editAddress.addressId || null,
        comment: state.editAddress.comment || '',
        instructions: state.editAddress.instructions || [],
      });
      store.setMapCenter(state.editAddress.coordinates || mapCenter);
      store.setDeliveryAddress(state.editAddress);
      store.setEtaCalculation(null);
      store.setInputAddressValue(state.editAddress.address1);
      store.geocoding().catch((error) => error && console.error(error));
      return;
    }
    store.setMapCenter(toJS(userStore.deliveryAddress?.coordinates || mapCenter));
    store.setDeliveryAddress(toJS(userStore.deliveryAddress));
    store.setEtaCalculation(toJS(orderStore.etaCalculation));
    if (store.deliveryAddress?.shortAddress) {
      store.setInputAddressValue(store.deliveryAddress?.shortAddress);
    }
    //eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if (!userStore.deliveryAddress?.coordinates || userStore.isFirstLaunch) {
      store.setIsAwaitGeolocation(true);
      navigator.geolocation.getCurrentPosition(
        (e) => {
          store.setGeolocationCoordinates({
            lat: e.coords.latitude,
            lng: e.coords.longitude,
          });
          store.setMapZoom(MAP_GEOLOCATION_ZOOM);
          store.setIsActiveGeolocation(true);
          store.setIsMapBusy(false);
          store.setIsAwaitGeolocation(false);
        },
        () => {
          store.setIsActiveGeolocation(false);
          store.setGeolocationCoordinates(null);
          store.setIsAwaitGeolocation(false);
        },
      );

      store.setIsMapBusy(true);
    }
    //eslint-disable-next-line
  }, [mainStore.isRNReady]);

  return (
    <div
      className={`delivery-address content-layout ${mainStore.isDesktop ? 'pt-65 w-min-100p' : ''}`}
    >
      <Header />
      <div className={`position-relative ${mainStore.isDesktop ? 'h-100p' : 'h-75p'}`}>
        <GMap />
        <img
          className={htmlClasses('delivery-address__marker', { _animate: store.isLoading })}
          src={MapPoint}
          alt=""
        />
        <MapLegend />
        <Geocoding />
      </div>
      {store.isChangeDeliveryAreaPopover ? <PopoverChangeDeliveryArea /> : <Cover />}
      <AddressNotFound />
      <AddressFound />
    </div>
  );
});
