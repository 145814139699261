import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import i18n, { Resource } from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { SENTRY_DSN } from './config';
import reportWebVitals from './reportWebVitals';
import Translations from './translations.json';
import MobileDetect from 'mobile-detect';
import TagManager from 'react-gtm-module';
import { Environment } from './stores/MainStore';
import { company } from './company/Company';
import { CompanyName, GtmConfig } from './company/interface';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV === 'development' ? 'development' : (process.env.REACT_APP_ENV || 'development'),
  tracesSampleRate: 1.0,
  maxValueLength: 5000,
  normalizeDepth: 0,
  enabled: process.env.NODE_ENV !== 'development',
  release: process.env.REACT_APP_RELEASE ? `frontend@${process.env.REACT_APP_RELEASE}` : undefined,
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.category === 'xhr' && breadcrumb.data && breadcrumb.data.url.indexOf(
      'yandex') !== -1) return null;
    if (breadcrumb.category === 'sentry.transaction') return null;
    return breadcrumb;
  },
});

i18n.use(initReactI18next).init({
  resources: Translations as Resource,
  lng: company.config.lang,
  fallbackLng: 'en-GB',
  interpolation: {
    escapeValue: false,
    defaultVariables: {
      companyName: company.config.name,
      companyEmail: company.config.email,
      ageRestricted: company.config.ageRestricted,
    },
  },
}).catch(console.error);

document.title = company.config.name;
const origin = window.location.origin;

const manifests = {
  [CompanyName.Jiffy]: {
    name: 'Jiffy',
    short_name: 'Jiffy',
    description: 'Jiffy',
    start_url: origin,
    background_color: '#000',
    theme_color: '#f44719',
    display: 'standalone',
    icons: [
      {
        src: `${origin}/favicon-jiffy/apple-touch-icon.png`,
        rel: 'apple-touch-icon',
        sizes: '152x152',
      }, {
        src: `${origin}/favicon-jiffy/favicon-32x32.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
      }, {
        src: `${origin}/favicon-jiffy/favicon-16x16.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
      }, {
        src: `${origin}/favicon-jiffy/safari-pinned-tab.svg`,
        rel: 'mask-icon',
        sizes: '16x16',
      },
    ],
  },
  [CompanyName.Baqal]: {
    name: 'Baqal',
    short_name: 'Baqal',
    description: 'Baqal',
    start_url: origin,
    background_color: '#000',
    theme_color: '#fdd427',
    display: 'standalone',
    icons: [
      {
        src: `${origin}/favicon-baqal/apple-touch-icon.png`,
        rel: 'apple-touch-icon',
        sizes: '152x152',
      }, {
        src: `${origin}/favicon-baqal/favicon-32x32.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
      }, {
        src: `${origin}/favicon-baqal/favicon-16x16.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
      }, {
        src: `${origin}/favicon-baqal/safari-pinned-tab.svg`,
        rel: 'mask-icon',
        sizes: '16x16',
      },
    ],
  },
  [CompanyName.Swifft]: {
    name: 'Swifft',
    short_name: 'Swifft',
    description: 'Swifft',
    background_color: '#000',
    theme_color: '#26a9e1',
    display: 'standalone',
    start_url: origin,
    icons: [
      {
        src: `${origin}/favicon-swifft/apple-touch-icon.png`,
        rel: 'apple-touch-icon',
        sizes: '152x152',
      }, {
        src: `${origin}/favicon-swifft/favicon-32x32.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
      }, {
        src: `${origin}/favicon-swifft/favicon-16x16.png`,
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
      }, {
        src: `${origin}/favicon-swifft/safari-pinned-tab.svg`,
        rel: 'mask-icon',
        sizes: '16x16',
      },
    ],
  },
};

if (manifests[company.name]) {
  const stringManifest = JSON.stringify(manifests[company.name]);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);
  document.querySelector('#manifest-placeholder')?.setAttribute('href', manifestURL);

  let link = document.querySelector('link[rel=\'shortcut icon\']') as HTMLLinkElement;
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  } else if (company.name) {
    link.href = `/favicon-${company.name}/favicon.ico`;
  }
}

const environment: Environment = process.env.NODE_ENV === 'development'
  ? 'development'
  : ((process.env.REACT_APP_ENV || 'development') as Environment);
const gtmConfig = company.config.apis.gtm;
if (gtmConfig) {
  let tagManagerConfig: { gtmId: string; auth?: string; preview?: string } = {
    gtmId: gtmConfig.gtmId as string,
  };
  if (environment !== 'production' && gtmConfig[environment]) {
    tagManagerConfig = { ...tagManagerConfig, ...gtmConfig[environment] as GtmConfig };
  }
  TagManager.initialize(tagManagerConfig);
}

const md = new MobileDetect(window.navigator.userAgent);
const os = md.os();

function openStore() {
  let storeLink = '';
  if (os === 'AndroidOS') storeLink = 'market://details?id=com.jiffygrocery.jiffy';
  else storeLink = 'itms-apps://apps.apple.com/us/app/jiffy-fresh-grocery-delivery/id1556301576';
  window.location.replace(storeLink);
}

if (os === 'AndroidOS' || os === 'iOS') {
  const params = new URLSearchParams(window.location.search);
  if (params.has('modal') && params.get('modal') === 'product' && params.has('id')) {
    const id = params.get('id');
    window.location.replace(`jiffyapp://?deep_link_value=${id}`);
    setTimeout(openStore, 1000);
  }
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
