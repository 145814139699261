import { CompanyConfig } from '../interface';

export const JiffyConfig: CompanyConfig = {
  id: 'bb111efb-a9d8-465d-b9ca-8d255f1d8e9d',
  lang: 'en-GB',
  name: 'Jiffy',
  officialName: 'Cloud Retail Ltd',
  email: 'support@jiffygrocery.co.uk',
  phonePlaceholder: '+44 (5679) 878-999',
  defaultPhoneCode: '+44',
  ageRestricted: 18,
  timeZone: 'Europe/London',
  minimalOrderFeeThreshold: 700,
  warehouse: {
    default: 'LTEDD',
    minimalOrderFeeWarehouseCodes: [],
  },
  map: {
    center: { lat: 51.51375815831753, lng: -0.15098046054686165 },
    zoom: 10,
  },
  links: {
    terms: 'https://jiffygrocery.co.uk/terms',
    privacy: 'https://jiffygrocery.co.uk/privacy',
    cookie: 'https://jiffygrocery.co.uk/cookie',
    deliveryZones: '',
    forRiders: '',
  },
  referral: {
    code: 'RFR-h209',
    discount: '5',
    minBasket: '10',
    downloadAppLink: 'https://click.jiffy.shop/PZKk/f11c621',
  },
  points: {
    value: '1=1p.',
    pointsPerUnit: 15,
    icon: 'jiffy-points',
  },
  appStoreUrl: {
    ios: 'itms-apps://apps.apple.com/id/app/jiffy-fresh-grocery-delivery/id1556301576?l=en',
    android: 'market://details?id=com.jiffygrocery.jiffy',
  },
  apis: {
    amplitude: {
      production: '062a77aff53f9051821a7cad69c3adaa',
      test: 'dbb67e8a6e181b6d07c30a49bc40e60d',
    },
    yaMetrika: {
      token: '76284265',
    },
    gtm: {
      development: {
        auth: 'Dvvj7HU1swJGrjeJ_VaIyw',
        preview: 'env-6',
      },
      staging: {
        auth: 'Pfnq3XHeF0zv41zWp_BqHw',
        preview: 'env-5',
      },
      gtmId: 'GTM-PVJ2RSZ',
    },
  },
};
