import i18n from 'i18next';
import * as Sentry from '@sentry/react';
import { NewOrderResponse } from '../api/Order';
import {
  PaymentIntentResult,
  Stripe,
  StripeCardElement,
  StripeCardElementOptions,
  StripeElements,
} from '@stripe/stripe-js';
import { CardElement } from '@stripe/react-stripe-js';
import { makeAutoObservable, toJS, runInAction } from 'mobx';
import { mainStore } from './MainStore';
import { catalogStore } from './CatalogStore';
import ImgVisa from '../assets/img/logo_visa.png';
import ImgMasterCard from '../assets/img/logo_mastercard.png';
import { makePersistable, isHydrated } from 'mobx-persist-store';
import { userStore } from './UserStore';
import { orderStore, RequestETAResponse } from './OrderStore';
import UnknownCard from '../assets/img/unknown_card.png';
import {
  PAYMENT_TIMEOUT,
  REQUEST_STRIPE_CONFIRM_TIMEOUT,
  PRESENT_PROMOCODES,
  PAYMENT_WAITING_TIME,
} from '../config';
import { ETADeliveryMethodType, ETAPaymentMethod } from '../api/ETA';
import { company } from '../company/Company';

export type OrderData = {
  address: string;
  address2?: string;
  fullName: string;
  email: string;
  phone: string;
  notes: string;
  comment: string;
  instructions: string[];
  saveCard: boolean;
  deliveryMethod: ETADeliveryMethodType;
  sessionId?: string;
};

export enum MobilePaymentMethod {
  ApplePay = 'applePay',
  GooglePay = 'googlePay',
}

export enum AdditionalPaymentMethod {
  KNET = 'KNET',
  VisaMastercard = 'Visa/Mastercard',
}

export type PaymentMethod = ETAPaymentMethod | MobilePaymentMethod | AdditionalPaymentMethod;

type StateData = {
  addressVal: string;
  address2Val: string;
  commentVal: string;
  instructionList: Record<number, string>;
  recipientPhoneVal: string;
  recipientNameVal: string;
  recipientEmailVal: string;
  isAddNewCard: boolean;
  agreeSaveCard: boolean;
  deliveryMethod: ETADeliveryMethodType;
  timestamp: number;
};

class CheckoutStore {
  addressVal = '';
  address2Val = '';
  phoneVal = '';
  isPhoneValid = true;
  isEmailSync = true;
  isPersonalDataSync = true;
  nameVal = '';
  emailVal = '';
  commentVal = '';
  recipientPhoneVal = '';
  isRecipientPhoneValid = false;
  recipientNameVal = '';
  recipientEmailVal = '';
  recipientTempPhoneVal = '';
  isRecipientTempPhoneValid = false;
  recipientTempNameVal = '';
  recipientTempEmailVal = '';
  agreeSaveCard = true;
  error: string | null = null;
  isLoading = false;
  isDisabled = true;
  isPersonalDataChanged = false;
  isShowRecipientPopover = false;
  isShowTimeoutPopover = false;
  isUnableConfirmCardPayment = false;
  isAddNewCard = false;
  paymentIntent: NewOrderResponse | null = null;
  paymentMethod: { card: StripeCardElement } | string = '';
  orderId = '';
  instructionList: Record<number, string> = {};
  activePaymentMethod: PaymentMethod | null = null;
  payments: { value: string; text: string }[] | null = null;
  paymentsMethodId: string | null = null;
  cardBrands: Record<string, { name: string; icon: string }> = {
    visa: {
      name: 'Visa',
      icon: ImgVisa,
    },
    mastercard: {
      name: 'MasterCard',
      icon: ImgMasterCard,
    },
  };
  cardStyle: StripeCardElementOptions = {
    style: {
      base: {
        color: '#333',
        fontFamily: '"Noto Sans", sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#878786',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };
  stateData: StateData | null = null;
  isRefreshed = false;
  deliveryMethod: ETADeliveryMethodType = ETADeliveryMethodType.JiffyDelivery;
  isUpdatedDeliveryPopover = false;
  deliveryPrices: RequestETAResponse | null = null;
  useBonuses = false;
  sessionId: string | undefined = undefined;

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'CheckoutStore',
      properties: ['activePaymentMethod', 'payments', 'paymentsMethodId', 'stateData'],
      storage: window.localStorage,
    }).catch((error) => error && console.error(error));
  }

  // Getters
  get isSynchronized(): boolean {
    return isHydrated(this);
  }

  get isPersonalDataValid(): boolean {
    return !!(
      this.isPhoneValid &&
      userStore.fullName.length &&
      this.isEmailValid &&
      this.isEmailSync &&
      this.isPersonalDataSync
    );
  }

  get isRecipientValid(): boolean {
    return !!(
      this.isPersonalDataValid &&
      this.isRecipientPhoneValid &&
      this.recipientNameVal.length &&
      this.isRecipientEmailValid
    );
  }

  get isRecipientTempValid(): boolean {
    return !!(
      this.isRecipientTempPhoneValid &&
      this.recipientTempNameVal.length &&
      this.isRecipientTempEmailValid
    );
  }

  get isEmailValid(): boolean {
    return mainStore.validateEmail(this.emailVal);
  }

  get isRecipientEmailValid(): boolean {
    return mainStore.validateEmail(this.recipientEmailVal);
  }

  get isRecipientTempEmailValid(): boolean {
    return mainStore.validateEmail(this.recipientTempEmailVal);
  }

  get isFormValid(): boolean {
    if (company.hasAddress2 && !this.address2Val.trim()) {
      return false;
    }

    return (
      (this.isPersonalDataValid || this.isRecipientValid) && this.activePaymentMethod !== null &&
      !!this.addressVal.trim()
    );
  }

  get availableDeliveryMethods(): ETADeliveryMethodType[] {
    const methodList = Object.keys(
      orderStore.etaCalculation?.cost || {}) as ETADeliveryMethodType[];
    if (!methodList.length) return [];
    return [
      ETADeliveryMethodType.JiffyDelivery,
      ETADeliveryMethodType.ClickAndCollect,
    ].filter((method) => methodList.includes(method));
  }

  get availablePaymentMethods(): PaymentMethod[] {
    const availablePaymentMethods: PaymentMethod[] = [];
    let paymentMethods = orderStore.etaCalculation?.paymentMethods[this.deliveryMethod] || [];

    if (!paymentMethods.length) {
      paymentMethods = orderStore.etaCalculation?.paymentMethods[ETADeliveryMethodType.JiffyDelivery] || [];
    }

    if (paymentMethods.length) {
      if (paymentMethods.includes(ETAPaymentMethod.Card)) {
        if (mainStore.isApplePay) availablePaymentMethods.push(MobilePaymentMethod.ApplePay);

        if (mainStore.isGooglePay && orderStore.paymentSystem !== 'myfatoorah') {
          availablePaymentMethods.push(MobilePaymentMethod.GooglePay);
        }

        if (orderStore.paymentSystem === 'myfatoorah') {
          availablePaymentMethods.push(AdditionalPaymentMethod.KNET);
          availablePaymentMethods.push(AdditionalPaymentMethod.VisaMastercard);
        }

        availablePaymentMethods.push(ETAPaymentMethod.Card);
      }

      if (paymentMethods.includes(ETAPaymentMethod.Cash)) {
        availablePaymentMethods.push(ETAPaymentMethod.Cash);
      }
    }

    return availablePaymentMethods;
  }

  // Setters
  setAddressVal(val: string) {
    this.addressVal = val;
  }

  setAddress2Val(val: string) {
    this.address2Val = val;
  }

  setPhoneVal(val: string) {
    this.phoneVal = val;
  }

  setIsPhoneValid(flag: boolean) {
    this.isPhoneValid = flag;
  }

  setIsEmailSync(flag: boolean) {
    if (flag) {
      this.setEmailVal(userStore.personalData.email || '');
    }
    this.isEmailSync = flag;
  }

  setIsPersonalDataSync(flag: boolean) {
    this.isPersonalDataSync = flag;
  }

  setNameVal(val: string) {
    this.nameVal = val;
  }

  setEmailVal(val: string) {
    this.emailVal = val;
  }

  setCommentVal(val: string) {
    this.commentVal = val;
  }

  setRecipientTempPhoneVal(val: string) {
    this.recipientTempPhoneVal = val;
  }

  setIsRecipientTempPhoneValid(flag: boolean) {
    this.isRecipientTempPhoneValid = flag;
  }

  setRecipientTempNameVal(val: string) {
    this.recipientTempNameVal = val;
  }

  setRecipientTempEmailVal(val: string) {
    this.recipientTempEmailVal = val;
  }

  setAgreeSaveCard(flag: boolean) {
    this.agreeSaveCard = flag;
  }

  setIsLoading(flag: boolean) {
    this.isLoading = flag;
  }

  setPaymentIntent(val: NewOrderResponse | null) {
    this.paymentIntent = val;
  }

  setPaymentMethod(val: { card: StripeCardElement } | string) {
    this.paymentMethod = val;
  }

  setOrderId(val: string) {
    this.orderId = val;
  }

  setError(val: string | null) {
    this.error = val;
  }

  setIsDisabled(flag: boolean) {
    this.isDisabled = flag;
  }

  setIsPersonalDataChanged(flag: boolean) {
    this.isPersonalDataChanged = flag;
  }

  setIsShowRecipientPopover(flag: boolean) {
    this.isShowRecipientPopover = flag;
  }

  setIsUnableConfirmCardPayment(flag: boolean) {
    this.isUnableConfirmCardPayment = flag;
  }

  setRecipient() {
    this.recipientPhoneVal = toJS(this.recipientTempPhoneVal);
    this.isRecipientPhoneValid = toJS(this.isRecipientTempPhoneValid);
    this.recipientNameVal = toJS(this.recipientTempNameVal);
    this.recipientEmailVal = toJS(this.recipientTempEmailVal);
  }

  addInstruction(val: string, index: number) {
    this.instructionList[index] = val;
  }

  deleteInstruction(index: number) {
    delete this.instructionList[index];
  }

  resetInstructionList() {
    this.instructionList = {};
  }

  setActivePaymentMethod(val: PaymentMethod | null) {
    this.activePaymentMethod = val;
    this.setError(null);
  }

  setIsAddNewCard(flag: boolean) {
    this.isAddNewCard = flag;
    this.setIsDisabled(flag);
    this.setError(null);
  }

  setPayments(val: { value: string; text: string }[] | null) {
    this.payments = val;
  }

  setPaymentsMethodId(val: string | null) {
    this.paymentsMethodId = val;
  }

  setIsShowTimeoutPopover(flag: boolean) {
    this.isShowTimeoutPopover = flag;
  }

  setStateData() {
    this.stateData = {
      addressVal: toJS(this.addressVal),
      address2Val: toJS(this.address2Val),
      commentVal: toJS(this.commentVal),
      instructionList: toJS(this.instructionList),
      recipientPhoneVal: toJS(this.recipientPhoneVal),
      recipientNameVal: toJS(this.recipientNameVal),
      recipientEmailVal: toJS(this.recipientEmailVal),
      isAddNewCard: toJS(this.isAddNewCard),
      agreeSaveCard: toJS(this.agreeSaveCard),
      deliveryMethod: toJS(this.deliveryMethod),
      timestamp: Date.now(),
    };
  }

  setDeliveryMethod(method: ETADeliveryMethodType) {
    this.deliveryMethod = method;
  }

  setIsUpdatedDeliveryPopover(flag: boolean) {
    this.isUpdatedDeliveryPopover = flag;
  }

  setDeliveryPrices(val: RequestETAResponse) {
    this.deliveryPrices = val;
  }

  setUseBonuses(flag: boolean) {
    this.useBonuses = flag;
  }

  setSessionId(val: string) {
    this.sessionId = val;
  }

  // Actions
  getOrderData(): OrderData {
    let instructions = Object.values(this.instructionList);
    const notes = [this.address2Val, instructions.join(', ').toUpperCase(), this.commentVal]
      .filter(i => i).join(', ');
    if (this.deliveryMethod === ETADeliveryMethodType.ClickAndCollect) instructions = [];
    let isSaveCard = toJS(this.agreeSaveCard);
    if (
      window.ReactNativeWebView &&
      this.activePaymentMethod === MobilePaymentMethod.ApplePay &&
      mainStore.isApplePay
    ) {
      isSaveCard = false;
    }
    if (
      window.ReactNativeWebView &&
      this.activePaymentMethod === MobilePaymentMethod.GooglePay &&
      mainStore.isGooglePay
    ) {
      isSaveCard = false;
    }
    return {
      address: this.addressVal,
      address2: this.address2Val,
      fullName: this.isRecipientValid ? this.recipientNameVal : userStore.fullName,
      email: this.isRecipientValid ? this.recipientEmailVal.trim() : this.emailVal.trim(),
      phone: this.isRecipientValid ? this.recipientPhoneVal : this.phoneVal,
      comment: this.commentVal,
      instructions: instructions,
      notes: notes,
      saveCard: isSaveCard,
      deliveryMethod: this.deliveryMethod,
      sessionId: this.sessionId,
    };
  }

  autofillRecipient() {
    this.recipientTempPhoneVal = toJS(this.phoneVal);
    this.isRecipientTempPhoneValid = toJS(this.isPhoneValid);
    this.recipientTempNameVal = userStore.fullName;
    this.recipientTempEmailVal = toJS(this.emailVal);
  }

  purchaseCompletedAnalytics(orderId: string) {
    const lastOrderETA = orderStore.etaCalculation?.duration.min || 0;
    mainStore.sendToRN('analytics', {
      name: 'Purchase: completed',
      params: {
        success: false,
      },
    });
    if (mainStore.analytics.totalSuccessfulOrders === 1) {
      mainStore.sendToRN('analytics', {
        name: 'af_first_purchase',
        params: {
          revenue: catalogStore.finalPrice,
          price: catalogStore.totalCartPrice.base,
          quantity: catalogStore.totalCartCount,
          currency: orderStore.currency.toUpperCase(),
          order_id: orderId,
        },
      });
      mainStore.sendAnalyticsToFirebase({
        name: 'first_purchase',
        params: {
          transaction_id: orderId,
          value: mainStore.toFloat(catalogStore.finalPrice),
          currency: orderStore.currency.toUpperCase(),
          tax: 0,
          shipping: 0,
          items: catalogStore.cartForFirebase,
          coupon: catalogStore.promocode.value,
        },
      });
      mainStore.sendToRN('trackOneSignalOutcome', {
        name: 'first_purchase_quantity',
        value: catalogStore.totalCartCount,
      });
      mainStore.sendToRN('trackOneSignalOutcome', {
        name: 'first_purchase_amount',
        value: catalogStore.totalCartPrice.base,
      });
    } else {
      mainStore.sendToRN('trackOneSignalOutcome', {
        name: 'purchase_quantity',
        value: catalogStore.totalCartCount,
      });
      mainStore.sendToRN('trackOneSignalOutcome', {
        name: 'purchase_amount',
        value: catalogStore.totalCartPrice.base,
      });
    }
    mainStore.sendToRN('setUserProperties', {
      'Commerce: last purchase date': new Date().toISOString(),
    });
    mainStore.sendToRN('setUserProperties', {
      'Commerce: last order eta': lastOrderETA,
    });
    mainStore.sendAnalyticsToFirebase({
      name: 'purchase',
      params: {
        transaction_id: orderId,
        value: mainStore.toFloat(catalogStore.finalPrice),
        currency: orderStore.currency.toUpperCase(),
        tax: 0,
        shipping: 0,
        items: catalogStore.cartForFirebase,
        coupon: catalogStore.promocode.value,
      },
    });
  }

  initStateData() {
    if (!this.stateData || Date.now() - this.stateData.timestamp > 6000) {
      this.stateData = null;
      this.isRefreshed = false;
      return;
    }
    const stateData = toJS(this.stateData);
    this.addressVal = stateData.addressVal;
    this.address2Val = stateData.address2Val;
    this.commentVal = stateData.commentVal;
    this.instructionList = stateData.instructionList;
    this.recipientPhoneVal = stateData.recipientPhoneVal;
    this.isRecipientPhoneValid = true;
    this.recipientNameVal = stateData.recipientNameVal;
    this.recipientEmailVal = stateData.recipientEmailVal;
    this.isAddNewCard = stateData.isAddNewCard;
    this.agreeSaveCard = stateData.agreeSaveCard;
    if (stateData.isAddNewCard) this.isDisabled = true;
    this.isRefreshed = true;
    this.stateData = null;
    this.deliveryMethod = stateData.deliveryMethod;
  }

  initActivePaymentMethod() {
    if (!this.availablePaymentMethods.length) {
      this.setActivePaymentMethod(null);
      return;
    }

    if (
      this.activePaymentMethod &&
      this.availablePaymentMethods.includes(this.activePaymentMethod)
    ) return;

    this.setActivePaymentMethod(this.availablePaymentMethods[0]);
  }

  async requestPayments() {
    if (orderStore.paymentSystem === 'myfatoorah') {
      this.setPayments(null);
      this.setPaymentsMethodId(null);
      return;
    }

    try {
      await orderStore.requestPayments().catch((error) => error && console.error(error));
      if (!orderStore.paymentCards?.length) {
        this.setPayments(null);
        this.setPaymentsMethodId(null);
        return;
      }
      const paymentList: { value: string; text: string }[] = [];
      for (let i = 0; i < orderStore.paymentCards.length; i++) {
        paymentList.push({
          value: orderStore.paymentCards[i].id,
          text: `<img class="w-38 h-22 flex-shrink-0" src="${
            this.cardBrands[orderStore.paymentCards[i].card.brand]?.icon || UnknownCard
          }" alt="" /><div class="fs-14 w-100p text-truncate mx-12 text-capitalize">${
            this.cardBrands[orderStore.paymentCards[i].card.brand]?.name ||
            orderStore.paymentCards[i].card.brand
          } ${
            orderStore.paymentCards[i].card.last4
          }</div><div class="flex-shrink-0 fs-12">${mainStore.addLeadingZero(
            orderStore.paymentCards[i].card.exp_month,
          )}/${orderStore.paymentCards[i].card.exp_year.toString().slice(-2)}</div>`,
        });
      }
      const defaultIndex = orderStore.paymentCards.findIndex(
        (el) => el.metadata.is_default === 'true',
      );
      paymentList.splice(0, 0, paymentList.splice(defaultIndex, 1)[0]);
      this.setPayments(paymentList);
      if (paymentList.length) {
        this.setPaymentsMethodId(paymentList[0].value);
        if (!this.isAddNewCard) this.setIsDisabled(false);
        mainStore.sendToRN('analytics', {
          name: 'Purchase: payment method filled in',
          params: {
            cart_id: undefined,
            payment_method: 'card',
            type: 'existing',
          },
        });
        mainStore.sendAnalyticsToFirebase({
          name: 'add_payment_info',
          params: {
            currency: orderStore.currency.toUpperCase(),
            payment_type: 'credit card',
            value: mainStore.toFloat(catalogStore.finalPrice),
            items: catalogStore.cartForFirebase,
            coupon: catalogStore.promocode.value,
          },
        });
      }
    } catch (error) {
      if (!error.response && (error.code === 'ECONNABORTED' || error.message === 'Network Error')) {
        return;
      }
      Sentry.withScope((scope) => {
        scope.setExtras({
          error: error.response,
        });
        Sentry.captureMessage('[Checkout] Failed payments loading', 'warning');
      });
    }
  }

  async orderCheckout(stripe?: Stripe | null, elements?: StripeElements | null) {
    if (mainStore.convertPoundsToPence(catalogStore.finalPrice) < 50) {
      mainStore.setIsZeroCartPopover(true);
      return;
    }

    this.setPaymentIntent(null);
    this.setIsLoading(true);
    this.setIsUnableConfirmCardPayment(false);
    this.setError(null);
    await this.syncAddress();
    let isCartChanged = false;
    try {
      isCartChanged = await catalogStore.calculateCart();
    } catch (error) {
      error && console.error(error);
    }
    if (isCartChanged) {
      this.setIsLoading(false);
      return;
    }
    try {
      if (orderStore.gift && orderStore.activeGift) {
        orderStore.setIsAuthInCart(false);
        if (!(await orderStore.fetchGifts())) {
          this.setIsLoading(false);
          return;
        }
      }
    } catch (error) {
      error && console.error(error);
    }
    if (this.deliveryMethod !== ETADeliveryMethodType.ClickAndCollect && orderStore.freezeETAExpired - Date.now() < 10_000) {
      try {
        const deliveryPrices = await orderStore.getDeliveryCost();
        if (deliveryPrices.oldPrice !== deliveryPrices.newPrice) {
          this.setIsLoading(false);
          return;
        }
      } catch (error) {
        error && console.error(error);
        const deliveryPrices = await orderStore.requestETA().catch(() => void 0);
        if (deliveryPrices && deliveryPrices.oldPrice !== deliveryPrices.newPrice) {
          this.setIsLoading(false);
          return;
        }
        if (!orderStore.etaCalculation) {
          this.setIsLoading(false);
          return;
        }
      }
    }
    const activePaymentMethod = this.activePaymentMethod;
    await orderStore.requestETA().catch(() => void 0);
    this.initActivePaymentMethod();
    if (activePaymentMethod !== this.activePaymentMethod) {
      mainStore.pushAlert('warning', i18n.t('checkoutPage:optionDisabled', {
        option: i18n.t(activePaymentMethod === ETAPaymentMethod.Cash ? 'cash' : 'bankCard'),
      }));
      this.setIsLoading(false);
      return;
    }
    let response: NewOrderResponse | null = null;
    try {
      response = await orderStore.newOrder(this.getOrderData());
    } catch (error) {
      this.setIsLoading(false);
      return;
    }
    if (!response || !response.order) {
      this.setIsLoading(false);
      return;
    }
    this.setPaymentIntent(response);
    if (this.activePaymentMethod !== ETAPaymentMethod.Cash) {
      if (orderStore.paymentSystem === 'myfatoorah') {
        if (
          window.ReactNativeWebView &&
          this.activePaymentMethod === MobilePaymentMethod.ApplePay &&
          mainStore.isApplePay
        ) {
          this.applePay(response).catch((error) => error && console.error(error));
          return;
        }
        if (response.payment?.myFatoorahPayment.error) {
          this.setError(i18n.t('errors:paymentSystemError'));
          this.setIsLoading(false);
          return;
        }
        if (response.payment?.myFatoorahPayment.paymentURL) {
          if (window.ReactNativeWebView) {
            mainStore.sendToRN(
              'openInAppBrowser', { url: response.payment?.myFatoorahPayment.paymentURL });
          } else {
            window.open(response.payment?.myFatoorahPayment.paymentURL, '_blank')?.focus();
          }
        }
      }
      if (orderStore.paymentSystem === 'stripe') {
        if (!response.payment_intent) {
          this.setIsLoading(false);
          return;
        }
        if (
          window.ReactNativeWebView &&
          this.activePaymentMethod === MobilePaymentMethod.ApplePay &&
          mainStore.isApplePay
        ) {
          this.applePay(response).catch((error) => error && console.error(error));
          return;
        }
        if (
          window.ReactNativeWebView &&
          this.activePaymentMethod === MobilePaymentMethod.GooglePay &&
          mainStore.isGooglePay
        ) {
          this.googlePay(response).catch((error) => error && console.error(error));
          return;
        }
        if (this.activePaymentMethod === ETAPaymentMethod.Card) {
          if (!stripe || !elements) return;
          let paymentMethod: { card: StripeCardElement } | string;
          if (this.payments && this.payments.length && !this.isAddNewCard) {
            paymentMethod = this.paymentsMethodId || this.payments[0].value;
          } else paymentMethod = { card: elements.getElement(CardElement) as StripeCardElement };
          this.setPaymentMethod(paymentMethod);
          this.stripeConfirmCardPayment(response, paymentMethod, stripe).catch(
            (error) => error && console.error(error));
          return;
        }
      }
    }
    this.setPaymentMethod('');
    this.finalizePayment().catch((error) => error && console.error(error));
  }

  async applePay(intent: NewOrderResponse) {
    if (orderStore.paymentSystem === 'myfatoorah') {
      mainStore.sendToRN(
        'initApplePay',
        {
          params: {
            cartItems: catalogStore.cart
              .map<Record<string, string>>((item) => {
                return {
                  label: item.name || '',
                  amount: mainStore.convertPenceToPounds(
                    (item.discountPrice ? item.discountPrice : item.price) * item.count),
                };
              })
              .concat({
                label: 'Jiffy',
                amount: catalogStore.finalPrice,
                type: 'final',
              }),
            country: 'GB',
            currency: orderStore.currency.toUpperCase(),
            shippingMethods: [
              {
                label: 'Delivery',
                amount: catalogStore.isFreeDelivery
                  ? '0'
                  : String(
                    orderStore.fee.shippingPounds || 0),
                identifier: 'delivery',
              },
            ],
          },
          clientSecret: undefined,
        },
        (e) => {
          Sentry.withScope((scope) => {
            scope.setExtras({
              response: e,
              paymentAmount: catalogStore.finalPrice,
            });
            Sentry.captureMessage('[ApplePay] Success', 'debug');
          });
        },
        (e) => {
          Sentry.withScope((scope) => {
            scope.setExtras({
              error: e,
              paymentAmount: catalogStore.finalPrice,
            });
            Sentry.captureMessage('[ApplePay] Failed', 'warning');
          });
        },
      );
    }

    if (!intent || !intent.payment_intent || !intent.payment_intent.client_secret || !intent.order) {
      this.setError(i18n.t('errors:paymentSystemError'));
      this.setIsLoading(false);
      return;
    }
    try {
      const paymentResult = await new Promise<'success' | 'failed' | 'canceled' | 'timeout'>(
        (resolve) => {
          if (!intent.payment_intent) {
            resolve('failed');
            return;
          }
          const timeout = setTimeout(() => {
            Sentry.withScope((scope) => {
              scope.setExtras({
                timeout: PAYMENT_TIMEOUT / 1000 + 'sec',
                request: {
                  paymentIntentSecret: intent.payment_intent?.client_secret || '',
                },
              });
              Sentry.captureMessage('[ApplePay] Timeout 10min', 'warning');
            });
            resolve('timeout');
          }, PAYMENT_TIMEOUT);
          mainStore.sendToRN(
            'initApplePay',
            {
              params: {
                cartItems: catalogStore.cart
                  .map<Record<string, string>>((item) => {
                    return {
                      label: item.name || '',
                      amount: mainStore.convertPenceToPounds(
                        (item.discountPrice ? item.discountPrice : item.price) * item.count),
                    };
                  })
                  .concat({
                    label: 'Jiffy',
                    amount: catalogStore.finalPrice,
                    type: 'final',
                  }),
                country: 'GB',
                currency: orderStore.currency.toUpperCase(),
                shippingMethods: [
                  {
                    label: 'Delivery',
                    amount: catalogStore.isFreeDelivery
                      ? '0'
                      : String(
                        orderStore.fee.shippingPounds || 0),
                    identifier: 'delivery',
                  },
                ],
              },
              clientSecret: intent.payment_intent.client_secret,
            },
            (e) => {
              clearTimeout(timeout);
              Sentry.withScope((scope) => {
                scope.setExtras({
                  response: e,
                  paymentAmount: catalogStore.finalPrice,
                });
                Sentry.captureMessage('[ApplePay] Success', 'debug');
              });
              resolve('success');
            },
            (e) => {
              clearTimeout(timeout);
              Sentry.withScope((scope) => {
                scope.setExtras({
                  error: e,
                  paymentAmount: catalogStore.finalPrice,
                });
                Sentry.captureMessage('[ApplePay] Failed', 'warning');
              });
              resolve(e.error?.code === 'Canceled' ? 'canceled' : 'failed');
            },
          );
        },
      );
      if (
        this.paymentIntent?.payment_intent?.client_secret !== intent.payment_intent.client_secret
      ) return;
      else {
        if (paymentResult === 'success') {
          await this.finalizePayment().catch((error) => error && console.error(error));
        } else {
          if (paymentResult === 'canceled') {
            await orderStore.orderCancel(intent.order.id).catch(
              (error) => error && console.error(error));
          }
          if (paymentResult === 'failed' || paymentResult === 'timeout') {
            this.setError(i18n.t('errors:paymentSystemError'));
          }
        }
      }
      this.setIsLoading(false);
    } catch (error) {
      if (
        this.paymentIntent?.payment_intent?.client_secret === intent.payment_intent.client_secret
      ) {
        this.setError(i18n.t('errors:paymentSystemError'));
        this.setIsLoading(false);
      }
      Sentry.withScope((scope) => {
        scope.setExtras({
          error: error,
        });
        Sentry.captureMessage('[ApplePay] Error exception', 'warning');
      });
    }
  }

  async googlePay(intent: NewOrderResponse) {
    if (!intent || !intent.payment_intent || !intent.payment_intent.client_secret || !intent.order) {
      this.setError(i18n.t('errors:paymentSystemError'));
      this.setIsLoading(false);
      return;
    }
    try {
      const paymentResult = await new Promise<'success' | 'failed' | 'canceled' | 'timeout'>(
        (resolve) => {
          if (!intent.payment_intent) {
            resolve('failed');
            return;
          }
          const timeout = setTimeout(() => {
            Sentry.withScope((scope) => {
              scope.setExtras({
                timeout: PAYMENT_TIMEOUT / 1000 + 'sec',
                request: {
                  paymentIntentSecret: intent.payment_intent?.client_secret || '',
                },
              });
              Sentry.captureMessage('[GooglePay] Timeout 10min', 'warning');
            });
            resolve('timeout');
          }, PAYMENT_TIMEOUT);
          mainStore.sendToRN(
            'requestGooglePayment',
            {
              transaction: {
                totalPrice: catalogStore.finalPrice,
                totalPriceStatus: 'FINAL',
                currencyCode: orderStore.currency.toUpperCase(),
              },
              merchantName: this.isRecipientValid ? this.recipientNameVal : userStore.fullName,
              clientSecret: intent.payment_intent.client_secret,
            },
            (e) => {
              clearTimeout(timeout);
              Sentry.withScope((scope) => {
                scope.setExtras({
                  response: e,
                  paymentAmount: catalogStore.finalPrice,
                });
                Sentry.captureMessage('[GooglePay] Success', 'debug');
              });
              resolve('success');
            },
            (e) => {
              clearTimeout(timeout);
              Sentry.withScope((scope) => {
                scope.setExtras({
                  error: e,
                  paymentAmount: catalogStore.finalPrice,
                });
                Sentry.captureMessage('[GooglePay] Failed', 'warning');
              });
              resolve(
                e.confirmationError?.code === 'PAYMENT_RESULT_CANCELED' ? 'canceled' : 'failed',
              );
            },
          );
        },
      );
      if (
        this.paymentIntent?.payment_intent?.client_secret !== intent.payment_intent.client_secret
      ) return;
      else {
        if (paymentResult === 'success') {
          await this.finalizePayment().catch((error) => error && console.error(error));
        } else {
          if (paymentResult === 'canceled') {
            await orderStore.orderCancel(intent.order.id).catch(
              (error) => error && console.error(error));
          }
          if (paymentResult === 'failed' || paymentResult === 'timeout') {
            this.setError(i18n.t('errors:paymentSystemError'));
          }
        }
      }
      this.setIsLoading(false);
    } catch (error) {
      if (
        this.paymentIntent?.payment_intent?.client_secret === intent.payment_intent.client_secret
      ) {
        this.setError(i18n.t('errors:paymentSystemError'));
        this.setIsLoading(false);
      }
      Sentry.withScope((scope) => {
        scope.setExtras({
          error: error,
        });
        Sentry.captureMessage('[GooglePay] Error exception', 'warning');
      });
    }
  }

  async stripeConfirmCardPayment(
    intent: NewOrderResponse,
    paymentMethod: { card: StripeCardElement } | string,
    stripe: Stripe,
  ) {
    if (!intent || !intent.payment_intent || !intent.payment_intent.client_secret || !intent.order) {
      this.setError(i18n.t('errors:paymentSystemError'));
      this.setIsLoading(false);
      return;
    }
    try {
      const paymentResult = await new Promise<PaymentIntentResult | 'failed' | 'timeout'>(
        (resolve, reject) => {
          if (!intent.payment_intent) {
            resolve('failed');
            return;
          }
          const timeout = setTimeout(() => {
            if (mainStore.appPlatform !== 'android') {
              Sentry.withScope((scope) => {
                scope.setExtras({
                  timeout: REQUEST_STRIPE_CONFIRM_TIMEOUT / 1000 + 'sec',
                  request: {
                    paymentIntentSecret: intent.payment_intent?.client_secret || '',
                    paymentMethod: paymentMethod,
                  },
                });
                Sentry.captureMessage(
                  '[Checkout] Stripe confirmCardPayment timeout',
                  'warning',
                );
              });
              resolve('timeout');
            }
          }, REQUEST_STRIPE_CONFIRM_TIMEOUT);
          const timeoutFroze = setTimeout(() => {
            Sentry.withScope((scope) => {
              scope.setExtras({
                timeout: PAYMENT_TIMEOUT / 1000 + 'sec',
                request: {
                  paymentIntentSecret: intent.payment_intent?.client_secret || '',
                  paymentMethod: paymentMethod,
                },
              });
              Sentry.captureMessage(
                '[Checkout] Stripe confirmCardPayment timeout 10min',
                'warning',
              );
            });
            resolve('timeout');
          }, PAYMENT_TIMEOUT);
          stripe
            .confirmCardPayment(intent.payment_intent.client_secret || '', {
              payment_method: paymentMethod,
            })
            .then((e) => {
              clearTimeout(timeout);
              clearTimeout(timeoutFroze);
              resolve(e);
            })
            .catch((e) => {
              clearTimeout(timeout);
              clearTimeout(timeoutFroze);
              reject(e);
            });
        },
      );
      if (
        this.paymentIntent?.payment_intent?.client_secret !== intent.payment_intent.client_secret
      ) {
        return;
      } else {
        if (paymentResult === 'timeout' && mainStore.appPlatform !== 'android') {
          this.setIsShowTimeoutPopover(true);
        }
        if (paymentResult === 'failed' || paymentResult === 'timeout') {
          this.setError(i18n.t('errors:paymentSystemError'));
          this.setIsLoading(false);
          return;
        }
        if (paymentResult.error) {
          this.setError(paymentResult.error.message || '');
          Sentry.withScope((scope) => {
            scope.setExtras({
              response: paymentResult,
            });
            Sentry.captureMessage(
              '[Checkout] Stripe confirmCardPayment error',
              'warning',
            );
          });
          this.setIsLoading(false);
          return;
        }
        await this.finalizePayment().catch((error) => error && console.error(error));
      }
      this.setIsLoading(false);
    } catch (error) {
      if (
        this.paymentIntent?.payment_intent?.client_secret === intent.payment_intent.client_secret
      ) {
        this.setError(i18n.t('errors:paymentSystemError'));
        this.setIsLoading(false);
      }
      Sentry.withScope((scope) => {
        scope.setExtras({
          error: error,
        });
        Sentry.captureMessage(
          '[Checkout] Stripe confirmCardPayment exception',
          'warning',
        );
      });
    }
  }

  async finalizePayment() {
    if (!this.paymentIntent || !this.paymentIntent.order || !this.paymentIntent.payment_intent) {
      return;
    }

    const isCardPayment = ([
      ETAPaymentMethod.Card,
      AdditionalPaymentMethod.KNET,
      AdditionalPaymentMethod.VisaMastercard,
    ] as PaymentMethod[]).includes(this.activePaymentMethod || ETAPaymentMethod.Card);

    try {
      this.setError(null);
      let count = orderStore.paymentSystem === 'myfatoorah' && isCardPayment ? 60 : 3;
      let bonuses = null;
      const waitingExpires = Date.now() + PAYMENT_WAITING_TIME * 1000;
      while (count > 0) {
        if (!this.paymentIntent || !this.paymentIntent.order) {
          this.setPaymentIntent(null);
          this.setError(i18n.t('errors:paymentSystemError'));
          this.setIsLoading(false);
          return;
        }
        if (
          orderStore.paymentSystem === 'myfatoorah' &&
          isCardPayment &&
          Date.now() >= waitingExpires
        ) {
          this.setPaymentIntent(null);
          this.setError(i18n.t('errors:paymentSystemError'));
          this.setIsLoading(false);
          return;
        }
        const { status, received_bonuses } = await orderStore.checkOrderPayment(
          this.paymentIntent.order.id).catch((error) => error && console.error(error));
        bonuses = received_bonuses;
        count--;
        if (!status || ['created', 'reserved'].includes(status)) {
          if (count) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
            continue;
          } else {
            this.setPaymentIntent(null);
            this.setError(i18n.t('errors:paymentSystemError'));
            this.setIsLoading(false);
            return;
          }
        }
        if (status === 'cancelled') {
          this.setPaymentIntent(null);
          this.setError(i18n.t('errors:paymentSystemError'));
          this.setIsLoading(false);
          return;
        }
        break;
      }

      await orderStore.updateOrdersAnalytics().catch((error) => error && console.error(error));
      orderStore.setOrderStatus('accepted');
      orderStore.setIsFirstOrder(false);
      mainStore.setNewDeliveryMethodForPromocodePopover(null);
      orderStore.setActiveOrderProductCount(catalogStore.totalCartCount);
      orderStore.setActiveOrderID(this.paymentIntent.order.id);
      this.setOrderId(this.paymentIntent.order.id);
      orderStore.setActiveOrderBonuses(bonuses);
      orderStore.setFreezeETAExpired(0);
      if (catalogStore.promocode.coupon) {
        runInAction(() => {
          const promocode = catalogStore.promocode.value.toUpperCase();
          if (PRESENT_PROMOCODES.includes(promocode) || catalogStore.promocode.discountLimit) {
            catalogStore.addUsedPromocodes(promocode);
          }
          mainStore.analytics.promocodeUsedCount += 1;
          mainStore.sendToRN('setUserProperties', {
            'Commerce: promocodes used': mainStore.analytics.promocodeUsedCount,
          });
        });
      }
      this.purchaseCompletedAnalytics(this.paymentIntent.order.id);
      orderStore.setActiveGift(null);
      orderStore.setGift(null);
      catalogStore.emptyCart();
      catalogStore.resetPromocode();
      checkoutStore.setUseBonuses(false);
      if (!userStore.deliveryAddress?.address1) userStore.setDeliveryAddress1(this.addressVal);
      if (!userStore.deliveryAddress?.address2) userStore.setDeliveryAddress2(this.address2Val);
      mainStore.sendToRN('removeTag', 'high_eta_without_order');
    } catch (e) {
      this.setError(i18n.t('errors:paymentSystemError'));
    }
  }

  async syncAddress() {
    try {
      if (userStore.deliveryAddress) {
        let isChangedAddress = false;
        if (this.commentVal !== userStore.deliveryAddress.comment) {
          isChangedAddress = true;
          runInAction(() => {
            if (!userStore.deliveryAddress) return;
            userStore.deliveryAddress.comment = this.commentVal;
          });
        }
        if (
          Object.values(this.instructionList).length !==
          userStore.deliveryAddress.instructions?.length
        ) {
          runInAction(() => {
            if (!userStore.deliveryAddress) return;
            if (
              catalogStore.isAdultItemInCart &&
              Object.values(this.instructionList).length === 2 &&
              userStore.deliveryAddress.instructions?.length === 3
            )
              return;
            isChangedAddress = true;
            userStore.deliveryAddress.instructions = Object.values(this.instructionList);
          });
        }
        if (!userStore.deliveryAddress.addressId) {
          await this.addNewAddress().catch((error) => error && console.error(error));
        } else {
          if (isChangedAddress) {
            const isSuccess = await userStore
              .updateAddress(userStore.deliveryAddress.addressId, {
                comment: userStore.deliveryAddress.comment || '',
                instructions: userStore.deliveryAddress.instructions || [],
              })
              .catch((error) => error && console.error(error));
            if (isSuccess) return;
            await this.addNewAddress().catch((error) => error && console.error(error));
          }
        }
      }
    } catch (error) {
      error && console.error(error);
    }
  }

  async addNewAddress() {
    if (!userStore.deliveryAddress) return;
    try {
      const addressId = await userStore
        .addAddress({
          street_address_1: userStore.deliveryAddress.address1,
          city: userStore.deliveryAddress.city,
          postcode: userStore.deliveryAddress.zip,
          latitude: userStore.deliveryAddress.coordinates.lat,
          longitude: userStore.deliveryAddress.coordinates.lng,
          country: userStore.deliveryAddress.country,
          comment: userStore.deliveryAddress.comment || '',
          instructions: userStore.deliveryAddress.instructions || [],
        })
        .catch((error) => error && console.error(error));
      runInAction(() => {
        if (!userStore.deliveryAddress) return;
        userStore.deliveryAddress.addressId = addressId || null;
      });
    } catch (error) {
      error && console.error(error);
    }
  }

  resetStore() {
    this.addressVal = '';
    this.phoneVal = '';
    this.isPhoneValid = true;
    this.isEmailSync = true;
    this.isPersonalDataSync = true;
    this.nameVal = '';
    this.emailVal = '';
    this.commentVal = '';
    this.recipientPhoneVal = '';
    this.isRecipientPhoneValid = false;
    this.recipientNameVal = '';
    this.recipientEmailVal = '';
    this.recipientTempPhoneVal = '';
    this.isRecipientTempPhoneValid = false;
    this.recipientTempNameVal = '';
    this.recipientTempEmailVal = '';
    this.agreeSaveCard = true;
    this.error = null;
    this.isLoading = false;
    this.isDisabled = true;
    this.isPersonalDataChanged = false;
    this.isShowRecipientPopover = false;
    this.isShowTimeoutPopover = false;
    this.isUnableConfirmCardPayment = false;
    this.isAddNewCard = false;
    this.paymentIntent = null;
    this.paymentMethod = '';
    this.orderId = '';
    this.instructionList = {};
    this.stateData = null;
    this.isRefreshed = false;
    this.isUpdatedDeliveryPopover = false;
    this.sessionId = undefined;
    if (
      !this.availableDeliveryMethods.length ||
      this.availableDeliveryMethods.includes(ETADeliveryMethodType.JiffyDelivery)
    ) {
      this.setDeliveryMethod(ETADeliveryMethodType.JiffyDelivery);
    } else {
      this.setDeliveryMethod(this.availableDeliveryMethods[0]);
    }
  }
}

export const checkoutStore = new CheckoutStore();
