import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Popover from './Popover';
import { catalogStore } from '../stores/CatalogStore';
import { mainStore } from '../stores/MainStore';
import Dialog from './Dialog';
import { PICKUP_ALCOHOL_COUPON_CODE } from '../stores/constants';

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleDismiss = () => mainStore.setIsInvalidPromocodePopover(false);
  const handleChange = () => {
    handleDismiss();
    catalogStore.resetPromocode();
    if (mainStore.convertPoundsToPence(catalogStore.finalPrice) < 50)
      mainStore.setIsZeroCartPopover(true);
    else history.push('/checkout');
  };

  const isZeroAmount = !catalogStore.totalCartPrice.amountLeftToDiscount || catalogStore.totalCartPrice.amountLeftToDiscount === "0"

  const popoverComponent = (
    <>
      {mainStore.isDesktop && <div className="h-24" />}
      <div className="fs-21 fw-500">{t('cartPage:promocodeNotFoundTitle')}</div>
      <div className="fs-14 lh-20 mt-20">
        {!isZeroAmount && !catalogStore.isPromoCodeApplied ? t(catalogStore.promocode.coupon?.code === PICKUP_ALCOHOL_COUPON_CODE ? 'errors:promocodePriceLessNoAlcohol' : 'errors:promocodePriceLess', {
          amountLeft: catalogStore.totalCartPrice.amountLeftToDiscount,
          discount: catalogStore.formatPromocodeDiscountAmount,
        })  : t('cartPage:promocodeNotFoundText')}
      </div>
      <div className="d-flex align-items-center justify-content-end mt-24">
        <div
          className="h-40 mr-20 px-10 lh-40 c-blue fs-14 fw-500 cursor-pointer"
          onClick={handleDismiss}
        >
          {t('cartPage:changePromocode')}
        </div>
        <div className="button _primary h-40 fs-14" onClick={handleChange}>
          {t('checkout')}
        </div>
      </div>
    </>
  );

  if (!mainStore.isDesktop) {
    return (
      <Popover isShow={mainStore.isInvalidPromocodePopover} onBackdropDismiss={handleDismiss}>
        {popoverComponent}
      </Popover>
    );
  } else {
    return (
      <Dialog show={mainStore.isInvalidPromocodePopover} onBackdrop={handleDismiss}>
        {popoverComponent}
      </Dialog>
    );
  }
});
