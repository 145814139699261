import { CompanyConfig } from '../interface';

export const BaqalConfig: CompanyConfig = {
  id: '0e715914-1b33-4387-92db-fe3103f865bb',
  lang: 'ar-KW',
  name: 'Baqal',
  officialName: 'IFA FOOD COMPANY KSCC',
  email: 'hello@baqal.com',
  phonePlaceholder: '+965 6222 2892',
  defaultPhoneCode: '+965',
  ageRestricted: 21,
  timeZone: 'Asia/Kuwait',
  minimalOrderFeeThreshold: 700,
  warehouse: {
    default: 'LTEDD',
    minimalOrderFeeWarehouseCodes: [],
  },
  map: {
    center: { lat: 29.2889669, lng: 47.9957713 },
    zoom: 12,
  },
  links: {
    terms: 'https://www.baqal.com/term-cond.html',
    privacy: 'https://baqal.com/privacypolicy.html',
    cookie: '',
    deliveryZones: '',
    forRiders: '',
    whatsapp: 'https://wa.me/96562222892',
  },
  referral: {
    code: 'BQLY23',
    discount: '2',
    minBasket: '7',
    downloadAppLink: 'https://click.jiffy.shop/PZKk/f11c621',
  },
  appStoreUrl: {
    ios: 'itms-apps://apps.apple.com/id/app/jiffy-fresh-grocery-delivery/id1556301576?l=en',
    android: 'market://details?id=com.jiffygrocery.jiffy',
  },
  apis: {},
};
