import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Popover from './Popover';
import { mainStore } from '../stores/MainStore';
import Dialog from './Dialog';
import { desktopStore } from '../stores/DesktopStore';

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = useLocation();
  const handleDismiss = () => {
    mainStore.setIsNeedChangeAddressPopover(false);
    if (!mainStore.isDesktop) {
      history.replace('/delivery-address', { source: pathname.slice(1) });
    } else {
      desktopStore.setShowMap(true);
    }
  };

  if (mainStore.isDesktop) {
    return (
      <Dialog show={mainStore.isShowNeedChangeAddressPopover} rejectable={false}>
        <div className="h-24"/>
        <div className="d-flex align-items-center">
          <div className="icon icon-attention s-20 fs-20 d-flex flex-center c-red mr-12" />
          <div className="fs-21 fw-500">{t('attention')}</div>
        </div>
        <div className="fs-14 lh-20 mt-20">{t('needChangeAddressPopover:announce')}</div>
        <div className="button _primary w-100p mt-24" onClick={handleDismiss}>
          {t('changeAddress')}
        </div>
      </Dialog>
    );
  } else {
    return (
      <Popover isShow={mainStore.isShowNeedChangeAddressPopover}>
        <div className="d-flex align-items-center">
          <div className="icon icon-attention s-20 fs-20 d-flex flex-center c-red mr-12" />
          <div className="fs-21 fw-500">{t('attention')}</div>
        </div>
        <div className="fs-14 lh-20 mt-20">{t('needChangeAddressPopover:announce')}</div>
        <div className="button _primary w-100p mt-24" onClick={handleDismiss}>
          {t('changeAddress')}
        </div>
      </Popover>
    );
  }
});
