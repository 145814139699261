import { ProductBadge } from '../ProductBadge/ProductBadge';
import React, { useEffect, useState } from 'react';
import { BadgesState, ProductItemBadgesListProps } from './interface';
import { useTranslation } from 'react-i18next';
import { ProductBadgeType } from './constants';
import { observer } from 'mobx-react-lite';
import { PIZZA_COMMODITY_GROUP } from '../constants';

/**
 * 1. Bundles should always as second badge, if it is presented
 *
 * 2. Normal case:
 *  - if discountPercentage presented - should be shown as a first badge
 *  - if discountPercentage was not presented,
 *    but promoRequiredQuantity is presented: should be shown as a first badge
 *  - if discountPercentage and promoRequiredQuantity were not presented,
 *    but own_trademark: should be shown as a first badge
 *
 * 3. Baked case - when baked badge presented:
 *  - baked badge - should be shown as first
 *  - if bundle is presented: should be shown as second badge
 *  - if bundle is not presented: should show badge from normal logic
 *
 * 4. Limited stock case - then same as for Baked case, baked has priority
 * */

const ProductItemBadgesList = (props: ProductItemBadgesListProps) => {
  const { offer } = props;
  const { t } = useTranslation();
  const [badgesState, setBadgesState] = useState<BadgesState>({});
  const { own_bakery, limited_stock, own_trademark } = offer.properties;
  const isBakery = offer.commodityGroup === PIZZA_COMMODITY_GROUP || own_bakery;

  const getOtherBadge = (): Nullable<ProductBadgeType> => {
    if (offer.discountPercentage?.length > 0) {
      return ProductBadgeType.DiscountPercent;
    }

    if (offer.promoRequiredQuantity) {
      return ProductBadgeType.DiscountQuantity;
    }

    if (own_trademark) {
      return ProductBadgeType.Trademark;
    }

    return null;
  };

  const getTopBadge = (): Nullable<ProductBadgeType> => {
    if (isBakery) {
      return ProductBadgeType.Bakery;
    }

    if (limited_stock) {
      return ProductBadgeType.LimitedStock;
    }

    const otherBadge = getOtherBadge();

    if (otherBadge) return otherBadge;

    if (offer.bundle) {
      return ProductBadgeType.Bundle;
    }

    return null;
  };

  const getBottomBadge = (): Nullable<ProductBadgeType> => {
    if (offer.bundle && getTopBadge() !== ProductBadgeType.Bundle) {
      return ProductBadgeType.Bundle;
    }

    if (isBakery || limited_stock) {
      return getOtherBadge();
    }

    return null;
  };

  useEffect(() => {
    if (offer) {
      setBadgesState({
        topBadge: getTopBadge() ?? undefined,
        bottomBadge: getBottomBadge() ?? undefined,
      });
    }
    // eslint-disable-next-line
  }, [offer]);

  const { topBadge, bottomBadge } = badgesState;
  const anyOtherBadge = [topBadge, bottomBadge];

  return (
    <>
      {anyOtherBadge.includes(ProductBadgeType.Bundle) && (
        <ProductBadge
          type="bundle"
          className={bottomBadge === ProductBadgeType.Bundle ? 'product-item__bottom-badge' : ''}
        >
          {t('productProperties:bundle')}
        </ProductBadge>
      )}

      {anyOtherBadge.includes(ProductBadgeType.DiscountPercent) && (
        <ProductBadge
          type="discount"
          className={
            bottomBadge === ProductBadgeType.DiscountPercent ? 'product-item__bottom-badge' : ''
          }
        >
          {offer.discountPercentage}
        </ProductBadge>
      )}

      {anyOtherBadge.includes(ProductBadgeType.DiscountQuantity) && (
        <ProductBadge
          type="discount"
          className={
            bottomBadge === ProductBadgeType.DiscountQuantity ? 'product-item__bottom-badge' : ''
          }
        >
          {offer.promoRequiredQuantity}+
        </ProductBadge>
      )}

      {anyOtherBadge.includes(ProductBadgeType.LimitedStock) && (
        <ProductBadge
          type="limited-stock"
        >
          <span dangerouslySetInnerHTML={{ __html: t('productProperties:limitedStock') }} />
        </ProductBadge>
      )}

      {anyOtherBadge.includes(ProductBadgeType.Trademark) && (
        <ProductBadge
          type="trademark"
          className={bottomBadge === ProductBadgeType.Trademark ? 'product-item__bottom-badge' : ''}
        />
      )}

      {topBadge === ProductBadgeType.Bakery && (
        <ProductBadge type="bakery">
          <span dangerouslySetInnerHTML={{ __html: t('productProperties:bakedInStore') }} />
        </ProductBadge>
      )}
    </>
  );
};

export default observer(ProductItemBadgesList);
