import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { GOOGLE_API_KEY } from '../config';
import GoogleMapReact from 'google-map-react';
import MapPoint from '../assets/img/map_point.svg';
import { company } from '../company/Company';

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<{ location?: GeoCoordinates }>();

  useEffect(() => {
    if (!state?.location) {
      history.length === 1 ? history.push('/') : history.goBack();
    }
  }, [state?.location, history]);

  return (
    <>
      <div className="flex-shrink-0 h-50 d-flex align-items-center px-7">
        <div
          className="icon icon-arrow-back d-flex flex-center s-50 fs-20 c-black"
          onClick={() => (history.length === 1 ? history.push('/') : history.goBack())}
        />
        <h3 className="w-100p text-center lh-20 pr-50">
          {t('statusWidget:readyForPickup.name')}
        </h3>
      </div>
      {state?.location !== undefined && (
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{
            key: GOOGLE_API_KEY,
            language: 'en-GB',
            region: 'GB',
          }}
          defaultCenter={state.location}
          defaultZoom={company.config.map.zoom}
          options={{
            disableDefaultUI: true,
            gestureHandling: 'greedy',
          }}
          shouldUnregisterMapOnUnmount={true}
        >
          <MapPin lat={state.location.lat} lng={state.location.lng} />
        </GoogleMapReact>
      )}
    </>
  );
});

const MapPin = observer(({ lat, lng }: GeoCoordinates) =>
  <img className="delivery-address__marker" src={MapPoint} alt="" />,
);
