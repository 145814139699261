import { observer } from 'mobx-react-lite';
import htmlClasses from 'html-classes';
import { ProductBadge } from '../../../../components/Badges/ProductBadge/ProductBadge';
import { mainStore } from '../../../../stores/MainStore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveGiftProps } from '../interface';

const ActiveGift = observer((props: ActiveGiftProps) => {
  const { onPopoverChooseGiftOpen, activeGift, cartPriceForGift } = props;
  const { t } = useTranslation();

  if (!activeGift) {
    return null;
  }

  const { preview_image, preview_image_thumb, name = '', price } = activeGift;

  return (
    <div
      className={htmlClasses('d-flex py-12 align-items-center overflow-hidden', {
        'pe-n o-2': cartPriceForGift,
      })}
      onClick={onPopoverChooseGiftOpen}
    >
      <div
        className="w-44 h-36 flex-shrink-0 img-contain position-relative"
        style={{
          backgroundImage:
            preview_image_thumb || preview_image
              ? `url(${preview_image_thumb || preview_image})`
              : 'none',
        }}
      />
      <div className="px-8 w-100p">
        <div className="fs-12 lh-16 c-udgray">{name}</div>
        <div className="d-flex align-items-center mt-4">
          <ProductBadge type="discount" className="position-relative flex-shrink-0 l-0 t-0">
            {t('free')}
          </ProductBadge>
          {price !== undefined && (
            <s className="fs-12 lh-16 fw-500 c-mgray">
              {mainStore.addCurrencySymbol(mainStore.addZeroFraction(price || ''))}
            </s>
          )}
        </div>
      </div>
      {cartPriceForGift.length === 0 && (
        <div className="icon icon-chevron-right s-28 fs-14 d-flex flex-center" />
      )}
    </div>
  );
});

export default ActiveGift;
