export const ENDPOINT = {
  customer: {
    code: {
      request: 'customer/sms/send',
      verify: 'customer/sms/verify',
    },
    logout: 'customer/auth/logout',
    personalData: {
      request: 'customer/',
      update: 'customer/',
      delete: 'customer/',
    },
    deviceData: {
      create: 'customer/auth/device/create',
      update: 'customer/auth/device/update/',
    },
    subscription: {
      update: 'customer/auth/subscription/update/',
    },
    token: {
      refresh: 'customer/auth/token-refresh',
    },
    address: {
      request: 'customer/address',
      add: 'customer/address',
      update: 'customer/address/',
      delete: 'customer/address/',
    },
  },
  bonuses: {
    balance: 'payment/v1/bonuses/balance',
    history: 'payment/v1/bonuses/history',
  },
  orders: {
    calculate: 'orders/v1/cart/calculate',
    new: 'orders/v1/orders/new',
    boughtItems: 'orders/v1/orders/bought-items',
    paid: 'orders/v1/orders/paid/',
    cancelByUser: 'orders/v1/orders/cancel-by-user',
    status: 'orders/v1/orders',
    getCurrency: 'orders/v1/settings/currency',
    paymentMethods: {
      list: 'payment/v1/payments/methods',
      add: 'payment/v1/payments/methods/add',
      remove: 'payment/v1/payments/methods/remove',
      setupIntent: 'payment/v1/payments/methods/setup-intent',
      setDefault: 'payment/v1/payments/methods/set-default',
    },
    feedback: {
      create: 'orders/v1/feedback/create',
      fill: 'orders/v1/feedback/fill/',
    }
  },
  promocodes: {
    get: 'promocodes-service/v1/promocodes/code/',
  },
  gifts: {
    getMechanisms: 'orders/v1/gift/gift-mechanisms?warehouse_code=',
  },
  tips: {
    pay: 'delivery/tips/',
    check: 'delivery/tips?order_id=',
  },
  payments: {
    prioritySystem: 'payment/v1/payments/priority-system',
    myfatoorahInitialize: 'payment/v1/payments/myfatoorah/initialize',
  },
  catalog: {
    category: {
      list: 'catalog/v1/categories/tree',
    },
    product: {
      listByCategory: 'catalog/v1/tree/categories/',
      listByBanner: 'catalog/v1/banner/products',
      request: 'catalog/v1/product/',
      bestsellers: 'catalog/v1/bestsellers',
      recommendations: 'catalog/v1/bi/recommendations?warehouse_code=',
    },
    banner: {
      list: 'catalog/v1/banner',
    },
    search: {
      request: 'catalog/v1/search',
      history: {
        list: 'catalog/v1/search-history',
        delete: 'catalog/v1/search-history/delete',
      },
    },
  },
  deliveryAddress: {
    autocomplete: 'geocoder/v1/api/autocomplete',
    zones: {
      request: 'eta/v1/api/zones',
    },
  },
  stories: {
    list: 'stories/v1/stories/byWarehouseCode/',
  },
  eta: {
    get: 'eta/v1/api/eta',
    getDeliveryCost: 'orders/v1/surge/delivery-cost',
  },
  favorites: {
    get: 'orders/v1/favorites/get',
    add: 'orders/v1/favorites/add',
    remove: 'orders/v1/favorites/remove/',
  }
};
