import { observer } from 'mobx-react-lite';
import { mainStore } from '../../../stores/MainStore';
import { catalogStore } from '../../../stores/CatalogStore';
import React from 'react';
import CategoryPurchasedBg from '../../../assets/img/category_purchased.png';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { CategoryItem } from './CategoryItem/CategoryItem';
import { useTranslation } from 'react-i18next';
import { SPECIAL_CATEGORY_NAME } from './CategoryItem/constants';
import { company } from '../../../company/Company';

const CategoryList = observer(() => {
  const { t } = useTranslation();

  const sendAnalytic = (catId: number, source: string) => {
    mainStore.sendToRN('analytics', {
      name: 'Catalog: category clicked',
      params: {
        category: catId,
        source: source,
      },
    });
  };
  const handleCustomCategoryClick = () => {
    if (mainStore.isDesktop) {
      mainStore.sendToRN('analytics', {
        name: 'Home Page: Purchased items open',
        params: {},
      });

      return;
    }

    mainStore.sendToRN('logAmplitudeEvent', {
      name: 'Home Page: Purchased items open',
    });
  };

  if (catalogStore.categoryList === null) {
    return (
      <>
        {[1, 2].map((item, i) => (
          <SkeletonTheme color="#EBECED" highlightColor="#F7F7F7" key={i}>
            <Skeleton className="h-20 mx-16 w-150 mt-24 br-30" />
            <div className="d-flex align-items-start flex-wrap px-11 mt-6">
              {[1, 2, 3, 4, 5, 6].map((subitem, j) => (
                <div className="category-list__col" key={j}>
                  <Skeleton className="pt-100p br-8" />
                </div>
              ))}
            </div>
          </SkeletonTheme>
        ))}
      </>
    );
  }

  return (
    <>
      {catalogStore.categoryList.map((item) => (
        <React.Fragment key={item.id}>
          <div className="category-title fw-500 mx-16 mt-32">{item.name}</div>

          <div className="category-list d-flex flex-wrap px-11">
            {item.subcategory.map((subitem, i) => {
              return (
                <React.Fragment key={subitem?.id}>
                  <CategoryItem
                    color={subitem.fontColor}
                    tileSize={subitem?.tileSize}
                    id={subitem.id}
                    name={subitem.name}
                    previewImagePath={subitem.previewImage}
                    backgroundColor={subitem.backgroundColor}
                    onClick={sendAnalytic}
                    target={`/category/${subitem.id}`}
                  />

                  {item.name === SPECIAL_CATEGORY_NAME && i === 0 && (
                    <CategoryItem
                      color={subitem.fontColor}
                      tileSize={1}
                      id={-1}
                      name={t(company.isHideFavourites ? 'purchasedItems' : 'faves')}
                      previewImagePath={CategoryPurchasedBg}
                      backgroundColor={subitem.backgroundColor}
                      onClick={handleCustomCategoryClick}
                      target={{
                        pathname: !mainStore.isDesktop ? '/favorites' : '/',
                        state: { activeTabNum: 1 },
                        search: !mainStore.isDesktop ? '' : '?modal=favorites',
                      }}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </React.Fragment>
      ))}
    </>
  );
});

export default CategoryList;
