import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { catalogStore } from '../../../stores/CatalogStore';
import { orderStore } from '../../../stores/OrderStore';
import { BESTSELLERS_ITEMS_LENGTH } from '../constants';
import ProductItem from '../../../components/ProductItem';
import { Link } from 'react-router-dom';

export const Bestsellers = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    catalogStore
      .requestBestsellers(true)
      .catch((error) => error && console.error(error));
    //eslint-disable-next-line
  }, [orderStore.etaWarehouseCode]);

  if (!catalogStore.bestsellersProducts.length) return <></>;

  return (
    <>
      <div className='category-title mx-16 mb-16 mt-16'>{t('bestsellers')}</div>
      <div className='scroll-list _16 hide-scroll-bar pb-8 mb-n8'>
        {catalogStore.bestsellersProducts.slice(0, BESTSELLERS_ITEMS_LENGTH).map((item) => (
          <ProductItem product={item} source='bestsellers_slider' isWithinSlider key={item.id} />
        ))}
        {catalogStore.bestsellersProducts.length > BESTSELLERS_ITEMS_LENGTH && (
          <Link className='product-item border-0 c-bg-none flex-center' to='/bestsellers'>
            <div className='text-center'>
              <div className='icon icon-arrow-long s-60 d-flex flex-center rounded-circle fs-24 c-bg-dgreen c-white' />
              <div className='mt-8 lh-20 c-black'>{t('seeAll')}</div>
            </div>
          </Link>
        )}
      </div>
    </>
  );
});
