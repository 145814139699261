import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';

type CheckboxProps = {
  checked?: boolean;
  className?: string;
};

export default observer(({ checked, className }: CheckboxProps) => {
  return (
    <div className={htmlClasses('input-checkbox', className, { _active: checked })}>
      <div className="icon icon-check" />
    </div>
  );
});
