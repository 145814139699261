import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import { mainStore } from '../stores/MainStore';
import { userStore } from '../stores/UserStore';
import { SubscriptionFlag } from '../api/Customer';
import { useHistory } from 'react-router-dom';
import { desktopStore } from '../stores/DesktopStore';

interface PopoverSubscribeProps {
  source: 'orderTracking' | 'chat' | 'notifications';
  onSuccess?: () => void;
}

export default observer(({ source, onSuccess }: PopoverSubscribeProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const setTranslatePath = () => {
    switch (source) {
      case 'orderTracking':
        return 'popoverSubscribe:orderTracking';
      case 'chat':
        return 'popoverSubscribe:chat';
      case 'notifications':
        return 'popoverSubscribe:notifications';
    }
  };
  const getSource = () => {
    switch (source) {
      case 'orderTracking':
        return 'Order tracking page';
      case 'chat':
        return 'Chat support';
      case 'notifications':
        return 'Notification settings';
    }
  };
  const translatePath = setTranslatePath();
  const handleSubscribe = async () => {
    const flags: SubscriptionFlag[] = [
      {
        name: 'personalised',
        push: true,
        email: !!userStore.personalData.email,
      },
      {
        name: 'discounts',
        push: true,
        email: !!userStore.personalData.email,
      },
      {
        name: 'third_party',
        push: true,
        email: !!userStore.personalData.email,
      },
    ];
    const isRNSubscribeSuccess = await new Promise<boolean>((resolve) => {
      if (!window.ReactNativeWebView) {
        resolve(true);
        return;
      }
      mainStore.sendToRN(
        'subscribeToNotifications',
        {
          email: userStore.personalData.email?.trim() || '',
          isPush: true,
          isEmail: !!userStore.personalData.email,
        },
        () => resolve(true),
        () => resolve(false),
      );
    });
    try {
      if (isRNSubscribeSuccess) {
        mainStore.setIsBlockedPush(false);
        if (userStore.isAuthorized) {
          await userStore.updateSubscription(flags, getSource());
        } else {
          await userStore.updateDeviceData(
            userStore.fullName,
            userStore.personalData.email?.trim() || '',
            flags,
          );
          await userStore.updateSubscription(flags, getSource());
        }
        onSuccess && onSuccess();
        if (mainStore.isDesktop) {
          if (userStore.isAuthorized) {
            mainStore.pushAlert('success', t(`popoverSubscribe:success${source === 'notifications' ? 'NoLink' : ''}`), () => {
              if (source !== 'notifications') desktopStore.setShowNotifications(true);
            });
          }
        } else {
          mainStore.pushAlert('success', t(`popoverSubscribe:success${source === 'notifications' ? 'NoLink' : ''}`), () => {
            if (source !== 'notifications') history.push('/notifications');
          });
        }
      } else {
        mainStore.setIsBlockedPush(true);
      }
    } catch (error) {
      error && console.error(error);
    }
    mainStore.setIsShowSubscribePopover(false);
  };
  const handleLater = () => {
    mainStore.setIsShowSubscribePopover(false);
    if (source === 'notifications') return;
    if (mainStore.isDesktop) {
      if (userStore.isAuthorized) {
        mainStore.pushAlert('warning', t('deliveryAddressPage:addressFound.skipSubscribe'), () => {
          desktopStore.setShowNotifications(true);
        });
      }
    } else {
      mainStore.pushAlert('warning', t('deliveryAddressPage:addressFound.skipSubscribe'), () => {
        history.push('/notifications');
      });
    }
  };

  return (
    <Popover isShow={mainStore.isShowSubscribePopover}>
      <h2>{t(`${translatePath}.header`)}</h2>
      <div className="fs-14 lh-20 mt-20  c-mgray">{t(`${translatePath}.desc`)}</div>
      <div className="d-flex align-items-center justify-content-end mt-20">
        <div
          className="h-40 mr-18 px-10 lh-40 c-blue fs-14 fw-500"
          onClick={handleLater}
        >
          {t('later')}
        </div>
        <div className="button _primary w-150 h-40 fs-14" onClick={handleSubscribe}>
          {t(`${translatePath}.submit`)}
        </div>
      </div>
    </Popover>
  );
});
