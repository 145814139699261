import { observer } from 'mobx-react-lite';
import React, { useState, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import InputText from './InputText';
import htmlClasses from 'html-classes';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';
import { userStore } from '../stores/UserStore';
import { IMaskInput, AnyMasked } from 'react-imask';
import { checkoutStore } from '../stores/CheckoutStore';
import Dialog from './Dialog';

type RateStarsProps = {
  rate: number;
  onChange: (val: number) => void;
  className?: string;
};

export default observer(() => {
  const { t } = useTranslation();
  const [rate, setRate] = useState(-1);
  const [rateDelivery, setRateDelivery] = useState(-1);
  const [rateVariety, setRateVariety] = useState(-1);
  const [rateQuality, setRateQuality] = useState(-1);
  const [commentVal, setCommentVal] = useState('');
  const [feedbackId, setFeedbackId] = useState('');
  const [orderId, setOrderId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isShowTip, setIsShowTip] = useState(false);
  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setCommentVal(e.currentTarget.value);
  const onDismiss = () => {
    mainStore.setIsRateOrderPopover(false);
    orderStore.setLastRatedOrderID(orderStore.activeOrderID);
    if (rate > 0) {
      if (
        rate >= 4 &&
        !userStore.personalData.isRateApp &&
        mainStore.isSuitableAppVersion('1.0.12')
      ) {
        mainStore.sendToRN('showRatePopup', null, (e) => {
          if (e.answer === 'NotNow') return;
          userStore.setIsRateApp(true);
        });
      }
      setRate(-1);
      setRateDelivery(-1);
      setRateVariety(-1);
      setRateQuality(-1);
      setCommentVal('');
      setFeedbackId('');
      setOrderId('');
      orderStore.setOrderStatus('none');
      orderStore.setActiveOrderID('');
      if (!orderStore.tipAmount) {
        mainStore.pushAlert('success', t('ratePopover:thanksTitleFeedback'));
      }
    }
    orderStore.setTipAmount('');
  };
  const handleSubmit = () => {
    if (isLoading) return;
    if (rate === -1 && orderStore.tipAmount) {
      mainStore.setIsPayTipPopover(true);
      onDismiss();
      return;
    }
    setIsLoading(true);
    orderStore
      .fillFeedback(feedbackId, {
        rating: rate,
        delivery_rating: rateDelivery,
        product_variety_rating: rateVariety,
        product_quality_rating: rateQuality,
        comment: commentVal,
      })
      .then((success) => {
        if (!success) return;
        mainStore.sendToRN('analytics', {
          name: 'Rate: order detailed',
          params: {
            order_id: orderId,
            delivery: rateDelivery,
            product_variety: rateVariety,
            product_quality: rateQuality,
            comment_provided: !!commentVal.length,
          },
        });
        mainStore.sendAnalyticsToFirebase({
          name: 'rate_order_detailed',
          params: {
            order_id: orderId,
            delivery: rateDelivery,
            product_variety: rateVariety,
            product_quality: rateQuality,
            comment_provided: !!commentVal.length,
          },
        });
        if (orderStore.tipAmount) mainStore.setIsPayTipPopover(true);
        onDismiss();
      })
      .catch((error) => error && console.error(error))
      .finally(() => setIsLoading(false));
  };
  const handleGeneralRate = (val: number) => {
    if (!orderId || isLoading) return;
    mainStore.sendToRN('sendTags', {
      last_order_feedback_score: val,
    });
    mainStore.sendToRN('setUserProperties', {
      'Commerce: last order rating': val,
    });
    if (feedbackId) {
      setRate(val);
      return;
    }
    setIsLoading(true);
    orderStore
      .newFeedback(orderId, val)
      .then((feedbackId) => {
        if (!feedbackId) return;
        setRate(val);
        setFeedbackId(feedbackId);
        mainStore.sendToRN('analytics', {
          name: 'Rate: order general',
          params: {
            order_id: orderId,
            score: val,
          },
        });
        mainStore.sendAnalyticsToFirebase({
          name: 'rate_order_general',
          params: {
            order_id: orderId,
            score: val,
          },
        });
      })
      .catch((error) => error && console.error(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!orderStore.activeOrderID) return;
    setOrderId(orderStore.activeOrderID);
    //eslint-disable-next-line
  }, [orderStore.activeOrderID]);

  useEffect(() => {
    if (!mainStore.isRateOrderPopover) return;
    orderStore.checkPayedTip(orderId).then((e) => {
      setIsShowTip(!e);
    }).catch((error) => error && console.error(error));
    checkoutStore.requestPayments().catch((error) => error && console.error(error));
    //eslint-disable-next-line
  }, [mainStore.isRateOrderPopover]);

  const component = (
    <>
      <div className={htmlClasses('fs-21 lh-25', {'mt-70': mainStore.isDesktop})}>{t('ratePopover:title')}</div>
      <div className="fs-14 lh-20 mt-10">{t('ratePopover:announce')}</div>
      <div className="d-flex mt-20">
        <RateStars
          className={htmlClasses('s-40 fs-40 mx-7', { 'pe-n': isLoading })}
          rate={rate}
          onChange={handleGeneralRate}
        />
      </div>
      {rate !== -1 && (
        <>
          <div className=" fs-19 mt-35">{t('delivery')}</div>
          <div className="d-flex mt-14">
            <RateStars
              className={htmlClasses('s-40 fs-40 mx-7', { 'pe-n': isLoading })}
              rate={rateDelivery}
              onChange={(val) => setRateDelivery(val)}
            />
          </div>
          <div className=" fs-19 mt-24">{t('productVariety')}</div>
          <div className="d-flex mt-14">
            <RateStars
              className={htmlClasses('s-40 fs-40 mx-7', { 'pe-n': isLoading })}
              rate={rateVariety}
              onChange={(val) => setRateVariety(val)}
            />
          </div>
          <div className=" fs-19 mt-24">{t('productQuality')}</div>
          <div className="d-flex mt-14">
            <RateStars
              className={htmlClasses('s-40 fs-40 mx-7', { 'pe-n': isLoading })}
              rate={rateQuality}
              onChange={(val) => setRateQuality(val)}
            />
          </div>
          <InputText className="input-textarea-feedback mt-30" value={commentVal}>
            <textarea
              className="input-textarea"
              rows={3}
              placeholder={t('optionalComment')}
              onChange={handleCommentChange}
              value={commentVal}
              maxLength={250}
              disabled={isLoading}
            />
          </InputText>
        </>
      )}

      {/* TODO: JIF-2675: should be uncomment when backend will be able to provide truly information about earned bonuses in tips
        orderStore.activeOrderBonuses && (
        <div className="d-flex align-items-start py-12 px-13 mt-22 br-8 border-tf2 mb-24">
          <div className="fw-500 c-text lh-20 fs-14">{t('ratePopover:companyPointsFrom')}</div>
          <div className="ml-auto c-black fs-14 lh-20 fw-500 d-flex">
            <div className="icon icon-jiffy-point c-black s-19 fs-19 d-flex flex-center mr-7" />

            {orderStore.activeOrderBonuses}
          </div>
        </div>
      )
      */}

      {isShowTip && <ChooseTipAmount />}
      <div className="d-flex align-items-center justify-content-end mx-n4 mt-30">
        <button
          className="button _secondary w-100p mx-4 flex-shrink-1"
          onClick={onDismiss}
          disabled={isLoading}
        >
          {isLoading ? <span className="spinner" /> : t('skip')}
        </button>
        {(rate !== -1 || orderStore.tipAmount.length > 0) && (
          <button
            className="button _primary w-100p mx-4 flex-shrink-1"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <span className="spinner" />
            ) : (
              t(orderStore.tipAmount ? 'continue' : 'submit')
            )}
          </button>
        )}
      </div>
    </>
  );

  if (mainStore.isDesktop) {
    return (
      <Dialog
        show={mainStore.isRateOrderPopover}
        onBackdrop={rate === -1 ? onDismiss : undefined}
        maxWidth="360px"
        minWidth="360px"
      >
        {component}
      </Dialog>
    );
  } else {
    return (
      <Popover
        isShow={mainStore.isRateOrderPopover}
        onBackdropDismiss={rate === -1 ? onDismiss : undefined}
      >
        {component}
      </Popover>
    );
  }
});

const RateStars = observer(({ rate, onChange, className }: RateStarsProps) => {
  return (
    <>
      {[1, 2, 3, 4, 5].map((item, i) => (
        <div
          className={htmlClasses(
            'icon icon-star d-flex flex-center rate-star cursor-pointer',
            className,
            { _active: i < rate },
          )}
          key={i}
          onClick={() => onChange(item)}
        />
      ))}
    </>
  );
});

const ChooseTipAmount = observer(() => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [customTip, setCustomTip] = useState('');
  const values = ['1', '2', '3', '4'];
  const handleSetTip = (val: string) => {
    orderStore.setTipAmount(orderStore.tipAmount === val ? '' : val);
  };
  const handleChangeCustomTip = (value: string, mask: AnyMasked) => {
    let val = value.slice(1);
    if (val) val = mainStore.toFloat(val).toString();
    setCustomTip(val);
    orderStore.setTipAmount(val);
    if (!val) mask.value = '';
  };
  const handleBlurCustomTip = (e: ChangeEvent<HTMLInputElement>) => {
    let val = e.currentTarget.value.slice(1);
    if (val) val = mainStore.toFloat(val).toString();
    setCustomTip(val);
    orderStore.setTipAmount(val);
  };
  const handleFocusCustomTip = () => {
    if (customTip) orderStore.setTipAmount(customTip);
  };

  useEffect(() => {
    setCustomTip('');
    //eslint-disable-next-line
  }, [mainStore.isRateOrderPopover]);

  useEffect(() => {
    if (!orderStore.tipAmount) {
      setActiveIndex(-1);
      return;
    }
    if (customTip && orderStore.tipAmount === customTip) {
      setActiveIndex(values.length);
      return;
    }
    for (let i = 0; i < values.length; i++) {
      if (orderStore.tipAmount === values[i]) {
        setActiveIndex(i);
        break;
      }
    }
    //eslint-disable-next-line
  }, [orderStore.tipAmount]);

  return (
    <>
      <div className="fs-21 mt-30">{t('ratePopover:addTip')}</div>
      <div className="d-flex pt-8 mt-8">
        {values.map((value, i) => (
          <div
            className={htmlClasses('checkout-instruction flex-shrink-0', {
              _active: i === activeIndex,
            })}
            onClick={() => handleSetTip(value)}
            key={i}
          >
            <div className="icon icon-check-box checkout-instruction__check s-18 d-flex flex-center flex-shrink-0 rounded-circle c-white fs-16" />
            <div>{mainStore.addCurrencySymbol(value)}</div>
          </div>
        ))}
        <div
          className={htmlClasses('checkout-instruction flex-shrink-0 p-0', {
            _active: activeIndex === values.length,
          })}
        >
          <div className="icon icon-check-box checkout-instruction__check s-18 d-flex flex-center flex-shrink-0 rounded-circle c-white fs-16" />
          <IMaskInput
            className="input-text w-85 text-center px-14 h-40"
            mask="`$#[.00]"
            definitions={{ '#': /[1-9]/ }}
            value={customTip}
            type="tel"
            placeholder={t('custom')}
            onAccept={handleChangeCustomTip}
            onBlur={handleBlurCustomTip}
            onFocus={handleFocusCustomTip}
          />
        </div>
      </div>
    </>
  );
});
