import i18n from 'i18next';
import { makeAutoObservable, runInAction } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';
import { userStore, DeliveryAddress } from './UserStore';
import { Category } from '../types/Category/interface';
import { catalogStore } from './CatalogStore';
import { orderStore } from './OrderStore';
import { IconProps } from '../components/ui/Icon/Icon';
import { CompanyName } from '../company/interface';
import { company } from '../company/Company';

interface MenuItem {
  title: string;
  icon: { name: string; isSvg?: false } | { name: IconProps['type']; isSvg: true };
  onClick: () => void;
  isHide?: boolean;
}

interface ISavedDeliveryState {
  isNewAddress: boolean;
  isShowBackBtn: boolean;
  editAddress: DeliveryAddress;
  source: string;
}

export const urlModalQueryName = [
  'login',
  'profile',
  'paymentMethods',
  'orders',
  'favorites',
  'notifications',
  'about',
  'product',
  'inject',
  'order',
  'tracking',
  'points',
  'map',
] as const;

class DesktopStore {
  desktopDeviceId: string | null = null;
  showMenu = false;
  showPointsPopover = false;
  showLogin = false;
  showAbout = false;
  showProfile = false;
  showBonuses = false;
  showFavorites = false;
  showPaymentMethods = false;
  showOrders = false;
  showOrder = false;
  showNotifications = false;
  showInject = false;
  showMap = false;
  showTracking = false;
  showProduct = false;
  selectedId = '';
  showChat = false;
  showChangeAddressDialog = false;
  showBanner = false;
  showQR = false;
  menuItems: MenuItem[] = [];
  searchBarValue = '';
  searchQuery: URLSearchParams = new URLSearchParams(window.location.search);
  lastSavedDiscountsState: { subCategories: Category[] | null; categoryName: string } | null =
    null;
  lastSavedDeliveryAddressState: ISavedDeliveryState | null = null;
  urlModalQueryMap: Record<typeof urlModalQueryName[number], () => void> = {
    login: (flag = true) => this.setShowLogin(flag),
    profile: (flag = true) => this.setShowProfile(flag),
    paymentMethods: (flag = true) => this.setShowPaymentMethods(flag),
    orders: (flag = true) => this.setShowOrders(flag),
    favorites: (flag = true) => this.setShowFavorites(flag),
    notifications: (flag = true) => this.setShowNotifications(flag),
    about: (flag = true) => this.setShowAbout(flag),
    product: (flag = true) => this.setShowProduct(flag),
    inject: (flag = true) => this.setShowInject(flag),
    order: (flag = true) => this.setShowOrder(flag),
    tracking: (flag = true) => this.setShowTracking(flag),
    points: (flag = true) => this.setShowBonuses(flag),
    map: (flag = true) => this.setShowMap(flag),
  };
  selectedCategory = '';
  selectedSubCat = '';
  subCat2Id = '334';
  subCategories: Category[] = [];
  savedSource = '';

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: 'DesktopStore',
      properties: ['showBanner', 'desktopDeviceId'],
      storage: window.localStorage,
    }).catch(() => void 0);
    i18n.on('initialized', () => {
      runInAction(() => {
        const menuItems: Record<string, MenuItem[]> = {
          [CompanyName.Swifft]: [
            {
              isHide: company.isDisableLoyaltyProgram,
              title: i18n.t('companyPoints', { companyName: company.config.name }),
              icon: { name: company.config.points?.icon || 'coins', isSvg: true },
              onClick: () => userStore.isAuthorized ? this.setShowBonuses(
                true) : this.setShowBonuses(true),
            },
            {
              title: i18n.t('myProfile'),
              icon: { name: 'profile-swifft', isSvg: true },
              onClick: () =>
                userStore.isAuthorized ? this.setShowProfile(true) : this.setShowLogin(true),
            },
            {
              title: i18n.t('paymentMethods'),
              icon: { name: 'payment-card' },
              onClick: () =>
                userStore.isAuthorized ? this.setShowPaymentMethods(true) : this.setShowLogin(true),
            },
            {
              title: i18n.t('myOrder_other'),
              icon: { name: 'bag-swifft', isSvg: true },
              onClick: () =>
                userStore.isAuthorized ? this.setShowOrders(true) : this.setShowLogin(true),
            },
            {
              title: i18n.t(company.isHideFavourites ? 'purchasedItems' : 'faves'),
              icon: { name: 'favorite-swifft', isSvg: true },
              onClick: () => this.setShowFavorites(true),
            },
            {
              title: i18n.t('notificationsSettings'),
              icon: { name: 'bell-swifft', isSvg: true },
              onClick: () =>
                userStore.isAuthorized ? this.setShowNotifications(true) : this.setShowLogin(true),
            },
            {
              title: i18n.t('about'),
              icon: { name: 'info' },
              onClick: () => this.setShowAbout(true),
            },
          ],
          [CompanyName.Baqal]: [
            {
              isHide: company.isDisableLoyaltyProgram,
              title: i18n.t('companyPoints', { companyName: company.config.name }),
              icon: { name: company.config.points?.icon || 'coins', isSvg: true },
              onClick: () => userStore.isAuthorized ? this.setShowBonuses(
                true) : this.setShowBonuses(true),
            },
            {
              title: i18n.t('myProfile'),
              icon: { name: 'profile', isSvg: true },
              onClick: () =>
                userStore.isAuthorized ? this.setShowProfile(true) : this.setShowLogin(true),
            },
            {
              title: i18n.t('paymentMethods'),
              icon: { name: 'payment-card' },
              onClick: () =>
                userStore.isAuthorized ? this.setShowPaymentMethods(true) : this.setShowLogin(true),
            },
            {
              title: i18n.t('myOrder_other'),
              icon: { name: 'bag-swifft', isSvg: true },
              onClick: () =>
                userStore.isAuthorized ? this.setShowOrders(true) : this.setShowLogin(true),
            },
            {
              title: i18n.t(company.isHideFavourites ? 'purchasedItems' : 'faves'),
              icon: { name: 'favorite-swifft', isSvg: true },
              onClick: () => this.setShowFavorites(true),
            },
            {
              title: i18n.t('notificationsSettings'),
              icon: { name: 'bell-swifft', isSvg: true },
              onClick: () =>
                userStore.isAuthorized ? this.setShowNotifications(true) : this.setShowLogin(true),
            },
            {
              title: i18n.t('about'),
              icon: { name: 'info' },
              onClick: () => this.setShowAbout(true),
            },
          ],
          [CompanyName.Jiffy]: [
            {
              isHide: company.isDisableLoyaltyProgram,
              title: i18n.t('companyPoints', { companyName: company.config.name }),
              icon: { name: 'jiffy-point' },
              onClick: () => userStore.isAuthorized ? this.setShowBonuses(
                true) : this.setShowBonuses(true),
            },
            {
              title: i18n.t('myProfile'),
              icon: { name: 'profile', isSvg: true },
              onClick: () =>
                userStore.isAuthorized ? this.setShowProfile(true) : this.setShowLogin(true),
            },
            {
              title: i18n.t('paymentMethods'),
              icon: { name: 'payment-card' },
              onClick: () =>
                userStore.isAuthorized ? this.setShowPaymentMethods(true) : this.setShowLogin(true),
            },
            {
              title: i18n.t(company.isHideFavourites ? 'purchasedItems' : 'faves'),
              icon: { name: 'favorite' },
              onClick: () => this.setShowFavorites(true),
            },
            {
              title: i18n.t('myOrder_other'),
              icon: { name: 'bag', isSvg: true },
              onClick: () =>
                userStore.isAuthorized ? this.setShowOrders(true) : this.setShowLogin(true),
            },
            {
              title: i18n.t('notificationsSettings'),
              icon: { name: 'bell', isSvg: true },
              onClick: () =>
                userStore.isAuthorized ? this.setShowNotifications(true) : this.setShowLogin(true),
            },
            {
              title: i18n.t('about'),
              icon: { name: 'info' },
              onClick: () => this.setShowAbout(true),
            },
          ],
        };
        this.menuItems = menuItems[company.name] as MenuItem[];
      });
    });
  }

  // Actions
  closeAllModals() {
    this.setShowLogin(false);
    this.setShowPointsPopover(false);
    this.setShowProfile(false);
    this.setShowPaymentMethods(false);
    this.setShowOrders(false);
    this.setShowFavorites(false);
    this.setShowNotifications(false);
    this.setShowAbout(false);
    this.setShowProduct(false);
    this.setShowInject(false);
    this.setShowOrder(false);
    this.setShowTracking(false);
  }

  get isSynchronized(): boolean {
    return isHydrated(this);
  }

  // Setters
  setSelectedCategory(category: string) {
    this.selectedCategory = category;
  }

  setShowPointsPopover(flag: boolean) {
    this.showPointsPopover = flag;
  }

  setDesktopDeviceId(s: string) {
    this.desktopDeviceId = s;
  }

  setSelectedSubCat(subCat: string) {
    this.selectedSubCat = subCat;
  }

  setLastSavedDiscountsState(
    state: { subCategories: Category[] | null; categoryName: string } | null,
  ) {
    this.lastSavedDiscountsState = state;
  }

  setLastSavedDeliveryAddressState(state: ISavedDeliveryState | null) {
    this.lastSavedDeliveryAddressState = state;
  }

  setShowBanner(flag: boolean) {
    this.showBanner = flag;
  }

  setShowMenu(flag: boolean) {
    this.showMenu = flag;
  }

  setShowProfile(flag: boolean) {
    this.showProfile = flag;
    this.setShowMenu(false);
  }

  setShowBonuses(flag: boolean) {
    this.showBonuses = flag;
    this.setShowMenu(false);
  }

  setShowPaymentMethods(flag: boolean) {
    this.showPaymentMethods = flag;
    this.setShowMenu(false);
  }

  setShowAbout(flag: boolean) {
    this.showAbout = flag;
    this.setShowMenu(false);
  }

  setShowFavorites(flag: boolean) {
    this.showFavorites = flag;
    this.setShowMenu(false);
  }

  setShowOrders(flag: boolean) {
    this.showOrders = flag;
    this.setShowMenu(false);
  }

  setShowOrder(flag: boolean) {
    this.showOrder = flag;
  }

  setShowNotifications(flag: boolean) {
    this.showNotifications = flag;
    this.setShowMenu(false);
  }

  setShowLogin(flag: boolean) {
    if (flag && !orderStore.etaCalculation) {
      this.showMap = flag;
      return;
    }
    this.showLogin = flag;
  }

  setShowMap(flag: boolean) {
    this.showMap = flag;
  }

  setShowTracking(flag: boolean) {
    this.showTracking = flag;
  }

  setShowProduct(flag: boolean) {
    this.showProduct = flag;
  }

  setShowInject(flag: boolean) {
    this.showInject = flag;
  }

  setSelectedId(id: string) {
    this.selectedId = id;
  }

  setSearchQuery(searchQuery: URLSearchParams) {
    this.searchQuery = searchQuery;
  }

  setShowChat(flag: boolean) {
    this.showChat = flag;
  }

  setSearchBarValue(s: string) {
    this.searchBarValue = s;
  }

  setShowQR(flag: boolean) {
    this.showQR = flag;
  }

  setSubCategories(data: Category[]) {
    this.subCategories = data;
  }

  setShowChangeAddressDialog(flag: boolean) {
    this.showChangeAddressDialog = flag;
  }

  setSavedSource(source: string) {
    this.savedSource = source;
  }

  requestCatalog(isDeeplink?: boolean) {
    catalogStore
      .requestProducts(this.subCat2Id, isDeeplink || false)
      .then((e) => {
        if (!e) {
          this.setSubCategories([]);
          return;
        }
        e.subcategory.forEach((el, i) => (!el.products.length ? e.subcategory.splice(i, 1) : null));
        this.setSubCategories(e.subcategory);
      })
      .catch(() => this.setSubCategories([]));
  }
}

export const desktopStore = new DesktopStore();
