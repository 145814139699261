import React, { useRef, useEffect, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';

function useOutsideAlerter(
  ref: React.MutableRefObject<HTMLDivElement>,
  onOutsideClick: () => void,
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        ref.current &&
        (event.target instanceof HTMLDivElement || event.target instanceof HTMLImageElement) &&
        !ref.current.contains(event.target)
      ) {
        onOutsideClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
    // eslint-disable-next-line
  }, [ref]);
}

interface OutsideAlerterProps {
  children: ReactNode;
  onOutsideClick: () => void;
  fullHeight?: boolean;
}

export default observer(({ children, onOutsideClick, fullHeight = false }: OutsideAlerterProps) => {
  const wrapperRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  useOutsideAlerter(wrapperRef, onOutsideClick);

  return (
    <div className={`${fullHeight ? 'h-100p' : ''}`} ref={wrapperRef}>
      {children}
    </div>
  );
});
