import { format } from 'date-fns';
import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Order, OrderShort } from '../api/Order';
import IllustrationFlyBird from '../assets/img/fly_bird.svg';
import LogoBaqal from '../assets/img/logo_baqal.svg';
import LogoSwifftB from '../assets/img/logo_swifft_b.png';
import PageHeader from '../components/PageHeader';
import ScrollRestoration from '../components/ScrollRestoration';
import { mainStore } from '../stores/MainStore';
import { orderStore, OrderStatusStore } from '../stores/OrderStore';
import { desktopStore } from '../stores/DesktopStore';
import { CompanyName } from '../company/interface';
import { company } from '../company/Company';

type OrderItemProps = {
  order: Order | OrderShort;
  isList: boolean;
};

const emptyCartImage: Record<CompanyName, string> = {
  [CompanyName.Jiffy]: IllustrationFlyBird,
  [CompanyName.Baqal]: LogoBaqal,
  [CompanyName.Swifft]: LogoSwifftB,
};

const OrderDesktopItemWrapper = observer(
  ({ children, isList, order }: OrderItemProps & { children: ReactNode }) => {
    const history = useHistory();
    const location = useLocation();
    const isOrderReady =
      order.public_status === 'delivered' ||
      order.public_status === 'failed' ||
      order.public_status === 'cancelled';

    const handleItemClick = () => {
      desktopStore.setShowOrders(false);
      history.push({
        pathname: location.pathname,
        search: `?modal=order&id=${order.id}`,
      });
      desktopStore.setSelectedId(String(order.id));
      desktopStore.setShowOrder(true);
    };

    if (!mainStore.isDesktop || !isOrderReady) {
      return (
        <Link
          className={!isList ? 'pe-n' : ''}
          to={`/${isOrderReady ? 'order' : 'status'}/${order.id}`}
        >
          {children}
        </Link>
      );
    } else {
      return (
        <div
          className={!isList ? 'pe-n cursor-pointer' : 'cursor-pointer'}
          onClick={handleItemClick}
        >
          {children}
        </div>
      );
    }
  },
);

export const OrderItem = observer(({ order, isList }: OrderItemProps) => {
  const { t } = useTranslation();
  // const history = useHistory();
  const [publicStatus, setPublicStatus] = useState<OrderStatusStore>('accepted');
  const correctOrderId = (id: string): string => {
    return id.length >= 14 ? id.replace(/(.{6})$/, '-$1') : id;
  };

  useEffect(() => {
    let status: OrderStatusStore;
    switch (order.public_status) {
      case 'in_delivery':
        status = 'inDelivery';
        break;
      case 'ready_to_ship':
        status = 'readyToShip';
        break;
      case 'ready_for_pickup':
        status = 'readyForPickup';
        break;
      default:
        status = order.public_status;
        break;
    }
    setPublicStatus(status);
    //eslint-disable-next-line
  }, []);

  // TODO remove?
  // const handleOpenChat = () => {
  //   desktopStore.setShowOrders(false);
  //   history.push('/');
  //   desktopStore.setShowChat(true);
  // };

  return (
    <>
      <OrderDesktopItemWrapper order={order} isList={isList}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div
              className={htmlClasses(
                'icon s-24 fs-18 d-flex flex-center ml-n5 c-blue mr-5',
                { 'icon-accepted': order.public_status === 'accepted' },
                { 'icon-picking': order.public_status === 'picking' },
                { 'icon-picking': order.public_status === 'ready_to_ship' },
                { 'icon-indelivery': order.public_status === 'in_delivery' },
                { 'icon-flag': order.public_status === 'delivered' },
                { 'icon-blocked': order.public_status === 'cancelled' },
                { 'icon-blocked': order.public_status === 'failed' },
              )}
            />
            <div className="fs-14 c-black">
              {t(`statusWidget:${publicStatus}.name`)}
            </div>
          </div>
          <div className="fs-14 fw-500 c-gray">
            {format(new Date(order.updated_at), 'HH:mm d MMM, eee')}
          </div>
        </div>
        <div className="d-flex align-items-top justify-content-between mt-12  fs-14 c-black">
          <div className="mr-12">
            {t('order')} #{correctOrderId(order.order_id)}
          </div>
          <div>{mainStore.addCurrencySymbol(
            mainStore.convertPenceToPounds(order.paid_total || 0))}</div>
        </div>
        <div className="fs-12 c-gray mt-12">
          {order.itemsCount.actual === order.itemsCount.requested
            ? t('item', { count: order.itemsCount.requested })
            : t('itemOf', { num1: order.itemsCount.actual, num2: order.itemsCount.requested })}
        </div>
        {order.address !== null && (
          <div className="fs-12 c-gray mt-12 lh-16">
            {`${order.address.zip || ''}, ${order.address.address_1 || ''}, ${
              order.address.address_2 || ''
            }, ${order.address.city || ''}, ${order.address.country_id || ''}`.replace(
              /^(, )|( ,)|(, )*$/g,
              '',
            )}
          </div>
        )}
      </OrderDesktopItemWrapper>
      {/* TODO: remove? */}
      {/* <div className="d-flex justify-content-end fs-14 c-blue mt-10">
        {!mainStore.isDesktop ? (
          <Link className="p-8 c-blue" to="/chat">
            {t('getHelp')}
          </Link>
        ) : (
          <div className="p-8 c-blue cursor-pointer" onClick={handleOpenChat}>
            {t('getHelp')}
          </div>
        )}
      </div> */}
    </>
  );
});

let isLoading = false;
let downloadIsPossible = false;

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const refLoaderTrigger = useRef<HTMLDivElement>(null);
  const [curPage, setCurPage] = useState(orderStore.currentOrdersPage);

  useEffect(() => {
    if (history.action === 'POP' && orderStore.orderList?.length) {
      downloadIsPossible = true;
      return;
    }
    setCurPage(1);
    isLoading = true;
    orderStore
      .requestOrders()
      .then((e) => {
        downloadIsPossible = e;
      })
      .catch((error) => error && console.error(error))
      .finally(() => {
        isLoading = false;
      });
  }, [history.action]);

  useEffect(() => {
    if (orderStore.currentOrdersPage === 1 || orderStore.currentOrdersPage === curPage) return;
    isLoading = true;
    orderStore
      .requestOrders(orderStore.currentOrdersPage)
      .then((e) => {
        downloadIsPossible = e;
      })
      .catch((error) => error && console.error(error))
      .finally(() => {
        isLoading = false;
      });
    //eslint-disable-next-line
  }, [orderStore.currentOrdersPage]);

  useEffect(() => {
    if (!window.IntersectionObserver) return;
    let observer: IntersectionObserver;
    setTimeout(() => {
      observer = new IntersectionObserver(([entry]) => {
        if (!entry.isIntersecting || isLoading || !downloadIsPossible) return;
        orderStore.setCurrentOrdersPage(orderStore.currentOrdersPage + 1);
      });
      if (refLoaderTrigger.current) {
        observer.observe(refLoaderTrigger.current);
      }
    }, 1000);
    return () => observer && observer.disconnect();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {!mainStore.isDesktop && (
        <>
          <ScrollRestoration pageName="orders" />
          <PageHeader title={t('myOrder_other')} />
        </>
      )}
      <div
        className={htmlClasses('scroll-layout h-100p', {
          'overflow-hidden': !orderStore.orderList,
          'px-24': !mainStore.isDesktop,
        })}
      >
        {orderStore.orderList !== null ? (
          orderStore.orderList.length > 0 ? (
            <>
              <div className="smt-16 pt-12">
                {orderStore.orderList.map((item) => (
                  <div className="sm-item c-bg-white br-8 p-16 d-block border-tf2" key={item.id}>
                    <OrderItem order={item} isList={true} />
                  </div>
                ))}
              </div>
              <div ref={refLoaderTrigger} />
              <div className="h-24" />
            </>
          ) : (
            <div className="d-flex h-100p flex-direction-column justify-content-center">
              <div className="flex-shrink-0">
                <img
                  className="d-block mx-auto w-312"
                  src={emptyCartImage[company.name]}
                  alt=""
                />
                <div className="fs-21 mt-24  text-center c-udgray fw-500">
                  {t('cartPage:nothingHere')}
                </div>
              </div>
            </div>
          )
        ) : (
          <div className="smt-16 pt-12">
            <SkeletonTheme color="#EBECED" highlightColor="#F7F7F7">
              <Skeleton className="sm-item br-8 h-200" count={5} />
            </SkeletonTheme>
          </div>
        )}
      </div>
    </>
  );
});
