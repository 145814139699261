import * as Sentry from '@sentry/react';
import axios, {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError,
  RawAxiosRequestHeaders,
} from 'axios';
import { REQUEST_TIMEOUT, API_URL } from '../config';
import i18n from 'i18next';
import { userStore } from '../stores/UserStore';
import { mainStore } from '../stores/MainStore';
import { ENDPOINT } from './constants';
import { company } from '../company/Company';

export interface ApiErrorResponse {
  code?: string;
  error?: string | boolean;
  errors?: string[] | {
    message: string;
    payload: { message: string };
  }[];
  message?: string | {
    children: unknown[];
    constraints: {
      isLength: string;
      isNotEmpty: string;
      isString: string;
      matches: string;
    };
    property: string;
    target: Record<string, unknown>;
  }[];
  statusCode?: number;
}

type OmitDistributive<T, K extends PropertyKey> = T extends any
  ? T extends Record<any, any>
    ? OmitItem<OmitRecursively<T, K>>
    : T
  : never;

type OmitItem<T> = Record<any, unknown> & { [P in keyof T]: T[P] };

export type OmitRecursively<T, K extends PropertyKey> = Omit<{ [P in keyof T]: OmitDistributive<T[P], K> },
  K>;

interface RequestAPIMethods {
  get(
    url: string, data?: Record<string, any>,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse['data']>;

  post(
    url: string, data?: Record<string, any>,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse['data']>;

  put(
    url: string, data?: Record<string, any>,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse['data']>;

  patch(
    url: string, data?: Record<string, any>,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse['data']>;

  delete(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse['data']>;

  refresh(url: string, data?: Record<string, any>): Promise<AxiosResponse['data']>;
}

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: REQUEST_TIMEOUT,
});

export const addToken = (config?: AxiosRequestConfig): AxiosRequestConfig => {
  const headers: RawAxiosRequestHeaders = {};

  if (userStore.token) {
    headers.Authorization = `Bearer ${userStore.token}`;
    delete config?.headers?.['x-company-id'];
  } else {
    headers['x-company-id'] = company.config.id;
    delete config?.headers?.Authorization;
    delete config?.headers?.authorization;
  }

  return Object.assign(config || {}, {
    headers: {
      ...(config?.headers || {}),
      ...headers,
    },
  });
};

export const requestWrapper = async (request: () => Promise<AxiosResponse>): Promise<AxiosResponse> => {
  let count = 4;
  while (count > 0) {
    count--;
    try {
      return await request();
    } catch (error) {
      if (!error.response && (error.code === 'ECONNABORTED' || error.message === 'Network Error')) {
        if (count) {
          const attempt = count;
          await new Promise((resolve) => setTimeout(resolve, (4 - attempt) * 1000));
          continue;
        } else return Promise.reject(error);
      }

      if (error.response?.status === 409 && error.response?.config?.url === ENDPOINT.orders.new) {
        /**
         * In case when user creates new order, and system returns error code 409,
         * front should do new attempt unless server will not return error code not equal 409 or success.
         *
         * Backend is assure us that they promise not to loop query with 409 error.
         *
         * "count" should be always more then 0 because new query should be sent 1 per sec endless
         * */
        count = 4;
        await new Promise((resolve) => setTimeout(resolve, 1000));
        continue;
      }

      return Promise.reject(error);
    }
  }
  return Promise.reject();
};

export const RequestAPI: RequestAPIMethods = {
  get: (url, data, config) =>
    requestWrapper(() =>
      axiosInstance.get(url + mainStore.convertObjToGet(data || {}), addToken(config))),

  post: (url, data, config) =>
    requestWrapper(() =>
      axiosInstance.post(url, data, addToken(config))),

  put: (url, data, config) =>
    requestWrapper(() =>
      axiosInstance.put(url, data, addToken(config))),

  patch: (url, data, config) =>
    requestWrapper(() =>
      axiosInstance.patch(url, data, addToken(config))),

  delete: (url, config) =>
    requestWrapper(() =>
      axiosInstance.delete(url, addToken(config))),

  refresh: (url, data) =>
    requestWrapper(() => axiosInstance
      .post(url, data, { headers: { Authorization: `Bearer ${userStore.refreshToken}` } })),
};

const loggingErrorResponse = ({ data, status, config }: AxiosResponse) => {
  if (status === 401 || status === 404) return;
  if (status === 400 && config.url?.includes('eta/get')) return;
  let url = config.url || '';
  if (!url.includes('http')) url = (config.baseURL || '') + (config.url || '');
  Sentry.withScope((scope) => {
    scope.setExtras({
      request: {
        url,
        method: config.method,
        request: config.data,
      },
      response: data,
      status: status,
    });
    Sentry.captureMessage(
      `[${status}] ${config.method?.toUpperCase()} ${url}`,
      status === 500 ? 'warning' : 'info',
    );
  });
};

export const refreshTokens = {
  isRefreshing: false,
  lastUpdateTimestamp: 0,
  reset: function () {
    this.isRefreshing = false;
    this.lastUpdateTimestamp = 0;
  },
  refresh: async function (error: AxiosError) {
    const { response } = error;

    if (!response) return Promise.reject(error);

    const { config, status } = response;

    if (!config || !config.url || status !== 401) return Promise.reject(error);

    const lastUpdateTimestampDelta = (Date.now() - this.lastUpdateTimestamp) / 1000;

    if (
      config.url.includes(ENDPOINT.customer.token.refresh) ||
      config.url.includes(ENDPOINT.customer.logout) ||
      lastUpdateTimestampDelta < 60
    ) {
      return Promise.reject(error);
    }

    if (!this.isRefreshing) {
      this.isRefreshing = true;

      try {
        await userStore.requestNewToken();
        this.isRefreshing = false;
        this.lastUpdateTimestamp = Date.now();
      } catch (e) {
        mainStore.pushAlert('error', i18n.t('errors:failedUpdateToken'));
        userStore.logout().catch(() => undefined);

        return Promise.reject(error);
      }
    } else {
      await new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (!this.isRefreshing) {
            clearInterval(interval);
            resolve();
          }
        }, 1000);
      });
    }

    if (!userStore.token) return Promise.reject(error);

    return axiosInstance.request(addToken(config));
  },
};

axiosInstance.interceptors.response.use(
  ({ data }) => data,
  (error) => {
    if (error && error.response) loggingErrorResponse(error.response);
    return refreshTokens.refresh(error);
  },
);
