import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useLocation, useParams, Link } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import ScrollRestoration from '../components/ScrollRestoration';
import { scrollTo, closeTags } from '../UI';
import IconGmoFree from '../assets/img/product-info-icons/icon-gmo-free.svg';
import IconHalal from '../assets/img/product-info-icons/icon-halal.svg';
import IconKosher from '../assets/img/product-info-icons/icon-kosher.svg';
import IconOnlyForAdults21 from '../assets/img/product-info-icons/icon-only-for-adults-21.svg';
import IconOnlyForAdults from '../assets/img/product-info-icons/icon-only-for-adults.svg';
import IconOrganic from '../assets/img/product-info-icons/icon-organic.svg';
import IconSugarFree from '../assets/img/product-info-icons/icon-sugar-free.svg';
import IconVegan from '../assets/img/product-info-icons/icon-vegan.svg';
import IconVegeterian from '../assets/img/product-info-icons/icon-vegeterian.svg';
import IconAllergenFree from '../assets/img/product-info-icons/icon-allergen-free.svg';
import IconDairyFree from '../assets/img/product-info-icons/icon-dairy-free.svg';
import IconGlutenFree from '../assets/img/product-info-icons/icon-gluten-free.svg';
import IconLowFat from '../assets/img/product-info-icons/icon-low-fat.svg';
import IconContainsSoya from '../assets/img/product-info-icons/icon-contains-soya.svg';
import IconContainsSulphites from '../assets/img/product-info-icons/icon-contains-sulphites.svg';
import IconFairtrade from '../assets/img/product-info-icons/icon-fairtrade.svg';
import IconMayContainNuts from '../assets/img/product-info-icons/icon-may-contain-nuts.svg';
import IconNoAddedSugar from '../assets/img/product-info-icons/icon-no-added-sugar.svg';
import IconSuitableForFreezing
  from '../assets/img/product-info-icons/icon-suitable-for-freezing.svg';
import {
  PRODUCT_DESCRIPTION_LENGTH,
  PRODUCT_SELLABLE_LIMIT,
  BUNDLE_SELLABLE_LIMIT,
} from '../config';
import Counter from '../components/Counter';
import noProductImg from '../assets/img/no_picture_product.jpg';
import { catalogStore } from '../stores/CatalogStore';
import { mainStore } from '../stores/MainStore';
import PopoverZoom from '../components/PopoverZoom';
import { makeAutoObservable } from 'mobx';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/zoom/zoom.min.css';
import SwiperClass from 'swiper/types/swiper-class';
import SwiperCore, { Zoom } from 'swiper';
import { a, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import NotStockingProduct from '../assets/img/fly_bird.svg';
import LogoBaqal from '../assets/img/logo_baqal.svg';
import LogoSwifftB from '../assets/img/logo_swifft_b.png';
import RecommendProductsSlider from './RecommendProductsSlider';
import { desktopStore } from '../stores/DesktopStore';
import { orderStore } from '../stores/OrderStore';
import ProductItemBadgesList
  from '../components/Badges/ProductItemBadgesList/ProductItemBadgesList';
import { PriceLockBadge } from '../components/Badges/PriceLockBadge/PriceLockBadge';
import {
  Product,
  ProductOffer,
  ProductVariant,
  ProductNutrition,
  ProductColor,
} from '../types/Product/interface';
import Icon from '../components/ui/Icon/Icon';
import { TescoBadge } from '../components/Badges/TescoBadge/TescoBadge';
import { CompanyName } from '../company/interface';
import { company } from '../company/Company';

SwiperCore.use([Zoom]);

type ProductSliderProps = {
  images: string[];
  onSlideClick?: () => void;
  allowZoom?: boolean;
};

type IconListProps = {
  variants: ProductVariant[];
};

type NutritionProps = {
  nutrition: ProductNutrition[];
  refObj?: React.RefObject<HTMLDivElement>;
};

type BodyProps = {
  offer: ProductOffer;
  colors: ProductColor[];
  sizes: string[];
  curColorText: string;
  curSize: string;
  onOfferChange: (colorText: string, size: string) => void;
};

type OtherPropertiesProps = {
  properties: ProductVariant[];
  refObj: React.RefObject<HTMLDivElement>;
};

type DescriptionProps = {
  text: string;
  title?: string;
};

type PopoverZoomSliderProps = {
  images: string[];
  onClose: () => void;
};

class SliderStore {
  activeSlide = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setActiveSlide(val: number) {
    this.activeSlide = val;
  }
}

const emptyCartImage: Record<CompanyName, string> = {
  [CompanyName.Jiffy]: NotStockingProduct,
  [CompanyName.Baqal]: LogoBaqal,
  [CompanyName.Swifft]: LogoSwifftB,
};

const sliderStore = new SliderStore();

const iconList: Record<string, string> = {
  allergen_free: IconAllergenFree,
  dairy_free: IconDairyFree,
  gluten_free: IconGlutenFree,
  low_fat: IconLowFat,
  for_vegetarians: IconVegeterian,
  is_organic_food: IconOrganic,
  for_halal: IconHalal,
  gmo_free: IconGmoFree,
  age_restriction: company.config.ageRestricted > 18 ? IconOnlyForAdults21 : IconOnlyForAdults,
  sugar_free: IconSugarFree,
  for_kosher: IconKosher,
  for_vegan: IconVegan,
  fairtrade: IconFairtrade,
  freezing_suitable: IconSuitableForFreezing,
  with_soya: IconContainsSoya,
  may_contain_nuts: IconMayContainNuts,
  with_sulphites: IconContainsSulphites,
  no_added_sugar: IconNoAddedSugar,
};

const ProductSlider = observer(({ images, onSlideClick, allowZoom }: ProductSliderProps) => {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const handleSlideChange = (swiper: SwiperClass) => {
    sliderStore.setActiveSlide(swiper.activeIndex);
  };
  const handleClick = () => {
    if (onSlideClick) onSlideClick();
  };

  useEffect(() => {
    if (!swiper) return;
    swiper.slideTo(sliderStore.activeSlide, 0);
    //eslint-disable-next-line
  }, [sliderStore.activeSlide, swiper]);

  if (!images.length) {
    return (
      <div className="product-slider">
        <div
          className="product-slider__slide"
          style={{ backgroundImage: `url(${noProductImg})` }}
        />
      </div>
    );
  }

  return (
    <>
      <Swiper
        className={htmlClasses('product-slider', { 'h-80p': allowZoom })}
        slidesPerView={1}
        onSlideChange={handleSlideChange}
        onSwiper={(swiper) => setSwiper(swiper)}
        zoom={allowZoom}
      >
        {images.map((img, i) => (
          <SwiperSlide key={i} onClick={handleClick} zoom={allowZoom}>
            {allowZoom ? (
              <img src={img} alt="" />
            ) : (
              <div
                className="product-slider__slide"
                style={{ backgroundImage: `url(${img})` }}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      {images.length > 1 && (
        <div className="onboarding__dots">
          {images.map((item, i) => (
            <div
              className={htmlClasses('onboarding__dot', { _active: i === sliderStore.activeSlide })}
              key={i}
            />
          ))}
        </div>
      )}
    </>
  );
});

const OtherProperties = observer(({ properties, refObj }: OtherPropertiesProps) => {
  const { t } = useTranslation();

  if (!properties.length) return <div ref={refObj} />;

  return (
    <div className="product-info__item">
      <div className="fs-16 fw-500 mb-16 mt-20" ref={refObj}>
        {t('other')}
      </div>
      <div className="smt-14">
        {properties.map(({ name, value }, i) => (
          <div className="sm-item d-flex align-items-start justify-content-between" key={i}>
            <div className="w-max-45p fs-12 lh-16 pr-8 c-gray">
              {t(`productProperties:${name}`)}
            </div>
            <div className="w-max-45p fs-12 lh-16 text-right">{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
});

const Nutrition = observer(({ nutrition, refObj }: NutritionProps) => {
  const { t } = useTranslation();

  if (!nutrition.length) return <div ref={refObj} />;

  return (
    <>
      <div className="fs-16 lh-36 fw-500 mt-12" ref={refObj}>
        {t('productPage:nutritionInfo')}
      </div>
      <div className="mt-10 d-flex justify-content-between align-items-center">
        <div className="w-30p fs-12 lh-16 fw-500">{t('productPage:typicalValues')}</div>
        <div className="w-60p d-flex">
          <div className="w-50p fs-12 mr-4 lh-16 fw-500 d-flex flex-direction-column justify-content-start align-items-center">
            <div>{t('productPage:per100g')}</div>
            <div>{t('productPage:ofProduct')}</div>
          </div>
          <div className="w-50p fs-12 lh-16 fw-500 d-flex flex-direction-column justify-content-start align-items-center">
            <div>{t('productPage:RI')}</div>
            <div>{t('productPage:per100g')}</div>
          </div>
        </div>
      </div>
      {nutrition.map(({ name, value, ratio, className }, i) => (
        <div
          className={htmlClasses('d-flex justify-content-between align-items-center fs-12 lh-19', {
            'pt-15 product-info__line': !(name === 'fat_saturates' || name === 'sugar'),
          })}
          key={i}
        >
          <div className="w-40p">
            {name === 'fat_saturates' || name === 'sugar'
              ? t(`nutritionTableNames:${name}`)
              : t(`productProperties:${name}`)}
          </div>
          <div className="w-60p d-flex">
            <div
              className={htmlClasses(
                'w-50p mr-4 d-flex flex-direction-column justify-content-start align-items-center',
                className,
              )}
            >
              <div>{value}</div>
            </div>
            <div
              className={htmlClasses(
                'w-50p d-flex justify-content-center align-items-center',
                className,
              )}
            >
              {ratio}
            </div>
          </div>
        </div>
      ))}
    </>
  );
});

const Description = observer(({ text, title }: DescriptionProps) => {
  const { t } = useTranslation();
  const [isShortText, setIsShortText] = useState(false);
  const [shortText, setShortText] = useState('');
  const handleSeeMoreButtonClick = () => {
    setIsShortText(!isShortText);
  };

  const reduceText = (text: string): string => {
    if (text.length <= PRODUCT_DESCRIPTION_LENGTH - 3) return '';
    let shortText = text.slice(0, PRODUCT_DESCRIPTION_LENGTH - 3);
    if (~shortText.lastIndexOf(' ')) {
      shortText = shortText.slice(0, shortText.lastIndexOf(' '));
    }
    setIsShortText(true);
    return closeTags(`${shortText}...`);
  };

  useEffect(() => {
    if (text) setShortText(reduceText(text));
    // eslint-disable-next-line
  }, []);

  if (!shortText && !text) return null;

  return (
    <div className="product-info__item">
      <div className="fs-16 fw-500 lh-36">{title ? t(`${title}`) : t('description')}</div>
      <div
        className="description__text mt-3 text-break-word"
        dangerouslySetInnerHTML={{
          __html: isShortText ? shortText : text,
        }}
      />
      {shortText.length > 0 && (
        <button
          className={htmlClasses('product-info__button mt-7 fs-14 lh-19 fw-500 c-blue', {
            'd-none': !text.length,
          })}
          type="button"
          onClick={handleSeeMoreButtonClick}
        >
          {isShortText ? t('seeMore') : t('seeLess')}
        </button>
      )}
    </div>
  );
});

const Awaiting = observer(() => {
  return (
    <>
      {!mainStore.isDesktop && <PageHeader />}
      <div className="h-100p">
        <div className="scroll-layout h-100p px-24 overflow-hidden">
          <SkeletonTheme color="#EBECED" highlightColor="#F7F7F7">
            <Skeleton className="h-450 br-8" />
          </SkeletonTheme>
        </div>
      </div>
    </>
  );
});

const Disclaimer = () => {
  const { t } = useTranslation();
  const disclaimerText: string[] = t('productPage:disclaimerText', { returnObjects: true, companyName: company.config.officialName });

  return (
    <>
      <div className="fs-16 fw-500 lh-26 mt-32">{t('disclaimer')}</div>
      {disclaimerText.map((item, i) => (
        <div className="fs-12 lh-16 mt-10" key={i}>
          {item}
        </div>
      ))}
    </>
  );
};

const IconList = observer(({ variants }: IconListProps) => {
  return (
    <ul className="product-card__icon-list w-max-100p">
      {variants.map(({ name, value }, i) => (
        <li className="w-65 text-top" key={i}>
          <img src={iconList[name]} alt="" />
          <div
            className={htmlClasses(
              'fs-10 lh-11 fw-500 mt-3 text-center',
              { 'c-lorange': name === 'allergen_free' || name === 'gluten_free' },
              { 'c-lsgray': name === 'dairy_free' || name === 'freezing_suitable' },
              { 'c-ftgray': name === 'low_fat' || name === 'with_soya' },
              { 'c-vgreen': name === 'for_vegetarians' },
              { 'c-lgreen': name === 'is_organic_food' },
              { 'c-text': name === 'for_halal' || name === 'gmo_free' },
              { 'c-red': name === 'age_restriction' || name === 'with_sulphites' },
              { 'c-sblue': name === 'sugar_free' },
              { 'c-udgray': name === 'for_kosher' },
              { 'c-salad3': name === 'for_vegan' },
              { 'c-fgray': name === 'fairtrade' },
              { 'c-ngray': name === 'may_contain_nuts' },
              { 'c-nred': name === 'no_added_sugar' },
            )}
          >
            {value}
          </div>
        </li>
      ))}
    </ul>
  );
});

const Body = observer(({ offer }: BodyProps) => {
  const { t } = useTranslation();
  const count = catalogStore.getCartItemCountById(offer.id);
  const hasDiscount = offer.discountPrice > 0 || offer.promoRequiredQuantity > 0;
  const discountPriceFormatted = offer.discountPrice ? offer.discountPriceFormatted : offer.promoQuantityDiscountPriceFormatted;
  const getLimit = (): number => {
    const limit = offer.bundle ? BUNDLE_SELLABLE_LIMIT : PRODUCT_SELLABLE_LIMIT;
    return offer.sellable > limit ? limit : offer.sellable;
  };
  const handleChangeCount = (count: number, action: 'add' | 'remove') => {
    catalogStore.setCartItemCountByProduct(offer, count, action, 'product_main');
  };

  useEffect(() => {
    if (count > offer.sellable) handleChangeCount(offer.sellable, 'remove');
    //eslint-disable-next-line
  }, [offer]);

  return (
    <div className="product-card__body">
      <div className="product-item__name _full text-break-word">{offer.name || ''}</div>
      <div className="product-item__footer">
        <div className="flex-shrink-0 d-flex justify-content-between align-items-end">
          {hasDiscount ? (
            <div className="product-item__price _discount d-flex flex-direction-column">
              {offer.isGrocery && offer.promoRequiredQuantity > 0 && (
                <div className=" fs-16 lh-19">
                  {t('from')}
                  {' '}
                  {offer.promoRequiredQuantity}
                  {t('pcs')}
                </div>
              )}
              {mainStore.addCurrencySymbol(discountPriceFormatted)}
            </div>
          ) : (
            <div
              className={htmlClasses(
                'product-item__price',
                { '_discount': offer.properties.tesco_price_match },
              )}
            >
              {mainStore.addCurrencySymbol(offer.priceFormatted)}
            </div>
          )}
          <div className="product-item__price-unit">
            {hasDiscount ? `${mainStore.addCurrencySymbol(offer.priceFormatted)} / ` : ''}
            {offer.pricePerUnit}
          </div>
        </div>
        <Counter
          className={hasDiscount || offer.properties.tesco_price_match ? '_discount' : ''}
          value={count}
          onChange={handleChangeCount}
          limit={getLimit()}
          height={48}
          addToCartCaption={t('addToBasket')}
        />
      </div>
    </div>
  );
});

const PopoverZoomSlider = observer(({ images, onClose }: PopoverZoomSliderProps) => {
  const [{ y }, set] = useSpring(() => ({ y: 0 }));
  const bind = useDrag(
    ({ last, vxvy: [, vy], movement: [, my], cancel, event }) => {
      if (event.type === 'touchmove' && (event as unknown as TouchEvent).touches.length > 1) {
        cancel();
        return;
      }
      if (my < 0) return;
      if (my >= 50) {
        onClose();
        return;
      }
      if (last) {
        if (my > 40 || vy > 0.5) {
          onClose();
          return;
        }
        set.start({ y: 0, immediate: false });
      } else set.start({ y: my, immediate: true });
    },
    { axis: 'y', useTouch: true, filterTaps: false },
  );

  return (
    <div className="h-100p c-bg-white">
      <a.div
        {...bind()}
        style={{ y }}
        className="d-flex flex-direction-column justify-content-center h-100p py-20"
      >
        <ProductSlider images={images} allowZoom={true} />
      </a.div>
    </div>
  );
});

const NotStocking = observer(() => {
  const { t } = useTranslation();

  return (
    <div className="h-100p d-flex flex-direction-column">
      <div className="flex-shrink-0 mt-auto">
        <img
          className="d-block mx-auto w-312"
          src={emptyCartImage[company.name]}
          alt=""
        />
        <div className="fs-21 fw-500  mt-24 text-center mx-auto">
          {t('productPage:notStocking')}
        </div>
      </div>
      <div className="h-24 flex-shrink-0" />
      <Link className="button _primary flex-shrink-0 mt-auto" to="/">
        {t('goMarket')}
      </Link>
      <div className="h-24 flex-shrink-0" />
    </div>
  );
});

// const BundleProductsList = observer(({ bundleProductItem }: { bundleProductItem: Product }) => {
//   const { t } = useTranslation();
//   const [showAll, setShowAll] = useState(false);
//   const history = useHistory();
//   const location = useLocation();

//   const handleProductClick = (product: Product) => {
//     desktopStore.setSavedSource('bundle');
//     history.push({
//       pathname: location.pathname,
//       search: `?modal=product&id=${product.id}`,
//     });
//     desktopStore.setSelectedId(String(product.id));
//     desktopStore.setShowProduct(true);
//   };

//   const bundleItemsQuantity = useMemo(() => {
//     const result: Record<string, number> = {};
//     if (!bundleProductItem.bundle) {
//       return result;
//     }

//     bundleProductItem.bundle.forEach((({ sku, quantity}) => {
//       result[sku] = quantity
//     }));

//     return result;
//   }, [JSON.stringify(bundleProductItem.bundle)]);

//   return bundleProductItem.bundle_products && bundleProductItem.bundle ? (
//     <>
//       <div className="fs-16 fw-500 lh-36 mt-24">{t('listOfItems')}</div>
//       {bundleProductItem.bundle_products.slice(0, showAll ? undefined : 3).map((product, i) => (
//         <React.Fragment key={i}>
//           {i !== 0 && <div className="h-1 c-bg-tf2 pe-n" />}
//           <div className="cart-item d-flex py-16 overflow-hidden position-relative">
//             <div
//               className="w-44 flex-shrink-0 img-contain position-relative"
//               style={{
//                 backgroundImage:
//                   product.preview_image?.path !== 'none'
//                     ? `url(${product.preview_image?.path})`
//                     : 'none',
//               }}
//             >
//               {!mainStore.isDesktop ? (
//                 <Link
//                   className="link-abs"
//                   to={{ pathname: `/product/${product.id}`, state: { source: 'bundle' } }}
//                 />
//               ) : (
//                 <div
//                   className="link-abs cursor-pointer"
//                   onClick={() => handleProductClick(product)}
//                 />
//               )}
//             </div>
//             <div className="pl-8 w-100p d-flex flex-direction-column">
//               {!mainStore.isDesktop ? (
//                 <Link
//                   className="fs-12 lh-16 mb-4 flex-shrink-0"
//                   to={{ pathname: `/product/${product.id}`, state: { source: 'bundle' } }}
//                 >
//                   {product.name || ''}
//                 </Link>
//               ) : (
//                 <div
//                   className="fs-12 lh-16 mb-4 flex-shrink-0 cursor-pointer"
//                   onClick={() => handleProductClick(product)}
//                 >
//                   {product.name || ''}
//                 </div>
//               )}

//               <div className="d-flex">
//                 <PizzaBadge className="mr-4" commodityGroup={product.formatted_properties.commodity_group} count={bundleItemsQuantity[product.code] || 1} />

//                 {product.discountPercentage.length > 0 && (
//                   <div className="br-3 c-bg-ulred fs-8 p-5 mb-4 c-red flex-shrink-0 align-self-start">
//                     {t('specialOffer')}: <span className="fw-500">{product.discountPercentage}</span>
//                   </div>
//                 )}
//               </div>


//               <div className="mt-auto flex-shrink-0 d-flex align-items-start">
//                 {product.discountPercentage.length > 0 ? (
//                   <div className="product-item__price _discount fs-12 w-50 flex-shrink-0">
//                     £
//                     {mainStore.convertPenceToPounds(
//                       mainStore.convertPoundsToPence(
//                         product.formatted_properties.discount_price?.value || 0,
//                       ),
//                     )}
//                     <s className="product-item__price-old fs-10">
//                       £
//                       {mainStore.convertPenceToPounds(
//                         mainStore.convertPoundsToPence(
//                           product.formatted_properties.price?.value || 0,
//                         ),
//                       )}
//                     </s>
//                   </div>
//                 ) : (
//                   <div className="product-item__price fs-12 w-50 flex-shrink-0">
//                     £
//                     {mainStore.convertPenceToPounds(
//                       mainStore.convertPoundsToPence(
//                         product.formatted_properties.price?.value || 0,
//                       ),
//                     )}
//                   </div>
//                 )}
//                 <div className="fs-12 c-t99">
//                   {product.pricePerUnit}
//                   {' / '}
//                   {product.discountPercentage.length > 0
//                     ? mainStore.convertPenceToPounds(
//                         mainStore.convertPoundsToPence(
//                           product.formatted_properties.discount_price?.value || 0,
//                         ),
//                       )
//                     : mainStore.convertPenceToPounds(
//                         mainStore.convertPoundsToPence(
//                           product.formatted_properties.price?.value || 0,
//                         ),
//                       )}{' '}
//                   £
//                 </div>
//                 <div className="flex-shrink-0 fs-12 ml-auto pl-8">
//                   {bundleItemsQuantity[product.code] || 1}pc
//                 </div>
//               </div>
//             </div>
//           </div>
//         </React.Fragment>
//       ))}
//       {!showAll && bundleProductItem.bundle_products.length > 3 && (
//         <div
//           className="c-blue fs-14 lh-19 d-flex justify-content-center mt-10"
//           onClick={() => setShowAll(true)}
//         >
//           {t('seeMore')}
//         </div>
//       )}
//     </>
//   ) : (
//     <></>
//   );
// });

export default observer(() => {
  const { t } = useTranslation();
  const { productId } = !mainStore.isDesktop
    ? useParams<{ productId: string }>()
    : { productId: String(desktopStore.selectedId) };
  const { state } = useLocation<{ source?: string; isDeeplink?: boolean }>();
  const refScrollLayout = useRef<HTMLDivElement>(null);
  const refNnutritionInfo = useRef<HTMLDivElement>(null);
  const refOther = useRef<HTMLDivElement>(null);
  const [isShowProductZoom, setIsShowProductZoom] = useState(false);
  const [product, setProduct] = useState<Product | null | undefined>(undefined);
  const [offer, setOffer] = useState<ProductOffer | null>(null);
  const [colorText, setColorText] = useState<string>('');
  const [size, setSize] = useState<string>('');
  const handleScrollTo = (target: HTMLDivElement | null, targetName: string) => {
    if (!target) return;
    scrollTo(refScrollLayout.current, target, 500, -10);
    if (!offer) return;
    mainStore.sendAnalytics(['analytics', 'firebase'], {
      name: 'Catalog: product page scrolled',
      params: {
        product_id: offer.productId,
        category_id: offer.categoryId,
        lvl1_category_id: undefined,
        lvl2_category_id: offer.parentCategoryId,
        depth: targetName,
        price: offer.priceFormatted,
        final_price: offer.discountPrice ? offer.discountPriceFormatted : offer.priceFormatted,
      },
    });
  };
  const handleFavorite = () => {
    if (!product) return;
    catalogStore.toggleFavorite(product, 'product_main');
    mainStore.sendToRN('hapticFeedback', {
      count: 1,
    });
  };
  const handleOfferChange = (colorText: string, size: string) => {
    setColorText(colorText);
    setSize(size);
  };

  useLayoutEffect(() => {
    if (!productId) return;
    if (!desktopStore.desktopDeviceId) return setProduct(undefined);
    catalogStore
      .requestProduct(
        productId, productId.indexOf('product_') !== -1 && isNaN(parseInt(productId.split('_')[1])))
      .then((e) => setProduct(e))
      .catch(() => {
        setProduct(null);
      });
    //eslint-disable-next-line
  }, [productId, state, desktopStore.desktopDeviceId]);

  useEffect(() => {
    if (!mainStore.isDesktop || !product) return;
    const hiddenTop = document.querySelector('.desktop__dialog-top-hidden');
    hiddenTop && hiddenTop.scrollIntoView({ behavior: 'auto', block: 'start' });
  }, [product]);

  useEffect(() => {
    if (!product) return;
    if (product.isGrocery) {
      setOffer(product.offers[0]);
      return;
    }
    if (!colorText && !size) {
      setColorText(product.offers[0].properties.color_text as string || '');
      setSize(product.offers[0].properties.size as string || '');
      return;
    }
    let altColorText = '';
    const offer = product.offers.filter((offer) => {
      if (colorText && size) {
        if (offer.properties.size as string === size) {
          altColorText = offer.colors[0]?.name || '';
          if (offer.properties.color_text as string === colorText) {
            return true;
          }
        }
      } else if (size && offer.properties.size as string === size) {
        return true;
      } else if (colorText && offer.properties.color_text as string === colorText) {
        return true;
      }
      return false;
    })[0] || null;
    if (offer) {
      setOffer(offer);
    } else {
      setColorText(altColorText);
    }
  }, [product, colorText, size]);


  useEffect(() => {
    if (!offer) return;
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Catalog: product page visited',
      params: {
        product_id: offer.productId,
        category_id: offer.categoryId,
        lvl1_category_id: undefined,
        lvl2_category_id: offer.parentCategoryId,
        source: state?.source || 'direct',
        price: offer.priceFormatted,
        final_price: offer.discountPrice ? offer.discountPriceFormatted : offer.priceFormatted,
      },
    });
    mainStore.sendToRN('sendTags', {
      last_category_interest_time: Math.floor(Date.now() / 1000),
    });
    mainStore.sendToRN('sendTags', {
      last_category_interest_lv2: offer.parentCategoryId,
    });
    mainStore.sendToRN('sendTags', {
      last_category_interest_lv3: offer.categoryId,
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'select_item',
      params: {
        currency: orderStore.currency.toUpperCase(),
        items: [
          {
            item_id: offer.sku,
            item_name: offer.name,
            quantity: 1,
            promotion_id: '',
            promotion_name: '',
            affiliation: '',
            coupon: catalogStore.promocode.value,
            creative_name: '',
            creative_slot: '',
            discount: offer.discountPrice
              ? mainStore.toFloat(mainStore.convertPenceToPounds(offer.price - offer.discountPrice))
              : 0,
            index: 0,
            item_brand: '',
            item_category: offer.categoryName,
            item_list_name: offer.categoryName,
            item_list_id: offer.categoryId,
            item_variant: '',
            location_id: '',
            tax: 0,
            price: mainStore.toFloat(offer.priceFormatted),
            currency: orderStore.currency.toUpperCase(),
          },
        ],
        value: mainStore.toFloat(offer.priceFormatted),
      },
    });
  }, [state, offer]);

  useEffect(() => {
    sliderStore.setActiveSlide(0);
  }, [offer]);

  if (product === undefined) return <Awaiting />;

  return (
    <>
      {!mainStore.isDesktop && (
        <>
          <ScrollRestoration pageName="product" />
          <PageHeader title={product?.parentCategoryName || ''} />
        </>
      )}
      <div
        className={htmlClasses('scroll-layout h-100p px-24', {
          'w-max-450 mx-n20': mainStore.isDesktop,
        })}
        ref={refScrollLayout}
      >
        {product !== null ? (
          offer !== null && (
            <>
              <div className="product-card">
                <div
                  className={`product-card__icon-favorite ${
                    catalogStore.favorites[product.id] ? 'c-red' : 'c-tc7'
                  }`}
                  onClick={handleFavorite}
                >
                  <Icon type={catalogStore.favorites[product.id] ? 'heart-dark' : 'heart'} size={24} />
                </div>

                <ProductItemBadgesList offer={offer} />

                <div className="position-relative">
                  <ProductSlider
                    images={offer.images}
                    onSlideClick={() => setIsShowProductZoom(true)}
                  />
                  {offer.isGrocery && (
                    <>
                      <PriceLockBadge offer={offer} className="product-card__price-lock-badge" />
                      <TescoBadge offer={offer} className="product-item__tesco-badge" />
                    </>
                  )}
                </div>
                <Body
                  offer={offer}
                  colors={offer.colors.length ? offer.colors : product.colors}
                  sizes={product.sizes}
                  curColorText={colorText}
                  curSize={size}
                  onOfferChange={handleOfferChange}
                />
                <div className="product-card__links">
                  <div
                    className="product-card__link cursor-pointer"
                    onClick={() => handleScrollTo(refNnutritionInfo.current, 'nutrition')}
                  >
                    {t('productPage:nutritionInfo')}
                  </div>
                  <div
                    className="product-card__link cursor-pointer"
                    onClick={() => handleScrollTo(refOther.current, 'other')}
                  >
                    {t('moreDetails')}
                  </div>
                </div>
              </div>
              <RecommendProductsSlider
                className="mb-30"
                sku={[offer.sku]}
                source="reco_card"
              />
              {/* {product.bundle && product.bundle_products && (
                <BundleProductsList bundleProductItem={product} />
              )} */}
              {offer.variants.length > 0 && (
                <>
                  <div className="mt-16 fs-16 fw-500">{t('aboutProduct')}</div>
                  <IconList variants={offer.variants} />
                </>
              )}
              <div className="mt-18">
                <Description text={offer.description} />
                {/* TODO: remove?
                <Description title={'brandDescription'} text={product.brandDescription} /> */}
                {typeof offer.properties.ingredients === 'string' && offer.properties.ingredients.length > 0 && (
                  <>
                    <div className="fs-16 fw-500 lh-36 mt-12">{t('productPage:ingredients')}</div>
                    <div
                      className="fs-12 lh-16 mt-3"
                      dangerouslySetInnerHTML={{
                        __html: offer.properties.ingredients,
                      }}
                    />
                    <div className="fs-16 fw-500 lh-36 mt-12">
                      {t('productPage:allergenInformation')}
                    </div>
                    <div
                      className="fs-12 lh-16 mt-3"
                      dangerouslySetInnerHTML={{ __html: t('productPage:allergenInfoText') }}
                    />
                  </>
                )}
                {typeof offer.properties.safery_allergen === 'string' && offer.properties.safery_allergen.length > 0 && (
                  <>
                    <div className="fs-16 fw-500 lh-36 mt-16">{t('productPage:safetyWarning')}</div>
                    <div
                      className="fs-12 lh-16 mt-3"
                      dangerouslySetInnerHTML={{
                        __html: offer.properties.safery_allergen,
                      }}
                    />
                  </>
                )}
                {offer.properties.age_restriction && !!offer.properties.alcohol_units && (
                  <>
                    <div className="fs-16 fw-500 lh-36 mt-12">{t('productPage:alcohol')}</div>
                    <div className="fs-12 lh-16 mt-3">
                      {!!offer.properties.abv && (
                        <div>
                          {t('productProperties:abv')} {offer.properties.abv}%
                        </div>
                      )}
                      <div>
                        {t('productPage:units')} {offer.properties.alcohol_units}%
                      </div>
                    </div>
                  </>
                )}
                <Nutrition nutrition={offer.nutrition} refObj={refNnutritionInfo} />
                <OtherProperties properties={offer.attributes} refObj={refOther} />
                <Disclaimer />
              </div>
              <div className="h-24" />
              <PopoverZoom isShow={isShowProductZoom}>
                <div
                  className="product-icon-close icon icon-close c-t99 d-flex flex-center cursor-pointer"
                  onClick={() => setIsShowProductZoom(false)}
                />
                <PopoverZoomSlider
                  images={offer.images}
                  onClose={() => setIsShowProductZoom(false)}
                />
              </PopoverZoom>
            </>
          )
        ) : (
          <NotStocking />
        )}
      </div>
    </>
  );
});
