import { CompanyName, CompanyConfig } from './interface';
import { JiffyConfig } from './configs/Jiffy';
import { BaqalConfig } from './configs/Baqal';
import { SwifftConfig } from './configs/Swifft';

class Company {
  private readonly currentCompany: CompanyName;
  private readonly defaultCompany: CompanyName = CompanyName.Jiffy;
  private readonly configs: Record<CompanyName, CompanyConfig> = {
    [CompanyName.Jiffy]: JiffyConfig,
    [CompanyName.Baqal]: BaqalConfig,
    [CompanyName.Swifft]: SwifftConfig,
  };

  constructor() {
    let currentCompany: CompanyName = sessionStorage.getItem('companyName') as CompanyName;
    if (!this.configs[currentCompany]) currentCompany = process.env.REACT_APP_COMPANY as CompanyName;
    if (!this.configs[currentCompany]) currentCompany = this.defaultCompany;
    this.currentCompany = currentCompany;
  }

  get config(): CompanyConfig {
    return this.configs[this.currentCompany];
  }

  get name(): CompanyName {
    return this.currentCompany;
  }

  get isHidePaymentMethodsPage(): boolean {
    return [CompanyName.Baqal, CompanyName.Swifft].includes(this.currentCompany);
  }

  get isDisableFreshChat(): boolean {
    return [CompanyName.Baqal].includes(this.currentCompany);
  }

  get isHideFavourites(): boolean {
    return [CompanyName.Baqal, CompanyName.Swifft].includes(this.currentCompany);
  }

  get isDisableLoyaltyProgram(): boolean {
    return [CompanyName.Baqal].includes(this.currentCompany);
  }

  get isHideMapLegend(): boolean {
    return ![CompanyName.Jiffy].includes(this.currentCompany);
  }

  get isShowTaxes(): boolean {
    return [CompanyName.Swifft].includes(this.currentCompany);
  }

  get isExceptionAlcohol(): boolean {
    return ![CompanyName.Baqal].includes(this.currentCompany);
  }

  get isHideTracking(): boolean {
    return [CompanyName.Swifft].includes(this.currentCompany);
  }

  get hasAddress2(): boolean {
    return [CompanyName.Baqal].includes(this.currentCompany);
  }

  get isTrimTrailingZeros(): boolean {
    return ![CompanyName.Baqal].includes(this.currentCompany);
  }

  get isShowRateExperience(): boolean {
    return ![CompanyName.Swifft].includes(this.currentCompany);
  }

  isCompanyNameExist(name: string): boolean {
    return this.configs[name as CompanyName] !== undefined;
  }

  isIncludedIn(nameList: string[]): boolean {
    return nameList.includes(this.currentCompany);
  }
}

export const company = new Company();
