import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import Popover from '../../../components/Popover';
import React from 'react';
import { ZeroDeviceIdPopoverProps } from '../interface';
import { company } from '../../../company/Company';

const ZeroDeviceIdPopover = observer((props: ZeroDeviceIdPopoverProps) => {
  const { isZeroDeviceId, onDismissPopover } = props;
  const { t } = useTranslation();

  return (
    <Popover isShow={isZeroDeviceId} onBackdropDismiss={onDismissPopover}>
      <div className="fs-21">{t('errors:unknown')}</div>

      <div className="fs-14 lh-20 mt-20">
        <Trans i18nKey="errors:zeroDeviceId">
          Please contact us by email directly
          <a
            href={`mailto:${company.config.email}`}
            className="c-blue"
            target="_blank"
            rel="noreferrer"
          >
            {company.config.email}
          </a>
          or call at +44 330 808 73 77
          <br />
          Tell the operator the error code: 000
        </Trans>
      </div>

      <div className="button _primary w-100p mt-24" onClick={onDismissPopover}>
        {t('understood')}
      </div>
    </Popover>
  );
});


export default ZeroDeviceIdPopover;
