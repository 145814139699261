import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Popover from './Popover';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';
import Dialog from './Dialog';

export default observer(() => {
  const { t } = useTranslation();
  const handleHideServicePopover = () => mainStore.setIsShowServiceFeePopover(false);

  const component = (
    <>
      <div className=" fs-21 c-black">{t('servicePopover:caption')}</div>

      <div className="mt-24 fs-14 c-black lh-20">
        <Trans i18nKey="servicePopover:description">
          <p>
            We do our absolute best to invest our profits into top quality Jiffy people, services and equipment — for you.
          </p>

          <p>
            Did you know that small orders cost as much as large ones to deliver?
          </p>

          <p>
            So to maintain our quality of service on bite-size orders, a small service fee has been added to small orders.
          </p>
        </Trans>
      </div>

      <div className="mt-24 fs-14 d-flex align-items-center">
        <div className="mr-4 icon-attention icon" />

        <div>{t('servicePopover:feeMayChange')}</div>
      </div>

      <div className="h-16" />
      <div className="mt-16 d-flex align-items-end justify-content-between c-dgray  fs-14">
        <div>{t('cart')}</div>
        <div>{t('etaPopover:serviceFee')} </div>
      </div>
      <div className="h-1 pe-n my-8 c-bg-linegray" />
      <div className="d-flex align-items-end justify-content-between c-black  fs-14">
        <div>{t('from')} {mainStore.addCurrencySymbol(0)}</div>
        <div>{mainStore.addCurrencySymbol(orderStore.fee.serviceFeeShippingPounds)}</div>
      </div>
      <div className="d-flex align-items-end justify-content-between c-black  fs-14 mt-4">
        <div>
          {t('from')} {mainStore.addCurrencySymbol(orderStore.fee.serviceFeeThresholdPounds)}
        </div>
        <div>{t('none')}</div>
      </div>

      <div className="button _primary w-100p mt-24" onClick={handleHideServicePopover}>
        {t('servicePopover:gotIt')}
      </div>
    </>
  );

  if (mainStore.isDesktop) {
    return (
      <Dialog show={mainStore.isShowServiceFeePopover} onBackdrop={handleHideServicePopover}>
        <div className="h-24"/>
        {component}
      </Dialog>
    );
  }

  return (
    <Popover
      isShow={mainStore.isShowServiceFeePopover}
      onBackdropDismiss={handleHideServicePopover}
    >
      {component}
    </Popover>
  );
});
