import htmlClasses from 'html-classes';

export interface IconProps {
  type: 'heart' | 'heart-dark' | 'cart' | 'lock' | 'tesco' | 'faq' | 'jiffy-points' | 'attention' | 'profile' | 'bag' | 'bell' | 'chat' | 'profile-swifft' | 'bag-swifft' | 'bell-swifft' | 'favorite-swifft' | 'fchat' | 'coins';
  className?: string;
  size?: number;
}

const Icon = (props: IconProps) => {
  const { className, type, size = 20 } = props;

  return (
    <svg
      name={type}
      role="icon"
      width={size}
      height={size}
      className={htmlClasses('icon', className)}
    >
      <use xlinkHref={`/iconsSprite.svg#${type}`} width={size} height={size} />
    </svg>
  );
};

export default Icon;
