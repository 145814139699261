import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { ALERT_DURATION } from '../config';
import { mainStore } from '../stores/MainStore';
import htmlClasses from 'html-classes';

export default observer(() => {
  const handleDismiss = () => {
    const alert = mainStore.popAlert();
    if (alert && alert.onDismiss) alert.onDismiss();
  };

  useEffect(() => {
    if (!mainStore.alerts.length) return;
    const timeout = setTimeout(() => {
      mainStore.popAlert();
    }, ALERT_DURATION);
    return () => clearTimeout(timeout);
  });

  if (!mainStore.alerts.length) return <></>;

  return (
    <>
      {mainStore.alerts.slice(-1).map((item) => (
        <div
          className={htmlClasses(
            `alert _fixed _${item.type}`, item.className, 'cursor-pointer',
            { 'desktop-alert': mainStore.isDesktop, }
          )}
          key={item.id}
          onClick={handleDismiss}
          dangerouslySetInnerHTML={{ __html: item.message }}
        />
      ))}
    </>
  );
});
