import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import Popover from './Popover';
import { userStore } from '../stores/UserStore';
import ExternalLink from './ExternalLink';
import { ETAPaymentMethod } from '../api/ETA';
import { company } from '../company/Company';
import { CompanyName } from '../company/interface';

type PopoverChangeAddressProps = {
  isShow: boolean;
  onDismiss: () => void;
  type: 'missingItems' | 'notDelivered';
  paymentMethod: ETAPaymentMethod;
};

export default observer(({ isShow, onDismiss, type, paymentMethod }: PopoverChangeAddressProps) => {
  const { t } = useTranslation();

  return (
    <Popover isShow={isShow} onBackdropDismiss={onDismiss}>
      <div className="fs-21">
        {type === 'notDelivered'
          ? t('orderPage:orderWasNotDelivered')
          : t('orderPage:someItemsMissing')}
      </div>
      <div className="fs-14 lh-19 mt-15 ">
        {t('sorry')}
        {userStore.personalData.firstName ? ` ${userStore.personalData.firstName}` : ''},{' '}
        {type === 'notDelivered'
          ? t('orderPage:weCouldntDeliverButRefund')
          : t(
            `orderPage:weCouldntDeliverButRefundMissingItems${paymentMethod === ETAPaymentMethod.Cash ? 'Cash' : ''}`)}
      </div>
      {paymentMethod !== ETAPaymentMethod.Cash && (
        <>
          <div className="h-1 c-bg-tf2 mt-18" />
          <div className="fs-14 lh-19  mt-18">{t('orderPage:howSoonRefund')}</div>
          <div className="fs-12 lh-16 mt-6 c-mgray">{t('orderPage:weWillRefundYourMoney')}</div>
          {type === 'missingItems' && (
            <>
              <div className="fs-14 lh-19  mt-10">
                {t('orderPage:doeThisHappenOften')}
              </div>
              <div className="fs-12 lh-16 mt-6 c-mgray">
                {t('orderPage:WeAreWorkingToMakeItReliable')}
              </div>
            </>
          )}
        </>
      )}
      {company.isIncludedIn([CompanyName.Swifft]) ? null :
        (<div className="d-flex align-items-center justify-content-end mt-24">
          {company.isIncludedIn([CompanyName.Baqal]) ? (
            <ExternalLink
              className="h-40 mr-20 px-10 lh-40 c-blue fs-14 fw-500"
              href="https://wa.me/96562222892"
              targetBlank
            >
              {t('getHelp')}
            </ExternalLink>
          ) : (
            <Link className="h-40 mr-20 px-10 lh-40 c-blue fs-14 fw-500" to="/chat">
              {t('getHelp')}
            </Link>
          )}
          <div className="button _primary w-85 h-40 fs-14" onClick={onDismiss}>
            {t('ok')}
          </div>
        </div>)
      }
    </Popover>
  );
});
