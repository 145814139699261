import React, { useLayoutEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { mainStore } from '../stores/MainStore';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProductList from '../components/ProductList';
import { catalogStore } from '../stores/CatalogStore';
import NotFound from '../assets/img/not_found.svg';
import { CategoriesSuggestion } from './Search';
import { SearchCatsResponse } from '../api/Catalog';
import { desktopStore } from '../stores/DesktopStore';

export default observer(() => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { query } = useParams<{ query: string }>();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (!mainStore.isDesktop) {
      history.replace('/');
    }
    mainStore.sendToRN('analytics', {
      name: 'Catalog: search visited',
      params: {
        source: 'direct',
      },
    });
    //eslint-disable-next-line
  }, []);

  const handleSelectCategory = (category: SearchCatsResponse) => {
    catalogStore.updateSearchHistory(category.name);
    if (category.parentCatID) {
      mainStore.sendToRN('analytics', {
        name: 'Catalog: category visited',
        params: {
          lvl3_category_id: category.id,
          source: 'search',
        },
      });
    }
    history.push({
      pathname: `/category/${category.parentCatID ? category.parentCatID : category.id}`,
      state: { scrollToId: category.parentCatID ? String(category.id) : null },
    });
  };

  useLayoutEffect(() => {
    const hiddenTop = document.querySelector('.desktop-top-hidden');
    hiddenTop && hiddenTop.scrollIntoView({ behavior: 'smooth', block: 'start' });

    return () => desktopStore.setSearchBarValue('');
  }, []);

  useLayoutEffect(() => {
    if (query) {
      setIsLoading(true);
      catalogStore
        .requestSearch(query, 'typein', 0)
        .catch(() => void 0)
        .finally(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location]);

  const handleShowNotFindProductPopover = () => mainStore.setIsNotFindProductPopover(true);

  return (
    <>
      {isLoading ? (
        <div className="h-min-550">
          <div className="spinner mx-auto mt-20" />
        </div>
      ) : catalogStore.searchProductList.length > 0 ? (
        <>
          <div className="desktop-top-hidden" />
          <CategoriesSuggestion onSelectCategory={handleSelectCategory} showHistory={false} />
          <ProductList productList={catalogStore.searchProductList} source="category" />
          <div
            className="button _bordered fw-500 w-100p mt-10"
            onClick={handleShowNotFindProductPopover}
          >
            {t('categoryPage:canNotFindItems')}
          </div>
        </>
      ) : (
        <>
          <div className="mt-35">
            <img className="h-130 mx-auto d-block" src={NotFound} alt="" />
            <div className="fs-21 fw-500 w-230 text-center mt-24 mx-auto">
              {t('searchPage:productNotExist')}
            </div>
          </div>
          <div className="h-24" />
        </>
      )}
    </>
  );
});
