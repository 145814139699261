import htmlClasses from 'html-classes';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import Counter from './Counter';
import noProductImg from '../assets/img/no_picture_product.jpg';
import { catalogStore } from '../stores/CatalogStore';
import { mainStore } from '../stores/MainStore';
import { desktopStore } from '../stores/DesktopStore';
import { PRODUCT_SELLABLE_LIMIT, BUNDLE_SELLABLE_LIMIT } from '../config';
import useIntersection from './IntersectionHook';
import { orderStore } from '../stores/OrderStore';
import ProductItemBadgesList from './Badges/ProductItemBadgesList/ProductItemBadgesList';
import { PriceLockBadge } from './Badges/PriceLockBadge/PriceLockBadge';
import { TescoBadge } from './Badges/TescoBadge/TescoBadge';
import { Product, ProductOffer } from '../types/Product/interface';
import Icon from './ui/Icon/Icon';
import { company } from '../company/Company';

type ProductItemProps = {
  product: Product;
  source: string;
  outStockCount?: number;
  isWithinSlider?: boolean;
  lvl3category?: number;
};

type FooterProps = {
  offer: ProductOffer;
  source: string;
  isWithinSlider?: boolean;
  lvl3category?: number;
};

const Footer = observer(({ offer, source, isWithinSlider, lvl3category }: FooterProps) => {
  // const history = useHistory();
  const count = catalogStore.getCartItemCountById(offer.id);
  const { t } = useTranslation();
  const hasDiscount = offer.discountPrice > 0 || offer.promoRequiredQuantity > 0;
  const discountPriceFormatted = offer.discountPrice ? offer.discountPriceFormatted : offer.promoQuantityDiscountPriceFormatted;
  const handleChangeCount = (count: number, action: 'add' | 'remove') => {
    catalogStore.setCartItemCountByProduct(
      offer,
      count,
      action,
      source,
      source === 'category' ? lvl3category : undefined,
    );
    // if (!orderStore.etaCalculation) history.push({
    //   pathname: '/delivery-address',
    //   state: { isShowBackBtn: true },
    // });
  };

  useEffect(() => {
    if (count > offer.sellable) handleChangeCount(offer.sellable, 'remove');
    //eslint-disable-next-line
  }, []);

  const getLimit = (): number => {
    const limit = offer.bundle ? BUNDLE_SELLABLE_LIMIT : PRODUCT_SELLABLE_LIMIT;
    return offer.sellable > limit ? limit : offer.sellable;
  };

  return (
    <div className="product-item__footer">
      <div className="flex-shrink-0 d-flex justify-content-between align-items-baseline">
        {hasDiscount ? (
          <div
            className={htmlClasses('product-item__price _discount', {
              'd-flex flex-direction-column': offer.promoRequiredQuantity > 0,
            })}
          >
            {offer.isGrocery ? (
                <>
                  {offer.promoRequiredQuantity > 0 && (
                    <div className=" fs-10 lh-14 fw-400">
                      {t('from')} {offer.promoRequiredQuantity}
                      {t('pcs')}
                    </div>
                  )}
                  <PriceLockBadge offer={offer} className="mb-2" />
                  {mainStore.addCurrencySymbol(discountPriceFormatted)}
                  {offer.promoRequiredQuantity === 0 && (
                    <s className="product-item__price-old">{mainStore.addCurrencySymbol(
                      offer.priceFormatted)}</s>
                  )}
                </>
              )
              : mainStore.addCurrencySymbol(discountPriceFormatted)}
          </div>
        ) : (
          <div className="product-item__price">
            {offer.isGrocery && (
              <PriceLockBadge offer={offer} className="mb-2" />
            )}

            <div className={htmlClasses(
              'product-item__price', { '_discount': offer.properties.tesco_price_match })}>
              {mainStore.addCurrencySymbol(offer.priceFormatted)}
            </div>
          </div>
        )}
      </div>

      {offer.isGrocery ? (
        <Counter
          className={hasDiscount || offer.properties.tesco_price_match ? '_discount' : ''}
          value={count}
          onChange={handleChangeCount}
          limit={getLimit()}
          height={36}
          addToCartCaption={t(isWithinSlider ? 'add' : 'addToBasket')}
        />
      ) : (
        <div
          className={htmlClasses(
            'counter-wrap position-relative',
            hasDiscount || offer.properties.tesco_price_match ? '_discount' : '',
          )}
        >
          {getLimit() > 0 ? (
            <Link
              className="counter-add position-relative z-1 h-36"
              to={{ pathname: `/product/${offer.productId}`, state: { source: source } }}
            >
              {t(isWithinSlider ? 'add' : 'addToBasket')}
            </Link>
          ) : <div className="counter-add _sold-out h-36">{t('soldOut')}</div>}
        </div>
      )}
    </div>
  );
});

const FavouriteIcon = observer(({ product }: { product: Product }) => {
  const { pathname } = useLocation();
  const handleFavorite = () => {
    catalogStore.toggleFavorite(product, pathname.split('/')[1] || '');
    mainStore.sendToRN('hapticFeedback', {
      count: 1,
    });
  };

  if (!product || company.isHideFavourites) return <></>;

  return (
    <div className={htmlClasses(
      'product-item__favorite',
      catalogStore.favorites[product.id] ? 'c-red _filled' : 'c-tc7',
    )} onClick={handleFavorite}>
      <Icon type={catalogStore.favorites[product.id] ? 'heart-dark' : 'heart'} />
    </div>
  );
});

export default observer(
  ({ product, source, outStockCount, isWithinSlider, lvl3category }: ProductItemProps) => {
    const { t } = useTranslation();
    const refProductItem = useRef<HTMLDivElement>(null);
    const offer = product.offers[0];
    const analyticsData = useMemo(() => ({
      product_id: product.id,
      category_id: product.categoryId,
      lvl1_category_id: undefined,
      lvl2_category_id: product.parentCategoryId,
      source: source,
      price: mainStore.toFloat(product.priceFormatted),
      final_price: mainStore.toFloat(
        product.discountPrice ? product.discountPriceFormatted : product.priceFormatted),
      warehouse_code: orderStore.etaWarehouseCode,
    }), [source, product]);
    if (source && outStockCount === undefined) useIntersection(refProductItem, analyticsData);
    const sendAnalytic = () => {
      mainStore.sendAnalytics(['BI', 'analytics', 'firebase'], {
        name: 'Catalog: product page clicked',
        params: analyticsData,
      });
    };
    const handleProductClick = () => {
      sendAnalytic();
      desktopStore.setSavedSource(source);
      desktopStore.setSelectedId(String(product.id));
      desktopStore.setShowProduct(true);
    };

    if (outStockCount && outStockCount > 0)
      return (
        <div className="h-min-150 h-100p d-flex flex-center">
          <div className="text-center ff-feixen fw-500">
            <div className="fs-36 c-tc7 lh-1">
              {'+'}
              {outStockCount}
            </div>
            <div className="fs-12 lh-16 c-tc7 my-12 w-max-110 mx-auto">
              {t('categoryPage:productsSoldOut')}
            </div>
            <div className="c-blue">{t('seeAll')}</div>
          </div>
        </div>
      );

    return (
      <div
        ref={refProductItem}
        className={htmlClasses(
          'product-item',
          { 'br-5': mainStore.isDesktop },
          { 'w-100p h-100p': source === 'discounts_slider' && mainStore.isDesktop },
        )}
      >
        <FavouriteIcon product={product} />

        <ProductItemBadgesList offer={offer} />

        <div
          className={htmlClasses('product-item__img', { 'o-4': !product.sellable })}
          style={{ backgroundImage: `url(${product.previewImageThumb || noProductImg})` }}
        >
          {!mainStore.isDesktop ? (
            <>
              <Link
                className="link-abs"
                to={{ pathname: `/product/${product.id}`, state: { source: source } }}
                onClick={sendAnalytic}
              />
              {product.isGrocery && (
                <TescoBadge offer={offer} size="small" className="product-item__tesco-badge" />
              )}
            </>
          ) : (
            <div className="link-abs cursor-pointer" onClick={handleProductClick} />
          )}

          {product.isGrocery && (
            <TescoBadge offer={offer} size="small" className="product-item__tesco-badge" />
          )}
        </div>
        {!mainStore.isDesktop ? (
          <Link
            className="product-item__name"
            to={{ pathname: `/product/${product.id}`, state: { source: source } }}
            onClick={sendAnalytic}
          >
            {product.name}
          </Link>
        ) : (
          <div className="product-item__name cursor-pointer" onClick={handleProductClick}>
            {product.name}
          </div>
        )}

        {product.isGrocery && (
          <div
            className={htmlClasses(
              'product-item__price-unit',
              { 'mt-4': !isWithinSlider },
            )}
          >
            {product.promoQuantityDiscountPrice > 0
              ? `${mainStore.addCurrencySymbol(product.priceFormatted)}/`
              : ''}
            {product.pricePerUnit}
          </div>
        )}

        <Footer offer={offer} source={source} isWithinSlider={isWithinSlider} lvl3category={lvl3category} />
      </div>
    );
  });
