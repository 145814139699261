import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { catalogStore } from '../../stores/CatalogStore';
import { mainStore } from '../../stores/MainStore';
import ProductItem from '../ProductItem';
import { ProductListProps } from './interface';
import {
  PRODUCT_ITEM_WRAPPER_CLASSES,
  PRODUCT_LIST_WRAPPER_CLASSES,
  SkeletonsQuantity,
} from './constants';
import ProductListSkeleton from './ProductListSkeleton';
import htmlClasses from 'html-classes';
import { Product } from '../../types/Product/interface';

const ProductList = observer((props: ProductListProps) => {
  const {
    productList,
    source,
    categoryId,
    onReady,
    isLoading,
    hideSoldOutInfo,
    showThreeInLine,
    notGroupOutOfStock = false,
  } = props;
  const { t } = useTranslation();
  const [inStockProductList, setInStockProductList] = useState<Product[] | null>(null);
  const [outStockProductList, setOutStockProductList] = useState<Product[] | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const handleExpand = () => {
    setIsExpanded(true);
    catalogStore.addProductsOutOfStockExpandedList(String(categoryId || source));
    mainStore.sendAnalytics(['BI', 'analytics'], {
      name: 'Sold out card: See all',
      params: {
        lvl3_category_id: categoryId,
        quantity: outStockProductList ? outStockProductList.length : 0,
      },
    });
  };

  useEffect(() => {
    if (!productList.length || source === 'favorites') {
      return;
    }

    const inStockList: Product[] = [];
    const outStockList: Product[] = [];

    for (let i = 0; i < productList.length; i++) {
      if (productList[i].sellable > 0) inStockList.push(productList[i]);
      else outStockList.push(productList[i]);
    }

    setInStockProductList(inStockList);
    setOutStockProductList(outStockList);
    setIsExpanded(
      catalogStore.productsOutOfStockExpandedList.indexOf(
        String(categoryId || source)) !== -1 || outStockList.length === 1,
    );
  }, [productList, source, categoryId]);

  useEffect(() => {
    if (inStockProductList === null || outStockProductList === null || !onReady || isReady) {
      return;
    }

    onReady();
    setIsReady(true);
  }, [inStockProductList, outStockProductList, onReady, isReady]);

  const getQuantityOfSkeletons = (itemsListCount: number): number => {
    if (itemsListCount === 0) {
      return showThreeInLine ? SkeletonsQuantity.Parity + 2 : SkeletonsQuantity.Parity;
    }

    const isParity = itemsListCount % 2 === 0;

    if (showThreeInLine) {
      return (SkeletonsQuantity.Parity + 2) + (isParity ? 0 : SkeletonsQuantity.NonParity);
    }

    return isParity ? SkeletonsQuantity.Parity : SkeletonsQuantity.NonParity;
  };

  if (!productList.length && !isLoading) {
    if (hideSoldOutInfo) {
      return <></>;
    }

    return <div className="text-center fs-14 c-gray">{t('soldOutAll')}</div>;
  }

  const itemWrapperClass = htmlClasses(
    PRODUCT_ITEM_WRAPPER_CLASSES, { 'threeInOne': showThreeInLine });

  if (isLoading && productList.length === 0) {
    return (
      <div className={PRODUCT_LIST_WRAPPER_CLASSES}>
        <ProductListSkeleton className={itemWrapperClass} quantity={getQuantityOfSkeletons(0)} />
      </div>
    );
  }

  if (source === 'favorites' || notGroupOutOfStock) {
    return (
      <div className={PRODUCT_LIST_WRAPPER_CLASSES}>
        {productList.map((item) => (
          <div className={itemWrapperClass} key={item.id}>
            <ProductItem product={item} source={source} />
          </div>
        ))}

        {isLoading && (
          <ProductListSkeleton
            className={itemWrapperClass}
            quantity={getQuantityOfSkeletons(productList.length)}
          />
        )}
      </div>
    );
  }

  if ((inStockProductList === null || outStockProductList === null)) {
    return <></>;
  }

  if (source === 'category' && mainStore.isDesktop) {
    return (
      <div className="desktop__grid-wrapper mb-20">
        {inStockProductList.map((item) => (
          <div className="desktop__grid-item" key={item.id}>
            <ProductItem product={item} source={source} />
          </div>
        ))}

        {outStockProductList.length > 1 && !isExpanded && !isLoading && (
          <div className="desktop__grid-item" onClick={handleExpand}>
            <ProductItem
              product={outStockProductList[0]}
              source={source}
              outStockCount={outStockProductList.length}
            />
          </div>
        )}

        {isExpanded && !isLoading && outStockProductList.map((item) => (
          <div className="desktop__grid-item" key={item.id}>
            <ProductItem product={item} source={source} />
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={PRODUCT_LIST_WRAPPER_CLASSES}>
      {inStockProductList.map((item) => (
        <div
          className={itemWrapperClass}
          key={item.id}
        >
          <ProductItem product={item} source={source} lvl3category={categoryId} />
        </div>
      ))}

      {outStockProductList.length > 1 && !isExpanded && !isLoading && (
        <div
          className={itemWrapperClass}
          onClick={handleExpand}
        >
          <ProductItem
            product={outStockProductList[0]}
            source={source}
            outStockCount={outStockProductList.length}
          />
        </div>
      )}

      {isExpanded && !isLoading && outStockProductList.map((item) => (
        <div
          className={itemWrapperClass}
          key={item.id}
        >
          <ProductItem product={item} source={source} />
        </div>
      ))}

      {/**
       We need to calculate quantity of skeletons only by "inStockProductList" because
       items from "outStockProductList" will be hidden during loading
       */}
      {isLoading && (
        <ProductListSkeleton
          className={itemWrapperClass}
          quantity={getQuantityOfSkeletons(inStockProductList.length)}
        />
      )}
    </div>
  );
});

export default ProductList;
