import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionFlag } from '../api/Customer';
import InputSwitch from '../components/InputSwitch';
import InputText from '../components/InputText';
import PageHeader from '../components/PageHeader';
import Popover from '../components/Popover';
import { userStore } from '../stores/UserStore';
import { mainStore } from '../stores/MainStore';
import PopoverSubscribe from '../components/PopoverSubscribe';
import htmlClasses from 'html-classes';
import Dialog from '../components/Dialog';

export default observer(() => {
  const { t } = useTranslation();
  const [isShowPopoverChangeEmail, setIsShowPopoverChangeEmail] = useState(false);
  const [isFlagChange, setIsFlagChange] = useState(false);
  const [emailVal, setEmailVal] = useState(userStore.personalData.email || '');
  const [isEmailValid, setIsEmailValid] = useState(!!userStore.personalData.email?.length);
  const [personalisedPush, setPersonalisedPush] = useState(
    userStore.personalData.subscriptionFlags['personalised']?.push || false,
  );
  const [personalisedEmail, setPersonalisedEmail] = useState(
    userStore.personalData.subscriptionFlags['personalised']?.email || false,
  );
  const [discountsPush, setDiscountsPush] = useState(
    userStore.personalData.subscriptionFlags['discounts']?.push || false,
  );
  const [discountsEmail, setDiscountsEmail] = useState(
    userStore.personalData.subscriptionFlags['discounts']?.email || false,
  );
  const [promotionsPush, setPromotionsPush] = useState(
    userStore.personalData.subscriptionFlags['third_party']?.push || false,
  );
  const [promotionsEmail, setPromotionsEmail] = useState(
    userStore.personalData.subscriptionFlags['third_party']?.email || false,
  );
  const [lastEmailFlag, setLastEmailFlag] = useState('');
  const [log, setLog] = useState('');
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailVal(e.currentTarget.value);
    setIsEmailValid(mainStore.validateEmail(e.currentTarget.value));
  };
  const handlePersonalisedPush = () => {
    setPersonalisedPush(!personalisedPush);
    setIsFlagChange(true);
  };
  const handlePersonalisedEmail = () => {
    if (!emailVal) {
      setLastEmailFlag('personalised');
      setIsShowPopoverChangeEmail(true);
      return;
    }
    setPersonalisedEmail(!personalisedEmail);
    setIsFlagChange(true);
  };
  const handleDiscountsPush = () => {
    setDiscountsPush(!discountsPush);
    setIsFlagChange(true);
  };
  const handleDiscountsEmail = () => {
    if (!emailVal) {
      setLastEmailFlag('discounts');
      setIsShowPopoverChangeEmail(true);
      return;
    }
    setDiscountsEmail(!discountsEmail);
    setIsFlagChange(true);
  };
  const handlePromotionsPush = () => {
    setPromotionsPush(!promotionsPush);
    setIsFlagChange(true);
  };
  const handlePromotionsEmail = () => {
    if (!emailVal) {
      setLastEmailFlag('third_party');
      setIsShowPopoverChangeEmail(true);
      return;
    }
    setPromotionsEmail(!promotionsEmail);
    setIsFlagChange(true);
  };
  const handlePopoverChangeEmailDismiss = () => {
    setEmailVal(userStore.personalData.email || '');
    setIsEmailValid(!!userStore.personalData.email?.length);
    setIsShowPopoverChangeEmail(false);
    setLastEmailFlag('');
  };
  const handlePopoverChangeEmailShow = () => {
    setEmailVal(userStore.personalData.email || '');
    setIsEmailValid(!!userStore.personalData.email?.length);
    setIsShowPopoverChangeEmail(true);
  };
  const syncRN = (): Promise<boolean> => {
    if (!window.ReactNativeWebView) return Promise.resolve(true);
    return new Promise<boolean>((resolve) => {
      mainStore.sendToRN(
        'subscribeToNotifications',
        {
          email: emailVal.trim(),
          isPush: personalisedPush || discountsPush || promotionsPush,
          isEmail: personalisedEmail || discountsEmail || promotionsEmail,
        },
        () => resolve(true),
        () => resolve(false),
      );
    });
  };
  const handleEmailSave = async (needEmailSave: boolean) => {
    setIsShowPopoverChangeEmail(false);
    setIsFlagChange(false);
    let newPersonalisedPush = personalisedPush;
    let newDiscountsPush = discountsPush;
    let newPromotionsPush = promotionsPush;
    let newLog = log;
    const isSuccess = await syncRN();
    if (isSuccess) {
      newLog += '<strong>Update in RN: success</strong><br>';
      mainStore.setIsBlockedPush(false);
    } else {
      newLog += '<strong>Update in RN: failure</strong><br>';
      mainStore.setIsBlockedPush(true);
      setPersonalisedPush(false);
      setDiscountsPush(false);
      setPromotionsPush(false);
      newPersonalisedPush = false;
      newDiscountsPush = false;
      newPromotionsPush = false;
      newLog += '<strong>Change flags</strong><br>';
      newLog += `personalised.push: ${newPersonalisedPush}<br>`;
      newLog += `discounts.push: ${newDiscountsPush}<br>`;
      newLog += `third_party.push: ${newPromotionsPush}<br>`;
    }
    const flags: SubscriptionFlag[] = [
      {
        name: 'personalised',
        push: newPersonalisedPush,
        email: personalisedEmail,
      },
      {
        name: 'discounts',
        push: newDiscountsPush,
        email: discountsEmail,
      },
      {
        name: 'third_party',
        push: newPromotionsPush,
        email: promotionsEmail,
      },
    ];
    if (userStore.isAuthorized) {
      try {
        await userStore.updateSubscription(flags, 'Notification settings');
        if (needEmailSave) {
          await userStore.updatePersonalEmail(emailVal.trim()).catch(
            (error) => error && console.error(error));
        }
      } catch (error) {
        error && console.error(error);
      }
    } else {
      try {
        await userStore.updateDeviceData(userStore.fullName, emailVal.trim(), flags);
        await userStore.updateSubscription(flags, 'Notification settings');
      } catch (error) {
        error && console.error(error);
      }
    }
    newLog += '<strong>Update subscription: subscription/update</strong><br>';
    newLog += `personalised.push: ${newPersonalisedPush}<br>`;
    newLog += `personalised.email: ${personalisedEmail}<br>`;
    newLog += `discounts.push: ${newDiscountsPush}<br>`;
    newLog += `discounts.email: ${discountsEmail}<br>`;
    newLog += `third_party.push: ${newPromotionsPush}<br>`;
    newLog += `third_party.email: ${promotionsEmail}<br>`;
    setLog(newLog);
  };
  const handleSuccessSubscribe = () => {
    setPersonalisedPush(userStore.personalData.subscriptionFlags['personalised']?.push || false);
    setPersonalisedEmail(userStore.personalData.subscriptionFlags['personalised']?.email || false);
    setDiscountsPush(userStore.personalData.subscriptionFlags['discounts']?.push || false);
    setDiscountsEmail(userStore.personalData.subscriptionFlags['discounts']?.email || false);
    setPromotionsPush(userStore.personalData.subscriptionFlags['third_party']?.push || false);
    setPromotionsEmail(userStore.personalData.subscriptionFlags['third_party']?.email || false);
  };

  useEffect(() => {
    if (!isFlagChange) return;
    handleEmailSave(false).catch((error) => error && console.error(error));
    //eslint-disable-next-line
  }, [isFlagChange]);

  useEffect(() => {
    if (!isShowPopoverChangeEmail) {
      if (!lastEmailFlag) return;
      switch (lastEmailFlag) {
        case 'personalised':
          handlePersonalisedEmail();
          break;
        case 'discounts':
          handleDiscountsEmail();
          break;
        case 'third_party':
          handlePromotionsEmail();
          break;
      }
      setLastEmailFlag('');
    }
    //eslint-disable-next-line
  }, [isShowPopoverChangeEmail]);

  useLayoutEffect(() => {
    let newLog = log;
    let newPersonalisedPush = personalisedPush;
    let newPersonalisedEmail = personalisedEmail;
    let newDiscountsPush = discountsPush;
    let newDiscountsEmail = discountsEmail;
    let newPromotionsPush = promotionsPush;
    let newPromotionsEmail = promotionsEmail;
    userStore
      .requestPersonalData()
      .then(() => {
        setEmailVal(userStore.personalData.email || '');
        setIsEmailValid(!!userStore.personalData.email?.length);
        newPersonalisedPush =
          userStore.personalData.subscriptionFlags['personalised']?.push || false;
        newPersonalisedEmail =
          userStore.personalData.subscriptionFlags['personalised']?.email || false;
        newDiscountsPush = userStore.personalData.subscriptionFlags['discounts']?.push || false;
        newDiscountsEmail = userStore.personalData.subscriptionFlags['discounts']?.email || false;
        newPromotionsPush = userStore.personalData.subscriptionFlags['third_party']?.push || false;
        newPromotionsEmail =
          userStore.personalData.subscriptionFlags['third_party']?.email || false;
        newLog += '<strong>Get personal data: customer/get</strong><br>';
        newLog += `personalised.push: ${newPersonalisedPush}<br>`;
        newLog += `personalised.email: ${newPersonalisedEmail}<br>`;
        newLog += `discounts.push: ${newDiscountsPush}<br>`;
        newLog += `discounts.email: ${newDiscountsEmail}<br>`;
        newLog += `third_party.push: ${newPromotionsPush}<br>`;
        newLog += `third_party.email: ${newPromotionsEmail}<br>`;
      })
      .catch((error) => error && console.error(error))
      .finally(() => {
        if (!window.ReactNativeWebView) {
          setPersonalisedPush(newPersonalisedPush);
          setPersonalisedEmail(newPersonalisedEmail);
          setDiscountsPush(newDiscountsPush);
          setDiscountsEmail(newDiscountsEmail);
          setPromotionsPush(newPromotionsPush);
          setPromotionsEmail(newPromotionsEmail);
          setLog(newLog);
          return;
        }
        mainStore.sendToRN('getPermission', {}, (e) => {
          if (e.permission) {
            newLog += '<strong>Get system permission for push: allowed</strong><br>';
            if (
              !newPersonalisedPush &&
              !newDiscountsPush &&
              !newPromotionsPush &&
              mainStore.isBlockedPush
            ) {
              mainStore.setIsBlockedPush(false);
              newPersonalisedPush = true;
              newDiscountsPush = true;
              newPromotionsPush = true;
              newLog += '<strong>Change flags</strong><br>';
              newLog += `personalised.push: ${newPersonalisedPush}<br>`;
              newLog += `discounts.push: ${newDiscountsPush}<br>`;
              newLog += `third_party.push: ${newPromotionsPush}<br>`;
            }
          } else {
            newLog += '<strong>Get system permission for push: blocked</strong><br>';
            mainStore.setIsBlockedPush(true);
            newPersonalisedPush = false;
            newDiscountsPush = false;
            newPromotionsPush = false;
            newLog += '<strong>Change flags</strong><br>';
            newLog += `personalised.push: ${newPersonalisedPush}<br>`;
            newLog += `discounts.push: ${newDiscountsPush}<br>`;
            newLog += `third_party.push: ${newPromotionsPush}<br>`;
          }
          const flags: SubscriptionFlag[] = [
            {
              name: 'personalised',
              push: newPersonalisedPush,
              email: personalisedEmail,
            },
            {
              name: 'discounts',
              push: newDiscountsPush,
              email: discountsEmail,
            },
            {
              name: 'third_party',
              push: newPromotionsPush,
              email: promotionsEmail,
            },
          ];
          mainStore.sendToRN('subscribeToNotifications', {
            email: emailVal.trim(),
            isPush: newPersonalisedPush || newDiscountsPush || newPromotionsPush,
            isEmail: personalisedEmail || discountsEmail || promotionsEmail,
          });
          userStore
            .updateSubscription(flags, 'Notification settings')
            .catch((error) => error && console.error(error))
            .finally(() => {
              mainStore.checkAndSetSubscribePopover('all');
            });
          setPersonalisedPush(newPersonalisedPush);
          setPersonalisedEmail(newPersonalisedEmail);
          setDiscountsPush(newDiscountsPush);
          setDiscountsEmail(newDiscountsEmail);
          setPromotionsPush(newPromotionsPush);
          setPromotionsEmail(newPromotionsEmail);
          newLog += '<strong>Update subscription: subscription/update</strong><br>';
          newLog += `personalised.push: ${newPersonalisedPush}<br>`;
          newLog += `personalised.email: ${newPersonalisedEmail}<br>`;
          newLog += `discounts.push: ${newDiscountsPush}<br>`;
          newLog += `discounts.email: ${newDiscountsEmail}<br>`;
          newLog += `third_party.push: ${newPromotionsPush}<br>`;
          newLog += `third_party.email: ${newPromotionsEmail}<br>`;
          setLog(newLog);
        });
      });
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mainStore.inBackground) return;
    const timeout = setTimeout(() => {
      setPersonalisedPush(userStore.personalData.subscriptionFlags['personalised']?.push || false);
      setDiscountsPush(userStore.personalData.subscriptionFlags['discounts']?.push || false);
      setPromotionsPush(userStore.personalData.subscriptionFlags['third_party']?.push || false);
    }, 1000);
    return () => clearTimeout(timeout);
    //eslint-disable-next-line
  }, [mainStore.inBackground]);

  return (
    <>
      {!mainStore.isDesktop && <PageHeader title={t('notificationsSettings')} />}
      <div className={htmlClasses('scroll-layout h-100p', { 'pt-12 px-24': !mainStore.isDesktop })}>
        <div className="fs-14 lh-22">{t('contact_other')}</div>
        <div className="fs-12 lh-16 c-mgray mt-8 mb-16">{t('notificationsPage:announce')}</div>
        <div className="d-flex align-items-start justify-content-between">
          <div className="c-tc4 fs-12 mr-24">{t('email')}</div>
          <div
            className="d-flex align-items-start fs-14 cursor-pointer"
            onClick={handlePopoverChangeEmailShow}
          >
            {userStore.personalData.email || t('unknown')}
            <div className="icon icon-pen s-20 ml-5 c-dgreen d-flex flex-center fs-18" />
          </div>
        </div>
        <div className="d-flex align-items-start justify-content-between mt-8">
          <div className="c-tc4 fs-12 mr-24">{t('phone')}</div>
          <div className="fs-14 pr-24">
            {mainStore.prettyPhoneNumber(userStore.personalData.phone || '') || t('unknown')}
          </div>
        </div>
        <div className="fs-14 lh-22 mt-20">
          {t('notificationsPage:personalisedOffers.name')}
        </div>
        <div className="fs-12 lh-16 c-mgray mt-8 mb-16">
          {t('notificationsPage:personalisedOffers.text')}
        </div>
        {!mainStore.isDesktop && (
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={handlePersonalisedPush}
          >
            <InputSwitch checked={personalisedPush} />
            <div className="fs-14 c-black ml-8">{t('push')}</div>
          </div>
        )}
        <div
          className="d-flex align-items-center mt-10 cursor-pointer"
          onClick={handlePersonalisedEmail}
        >
          <InputSwitch checked={personalisedEmail} />
          <div className="fs-14 c-black ml-8">{t('email_other')}</div>
        </div>
        <div className="fs-14 lh-22 mt-30">
          {t('notificationsPage:discountsAndSpecialOffers.name')}
        </div>
        <div className="fs-12 lh-16 c-mgray mt-8 mb-16">
          {t('notificationsPage:discountsAndSpecialOffers.text')}
        </div>
        {!mainStore.isDesktop && (
          <div className="d-flex align-items-center cursor-pointer" onClick={handleDiscountsPush}>
            <InputSwitch checked={discountsPush} />
            <div className="fs-14 c-black ml-8">{t('push')}</div>
          </div>
        )}
        <div
          className="d-flex align-items-center mt-10 cursor-pointer"
          onClick={handleDiscountsEmail}
        >
          <InputSwitch checked={discountsEmail} />
          <div className="fs-14 c-black ml-8">{t('email_other')}</div>
        </div>
        <div className="fs-14 lh-22 mt-30">
          {t('notificationsPage:thirdPartyPromotions.name')}
        </div>
        <div className="fs-12 lh-16 c-mgray mt-8 mb-16">
          {t('notificationsPage:thirdPartyPromotions.text')}
        </div>
        {!mainStore.isDesktop && (
          <div className="d-flex align-items-center cursor-pointer" onClick={handlePromotionsPush}>
            <InputSwitch checked={promotionsPush} />
            <div className="fs-14 c-black ml-8">{t('push')}</div>
          </div>
        )}
        <div
          className="d-flex align-items-center mt-10 cursor-pointer"
          onClick={handlePromotionsEmail}
        >
          <InputSwitch checked={promotionsEmail} />
          <div className="fs-14 c-black ml-8">{t('email_other')}</div>
        </div>
        <div className="h-42" />
      </div>
      {process.env.REACT_APP_ENV !== 'production' && (
        <div
          className={htmlClasses('h-200 overflow-auto px-24 fs-10', {
            'my-20': !mainStore.isDesktop,
          })}
          dangerouslySetInnerHTML={{ __html: log }}
        />
      )}
      {!mainStore.isDesktop ? (
        <Popover
          isShow={isShowPopoverChangeEmail}
          onBackdropDismiss={handlePopoverChangeEmailDismiss}
        >
          <h2>{t('changeEmail')}</h2>
          <InputText
            className="mt-24"
            label={t('email')}
            value={emailVal}
            isValid={isEmailValid}
            isError={!!(emailVal.length && !isEmailValid)}
            onChange={handleEmailChange}
          />
          <div className="d-flex align-items-center justify-content-end mt-20">
            <div
              className="h-40 mr-20 px-10 lh-40 c-blue fs-14  fw-500 cursor-pointer"
              onClick={handlePopoverChangeEmailDismiss}
            >
              {t('cancel')}
            </div>
            <button
              className="button _primary w-120 h-40 fs-14"
            onClick={() => handleEmailSave(true).catch((error) => error && console.error(error))}
              disabled={!isEmailValid}
            >
              {t('Change')}
            </button>
          </div>
        </Popover>
      ) : (
        <Dialog show={isShowPopoverChangeEmail} onBackdrop={handlePopoverChangeEmailDismiss}>
          <div className="dialog__header">
            <h2>{t('changeEmail')}</h2>
          </div>
          <InputText
            className="mt-24"
            label={t('email')}
            value={emailVal}
            isValid={isEmailValid}
            isError={!!(emailVal.length && !isEmailValid)}
            onChange={handleEmailChange}
          />
          <div className="d-flex align-items-center justify-content-end mt-20">
            <div
              className="h-40 mr-20 px-10 lh-40 c-blue fs-14  fw-500 cursor-pointer"
              onClick={handlePopoverChangeEmailDismiss}
            >
              {t('cancel')}
            </div>
            <button
              className="button _primary w-120 h-40 fs-14"
              onClick={() => handleEmailSave(true).catch((error) => error && console.error(error))}
              disabled={!isEmailValid}
            >
              {t('Change')}
            </button>
          </div>
        </Dialog>
      )}
      <PopoverSubscribe source="notifications" onSuccess={handleSuccessSubscribe} />
    </>
  );
});
