import { mainStore } from '../stores/MainStore';
import { userStore } from '../stores/UserStore';
import { REQUEST_TIMEOUT, BI_API_ROOT } from '../config';
import axios from 'axios';
import { desktopStore } from '../stores/DesktopStore';
import { company } from '../company/Company';
import { orderStore } from '../stores/OrderStore';

const axiosInstance = axios.create({
  baseURL: BI_API_ROOT,
  timeout: REQUEST_TIMEOUT,
});

interface BIRequestsInterface {
  sendAnalytics(
    eventName: string, data: Record<string, any> | Record<string, any>[]): Promise<unknown>;
}

export const BIRequests: BIRequestsInterface = {
  sendAnalytics: (eventName, data) =>
    axiosInstance
      .post(
        'bi/event/',
        {
          eventName: eventName,
          properties: data,
          os: {
            platform: mainStore.appPlatform,
            version: mainStore.appVersion,
          },
          userId: userStore.personalData.id,
          clientSource: 'Site',
          deviceId: desktopStore.desktopDeviceId || '',
          whCode: orderStore.etaWarehouseCode,
        },
        {
          headers: {
            'api-key': process.env.REACT_APP_BI_API_KEY as string,
            'x-company-id': company.config.id,
          },
        },
      ),
};
