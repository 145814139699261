import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import ScrollRestoration from '../components/ScrollRestoration';
import { scrollTo } from '../UI';
import { mainStore } from '../stores/MainStore';
import { catalogStore } from '../stores/CatalogStore';
import htmlClasses from 'html-classes';
import ProductList from '../components/ProductList';
import Skeleton from 'react-loading-skeleton';
import { Product } from '../types/Product/interface';

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [products, setProducts] = useState<Product[] | null>(null);
  const [isSuitableAppVersion, setIsSuitableAppVersion] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const handleSearchLink = () => {
    catalogStore.setSearchQuery('');
    mainStore.sendToRN('analytics', {
      name: 'Catalog: search clicked',
      params: {
        source: 'category',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'catalog_search_clicked',
      params: {
        source: 'category',
      },
    });
  };
  const handleShowNotFindProductPopover = () => mainStore.setIsNotFindProductPopover(true);
  const handleScrollTop = () => scrollTo('scroll-layout', 0, 200);

  useEffect(() => {
    catalogStore
      .requestBestsellers(true)
      .then((e) => {
        setProducts(e);
        if (!e.length) return;
        const isSuitableAppVersionNew = mainStore.isSuitableAppVersion('1.0.12');
        if (!isSuitableAppVersionNew) mainStore.setIsOutdatedAppPopover(true);
        setIsSuitableAppVersion(isSuitableAppVersionNew);
        mainStore.sendToRN('analytics', {
          name: 'Catalog: Bestsellers visited',
          params: {
            source: 'homepage',
          },
        });
        mainStore.sendToRN('firebaseAnalytics', {
          name: 'catalog_bestsellers_visited',
          params: {
            source: 'homepage',
          },
        });
      })
      .catch((error) => error && console.error(error));
  }, []);

  return (
    <>
      {products && isReady && <ScrollRestoration pageName="bestsellers" />}
      <div className="d-flex h-50 flex-shrink-0 align-items-center">
        <div
          className="icon icon-arrow-back d-flex flex-center s-50 fs-20 c-black"
          onClick={() => (history.length === 1 ? history.push('/') : history.goBack())}
        />
        <h3 className="text-center w-100p text-truncate">{t('bestsellers')}</h3>
        <Link
          className="icon icon-search d-flex flex-center s-50 fs-20"
          to={{ pathname: '/search', state: { source: 'category' } }}
          onClick={handleSearchLink}
        />
      </div>
      <div className={htmlClasses('scroll-layout h-100p px-24', { 'overflow-hidden': !products })}>
        {products !== null ? (
          products.length > 0 ? (
            <>
              <ProductList
                productList={products}
                source="bestsellers_slider"
                onReady={() => setIsReady(true)}
                showThreeInLine
              />
              {isSuitableAppVersion && (
                <div
                  className="button _bordered w-100p mt-22"
                  onClick={handleShowNotFindProductPopover}
                >
                  {t('categoryPage:canNotFindItems')}
                </div>
              )}
              <div
                className={htmlClasses(
                  'button _bordered w-100p',
                  isSuitableAppVersion ? 'mt-10' : 'mt-22',
                )}
                onClick={handleScrollTop}
              >
                {t('backToTop')}
              </div>
            </>
          ) : (
            <div className="text-center p-28 fs-14 c-gray">{t('comingSoon')}</div>
          )
        ) : (
          <div className="d-flex mx-n4 flex-wrap">
            {[1, 2, 3, 4].map((subitem, j) => (
              <div className="w-50p px-4 pb-8 flex-shrink-0" key={j}>
                <Skeleton className="product-item br-8 h-200" />
              </div>
            ))}
          </div>
        )}
        <div className="h-24" />
      </div>
    </>
  );
});
