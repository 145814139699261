import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import LogoJiffy from '../assets/img/logo_jiffy.svg';
import LogoBaqal from '../assets/img/logo_baqal.svg';
import LogoSwifftB from '../assets/img/logo_swifft_b.png';
import PageHeader from '../components/PageHeader';
import { mainStore } from '../stores/MainStore';
import ExternalLink from '../components/ExternalLink';
import { desktopStore } from '../stores/DesktopStore';
import { CompanyName } from '../company/interface';
import { company } from '../company/Company';

type LinkItem = {
  title: string;
  page: string;
  link: string;
};

const logo: Record<CompanyName, string> = {
  [CompanyName.Jiffy]: LogoJiffy,
  [CompanyName.Baqal]: LogoBaqal,
  [CompanyName.Swifft]: LogoSwifftB,
};

export default observer(() => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const linkList: LinkItem[] = [
    {
      title: t('aboutPage:termsAndConditions'),
      page: 'terms',
      link: company.config.links.terms,
    },
    {
      title: t('aboutPage:securityPolicy'),
      page: 'privacy',
      link: company.config.links.privacy,
    },
    {
      title: t('aboutPage:privacyAndCookiesPolicy'),
      page: 'cookie',
      link: company.config.links.cookie,
    },
  ];
  const getAppVersion = (): string => {
    let version = mainStore.appVersion;
    if (!version) return t('unknown');
    if (mainStore.appBuildVersion) version += `-${mainStore.appBuildVersion}`;
    if (mainStore.appCodePushVersion) version += `(${mainStore.appCodePushVersion})`;
    return version;
  };

  const handleClick = (item: LinkItem) => {
    desktopStore.setShowAbout(false);
    history.push({
      pathname: location.pathname,
      search: '?modal=inject',
      state: { page: item.page },
    });
    desktopStore.setShowInject(true);
  };

  const links = linkList.reduce((acc: JSX.Element[], item, i) => {
    if (item.link) {
      acc.push(
        <div className="sm-item" key={i}>
          {!mainStore.isDesktop ? (
            <ExternalLink
              className="c-blue"
              to={{ pathname: '/inject', state: { page: item.page } }}
              href={item.link}
            >
              {item.title}
            </ExternalLink>
          ) : (
            <div className="c-blue cursor-pointer" onClick={() => handleClick(item)}>
              {item.title}
            </div>
          )}
        </div>
      );
    }

    return acc;
  }, [])

  return (
    <>
      {!mainStore.isDesktop && <PageHeader title={t('about')} />}
      <div className="scroll-layout h-100p px-24 fs-14 fw-500">
        <img className="d-block mt-10 w-150" src={logo[company.name]} alt="" />
        <div className="mt-16">
          {t('aboutPage:appVersion', { version: getAppVersion() })}
        </div>
        {mainStore.environment !== 'production' && (
          <>
            <div className="mt-10">
              Environment: {mainStore.environment === 'staging' ? 'Staging' : 'Development'}
            </div>
          </>
        )}
        <div className="mt-35 smt-16">
          {links}
          <div className="sm-item c-gray">
            <Trans i18nKey="aboutPage:contactEmail">
              If you have any questions or suggestions please contact
              <a
                href={`mailto:${company.config.email}`}
                className="c-blue"
              >
                {company.config.email}
              </a>
            </Trans>
          </div>
        </div>
        <div className="h-50" />
      </div>
    </>
  );
});
