import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Popover from './Popover';
import { catalogStore, CartItem } from '../stores/CatalogStore';
import { mainStore } from '../stores/MainStore';
import { orderStore } from '../stores/OrderStore';
import LineThroughWrap from './LineThroughWrap';
import Dialog from './Dialog';
import noProductImg from '../assets/img/no_picture_product.jpg';
import htmlClasses from 'html-classes';

type ListItemsProps = {
  item: CartItem;
};

export default observer(() => {
  const { t } = useTranslation();
  const handleDismiss = () => {
    mainStore.setIsShowOutStockPopover(false);
    catalogStore.emptyOutStock();
    if (orderStore.activeGift && orderStore.cartPriceForGift) {
      orderStore.setActiveGift(null);
    }
  };

  const component = (
    <>
      <div className="overflow-auto mx-n24">
        {catalogStore.outStockProductList.length > 0 && (
          <div className="position-relative">
            <h2 className="px-24 pt-20 pb-10 position-sticky t-0 z-4 c-bg-white">
              {t('cartPage:outStock')}
            </h2>
            {catalogStore.outStockProductList.map((item) => (
              <ListItem item={item} key={item.id} />
            ))}
          </div>
        )}
        {catalogStore.changePriceProductList.length > 0 && (
          <div className="position-relative">
            <h2 className="px-24 pt-20 pb-10 position-sticky t-0 z-4 c-bg-white">
              {t('cartPage:itemsPriceChanged')}
            </h2>
            {catalogStore.changePriceProductList.map((item) => (
              <ListItem item={item} key={item.id} />
            ))}
          </div>
        )}
        {catalogStore.changeCountProductList.length > 0 && (
          <div className="position-relative">
            <h2 className="px-24 pt-20 pb-10 position-sticky t-0 z-4 c-bg-white">
              {t('cartPage:itemsCountChanged')}
            </h2>
            {catalogStore.changeCountProductList.map((item) => (
              <ListItem item={item} key={item.id} />
            ))}
          </div>
        )}
        {catalogStore.adultProductList.length > 0 && (
          <div className="position-relative">
            <h2 className="px-24 pt-20 pb-10 position-sticky t-0 z-4 c-bg-white">
              {t('cartPage:adultProducts')}
            </h2>
            {catalogStore.adultProductList.map((item) => (
              <ListItem item={item} key={item.id} />
            ))}
          </div>
        )}
        {orderStore.activeGift !== null && orderStore.cartPriceForGift.length > 0 && (
          <div className="position-relative">
            <h2 className="px-24 pt-20 pb-10 position-sticky t-0 z-4 c-bg-white">
              {t('cartPage:andNoMoreGift')}
            </h2>
            <div className="cart-item d-flex py-16 overflow-hidden position-relative c-bg-tf7 px-24 mt-4">
              <div
                className="w-44 flex-shrink-0 img-contain position-relative"
                style={{
                  backgroundImage:
                    orderStore.activeGift.preview_image_thumb || orderStore.activeGift.preview_image
                      ? `url(${
                        orderStore.activeGift.preview_image_thumb ||
                        orderStore.activeGift.preview_image
                      })`
                      : 'none',
                }}
              />
              <div className="px-8 w-100p fs-12 lh-16">
                <div className="mb-4">{orderStore.activeGift.name || ''}</div>
                <div className="c-mgray">
                  {t('cartPage:cartLessThanRequiredToReceiveGift')}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex-shrink-0">
        <div className="d-flex align-items-start justify-content-between fs-12 mt-12">
          <div className="c-tad mr-20">{t('etaPopover:deliveryFee')}:</div>
          <div className="c-udgray">
            <LineThroughWrap isWrapped={catalogStore.isFreeDelivery}>
              {mainStore.addCurrencySymbol(orderStore.fee.shippingPounds || 0)}
            </LineThroughWrap>
          </div>
        </div>
        <div className="h-1 pe-n c-bg-tf2 my-8" />
        <div className="d-flex align-items-start justify-content-between fs-12">
          <div className="c-tad mr-20">{t('cartPage:newTotal')}:</div>
          <div className="c-udgray">{mainStore.addCurrencySymbol(catalogStore.finalPrice)}</div>
        </div>
        <div className="button _primary w-100p mt-12" onClick={handleDismiss}>
          {t('understood')}
        </div>
      </div>
    </>
  );

  if (!mainStore.isDesktop) {
    return (
      <Popover
        className="d-flex flex-direction-column overflow-hidden pt-0"
        isShow={mainStore.isShowOutStockPopover}
        onBackdropDismiss={handleDismiss}
      >
        {component}
      </Popover>
    );
  } else {
    return (
      <Dialog onBackdrop={handleDismiss} show={mainStore.isShowOutStockPopover}>
        {component}
      </Dialog>
    );
  }
});

const ListItem = observer(({ item }: ListItemsProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={htmlClasses("cart-item d-flex py-16 overflow-hidden position-relative c-bg-tf7 px-24 mt-4", {
        '_fashion': !item.isGrocery,
      })}
    >
      <div
        className="w-44 flex-shrink-0 img-contain position-relative"
        style={{
          backgroundImage:
            item.previewImageThumb
              ? `url(${item.previewImageThumb})`
              : `url(${noProductImg})`,
        }}
      />
      <div className='px-8 w-100p d-flex flex-direction-column align-items-start'>
        <div className='fs-12 lh-16 mb-4 flex-shrink-0'>
          {item.name}
        </div>

        {!item.isGrocery && (
          <>
            {item.properties.color_text && (
              <div className='product-item__colors flex-shrink-0 mb-5'>
                <div className='product-item__colors-label mr-6 ml-0'>{t('color')}:</div>
                <div
                  className={htmlClasses('product-item__colors-item', {
                    '_border': mainStore.isColorLight(item.properties.color as string || ''),
                    '_multicolor': (item.properties.color_text as string).toLowerCase() === 'multicolor',
                  })}
                  style={{ backgroundColor: item.properties.color as string || undefined }}
                />
                <div className='product-item__colors-label'>
                  {item.properties.color_text}
                </div>
              </div>
            )}
            {item.properties.size && (
              <div className='product-item__colors flex-shrink-0 mb-5'>
                <div className='product-item__colors-label ml-0'>{t('size')}:</div>
                <div className='product-item__colors-label'>
                  {item.properties.size}
                </div>
              </div>
            )}
          </>
        )}

        {item.discountPercentage?.length > 0 && (
          <>
            {item.isGrocery ? (
              <div className='br-3 c-bg-ulred fs-8 p-5 mb-4 c-red flex-shrink-0 lh-1'>
                {t('specialOffer')}: <span className='fw-500'>{item.discountPercentage}</span>
              </div>
            ) : (
              <div className='flex-shrink-0 ff-feixen-book fs-12 c-gray'>
                {item.discountPercentage} off
              </div>
            )}
          </>
        )}
        {item.isGrocery && item.promoQuantityDiscountPrice > 0 && (
          <div className='br-3 c-bg-ulred fs-8 p-5 mb-4 c-red flex-shrink-0 lh-1'>
            {t('specialOffer')}:{' '}
            <span className='fw-500'>
              {mainStore.addCurrencySymbol(item.promoQuantityDiscountPriceFormatted)} {t(
              'from')} {item.promoRequiredQuantity}
              {t('pcs')}
            </span>
          </div>
        )}
        {item.isGrocery ? (
          <div className='mt-12 flex-shrink-0 d-flex align-items-center'>
            {item.discountPrice > 0 ? (
              <div className='product-item__price _discount fs-12 flex-shrink-0'>
                {mainStore.addCurrencySymbol(item.discountPriceFormatted)}
                <s className='product-item__price-old fs-10'>
                  {mainStore.addCurrencySymbol(item.priceFormatted)}
                </s>
              </div>
            ) : catalogStore.getPrice(item) !== item.price ? (
              <div className='product-item__price _discount fs-12 flex-shrink-0'>
                {mainStore.addCurrencySymbol(item.promoQuantityDiscountPriceFormatted)}
                <s className='product-item__price-old fs-10'>
                  {mainStore.addCurrencySymbol(item.priceFormatted)}
                </s>
              </div>
            ) : (
              <div className='product-item__price fs-12 flex-shrink-0'>
                {mainStore.addCurrencySymbol(item.priceFormatted)}
              </div>
            )}
            <div className='fs-12 c-t99'>· {item.pricePerUnit}</div>
          </div>
        ) : (
          <div className='flex-shrink-0 d-flex align-items-center mt-12'>
            {item.discountPrice > 0 ? (
              <div className='product-item__price fs-12 flex-shrink-0'>
                {mainStore.addCurrencySymbol(item.discountPriceFormatted)}
              </div>
            ) : catalogStore.getPrice(item) !== item.price ? (
              <div className='product-item__price fs-12 flex-shrink-0'>
                {mainStore.addCurrencySymbol(item.promoQuantityDiscountPriceFormatted)}
              </div>
            ) : (
              <div className='product-item__price fs-12 flex-shrink-0'>
                {mainStore.addCurrencySymbol(item.priceFormatted)}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
});
