import React, { ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { urlModalQueryName, desktopStore } from '../stores/DesktopStore';
import { toJS } from 'mobx';

interface DialogProps {
  show: boolean;
  children: ReactNode;
  onBackdrop?: () => void;
  maxWidth?: string;
  minWidth?: string;
  minHeight?: string;
  modalParam?: typeof urlModalQueryName[number];
  idParam?: string;
  rejectable?: boolean;
}

export default observer(
  ({
    show = false,
    children,
    onBackdrop,
    maxWidth,
    minWidth = '500px',
    minHeight,
    modalParam,
    idParam,
    rejectable = true,
  }: DialogProps) => {
    const location = useLocation();
    const history = useHistory();
    const matchCatalog = useRouteMatch('/');
    const matchCategory = useRouteMatch('/category/:slug');
    const matchDiscounts = useRouteMatch('/discounts');
    const matchCheckout = useRouteMatch('/checkout');
    const matchStatus = useRouteMatch('/status/:id');
    const matchSearch = useRouteMatch('/search/:query');
    const matchBannerPage = useRouteMatch('/banner/:id');
    const matchTrackingWarehouse= useRouteMatch('/tracking-warehouse');
    const matchReferral = useRouteMatch('/referral');

    const handleClose = () => {
      onBackdrop && onBackdrop();
      if (modalParam) {
        const query = desktopStore.searchQuery;
        query.delete('modal');
        query.delete('id');
        const pathname = location.pathname + '?' + query.toString();
        if (matchDiscounts) {
          if (desktopStore.lastSavedDiscountsState?.subCategories) {
            const state = toJS(desktopStore.lastSavedDiscountsState);
            desktopStore.setLastSavedDiscountsState(null);
            history.push(pathname, state);
          }
        } else {
          history.push(pathname);
        }
      }
    };

    useEffect(() => {
      if (
        matchCatalog?.isExact ||
        matchCheckout?.isExact ||
        matchStatus?.isExact ||
        matchCategory?.isExact ||
        matchSearch?.isExact ||
        matchBannerPage?.isExact ||
        matchDiscounts?.isExact ||
        matchTrackingWarehouse?.isExact ||
        matchReferral?.isExact
      ) {
        const query = desktopStore.searchQuery;
        if (modalParam && show && !query.has('modal')) {
          query.append('modal', modalParam);
          if (idParam && show && !query.has('id')) {
            query.append('id', idParam);
          }
          history.push(location.pathname + '?' + query.toString());
        }
      }
      //eslint-disable-next-line
    }, [show]);

    if (!show) {
      return null;
    }

    return (
      <div className="dialog">
        <div className="dialog__bg" onClick={rejectable ? handleClose : () => null} />
        <div
          className="dialog__body"
          style={{ minWidth: minWidth, minHeight: minHeight, maxWidth }}
        >
          {rejectable && (
            <div
              className="icon icon-close fw-500 dialog__icon-close s-32 fs-18 c-mgray d-flex flex-center cursor-pointer z-1"
              onClick={handleClose}
            />
          )}
          {children}
        </div>
      </div>
    );
  },
);
