import { observer } from 'mobx-react-lite';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ContentLayout from '../components/ContentLayout';
import About from './About';
import Auth from './Auth/Auth';
import Cart from './Cart';
import Category from './Category';
import Checkout from './Checkout';
import DeliveryTracking from './DeliveryTracking';
import Favorites from './Favorites';
import Main from './Main';
import Notifications from './Notifications';
import Order from './Order';
import Orders from './Orders';
import Product from './Product';
import Profile from './Profile';
import Search from './Search';
import Status from './Status';
import PaymentMethods from './PaymentMethods';
import { userStore } from '../stores/UserStore';
import Banner from './Banner';
import Discounts from './Discounts';
import Bestsellers from './Bestsellers';
import WarehouseTracking from './WarehouseTracking';
import { orderStore } from '../stores/OrderStore';
import Stories from './StoriesPage';
import ReferralPage from './ReferralPage';
import JiffyPoints from './JiffyPoints/JiffyPoints';

export default observer(() => {
  return (
    <ContentLayout>
      <Switch>
        <Route path="/" component={Main} exact />
        <Route path="/about" component={About} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/stories/:id" component={Stories} />
        <Route path="/referral" component={ReferralPage} />
        <Route path="/points" component={JiffyPoints} />
        <Route path="/payment-methods" component={userStore.isAuthorized ? PaymentMethods : Auth} />
        <Route path="/orders" component={userStore.isAuthorized ? Orders : Auth} />
        <Route path="/order/:id" component={userStore.isAuthorized ? Order : Auth} />
        <Route path="/profile" component={userStore.isAuthorized ? Profile : Auth} />
        <Route path="/status/:id" component={userStore.isAuthorized ? Status : Auth} />
        <Route path="/tracking/:id" component={userStore.isAuthorized ? DeliveryTracking : Auth} />
        <Route path="/tracking-warehouse" component={userStore.isAuthorized ? WarehouseTracking : Auth} />
        <Route path="/auth" component={Auth} />
        {!orderStore.etaCalculation && <Redirect to="/delivery-address" />}
        <Route path="/category/:subCat2Id" component={Category} />
        <Route path="/discounts" component={Discounts} />
        <Route path="/bestsellers" component={Bestsellers} />
        <Route path="/banner/:bannerId" component={Banner} />
        <Route path="/product/:productId" component={Product} />
        <Route path="/search" component={Search} />
        <Route path="/cart" component={Cart} />
        <Route path="/favorites" component={Favorites} />
        <Route path="/checkout" component={userStore.isAuthorized ? Checkout : Auth} />
        <Redirect to="/" />
      </Switch>
    </ContentLayout>
  );
});
