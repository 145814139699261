import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import NotFound from '../assets/img/not_found.svg';
import useDebounce from '../components/DebounceHook';
import InputText from '../components/InputText';
import ProductList from '../components/ProductList';
import ScrollRestoration from '../components/ScrollRestoration';
import { catalogStore } from '../stores/CatalogStore';
import { mainStore } from '../stores/MainStore';
import htmlClasses from 'html-classes';
import { scrollTo } from '../UI';
import { SearchCatsResponse } from '../api/Catalog';
import { SEARCH_CATEGORY_STANDARD_LENGTH, SEARCH_PRODUCTS_STANDARD_LENGTH } from '../config';
import { orderStore } from '../stores/OrderStore';
import { company } from '../company/Company';
import { CompanyName } from '../company/interface';


type HistoryAndPopularProps = {
  onSetSuggest: (value: string, source: 'idea' | 'hint' | 'history') => void;
  isLoading: boolean;
  showHistory?: boolean;
};

interface CategoriesSuggestionProps {
  showHistory: boolean;
  onSelectCategory: (category: SearchCatsResponse) => void;
}

export const HistoryAndPopular = observer(
  ({ onSetSuggest, isLoading, showHistory = false }: HistoryAndPopularProps) => {
    const { t } = useTranslation();
    // TODO remove?
    const [ideasList, setIdeasList] = useState<string[]>([]);
    const handleClearSearchHistory = () => {
      catalogStore.deleteSearchHistory().catch((error) => error && console.error(error));
    };

    useEffect(() => {
      if (!company.isIncludedIn([CompanyName.Jiffy])) return;

      if (!orderStore.etaWarehouseCode) {
        setIdeasList(catalogStore.searchIdeasList);
        return;
      }

      setIdeasList(catalogStore.searchIdeasList);
    }, [orderStore.etaWarehouseCode]);


    if (isLoading && !mainStore.isDesktop) return <div className="spinner mx-auto mt-20" />;

    if (!showHistory) return <></>;

    return (
      <div className={!mainStore.isDesktop ? 'mx-n8' : 'px-10 mt-10'}>
        {catalogStore.searchHistory.length > 0 && (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <div className="fs-16 lh-24 fw-500">{t('searchPage:searchHistory')}</div>
              <div
                className="fs-14 ml-14 c-blue fw-500 cursor-pointer"
                onClick={handleClearSearchHistory}
              >
                {t('clear')}
              </div>
            </div>
            <div className="mx-n6 mb-20 d-flex flex-wrap">
              {catalogStore.searchHistory.map((item, i) => (
                <div
                  className="px-12 lh-30 mt-12 mx-6 br-8 c-bg-bg border-linegray fs-14 fw-500 text-nowrap text-truncate overflow-hidden w-max-100p cursor-pointer"
                  onClick={() => onSetSuggest(item, 'history')}
                  key={i}
                >
                  {item}
                </div>
              ))}
            </div>
          </>
        )}
        {ideasList.map((item, i) => (
          <>
            <div className="fs-16 lh-24 fw-500 mb-12">{t('searchPage:popular')}</div>
            <React.Fragment key={i}>
              {i > 0 && <div className="h-1 c-bg-tf2 my-12" />}
              <div
                className="d-flex align-items-center fs-14 fw-500 cursor-pointer"
                onClick={() => onSetSuggest(item, 'idea')}
              >
                {item}
              </div>
            </React.Fragment>
          </>
        ))}
        <div className="h-24" />
      </div>
    );
  },
);

export const CategoriesSuggestion = observer(
  ({ showHistory = false, onSelectCategory }: CategoriesSuggestionProps) => {
    const { t } = useTranslation();
    const [showAll, setShowAll] = useState(false);

    const handleToggle = () => {
      setShowAll((prev) => !prev);
    };

    if (showHistory) return <></>;

    if (!catalogStore.searchCatsList.length) return <></>;

    return (
      <div className="mx-n8 mb-24 overflow-hidden">
        {catalogStore.searchCatsList
          .slice(0, showAll ? undefined : SEARCH_CATEGORY_STANDARD_LENGTH)
          .map((item, i) => (
            <React.Fragment key={i}>
              <div
                className="d-flex align-items-center fs-14 fw-500 cursor-pointer"
                onClick={() => onSelectCategory(item)}
              >
                <span className="text-nowrap">{item.name}</span>
                {item.parentCatName && (
                  <span className="ml-2 c-gray text-truncate">
                    {'• '}
                    {item.parentCatName}
                  </span>
                )}
              </div>
              <div className="h-1 c-bg-tf2 my-12" />
            </React.Fragment>
          ))}
        {catalogStore.searchCatsList.length > SEARCH_CATEGORY_STANDARD_LENGTH && (
          <div className="text-center c-blue fw-500 fs-14 cursor-pointer" onClick={handleToggle}>
            {t(`show${showAll ? 'Less' : 'More'}`)}
          </div>
        )}
      </div>
    );
  },
);

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state } = useLocation<{ source?: string }>();
  const refSearchField = useRef<HTMLInputElement>(null);
  const [searchVal, setSearchVal] = useState(catalogStore.searchQuery || '');
  const [isFocused, setIsFocused] = useState(!catalogStore.searchQuery);
  const [isBlur, setIsBlur] = useState(!!catalogStore.searchQuery);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuitableAppVersion, setIsSuitableAppVersion] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const debSearchVal = useDebounce<string>(searchVal, 500);
  const handleShowNotFindProductPopover = () => mainStore.setIsNotFindProductPopover(true);
  const handleScrollTop = () => scrollTo('scroll-layout', 0, 200);

  useEffect(() => {
    if (history.action === 'PUSH') catalogStore.resetProductsOutOfStockExpandedList();
    mainStore.sendToRN('analytics', {
      name: 'Catalog: search visited',
      params: {
        source: state?.source || 'direct',
      },
    });
    mainStore.sendToRN('firebaseAnalytics', {
      name: 'catalog_search_visited',
      params: {
        source: state?.source || 'direct',
      },
    });
  }, [state, history.action]);

  useEffect(() => {
    if (debSearchVal.length < 2 || !isFocused) return;
    setIsLoading(true);
    setShowAllProducts(false);
    catalogStore
      .requestSearch(debSearchVal, 'typein', SEARCH_PRODUCTS_STANDARD_LENGTH)
      .catch((error) => error && console.error(error))
      .finally(() => setIsLoading(false));
    //eslint-disable-next-line
  }, [debSearchVal]);

  useEffect(() => {
    if (!isBlur) return;
    const timeout = setTimeout(() => {
      setIsFocused(false);
    }, 100);
    return () => clearTimeout(timeout);
  }, [isBlur]);

  useEffect(() => {
    catalogStore.requestSearchHistory().catch((error) => error && console.error(error));
    const isSuitableAppVersionNew = mainStore.isSuitableAppVersion('1.0.12');
    if (!isSuitableAppVersionNew) mainStore.setIsOutdatedAppPopover(true);
    setIsSuitableAppVersion(isSuitableAppVersionNew);
    if (!catalogStore.searchQuery) return;
    if (history.action === 'POP' && catalogStore.searchProductList.length) return;
    handleSearchSubmit();
    //eslint-disable-next-line
  }, []);

  const search = () => {
    if (!searchVal.length) return;
    refSearchField.current?.blur();
    setShowAllProducts(true);
    setIsLoading(true);
    catalogStore.resetProductsOutOfStockExpandedList();
    catalogStore
      .requestSearch(searchVal, 'typein', 0)
      .catch((error) => error && console.error(error))
      .finally(() => setIsLoading(false));
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchVal(e.currentTarget.value);
    catalogStore.setSearchQuery(e.currentTarget.value);
  };
  const handleSearchClear = () => {
    setSearchVal('');
    catalogStore.setSearchQuery('');
    refSearchField.current?.focus();
  };
  const handleSearchFocus = () => {
    setIsFocused(true);
    setIsBlur(false);
  };
  const handleSelectCategory = (category: SearchCatsResponse) => {
    setIsFocused(false);
    if (category.parentCatID) {
      mainStore.sendToRN('analytics', {
        name: 'Catalog: category visited',
        params: {
          lvl3_category_id: category.id,
          source: 'search',
        },
      });
    }
    history.push({
      pathname: `/category/${category.parentCatID ? category.parentCatID : category.id}`,
      state: { scrollToId: category.parentCatID ? String(category.id) : null },
    });
  };
  const handleSetSuggestion = (value: string, source: 'idea' | 'hint' | 'history') => {
    setSearchVal(value);
    catalogStore.setSearchQuery(value);
    setIsFocused(false);
    setIsLoading(true);
    mainStore.setPageScrollY('search', 0);
    catalogStore.resetProductsOutOfStockExpandedList();
    catalogStore
      .requestSearch(value, source, SEARCH_PRODUCTS_STANDARD_LENGTH)
      .catch((error) => error && console.error(error))
      .finally(() => setIsLoading(false));
  };
  const handleSearchBlur = () => {
    setIsBlur(true);
  };
  const handleSearchSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    search();
  };

  return (
    <>
      <div className="d-flex search-bar__wrap flex-shrink-0 align-items-center">
        <div
          className="icon icon-arrow-back d-flex flex-center s-50 fs-20 c-black cursor-pointer"
          onClick={() => (history.length === 1 ? history.push('/') : history.goBack())}
        />
        <form className="w-100p mr-16" onSubmit={handleSearchSubmit}>
          <InputText className="search-bar" value={searchVal}>
            <div className="icon icon-search search-bar__icon-search c-t99 d-flex flex-center fs-20 pe-n" />
            {searchVal.length > 0 && (
              <div
                className="icon icon-close s-24 fs-18 c-t99 d-flex flex-center cursor-pointer"
                onClick={handleSearchClear}
              />
            )}
            <input
              className="input-text"
              type="text"
              ref={refSearchField}
              placeholder={t('searchPage:inputPlaceholder')}
              onChange={handleSearchChange}
              autoFocus={!catalogStore.searchQuery}
              value={searchVal}
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
            />
          </InputText>
        </form>
      </div>
      <div className="h-16 flex-shrink-0" />
      <div className="scroll-layout h-100p px-24">
        {!searchVal && !mainStore.isDesktop ? (
          <HistoryAndPopular
            onSetSuggest={handleSetSuggestion}
            isLoading={isLoading}
            showHistory={!searchVal}
          />
        ) : (
          <>
            {isLoading ? (
              <div className="spinner mx-auto mt-20" />
            ) : catalogStore.searchProductList.length > 0 && searchVal.length > 1 ? (
              <>
                {isReady && <ScrollRestoration pageName="search" />}
                <CategoriesSuggestion
                  onSelectCategory={handleSelectCategory}
                  showHistory={!searchVal}
                />
                <ProductList
                  productList={catalogStore.searchProductList}
                  source="search"
                  showThreeInLine
                  onReady={() => setIsReady(true)}
                />
                {!showAllProducts && catalogStore.searchProductList.length >= 30 && (
                  <div className="button _bordered fw-500 w-100p mt-22 fw-500" onClick={search}>
                    {t('seeAllProducts')}
                  </div>
                )}
                {isSuitableAppVersion && showAllProducts && (
                  <div
                    className="button _bordered fw-500 w-100p mt-22"
                    onClick={handleShowNotFindProductPopover}
                  >
                    {t('categoryPage:canNotFindItems')}
                  </div>
                )}
                {showAllProducts && (
                  <div
                    className={htmlClasses(
                      'button _bordered fw-500 w-100p',
                      isSuitableAppVersion ? 'mt-10' : 'mt-22',
                    )}
                    onClick={handleScrollTop}
                  >
                    {t('backToTop')}
                  </div>
                )}
                <div className="h-24" />
              </>
            ) : (
              <>
                <div className="mt-35">
                  <img className="h-130 mx-auto d-block" src={NotFound} alt="" />
                  <div className="fs-21 fw-500 w-230 text-center mt-24 mx-auto">
                    {t('searchPage:productNotExist')}
                  </div>
                </div>
                <div className="h-24" />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
});
