import React, { useLayoutEffect, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { mainStore } from '../stores/MainStore';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { catalogStore } from '../stores/CatalogStore';
import { CategoryDescription } from './Category';
import ProductList from '../components/ProductList';
import Skeleton from 'react-loading-skeleton';
import { desktopStore } from '../stores/DesktopStore';
import { useTranslation } from 'react-i18next';
import { runInAction } from 'mobx';
import { orderStore } from '../stores/OrderStore';
import { Category } from '../types/Category/interface';
import 'swiper/swiper.scss';

export default observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const [products, setProducts] = useState<Category[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { pathname } = useLocation<{ isDeeplink: boolean }>();
  const { state } = useLocation<{ scrollToId?: string | null }>();
  const [isReady, setIsReady] = useState(false);
  const match = matchPath<{ slug: string }>(pathname, {
    path: '/category/:slug',
    exact: true,
    strict: false,
  });
  const handleShowNotFindProductPopover = () => mainStore.setIsNotFindProductPopover(true);

  useLayoutEffect(() => {
    if (!mainStore.isDesktop) {
      history.replace('/');
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (state && state?.scrollToId) {
      const el = document.querySelector(`.product-list__header#subcategory-${state.scrollToId}`);
      // desktopStore.setSelectedSubCat(item.name);
      el && el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      const hiddenTop = document.querySelector('.desktop-top-hidden');
      hiddenTop && hiddenTop.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [state, isReady]);

  useEffect(() => {
    if (history.action === 'PUSH') catalogStore.resetProductsOutOfStockExpandedList();
    setIsLoading(true);
    catalogStore
      .requestProducts(match?.params.slug || '', false)
      .then((e) => {
        if (!e) {
          desktopStore.setSelectedCategory('');
          setProducts([]);
          return;
        }
        desktopStore.setSelectedCategory(e.categoryName);
        setProducts(e.subcategory);
        runInAction(() => {
          catalogStore.subCategories = e.subcategory;
        });
        if (match?.params.slug) {
          mainStore.sendAnalyticsToFirebase({
            name: 'view_item_list',
            params: {
              item_list_name: e.categoryName,
              item_list_id: match.params.slug,
            },
          });
          mainStore.sendAnalyticsToFirebase({
            name: 'catalog_category_visited',
            params: {
              category: match.params.slug,
              source: 'homepage',
            },
          });
        }
        setIsLoading(false);
      })
      .catch(() => {
        desktopStore.setSelectedCategory('');
        setProducts([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, orderStore.etaWarehouseCode]);

  useEffect(() => {
    if (!match?.params.slug) return;
    mainStore.sendToRN('analytics', {
      name: 'Catalog: category visited',
      params: {
        category: match.params.slug,
        source: 'homepage',
      },
    });
    // mainStore.sendToRN('sendTags', {
    //   last_category_interest_time: Math.floor(Date.now() / 1000),
    // });
    // mainStore.sendToRN('sendTags', {
    //   last_category_interest_lv2: subCat2Id,
    // });
  }, [match?.params.slug]);

  if (products?.length && !isLoading) {
    return (
      <>
        <div className="desktop-top-hidden" />
        <div className="overflow-auto h-min-550" style={{ scrollPaddingTop: '104px' }}>
          {products.filter((o) => o.products.length).length ? (
            products.map((o, i) =>
              o.products.length ? (
                <React.Fragment key={i}>
                  <div
                    className="desktop__title product-list__header c-bg-white mb-10"
                    id={`subcategory-${String(o.id)}`}
                  >
                    {o.name}
                    <span className="c-gray"> {o.products.length}</span>
                  </div>
                  <CategoryDescription name={o.name} description={o.description || ''} categoryId={o.id} />
                  <ProductList
                    productList={o.products}
                    source="category"
                    categoryId={o.id}
                    onReady={() => setIsReady(true)}
                  />
                </React.Fragment>
              ) : null,
            )
          ) : (
            <div className="text-center p-28 fs-14 fw-500 c-gray">{t('comingSoon')}</div>
          )}
          <div
            className="button _bordered fw-500 w-100p mt-10"
            onClick={handleShowNotFindProductPopover}
          >
            {t('categoryPage:canNotFindItems')}
          </div>
        </div>
      </>
    );
  } else {
    if (products && products.length === 0 && !isLoading) {
      return (
        <div className="text-center p-28 fs-14 fw-500 c-gray h-min-600">
          {t('comingSoon')}
        </div>
      );
    }

    return (
      <>
        <div className="desktop__title ml-20 mb-10">
          <Skeleton className="fs-24 fw-500 w-50p h-100p" />
        </div>
        <div className="desktop__grid-wrapper">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((_item, i) => (
            <div className="desktop__grid-item" key={i}>
              <Skeleton className="product-item br-8 h-200" />
            </div>
          ))}
        </div>
      </>
    );
  }
});
