import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import ExternalLink from '../../../components/ExternalLink';
import React from 'react';
import { mainStore } from '../../../stores/MainStore';
import { desktopStore } from '../../../stores/DesktopStore';
import { useHistory, useLocation } from 'react-router-dom';
import { company } from '../../../company/Company';

const TermsAndConditions = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const handleInjectClick = (page: string) => {
    desktopStore.setShowLogin(false);
    history.push({
      pathname: location.pathname,
      search: '?modal=inject',
      state: { page },
    });
    desktopStore.setShowInject(true);
  };

  return (
    <>
      <div className="mt-35 text-center fs-12 lh-16 c-dgray w-max-310 mx-auto">
        <Trans i18nKey="authPage:terms">
          By continuing, you accept our
          {!mainStore.isDesktop ? (
            <ExternalLink
              className="c-dgray text-underline"
              to={{ pathname: '/inject', state: { page: 'terms' } }}
              href={company.config.links.terms}
            >
              Terms and Conditions
            </ExternalLink>
          ) : (
            <span
              onClick={() => handleInjectClick('terms')}
              className="c-dgray text-underline cursor-pointer"
            >
                      Terms and Conditions
                    </span>
          )}
          and
          {!mainStore.isDesktop ? (
            <ExternalLink
              className="c-dgray text-underline"
              to={{ pathname: '/inject', state: { page: 'privacy' } }}
              href={company.config.links.privacy}
            >
              Privacy policy
            </ExternalLink>
          ) : (
            <span
              onClick={() => handleInjectClick('privacy')}
              className="c-dgray text-underline cursor-pointer"
            >
                      Privacy policy
                    </span>
          )}
          .
        </Trans>
      </div>

      <div className="text-center fs-12 lh-16 c-dgray w-max-310 mx-auto mt-5">
        {t('authPage:personalData')}{' '}
        <Trans i18nKey="authPage:moreInfo">
          For more information
          {!mainStore.isDesktop ? (
            <ExternalLink
              className="c-dgray text-underline"
              to={{ pathname: '/inject', state: { page: 'privacy' } }}
              href={company.config.links.privacy}
            >
              see here
            </ExternalLink>
          ) : (
            <span
              onClick={() => handleInjectClick('privacy')}
              className="c-dgray text-underline cursor-pointer"
            >
                      see here
                    </span>
          )}
          .
        </Trans>
        <p>
          <Trans i18nKey="authPage:protectedByReCAPTCHA">
            <ExternalLink
              className="c-dgray text-underline"
              href="https://www.google.com/intl/en/policies/privacy/"
            >
              privacy
            </ExternalLink>

            <ExternalLink
              className="c-dgray text-underline"
              href="https://www.google.com/intl/en/policies/terms/"
            >
              terms
            </ExternalLink>
          </Trans>
        </p>
      </div>
    </>
  );
});

export default TermsAndConditions;
