import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory } from 'react-router-dom';
import { IStory } from '../api/Stories';
import { storiesStore } from '../stores/StoriesStore';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperClass from 'swiper/types/swiper-class';
import htmlClasses from 'html-classes';
import { mainStore } from '../stores/MainStore';

let isPause = false;
let pointerEvent: PointerEvent | null;
let isClick = false;

export default observer(() => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [progressWidth, setProgressWidth] = useState(0);
  const [story, setStory] = useState<IStory | null>(null);
  const handleSkip = () => {
    story && currentSlide === story.items.length - 1 && storiesStore.setStoryViewed(story.id);
    history.push('/');
  };
  const handleSlideChange = (swiper: SwiperClass) => {
    setCurrentSlide(swiper.activeIndex);
  };
  const handleLinkAction = (link: string, sendAnalytics = false) => {
    if (!story) return;
    if (sendAnalytics) {
      mainStore.sendToRN('analytics', {
        name: 'Stories: button clicked',
        params: {
          id: story.id,
          conversion: link,
        },
      });
    }
    const externalLinkReg = new RegExp('^(http|https)://', 'i');
    if (externalLinkReg.test(link)) {
      if (window.ReactNativeWebView) {
        mainStore.sendToRN('openInAppBrowser', { url: link });
      } else window.location.href = link;
      history.replace('/');
    } else history.replace(link);
  };
  const handleFinishStory = () => {
    if (!story) return;
    storiesStore.setStoryViewed(story.id);
    if (story.endStory) {
      handleLinkAction(story.endStory);
      return;
    }
    const nextStoryId = storiesStore.getNextStoryId(story.id);
    if (nextStoryId) history.replace(`/stories/${nextStoryId}`);
    else history.push('/');
  };
  const handleTouchStart = (swiper: SwiperClass, event: TouchEvent | MouseEvent | PointerEvent) => {
    isPause = true;
    pointerEvent = event as PointerEvent;
  };
  const handleTouchEnd = (swiper: SwiperClass, event: TouchEvent | MouseEvent | PointerEvent) => {
    isPause = false;
    if (!pointerEvent) {
      isClick = false;
      return;
    }
    isClick =
      pointerEvent.offsetX === (event as PointerEvent).offsetX &&
      pointerEvent.offsetY === (event as PointerEvent).offsetY &&
      (event as PointerEvent).timeStamp - pointerEvent.timeStamp <= 100;
  };
  const handleChangeSlide = (direction: 'prev' | 'next', force = false) => {
    if (!force && !isClick) return;
    if (!swiper || !story) return;
    let nextSlide = currentSlide;
    if (direction === 'next') nextSlide++;
    else nextSlide--;
    if (nextSlide < 0) {
      const prevStoryId = storiesStore.getPreviousStoryId(story.id);
      if (prevStoryId) {
        history.replace(`/stories/${prevStoryId}`);
        return;
      } else nextSlide = 0;
    }
    if (nextSlide >= story.items.length) handleFinishStory();
    swiper.slideTo(nextSlide, 500);
  };
  const handleSwipeToEnd = () => {
    if (!currentSlide) handleChangeSlide('prev', true);
    else handleChangeSlide('next', true);
  };

  useEffect(() => {
    if (!id) {
      history.replace('/');
      return;
    }
    const story = storiesStore.getStoryById(id);
    if (!story) {
      history.replace('/');
      return;
    }
    setStory(story);
    storiesStore.setStoryOpened(id);
    mainStore.sendToRN('analytics', {
      name: 'Stories: Open',
      params: {
        id: id,
        conversion: story.endStory,
        display_name: story.name,
      },
    });
    //eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (!swiper || !story) return;
    setCurrentSlide(0);
    setProgressWidth(0);
    swiper.slideTo(0, 0);
  }, [swiper, story]);

  useEffect(() => {
    if (!swiper || !story || story.duration === 0) return;
    let timeElapsed = 0;
    setProgressWidth(0);
    const timer = setInterval(() => {
      if (isPause) return;
      timeElapsed += 100;
      const slideDuration = story.duration * 1000;
      setProgressWidth(Math.ceil((timeElapsed * 100) / slideDuration));
      if (timeElapsed >= slideDuration) handleChangeSlide('next', true);
    }, 100);
    return () => clearInterval(timer);
    //eslint-disable-next-line
  }, [currentSlide, swiper, story]);

  if (!story) return <></>;

  return (
    <div className="content-layout pt-0">
      <div className="story-navigation-bar">
        <div className="story-progress">
          {story.items.map((el, i) => (
            <div
              className={htmlClasses('story-progress__item', {
                _active: i === currentSlide,
                _passed: i < currentSlide,
              })}
              key={i}
            >
              <div style={{ width: i === currentSlide ? `${progressWidth}%` : '0' }} />
            </div>
          ))}
        </div>

        <div className="story__close" onClick={handleSkip} />
      </div>

      <Swiper
        className="w-100p h-100p story__slider"
        slidesPerView={1}
        onSlideChange={handleSlideChange}
        onSwiper={(swiper) => setSwiper(swiper)}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onSlideResetTransitionEnd={handleSwipeToEnd}
      >
        <div className="story__control _prev" onClick={() => handleChangeSlide('prev')} />
        <div className="story__control _next" onClick={() => handleChangeSlide('next')} />
        {story.items.map((slide, i) => (
          <SwiperSlide key={i}>
            <div className="story__slide" style={{ backgroundImage: `url(${slide.image})` }}>
              {slide.buttons.length > 0 && (
                <div className="story__btn-wrap">
                  <div
                    className="button stories-action-button"
                    style={storiesStore.getStylesForButton(slide.buttons[0])}
                    onClick={() => slide.buttons[0].action && handleLinkAction(
                      slide.buttons[0].action, true)}
                  >
                    {slide.buttons[0].text}
                  </div>
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
});
